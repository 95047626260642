<div class="container-fluid nopadding">
    <div class="d-flex flex-column flex-root h-100">
        <!--begin::Login-->
        <div class="d-flex flex-column flex-lg-row flex-column-fluid bg-white">
            <!--begin::Aside-->

            <div class="d-flex col-lg-5 flex-column nopadding">
                <img *ngIf="coverPage != ''" [src]="coverPage" />
            </div>

            <div
                class="nopadding col-lg-7 mt-8 mt-lg-8 flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden">
                <!--begin::Content body-->
                <div class="d-flex flex-column-fluid flex-center mx-8 login">
                    <div class="">
                        <div class="pb-13 pt-lg-0 pt-5 text-center">
                            <div class="areaLogo">
                                <img
                                    src="assets/images/forza-delivery-logo.png" />
                                <img
                                    src="assets/images/envio-facil-entrega-rapida-color.png" />
                            </div>
                        </div>

                        <form [formGroup]="user">
                            <div class="mb-8">
                                <app-input
                                    type="email"
                                    title="Usuario"
                                    placeholder="Ingresar usuario"
                                    (keyup.enter)="corporateLogin()"
                                    (formReady)="addForm('user', $event)"
                                    [validators]="validate.user">
                                </app-input>
                            </div>
                            <div class="mb-8">
                                <app-input
                                    (onClickIcon)="OnclickEye()"
                                    type="{{
                                        showIconEye ? 'text' : 'password'
                                    }}"
                                    title="Contraseña"
                                    placeholder="Ingresar Contraseña"
                                    (keyup.enter)="corporateLogin()"
                                    suffix="{{
                                        showIconEye ? iconEyeClose : iconEye
                                    }}"
                                    (formReady)="addForm('password', $event)"
                                    [validators]="validate.password"
                                    [regex]="null">
                                </app-input>
                            </div>
                        </form>

                        <div class="pb-lg-0 pb-5 mt-4">
                            <button
                                class="btn btn-outline-warning"
                                (click)="corporateLogin()"
                                [disabled]="user.invalid">
                                Iniciar sesión
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <label class="version">v.{{ version }}</label>
</div>
