// html-to-image.service.ts
import { Injectable } from '@angular/core';
import * as html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root',
})
export class GenerateImgFromHtmlService {
    async downloadContent(content: any, name: string) {
        const containerHTML = document.createElement('div');
        containerHTML.style.position = 'absolute';
        containerHTML.style.bottom = '1000000px';
        document.body.appendChild(containerHTML);
        containerHTML.innerHTML = content;
        // Utilizar html2canvas para convertir el content HTML a una imagen
        (html2canvas as any)(containerHTML).then(async (canvas) => {
            // Crear un enlace para descargar la imagen
            const a = document.createElement('a');
            a.href = canvas.toDataURL('image/png');
            a.download = name;
            a.hidden = true;
            a.click();
            document.body.removeChild(containerHTML);
        });
    }
}
