import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConnectionApiService } from './connection-api.service';
@Injectable({
    providedIn: 'root',
})
export class BillingService {
    constructor(private api: ConnectionApiService) {}

    manageBilling(body: any, loader: boolean = true): Observable<any> {
        return this.api.postData(body, loader);
    }

    billingRequest(body: any, loader: boolean = true): Observable<any> {
        return this.api.postDataPayment(body, loader);
    }
}
