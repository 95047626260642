import { Component, OnInit, Input } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
defineLocale('es', esLocale);

@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DateComponent,
            multi: true,
        },
    ],
})
export class DateComponent implements OnInit {
    @Input()
    myvalue: any;
    @Input()
    textLabel: string;
    @Input()
    placeholder: string;
    @Input()
    style: number;
    datePickerObj: any = {};
    @Input()
    control: any;
    touched: boolean = false;
    @Input()
    disabled: boolean = false;
    @Input()
    mask: Array<number>;
    @Input()
    separador: string;
    // Info: El campo regex debe iniciar con el caracter ^ y finalizar con $
    @Input()
    regex: string;
    @Input()
    fromDate: Date;
    @Input()
    toDate: Date;
    @Input()
    dateNow: Date;
    @Input()
    disabledDates: any;
    @Input() required: boolean = false;
    defaultCounter: number = 0;
    temporaryDefaultDate: any;
    contador = 0;
    construirFecha: boolean = false;
    fechaIngresada: string = '';
    model: NgbDateStruct;
    date: { year: number; month: number };

    colorTheme = 'theme-green';
    bsConfig: Partial<BsDatepickerConfig>;
    bsValue = new Date();

    constructor(private bsLocaleService: BsLocaleService) {
        this.disabled = true;
        this.fromDate = null;
        this.toDate = null;
        this.dateNow = new Date();
        this.bsLocaleService.use('es'); //fecha en español, datepicker
    }

    ngOnInit() {
        this.bsConfig = Object.assign(
            {},
            { containerClass: 'theme-orange' },
            { adaptivePosition: true },
            { showWeekNumbers: false }
        );
        this.myvalue = new Date();
        this.datePickerObj = {
            inputDate: this.dateNow, // fin
            fromDate: this.fromDate, // default null inicio
            toDate: this.toDate, // default null fin
            dateFormat: 'DD/MM/YYYY',
            showTodayButton: false,
            closeOnSelect: true,
            setLabel: 'Aceptar',
            todayLabel: '',
            closeLabel: 'Cerrar',
            titleLabel: 'SELECCIONE UNA FECHA',
            monthsList: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
            ],
            weeksList: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
            clearButton: true,
        };
    }

    onDateChange(newDate: Date) {
        if (this.defaultCounter == 0) {
            this.temporaryDefaultDate = newDate;
            this.defaultCounter++;
        }

        if (newDate) {
            if (this.defaultCounter == 0) {
                this.control.setValue(newDate);
            } else {
                if (this.temporaryDefaultDate != newDate) {
                    this.control.setValue(newDate);
                } else {
                    let date = new Date();
                    this.control.setValue(date);
                }
            }
            this.construirFecha = false;
        }
    }

    onChange = (_: any) => {};

    change2(event) {
        if (event.target.value !== null) {
            if (event.target.value.length === 1) {
                this.construirFecha = false;
            }
        }
        let fecha;
        if (this.control.status === 'VALID') {
            let separador = [];
            separador = event.target.value.toString().split('/');
            fecha = new Date(
                separador[1] + '/' + separador[0] + '/' + separador[2]
            );
            if (fecha.toString() === 'Invalid Date') {
                this.construirFecha = true;
                this.fechaIngresada = event.target.value;
                event.target.value = '';
            } else {
                const fechaIngresada = fecha.toLocaleDateString().split('/');
                const fechaInicio = this.fromDate
                    .toLocaleDateString()
                    .split('/');
                const fechaFin = this.dateNow.toLocaleDateString().split('/');
                if (
                    (separador[0] === '30' && separador[1] === '02') ||
                    (separador[0] === '31' && separador[1] === '02')
                ) {
                    // No toma en cuentra las fehcas 30/02/ y 31/02
                    this.construirFecha = true;
                    this.fechaIngresada = event.target.value;
                    event.target.value = '';
                } else if (
                    Number(separador[2]) % 4 === 0 &&
                    (Number(separador[2]) % 100 !== 0 ||
                        Number(separador[2]) % 400 === 0)
                ) {
                    if (
                        this.compararFechas(
                            fecha,
                            this.fromDate,
                            this.dateNow
                        ) === 2
                    ) {
                        this.construirFecha = true;
                        this.fechaIngresada = event.target.value;
                        event.target.value = '';
                    }
                } else {
                    if (separador[0] === '29' && separador[1] === '02') {
                        this.construirFecha = true;
                        this.fechaIngresada = event.target.value;
                        event.target.value = '';
                    } else {
                        if (
                            this.compararFechas(
                                fecha,
                                this.fromDate,
                                this.dateNow
                            ) === 2
                        ) {
                            this.construirFecha = true;
                            this.fechaIngresada = event.target.value;
                            event.target.value = '';
                        }
                    }
                }
            }
        } else {
            if (event.target.value) {
                if (event.target.value.length === 10) {
                    let separador = [];
                    separador = event.target.value.toString().split('/');
                    fecha = new Date(
                        separador[1] + '/' + separador[0] + '/' + separador[2]
                    );
                    if (fecha.toString() === 'Invalid Date') {
                        this.construirFecha = true;
                        this.fechaIngresada = event.target.value;
                        event.target.value = '';
                    } else {
                        if (
                            this.compararFechas(
                                fecha,
                                this.fromDate,
                                this.dateNow
                            ) === 2
                        ) {
                            this.construirFecha = true;
                            this.fechaIngresada = event.target.value;
                            event.target.value = '';
                        }
                    }
                }
            }
        }
        this.onChange(event.target.value);
    }

    compararFechas(fechaEn, fechaIni, fechaFin) {
        let ingresada: Date = new Date(fechaEn);
        let inicio: Date = new Date(fechaIni);
        let fin: Date = new Date(fechaFin);
        ingresada.setMilliseconds(0);
        inicio.setMilliseconds(0);
        fin.setMilliseconds(0);
        ingresada.setSeconds(0);
        inicio.setSeconds(0);
        fin.setSeconds(0);
        ingresada.setMinutes(0);
        inicio.setMinutes(0);
        fin.setMinutes(0);
        ingresada.setHours(0);
        inicio.setHours(0);
        fin.setHours(0);
        if (
            ingresada.getTime() > inicio.getTime() &&
            ingresada.getTime() < fin.getTime()
        ) {
            return 1;
        } else if (
            ingresada.getTime() < inicio.getTime() ||
            ingresada.getTime() > fin.getTime()
        ) {
            return 2;
        } else {
            return 3;
        }
    }

    change(event) {
        if (this.mask && this.separador) {
            this.writeValue(
                this.mascara(
                    event.target.value,
                    this.separador,
                    this.mask,
                    true
                )
            );
        }
        if (this.regex) {
            event.target.value = this.checkPattern(
                event.target.value,
                this.regex
            );
        }
        this.onChange(event.target.value);
    }

    checkPattern(value: string, pattern) {
        const patron = new RegExp(pattern, 'm');
        if (!patron.test(value)) {
            return value.substr(0, value.length - 1);
        }
        return value;
    }

    mascara(
        val: string,
        sep: string,
        pat: Array<number>,
        nums: boolean
    ): string {
        let val2 = '';
        const valAux = val.replace(sep, '').trim();
        const arrayMask = new Array();
        let newIndex = 0;
        let newEnd = 0;
        let codeChar;
        const code0: number = '0'.charCodeAt(0);
        const code9: number = '9'.charCodeAt(0);
        if (nums) {
            val2 = '';
            for (let index = 0; index < valAux.length; index++) {
                codeChar = valAux.charCodeAt(index);
                if (codeChar >= code0 && codeChar <= code9) {
                    val2 = val2 + valAux.charAt(index);
                }
            }
        }
        for (let s = 0; s < pat.length; s++) {
            newEnd = newIndex + pat[s];
            newEnd = newEnd > val2.length ? val2.length : newEnd;
            arrayMask[s] =
                newIndex > val2.length - 1
                    ? ''
                    : val2.substring(newIndex, newEnd);
            newIndex += pat[s];
        }
        for (let q = 0; q < arrayMask.length; q++) {
            if (q === 0) {
                val = arrayMask[q];
            } else {
                if (arrayMask[q] !== '' && arrayMask[q] !== sep) {
                    val += sep + arrayMask[q];
                }
            }
        }
        return val;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(obj: any): void {
        this.myvalue = obj;
    }
}
