// google-analytics.service.ts
import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    sendEvent(
        eventName: string,
        eventCategory: string,
        eventLabel: string = null,
        eventValue: any
    ) {
        gtag('event', eventName, {
            event_category: eventCategory,
            event_label: eventLabel,
            value: eventValue,
        });
    }
}
