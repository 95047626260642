<!-- START Sidebar (left)-->
<div class="aside-inner border-box-guide ml-2">
    <div class="card has-user-block" style="margin-bottom: 0 !important">
        <app-userblock></app-userblock>
    </div>
    <nav
        class="sidebar nav-size"
        sidebar-anyclick-close=""
        [class.show-scrollbar]="settings.getLayoutSetting('asideScrollbar')">
        <!-- START sidebar nav-->
        <ul class="sidebar-nav">
            <!-- START user info-->
            <!-- END user info-->
            <!--*ngFor="let menu of menuItems; let i = index"
                appJrAnchor="{{ stepsArrayNuevoPortal[i] }}"-->

            <li
                *ngFor="let item of menuItems; let i = index"
                appJrAnchor="{{ item.Path }}"
                [ngClass]="{ 'nav-heading': item.heading }"
                [routerLinkActive]="['active']">
                <!-- menu heading -->
                <span *ngIf="item.heading">{{
                    (item.translate | translate) || item.Module
                }}</span>
                <!-- external links -->
                <a
                    href
                    *ngIf="!item.heading && !item.SubModule && item.elink"
                    [attr.target]="item.target"
                    [attr.href]="item.elink"
                    title="{{ item.Module }}">
                    <span
                        class="float-right"
                        *ngIf="item.alert"
                        [ngClass]="item.label || 'badge badge-success'"
                        >{{ item.alert }}</span
                    >
                    <em class="{{ item.Icon }}" *ngIf="item.Icon"></em>
                    <span>{{
                        (item.translate | translate) || item.Module
                    }}</span>
                </a>
                <!-- single menu item -->
                <a
                    href
                    *ngIf="!item.heading && !item.SubModule && !item.elink"
                    [routerLink]="item.Path"
                    [attr.route]="item.Path"
                    title="{{ item.Module }}"
                    (click)="saveRoute(item.Path); toggleSubmenuClick($event)"
                    (mouseenter)="toggleSubmenuHover($event)">
                    <span
                        class="float-right b"
                        *ngIf="item.alert"
                        [ngClass]="item.label || 'badge badge-success'"
                        >{{ item.alert }}</span
                    >
                    <em class="{{ item.Icon }}" *ngIf="item.Icon"></em>
                    <span>{{
                        (item.translate | translate) || item.Module
                    }}</span>
                </a>
                <!-- has submenu -->
                <a
                    href
                    *ngIf="!item.heading && item.SubModule"
                    title="{{ item.Module }}"
                    (click)="toggleSubmenuClick($event)"
                    (mouseenter)="toggleSubmenuHover($event)">
                    <span
                        class="float-right"
                        *ngIf="item.alert"
                        [ngClass]="item.label || 'badge badge-success'"
                        >{{ item.alert }}</span
                    >
                    <em class="{{ item.Icon }}" *ngIf="item.Icon"></em>
                    <span>{{
                        (item.translate | translate) || item.Module
                    }}</span>
                </a>
                <!-- SUBLEVEL -->
                <ul
                    *ngIf="item.SubModule"
                    class="sidebar-nav sidebar-subnav"
                    [routerLinkActive]="['opening']">
                    <li class="sidebar-subnav-header">
                        {{ (item.translate | translate) || item.Module }}
                    </li>
                    <li
                        *ngFor="let subitem of item.SubModule"
                        [routerLinkActive]="['active']">
                        <!-- sublevel: external links -->
                        <a
                            href
                            *ngIf="
                                !subitem.heading &&
                                !subitem.SubModule &&
                                subitem.elink
                            "
                            [attr.target]="subitem.target"
                            [attr.href]="subitem.elink"
                            title="{{ subitem.Module }}">
                            <span
                                class="float-right"
                                *ngIf="subitem.alert"
                                [ngClass]="
                                    subitem.label || 'badge badge-success'
                                "
                                >{{ subitem.alert }}</span
                            >
                            <em
                                class="{{ subitem.Icon }}"
                                *ngIf="subitem.Icon"></em>
                            <span>{{
                                (subitem.translate | translate) ||
                                    subitem.Module
                            }}</span>
                        </a>
                        <!-- sublevel: single menu item  -->
                        <a
                            href
                            *ngIf="!subitem.SubModule && !subitem.elink"
                            [routerLink]="subitem.Path"
                            [attr.route]="subitem.Path"
                            (click)="saveRoute(subitem.Path)"
                            title="{{ subitem.Module }}">
                            <span
                                class="float-right"
                                *ngIf="subitem.alert"
                                [ngClass]="
                                    subitem.label || 'badge badge-success'
                                "
                                >{{ subitem.alert }}</span
                            >
                            <em
                                href
                                [routerLink]="subitem.Path"
                                [attr.route]="subitem.Path"
                                class="{{ subitem.Icon }}"
                                (click)="saveRoute(subitem.Path)"
                                *ngIf="subitem.Icon"></em>
                            <span
                                href
                                [routerLink]="subitem.Path"
                                [attr.route]="subitem.Path"
                                (click)="saveRoute(subitem.Path)"
                                >{{
                                    (subitem.translate | translate) ||
                                        subitem.Module
                                }}</span
                            >
                        </a>
                        <!-- sublevel: has submenu -->
                        <a
                            href
                            *ngIf="subitem.SubModule"
                            title="{{ subitem.Module }}"
                            (click)="toggleSubmenuClick($event)"
                            (mouseenter)="toggleSubmenuHover($event)">
                            <span
                                class="float-right"
                                *ngIf="subitem.alert"
                                [ngClass]="
                                    subitem.label || 'badge badge-success'
                                "
                                >{{ subitem.alert }}</span
                            >
                            <em
                                class="{{ subitem.Icon }}"
                                *ngIf="subitem.Icon"></em>
                            <span>{{
                                (subitem.translate | translate) ||
                                    subitem.Module
                            }}</span>
                        </a>
                        <!-- SUBLEVEL 2 -->
                        <ul
                            *ngIf="subitem.SubModule"
                            class="sidebar-nav sidebar-subnav level2"
                            [routerLinkActive]="['opening']">
                            <li
                                *ngFor="let subitem2 of subitem.SubModule"
                                [routerLinkActive]="['active']">
                                <!-- sublevel 2: single menu item  -->
                                <a
                                    href
                                    *ngIf="!subitem2.SubModule"
                                    [routerLink]="subitem2.Path"
                                    [attr.route]="subitem2.Path"
                                    title="{{ subitem2.Module }}">
                                    <span
                                        class="float-right"
                                        *ngIf="subitem2.alert"
                                        [ngClass]="
                                            subitem2.label ||
                                            'badge badge-success'
                                        "
                                        >{{ subitem2.alert }}</span
                                    >
                                    <em
                                        class="{{ subitem2.Icon }}"
                                        *ngIf="subitem2.Icon"></em>
                                    <span>{{
                                        (subitem2.translate | translate) ||
                                            subitem2.Module
                                    }}</span>
                                </a>
                                <!-- sublevel2: has submenu -->
                                <a
                                    href
                                    *ngIf="subitem2.SubModule"
                                    title="{{ subitem2.Module }}"
                                    (click)="toggleSubmenuClick($event)"
                                    (mouseenter)="toggleSubmenuHover($event)">
                                    <span
                                        class="float-right"
                                        *ngIf="subitem2.alert"
                                        [ngClass]="
                                            subitem2.label ||
                                            'badge badge-success'
                                        "
                                        >{{ subitem2.alert }}</span
                                    >
                                    <em
                                        class="{{ subitem2.Icon }}"
                                        *ngIf="subitem2.Icon"></em>
                                    <span>{{
                                        (subitem2.translate | translate) ||
                                            subitem2.Module
                                    }}</span>
                                </a>
                                <!-- SUBLEVEL 3 -->
                                <ul
                                    *ngIf="subitem2.SubModule"
                                    class="sidebar-nav sidebar-subnav level3"
                                    [routerLinkActive]="['opening']">
                                    <li
                                        *ngFor="
                                            let subitem3 of subitem2.SubModule
                                        "
                                        [routerLinkActive]="['active']">
                                        <!-- sublevel 2: single menu item  -->
                                        <a
                                            href
                                            *ngIf="!subitem3.SubModule"
                                            [routerLink]="subitem3.Path"
                                            [attr.route]="subitem3.Path"
                                            title="{{ subitem3.Module }}">
                                            <span
                                                class="float-right"
                                                *ngIf="subitem3.alert"
                                                [ngClass]="
                                                    subitem3.label ||
                                                    'badge badge-success'
                                                "
                                                >{{ subitem3.alert }}</span
                                            >
                                            <em
                                                class="{{ subitem3.Icon }}"
                                                *ngIf="subitem3.Icon"></em>
                                            <span>{{
                                                (subitem3.translate
                                                    | translate) ||
                                                    subitem3.Module
                                            }}</span>
                                        </a>
                                        <!-- sublevel3: has submenu -->
                                        <a
                                            href
                                            *ngIf="subitem3.SubModule"
                                            title="{{ subitem3.Module }}"
                                            (click)="toggleSubmenuClick($event)"
                                            (mouseenter)="
                                                toggleSubmenuHover($event)
                                            ">
                                            <span
                                                class="float-right"
                                                *ngIf="subitem3.alert"
                                                [ngClass]="
                                                    subitem3.label ||
                                                    'badge badge-success'
                                                "
                                                >{{ subitem3.alert }}</span
                                            >
                                            <em
                                                class="{{ subitem3.Icon }}"
                                                *ngIf="subitem3.Icon"></em>
                                            <span>{{
                                                (subitem3.translate
                                                    | translate) ||
                                                    subitem3.Module
                                            }}</span>
                                        </a>
                                        <!-- SUBLEVEL 4 -->
                                        <ul
                                            *ngIf="subitem3.SubModule"
                                            class="sidebar-nav sidebar-subnav level3"
                                            [routerLinkActive]="['opening']">
                                            <li
                                                *ngFor="
                                                    let subitem4 of subitem3.SubModule
                                                "
                                                [routerLinkActive]="['active']">
                                                <!-- sublevel 2: single menu item  -->
                                                <a
                                                    href
                                                    *ngIf="!subitem4.SubModule"
                                                    [routerLink]="subitem4.Path"
                                                    [attr.route]="subitem4.Path"
                                                    title="{{
                                                        subitem4.Module
                                                    }}">
                                                    <span
                                                        class="float-right"
                                                        *ngIf="subitem4.alert"
                                                        [ngClass]="
                                                            subitem4.label ||
                                                            'badge badge-success'
                                                        "
                                                        >{{
                                                            subitem4.alert
                                                        }}</span
                                                    >
                                                    <em
                                                        class="{{
                                                            subitem4.Icon
                                                        }}"
                                                        *ngIf="
                                                            subitem4.Icon
                                                        "></em>
                                                    <span>{{
                                                        (subitem4.translate
                                                            | translate) ||
                                                            subitem4.Module
                                                    }}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li></li>
                        </ul>
                    </li>

                    <li></li>
                </ul>
            </li>
        </ul>
        <!-- END sidebar nav-->
    </nav>
    <div
        class="col-12 footer-sidebar py-3 pl-4 text-left"
        (click)="authService.setLogout()">
        <i
            role="button"
            class="bi bi-box-arrow-in-left pr-2 h3 align-middle"></i>
        <label role="button" class="m-0 py-2">Cerrar Sesión</label>
    </div>
</div>
<!-- END Sidebar (left)-->
