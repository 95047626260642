import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EmittersService {
    private changeGuideNumbers: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    private realoadCart: EventEmitter<boolean> = new EventEmitter<boolean>();
    private onClickSendToStore: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    private onClickMap: EventEmitter<boolean> = new EventEmitter<boolean>();
    private onclickEdit: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    emitChangeGuideNumbers(value: boolean) {
        this.changeGuideNumbers.emit(value);
    }

    getChangeGuideNumbers() {
        return this.changeGuideNumbers;
    }

    emitRealoadCart(value: boolean = true) {
        this.realoadCart.emit(value);
    }

    getRealoadCart() {
        return this.realoadCart;
    }

    emitOnClickSendToStore(value: boolean = true) {
        this.onClickSendToStore.emit(value);
    }

    getOnClickSendToStore() {
        return this.onClickSendToStore;
    }

    emitGetLocation(value: boolean = true) {
        return this.onClickMap.emit(value);
    }

    emitClickEdit(value: boolean = true) {
        return this.onclickEdit.emit(value);
    }

    getLocation() {
        return this.onClickMap;
    }

    getAddressEdit() {
        return this.onclickEdit;
    }
}
