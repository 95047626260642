<div
    *ngIf="data.length > 0"
    class="container-fluid d-flex align-items-center container-slick">
    <ngx-slick-carousel
        #slickModal="slick-carousel"
        class="carousel my-0 py-0 h-100"
        id="carouselDetail"
        [config]="slideConfig">
        <div
            ngxSlickItem
            class="slide parcels-center my-0 py-0 h-100"
            *ngFor="let item of data; let i = index">
            <div class="w-100">
                <img
                    (click)="goToLink(item)"
                    (load)="getWindowWidth(true)"
                    class="w-100 image-carousel"
                    [ngClass]="{
                        'cursor-pointer':
                            item?.HyperlinkURL != '' && item?.HyperlinkURL
                    }"
                    [src]="
                        width == 'XSImageURL'
                            ? item.XSImageURL
                            : width == 'MDImageURL'
                            ? item.MDImageURL
                            : width == 'XLImageURL'
                            ? item.XLImageURL
                            : width == 'XXLImageURL'
                            ? item.XXLImageURL
                            : item.XXXLImageURL
                    "
                    alt="" />
            </div>
        </div>
    </ngx-slick-carousel>
    <i
        class="bi icon-arrow-left-white btnPrev cursor-pointer"
        *ngIf="data?.length > 1"
        (click)="next?.slickPrev()"></i>
    <i
        class="bi icon-arrow-left-white btnNext cursor-pointer"
        *ngIf="data?.length > 1"
        (click)="next?.slickNext()"></i>
</div>
