import { Injectable } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
//import { google } from '@agm/core/services/google-maps-types';
declare var google: any;
declare var $: any;
@Injectable({
    providedIn: 'root',
})
export class MapAddressPredictionService {
    private autocompleteService;
    constructor(private mapsAPILoader: MapsAPILoader) {
        this.mapsAPILoader.load().then(() => {
            this.autocompleteService =
                new google.maps.places.AutocompleteService();
        });
    }
    getSuggestAddress(address) {
        var deferred = $.Deferred();
        this.autocompleteService.getPlacePredictions(
            { input: address },
            (predictions, status) => {
                //let autocompleteItems = [];
                //predictions.forEach((prediction) => {
                //    autocompleteItems.push(prediction);
                //});
                if (status != google.maps.places.PlacesServiceStatus.OK) {
                    deferred.reject('Request failed: ' + status); // ***
                } else {
                    // ***
                    deferred.resolve({ predictions, status }); // ***
                }
            }
        );
        return deferred.promise();
    }
}
