import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DetectNavigationService {
    constructor() {}

    /**
     * @description Retorna el tipo de navegador
     * @author Randy Can
     * @date 16/08/2022
     * @returns {*}  {number}
     * @memberof DetectNavigationService
     */
    browser(): number {
        var sBrowser: number = 0,
            sUsrAg = navigator.userAgent;

        if (sUsrAg.indexOf('Chrome') > -1) {
            sBrowser = 1; //'Google Chrome';
        } else if (sUsrAg.indexOf('Safari') > -1) {
            sBrowser = 2; //'Apple Safari';
        } else if (sUsrAg.indexOf('Opera') > -1) {
            sBrowser = 3; //'Opera';
        } else if (sUsrAg.indexOf('Firefox') > -1) {
            sBrowser = 4; //'Mozilla Firefox';
        } else if (sUsrAg.indexOf('MSIE') > -1) {
            sBrowser = 5; //'Microsoft Internet Explorer';
        }
        return sBrowser;
    }
}
