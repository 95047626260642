<!-- <div class="mb-4" [formGroup]="formInput">
    <label for="validationServer01" class="pl-1 mb-1" *ngIf="label"
        ><strong>{{ this.label }}</strong
        ><span *ngIf="required" class="text-danger h4">*</span></label
    >
</div> -->
<div class="form-group col-xs-6 pt-2" id="form-group-map">
    <div class="d-flex justify-content-between">
        <label position="stacked" class="font-weight-bolder text-dark"
            >{{ labelMap }}
            <span
                *ngIf="requiredSymbol"
                title="Campo requerido"
                class="text-danger font-size-md"
                >{{ requiredSymbol }}</span
            >
            <span
                *ngIf="subtleAnnotation"
                class="text-muted font-size-sm font-weight-normal"
                >&nbsp;{{ subtleAnnotation }}</span
            >
        </label>
    </div>
    <div class="map-content">
        <div class="suggest-input px-3" *ngIf="showInputSuggest">
            <label class="text-dark font-weight-bolder"
                >Ingresar referencia
            </label>
            <input
                #InputSuggestElement
                (keyup)="UpdateSearchResults()"
                type="text"
                min="0"
                placeholder="Ingrese ubicación ej. zona 10, colonia, condominio, centro comercial etc..."
                max="50"
                min="2"
                class="form-control form-control-solid rounded-lg" />
            <div style="position: relative">
                <div
                    class="contentlist"
                    style="z-index: 2; position: absolute; width: 100%"
                    class="pt-0">
                    <ul class="list-group" draggable="false">
                        <li
                            (click)="this.selectedSuggestItem(option)"
                            *ngFor="let option of autocompleteAddressItems"
                            class="list-group-item"
                            style="cursor: pointer">
                            <em
                                _ngcontent-asb-103=""
                                class="fa {{ option.icon }}"></em>
                            {{ option.description }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <agm-map
            [style.height.px]="height"
            [latitude]="AUCKLAND.lat"
            [longitude]="AUCKLAND.lng"
            [restriction]="countryRestriction"
            [zoom]="zoom"
            [scrollwheel]="scrollwheel"
            [zoomControl]="zoomControl"
            [fullscreenControl]="fullscreenControl"
            [streetViewControl]="streetViewControl"
            [disableDefaultUI]="disableDefaultUI"
            (mapClick)="mapClick($event)"
            [draggable]="draggable"
            class="gmap">
            <agm-marker
                (dragEnd)="dragEndMarker($event, i)"
                *ngFor="let marker of markerList; index as i"
                [latitude]="marker.lat"
                [markerDraggable]="false"
                [longitude]="marker.lng"
                [iconUrl]="marker.icon"
                [label]="marker?.label"
                (markerClick)="markerClicked($event, marker)">
                <agm-info-window
                    *ngIf="marker?.tooltipText"
                    [isOpen]="marker?.isOpen">
                    <strong
                        ><label class="text-h2">{{
                            marker?.tooltipText
                        }}</label></strong
                    >
                </agm-info-window>
            </agm-marker>
        </agm-map>
    </div>
</div>
