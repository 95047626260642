<app-modal
    #modalselectListWithHeaders
    size="md"
    title="{{ title }}"
    [idModal]="idModal"
    [pt]="false">
    <div class="container headers-modal">
        <button
            *ngFor="let item of config"
            (click)="onClickHeader(item)"
            class="btn btn-outline-warning text-nowrap w-100 btnHeaders"
            [ngClass]="{ 'active text-white': item.selected }">
            {{ item.text }}
        </button>
    </div>
    <div class="container" *ngFor="let item of config">
        <ng-container *ngIf="item.selected">
            <div
                class="container headers-modal text-center mt-2 mb-1"
                *ngIf="item.headers.length > 0">
                <div
                    class="form-check w-100 btnHeaders"
                    *ngFor="let radio of item.headers; let i = index">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="RadiosSubHeaders"
                        id="SubHeader{{ i }}"
                        [value]="radio.value"
                        [checked]="radio.selected"
                        (click)="onClickSubHeader(item, radio)" />
                    <label
                        class="form-check-label"
                        for="SubHeader{{ i }}"
                        style="margin-top: 2px">
                        {{ radio.text }}
                    </label>
                </div>
            </div>
            <div class="row" *ngIf="item.showBarSearch">
                <div class="col">
                    <div class="input-group mb-3 mt-2">
                        <div class="input-group-prepend">
                            <span
                                (click)="onEnterSearch(item)"
                                class="input-group-text no-border-radius"
                                ><i class="fa fa-search"></i
                            ></span>
                        </div>
                        <input
                            #searchInput
                            autocomplete="off"
                            id="searchInput"
                            (keyup.enter)="onEnterSearch(item)"
                            [(ngModel)]="searchText"
                            type="text"
                            class="form-control no-border-radius"
                            placeholder="Búsqueda" />
                    </div>
                </div>
            </div>
            <div class="row contentlist">
                <div class="col">
                    <div
                        class="list-group mt-2"
                        *ngFor="let option of item.list">
                        <div class="row">
                            <div class="col-12">
                                <strong class="h5 ml-1 text-dark mb-0">{{
                                    option.text
                                }}</strong>
                            </div>
                            <div class="col-12 ml-1">
                                <label
                                    role="button"
                                    *ngIf="option.isReferredCustomer == '0'"
                                    class="text-h3"
                                    (click)="
                                        itemSelected(
                                            item,
                                            option,
                                            option.subList[0],
                                            1
                                        )
                                    "
                                    >Nueva dirección desde link&nbsp;<em
                                        (click)="
                                            itemSelected(
                                                item,
                                                option,
                                                option.subList[0],
                                                1
                                            )
                                        "
                                        *ngIf="option.isReferredCustomer == '0'"
                                        class="fa fa-plus-circle fa-1x box"></em
                                ></label>
                            </div>
                        </div>
                        <ul
                            *ngFor="let optionSubList of option.subList"
                            (click)="
                                itemSelected(item, option, optionSubList, 0)
                            "
                            class="list-group"
                            draggable="false"
                            style="cursor: pointer">
                            <li
                                #itemsOption
                                data-dismiss="modal"
                                class="list-group-item cursor-pointer mt-0"
                                draggable="true">
                                {{ optionSubList.text }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <!-- *ngFor="let option of list | filter: searchText" {{ option.text }}(click)="itemSelected(option)" -->
</app-modal>
