import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { Constants, environment } from '../../environments/environment';
import { Contract } from '../Storage/contract';
import { SecureSimpleStorage } from '../Storage/secure-simple-storage';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
declare var gtag;
@Injectable({
    providedIn: 'root',
})
export class ConnectionApiService {
    timer: any;
    public stopRequest: Subject<void> = new Subject<void>();
    loaderSwall: any = null;

    constructor(
        private secureStorage: SecureSimpleStorage,
        private http: HttpClient
    ) {}

    isLogged(): Observable<any> {
        return new Observable((observer: Observer<boolean>) => {
            this.secureStorage.get(Contract.user).subscribe(
                () => {
                    observer.next(true);
                    observer.complete();
                },
                () => {
                    observer.next(false);
                    observer.complete();
                }
            );
        });
    }

    refreshExpirationLogin() {
        this.isLogged().subscribe((result) => {
            if (result) {
                this.setIntervalLogin();
            }
        });
    }

    login(usuario): Observable<any> {
        this.setIntervalLogin();
        return this.secureStorage.save(Contract.user, JSON.stringify(usuario));
    }

    public setIntervalLogin() {
        // if (this.timer) {
        //     this.timer.unsubscribe();
        // }
        // let count = 0;
        // let time = 0; //Variable para determinar el tiempo de la sesión
        // if (environment.rol == 'Express') {
        //     time = Constants.sesionTimeExpress;
        // } else {
        //     time = Constants.sesionTime;
        // }
        // this.timer = interval(time).subscribe(() => {
        //     if (count === 0) {
        //         this.sweet
        //             .warningAlert(
        //                 'Sesión caducada',
        //                 '¿ Desea extender su sesión ?',
        //                 60000
        //             )
        //             .then((result) => {
        //                 if (result.isConfirmed) {
        //                     this.refreshExpirationLogin();
        //                 } else if (result.isDismissed) {
        //                     this.setLogout();
        //                 }
        //             });
        //     }
        //     count++;
        //     if (count === 10) {
        //         count = 1;
        //     }
        // });
    }

    MakeRequest(body: any | null): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            const options = {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
            };

            let requestStartTime = new Date();
            this.http
                .post(Constants.api, body, options)
                .pipe(takeUntil(this.stopRequest))
                .subscribe(
                    (response: any) => {
                        let requestEndTime = new Date();
                        let requestTimeResponse =
                            requestEndTime.valueOf() -
                            requestStartTime.valueOf();

                        let data: any = null;
                        let payload = null;
                        let responseD, rawData;
                        responseD = JSON.parse(response.d);

                        if (responseD) {
                            rawData = responseD.Data;
                            data = rawData ? JSON.parse(rawData) : null;
                            if (data && data.PayLoad) {
                                payload = data.PayLoad;
                            }
                        }

                        if (rawData == null) {
                            let publicMessage =
                                'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.';
                            let errorMessage = responseD.Message
                                ? responseD.Message
                                : publicMessage;

                            gtag('event', 'exception', {
                                description: errorMessage,
                                fatal: true,
                                non_interaction: true,
                                send_to: environment.googleAnalyticsTechnical,
                            });
                            observer.error(publicMessage);
                        } else {
                            if (
                                environment.googleAnalyticsTechnical &&
                                body.path
                            ) {
                                gtag('event', 'fdapi_call', {
                                    app: 'hermesweb',
                                    endpoint: body.path,
                                    time_response: requestTimeResponse,
                                    send_to:
                                        environment.googleAnalyticsTechnical,
                                });
                            }
                        }

                        observer.next(payload);
                        observer.complete();
                    },
                    (error) => {
                        if (environment.googleAnalyticsTechnical && body.path) {
                            try {
                                let message = body.path + ' - ';

                                if (typeof error.message == 'string') {
                                    message += error.message;
                                }

                                gtag('event', 'exception', {
                                    description: message,
                                    fatal: true,
                                    non_interaction: true,
                                    send_to:
                                        environment.googleAnalyticsTechnical,
                                });
                            } catch (err) {}
                        }
                        observer.error(
                            'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.'
                        );
                    }
                );
        });
    }

    postData(
        body: any | null,
        loader: boolean = true,
        textLoader: string = 'Cargando...'
    ): Observable<any> {
        this.refreshExpirationLogin();
        return new Observable((observer: Observer<Array<any>>) => {
            if (loader) {
                this.loader(textLoader);
            }
            let request = {
                path: body.path,
                data: JSON.stringify(body.data),
            };
            this.MakeRequest(request).subscribe(
                (data: any) => {
                    const resp = JSON.parse(data);
                    observer.next(resp);
                    observer.complete();
                    if (loader && this.loaderSwall) {
                        this.loaderSwall.close();
                        this.loaderSwall = null;
                    }
                },
                (error: any) => {
                    observer.error(error);
                    if (loader && this.loaderSwall) {
                        this.loaderSwall.close();
                        this.loaderSwall = null;
                    }
                }
            );
        });
    }

    loader(textLoader: string = 'Cargando...') {
        this.loaderSwall = Swal.fire({
            html: `<div
            class="hwa-fdworking hwa-spinner text-center pt-4 pb-4">
            <img
                src="assets/images/fd-icon.png"
                style="height: 75px; width: 75px"
                class="hwa-icon-loader" />
        </div>
        <span class='text-inverse-dark h4'><strong>${textLoader}</strong></span>`,
            showCancelButton: false,
            showConfirmButton: false,
            width: '280px',
            backdrop: true,
            allowOutsideClick: false,
        });
    }
    postDataPayment(body: any | null, loader: boolean = true): Observable<any> {
        //this.authService.refreshExpirationLogin();
        if (loader) {
            this.loader();
        }
        return new Observable((observer: Observer<Array<any>>) => {
            let request = {
                path: body.path,
                data: JSON.stringify(body.data),
            };
            this.MakeRequestPayment(request).subscribe(
                (data: any) => {
                    const resp = JSON.parse(data);

                    if (!resp.ObjectValue) {
                        observer.error(
                            'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.'
                        );
                    }

                    observer.next(resp.ObjectValue);
                    observer.complete();
                    if (loader && this.loaderSwall) {
                        this.loaderSwall.close();
                        this.loaderSwall = null;
                    }
                },
                (error: any) => {
                    observer.error(error);
                    if (loader && this.loaderSwall) {
                        this.loaderSwall.close();
                        this.loaderSwall = null;
                    }
                }
            );
        });
    }
    MakeRequestPayment(body: any | null): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            const options = {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                },
            };
            this.http.post(Constants.api, body, options).subscribe(
                (response: any) => {
                    let data = null;
                    let payload = null;

                    if (response.d) {
                        let rawData = JSON.parse(response.d).Data;
                        data = rawData ? JSON.parse(rawData) : null;
                        if (data.PayLoad) {
                            payload = data.PayLoad;
                        }
                    }

                    if (data == null || payload == null) {
                        observer.error(
                            'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.'
                        );
                    }
                    observer.next(payload);
                    observer.complete();
                },
                (error) => {
                    observer.error(
                        'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.'
                    );
                }
            );
        });
    }

    postDataWhitoutValidation(body: any | null): Observable<any> {
        // this.authService.refreshExpirationLogin();
        return new Observable((observer: Observer<Array<any>>) => {
            let request = {
                path: body.path,
                data: JSON.stringify(body.data),
            };
            this.MakeRequest(request).subscribe(
                (data: any) => {
                    const resp = JSON.parse(data);
                    observer.next(resp);
                    observer.complete();
                },
                (error: any) => {
                    observer.error(error);
                }
            );
        });
    }

    postDataPaymentHTML(body: any | null): Observable<any> {
        return new Observable((observer: Observer<Array<any>>) => {
            let request = {
                path: body.path,
                data: JSON.stringify(body.data),
            };
            this.MakeRequestPayment(request).subscribe(
                (data: any) => {
                    const resp = JSON.parse(data);
                    if (!resp.PayDetail) {
                        observer.error(
                            'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.'
                        );
                    }

                    observer.next(resp.PayDetail);
                    observer.complete();
                },
                (error: any) => {
                    observer.error(error);
                }
            );
        });
    }

    postDataTokenize(body: any | null): Observable<any> {
        return new Observable((observer: Observer<Array<any>>) => {
            let request = {
                path: body.path,
                data: JSON.stringify(body.data),
            };
            this.MakeRequest(request).subscribe(
                (data: any) => {
                    const resp = JSON.parse(data);
                    if (!resp.ObjectValue) {
                        observer.error(resp.ObjectValue[0].ErrorMsg);
                    }

                    observer.next(resp);
                    observer.complete();
                },
                (error: any) => {
                    observer.error(error);
                }
            );
        });
    }

    postDataPayment3DSecureGroup(body: any | null): Observable<any> {
        return new Observable((observer: Observer<Array<any>>) => {
            let request = {
                path: body.path,
                data: JSON.stringify(body.data),
            };
            this.MakeRequestPayment(request).subscribe(
                (data: any) => {
                    const resp = JSON.parse(data);

                    if (!resp.ObjectValue) {
                        observer.error(
                            'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.'
                        );
                    }

                    observer.next(resp);
                    observer.complete();
                },
                (error: any) => {
                    observer.error(error);
                }
            );
        });
    }

    getDataOAC(path: string, loader: boolean = true) {
        if (loader) {
            this.loader();
        }
        return new Observable((observer: Observer<any>) => {
            this.http.get(path).subscribe(
                (response) => {
                    observer.next(response);
                    observer.complete();
                    if (loader && this.loaderSwall) {
                        this.loaderSwall.close();
                        this.loaderSwall = null;
                    }
                },
                (error) => {
                    observer.error(
                        'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.'
                    );
                    if (loader && this.loaderSwall) {
                        this.loaderSwall.close();
                        this.loaderSwall = null;
                    }
                }
            );
        });
    }

    postDataOAC(path: string, body: any, loader: boolean = true) {
        if (loader) {
            this.loader();
        }
        return new Observable((observer: Observer<any>) => {
            this.http.post(path, body).subscribe(
                (response) => {
                    observer.next(response);
                    observer.complete();
                    if (loader && this.loaderSwall) {
                        this.loaderSwall.close();
                        this.loaderSwall = null;
                    }
                },
                (error) => {
                    observer.error(
                        'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.'
                    );
                    if (loader && this.loaderSwall) {
                        this.loaderSwall.close();
                        this.loaderSwall = null;
                    }
                }
            );
        });
    }

    putDataOAC(path: string, body: any, loader: boolean = true) {
        if (loader) {
            this.loader();
        }
        return new Observable((observer: Observer<any>) => {
            this.http.put(path, body).subscribe(
                (response) => {
                    observer.next(response);
                    observer.complete();
                    if (loader && this.loaderSwall) {
                        this.loaderSwall.close();
                        this.loaderSwall = null;
                    }
                },
                (error) => {
                    observer.error(
                        'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.'
                    );
                    if (loader && this.loaderSwall) {
                        this.loaderSwall.close();
                        this.loaderSwall = null;
                    }
                }
            );
        });
    }

    deleteDataOAC(path: string, loader: boolean = true) {
        if (loader) {
            this.loader();
        }
        return new Observable((observer: Observer<any>) => {
            this.http.delete(path).subscribe(
                (response) => {
                    observer.next(response);
                    observer.complete();
                    if (loader && this.loaderSwall) {
                        this.loaderSwall.close();
                        this.loaderSwall = null;
                    }
                },
                (error) => {
                    observer.error(
                        'Ha ocurrido un error al realizar la operación, intente de nuevo y si persiste comuníquese con soporte al cliente.'
                    );
                    if (loader && this.loaderSwall) {
                        this.loaderSwall.close();
                        this.loaderSwall = null;
                    }
                }
            );
        });
    }
}
