import { SparklineDirective } from './../../app/shared/directives/sparkline/sparkline.directive';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { ComponentCommunicationService } from 'src/utility/services/marketplace/component-communication.service';
import { SweetAlert } from 'src/utility/sweetAlert';

@Component({
    selector: 'app-dinamic-carousel',
    templateUrl: './dinamic-carousel.component.html',
    styleUrls: ['./dinamic-carousel.component.scss'],
    host: {
        '(window:resize)': 'getWindowWidth()',
    },
})
export class DinamicCarouselComponent implements OnInit, AfterViewInit {
    @Input() carousel: any;
    @Input() index: number = null;
    @ViewChild('slickModal') next?: SlickCarouselComponent;
    label: string = null;
    slideConfig = {
        slidesToShow: 1,
        infinite: true,
        // infinite: true,
        // autoplay: true,
        // autoplaySpeed: 10000,
        dots: true,
    };
    @Output() componentLoaded: EventEmitter<void> = new EventEmitter<void>();
    constructor(
        private componentCommunicationService: ComponentCommunicationService,
        private connectionApi: ConnectionApiService,
        private sweetAlert: SweetAlert,
        private router: Router
    ) {}

    ngAfterViewInit(): void {
        this.componentLoaded.emit();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.getWindowWidth();
        }, 100);
        this.label = this.carousel.Tag;
    }

    ngOnChanges(changes: any): void {}

    async viewDetail(idProduct: any, name: any) {
        this.router.navigateByUrl(
            `/detalle-producto/${name
                .replace(/\s+/g, '-')
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()}`
        );
    }

    getWindowWidth() {
        let element = document?.getElementById(
            `container-label-${this.index}`
        )?.clientWidth;
        if (element == undefined || !this.label) {
            return;
        }
        let font: number = 0;
        let bodyWidth = document?.body?.clientWidth;
        let largeText: boolean = this.label.length > 7;
        if (largeText) {
            font =
                bodyWidth < 340 ? (element * 200) / 215 : (element * 200) / 240;
            document.documentElement.style.setProperty(
                '--font-size-percentage2-large',
                `${font}%`
            );
        } else {
            font =
                bodyWidth < 340 ? (element * 200) / 160 : (element * 200) / 175;
            document.documentElement.style.setProperty(
                '--font-size-percentage2-short',
                `${font}%`
            );
        }
    }
}
