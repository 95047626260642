import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConnectionApiService } from './connection-api.service';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(private api: ConnectionApiService) {}

    login(body: any): Observable<any> {
        return this.api.postData(body);
    }

    resetpassword(body: any): Observable<any> {
        return this.api.postData(body);
    }

    passwordresettoken(body: any): Observable<any> {
        return this.api.postData(body);
    }

    newPassword(body: any): Observable<any> {
        let request = {
            path: body.path,
            data: JSON.stringify(body.data),
        };
        return this.api.MakeRequest(request);
    }

    GuiadeURL(body: any): Observable<any> {
        let request = {
            path: body.path,
            data: JSON.stringify(body.data),
        };
        return this.api.MakeRequest(request);
    }
}
