import { Injectable } from '@angular/core';
import { time } from 'console';

@Injectable()
export class MenuService {
    menuItems: Array<any>;
    secondMenuItems: Array<any>;

    constructor() {
        this.menuItems = [];
        this.secondMenuItems = [];
    }

    addMenu(
        items: Array<{
            Module: string;
            heading?: boolean;
            MenuId?: number;
            Path?: string; // internal route links
            elink?: string; // used only for external links
            target?: string; // anchor target="_blank|_self|_parent|_top|framename"
            Icon?: string;
            Rol?: string;
            alert?: string;
            SubModule?: Array<any>;
            NewFunction?: number;
        }>
    ) {
        items.forEach((item) => {
            if (item.MenuId === 2) {
                this.secondMenuItems.push(item);
            } else {
                this.menuItems.push(item);
            }
        });
    }

    getMenu() {
        return this.menuItems;
    }

    getSecondaryMenu() {
        return this.secondMenuItems;
    }

    clearMenu() {
        this.menuItems = [];
    }

    clearSecondaryMenu() {
        this.secondMenuItems = [];
    }
}
