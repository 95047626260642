<div class="card" *ngIf="isOffSideBar">
    <button
        class="btn btn-outline-warning mr-1 b w-80"
        *ngIf="btnPayment"
        (click)="redirectToPay()">
        <i class="bi bi-cash-stack h3"></i> {{ textFinalizarYEnviarPaquetes() }}
    </button>
    <label
        class="text-h2 mt-4 ml-1 d-flex justify-content-between"
        *ngIf="btnPayment">
        Detalle Carrito
        <span>{{ currencyFormat(amountTotal) }}</span>
    </label>
</div>
<div
    class="card"
    style="overflow-y: auto; overflow-x: hidden"
    [ngClass]="{ detailCard: isOffSideBar }">
    <div class="card-body pt-0">
        <div
            class="mb-3 border-bottom p-1 pt-0"
            *ngFor="let item of listProductsOfCart; let i = index">
            <div class="row">
                <div class="col-12 d-flex justify-content-between">
                    <label class="text-h1">
                        <strong class="text-warning active guide">
                            {{ item?.CatProductName }}
                        </strong>
                    </label>
                </div>
            </div>
            <div class="row mb-2 justify-content-between">
                <div class="col-12 col-sm-7 col-lg-6">
                    <div class="row">
                        <div
                            class="col-12 ml-0 pl-0"
                            [ngClass]="{
                                'col-sm-auto': isOffSideBar,
                                'col-12': !isOffSideBar
                            }">
                            <div class="col-12">
                                <label class="text-h3">
                                    Cantidad: 1 {{ item.CatProductName }}
                                </label>
                            </div>
                            <div class="col-12">
                                <label class="text-h3">
                                    Precio:
                                    {{ item.CurrencySymbol }}
                                    {{ item.CatProductCost | number : '1.2-2' }}
                                </label>
                            </div>
                            <div class="col-12" *ngIf="item.ExpirationProduct">
                                <label class="text-h3">
                                    Vigencia:
                                    {{ item.ExpirationProduct }}
                                </label>
                            </div>
                        </div>
                        <div
                            class="col-12 ml-0 pl-0"
                            *ngIf="
                                !isOffSideBar &&
                                item.CatProductDiscountValue != 0
                            ">
                            <div class="col-12">
                                <label class="text-h3">
                                    Descuento: Q.{{
                                        item.CatProductDiscountValue
                                            | number : '1.2-2'
                                    }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-5 col-lg-6 mt-2 mt-sm-0">
                    <div class="row d-flex justify-content-between">
                        <div class="col-auto" *ngIf="!isOffSideBar">
                            <label class="switch switch-lg m-0">
                                <label
                                    class="text-h3 mr-2 d-flex align-items-center"
                                    ><em
                                        class="icon-gift mr-2"
                                        style="width: 18px"></em
                                    >Envíar como regalo&nbsp;</label
                                >
                                <input
                                    id="inputIsGift{{ i }}"
                                    value="false"
                                    type="checkbox"
                                    (input)="changeInputGift(i)" />
                                <span></span>
                            </label>
                        </div>
                        <div class="col-12 mt-1 mb-2" *ngIf="!isOffSideBar">
                            <div class="row d-flex justify-content-end">
                                <div class="col-12">
                                    <app-input
                                        *ngIf="item.showGift"
                                        title="Ingrese el correo electrónico"
                                        (formReady)="
                                            addForm('inputGift' + i, $event)
                                        "
                                        (onChangeInput)="
                                            onChangeEmail($event, i)
                                        "
                                        [validators]="validator.gift"
                                        (onChangeInput)="
                                            sendValueForGiftInput(i)
                                        "></app-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="mt-2 col-12 d-flex justify-content-start align-items-center">
                    <button
                        class="btn btn-outline-danger btn-danger btn-delete-product px-4"
                        (click)="deleteGuideOfCart(item)">
                        <i class="bi bi-trash3"></i>
                        <span class="ml-2">
                            <label class="mb-0 cursor-pointer"> Quitar </label>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
