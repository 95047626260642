<div class="row d-flex justify-content-center align-items-center h-100 p-1">
    <div
        class="col-12 col-sm-12 col-md-3 co-lg-3 col-xl-3 p-0 d-flex justify-content-center">
        <a
            *ngFor="let item of footerInfo"
            class="buttonIcon"
            [href]="item.Link"
            target="_blank"
            rel="noopener noreferrer">
            <em class="bi {{ item.icon }} sizeIcon p-0 m-0"></em>
        </a>
    </div>
    <div
        class="col-12 col-sm-6 col-md-3 co-lg-3 col-xl-3 p-0 d-flex justify-content-center align-items-center">
        <a class="m-0" href="tel:{{ pbx }}">
            <span class="buttonIcon">
                <i class="bi bi-telephone-outbound-fill sizeIcon"></i>
            </span>
            <span class="iconText m-0">PBX: {{ pbx }}</span>
        </a>
    </div>
    <div
        class="col-12 col-sm-6 col-md-3 co-lg-3 col-xl-3 p-0 d-flex justify-content-center">
        <a (click)="goToTerms()" rel="noopener noreferrer">
            <span class="iconText cursor-pointer">Términos y Condiciones</span>
        </a>
    </div>
    <div
        class="col-12 col-sm-12 col-md-3 co-lg-3 col-xl-3 p-0 d-flex justify-content-center">
        <img
            class="imageLogoForza"
            src="./assets/img/footer/footer-forza-white.png" />
    </div>
</div>
