import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ComponentCommunicationService {
    private responseSubElemnt: BehaviorSubject<any> = new BehaviorSubject([]);
    private responseProductSearch: BehaviorSubject<any> = new BehaviorSubject(
        []
    );
    private responseForCategories: BehaviorSubject<any> = new BehaviorSubject(
        []
    );

    /**
     * @description Métodos para poder compartir responde de detalle de producto seleccionado en el homePage
     * @author Byron de León
     * @date 04/12/2023
     * @param {*} response
     */
    sendResponseProductDetail(response: any) {
        this.responseSubElemnt.next(response);
    }

    getResponseProductDetail() {
        return this.responseSubElemnt.asObservable();
    }

    sendResponseForProductSearch(typeSearch: any, response: any) {
        this.responseProductSearch.next([typeSearch, response]);
    }

    getResponseForProductSearch() {
        return this.responseProductSearch.asObservable();
    }

    sendResponseForCategories(loaded: boolean) {
        this.responseForCategories.next(loaded);
    }

    getResponseForCategories() {
        return this.responseForCategories.asObservable();
    }

    constructor() {}
}
