import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FormatService {
    constructor() {}

    formatAmount(amount) {
        var indice: number = 0;
        let formatedNumber = Number(amount).toFixed(2);
        let newAmount = String(formatedNumber).split('.');
        let auxAmount = newAmount[0];
        let amountFormated = '';
        for (let i = auxAmount.length - 1; i >= 0; i--) {
            amountFormated = String(auxAmount)[i] + amountFormated;
            indice++;
            if (indice >= 3 && i > 0) {
                indice = 0;
                amountFormated = ',' + amountFormated;
            }
        }
        return `${amountFormated}.${newAmount[1]}`;
    }

    camelize(str) {
        str = ' ' + str.trim().toLowerCase();
        return str
            .replace(/\s(.)/g, function ($1) {
                return $1.toUpperCase();
            })
            .replace(/^(.)/, function ($1) {
                return $1.toLowerCase();
            })
            .trim();
    }
}
