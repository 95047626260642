import { CustomStepInfo } from '../ngx-joyride/custom-step-info';

export const stepsArrayPortalSideBar: CustomStepInfo[] = [
    {
        stepId: 'step1',
        anchorId: '/mis-envios',
        header: '',
        text: 'Rastrea tus envíos.',
    },
    {
        stepId: 'step2',
        anchorId: '/individual/crear-guia/estandar',
        header: '',
        text: 'Genera guías y programa tus productos.',
    },
    {
        stepId: 'step3',
        anchorId: '/individual/crear-guia/cod',
        header: '',
        text: 'Genera guías y programa tus productos con COD.',
    },
    {
        stepId: 'step4',
        anchorId: '/individual/link-entrega',
        header: '',
        text: 'Permite a tus clientes adquirir tus productos.',
    },
];

export const stepsArrayPortalHeaderBar: CustomStepInfo[] = [
    {
        stepId: 'step5',
        anchorId: 'carrito',
        header: '',
        text: 'Paga tus recolecciones y entregas',
    },
];

export const stepsNuevoPortal = ['step1', 'step2', 'step3', 'step4', 'step5'];
