export const regexNumber = /[a-zA-Z\/\\|\[\]\{\}?><\s]/gi;
export const regexPhone = /[a-zA-Z\/\\|\[\]\{\}?><\.]/gi;
export const regexGuide = /[^0-9]/gi;
export const regexInt = /[^0-9]/gi;
export const regexMultipleGuide = /[^0-9],/gi;
export const regexTags = /[^a-zA-ZÀ-ÿ0-9\.]/gi;
export const emailRegexValidators =
    '[a-zA-Z0-9_.+-]+@([a-zA-Z0-9_-])+(\\.[a-zA-Z0-9_+-]+)+';
export const regexFilter = /[\\|\[\]\{\}?><]/gi;
export const regexOnlyNumber = /^[0-9]+$/;
