<div class="login-container p-0 m-0 bg-white">
    <div class="row w-100 p-0 m-0">
        <div class="col-lg-5 col-md-12 col-sm-12 col-12 p-0 m-0">
            <img
                [src]="
                    countrySelected.value === 'GT'
                        ? './assets/img/Login/login-marketplace.jpg'
                        : './assets/img/Login/login-marketplace-hn.jpg'
                "
                alt="Login Marge"
                class="imageSection p-0 m-0" />
        </div>
        <div
            class="col-lg-7 col-md-12 col-sm-12 col-12 form-container bg-white">
            <div class="row p-0">
                <div class="col-12 d-flex justify-content-center p-0 m-0">
                    <div class="areaLogo p-0">
                        <img src="assets/images/forza-delivery-logo.png" />
                        <img
                            src="assets/images/envio-facil-entrega-rapida-color.png" />
                    </div>
                </div>
            </div>
            <div
                class="w-100 container-select d-flex align-item-center justify-content-center mb-5">
                <div class="dropdown">
                    <button
                        class="dropdown-toggle-country w-100 text-left d-flex align-items-center justify-content-initial"
                        style="border: none"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false">
                        <em
                            [class]="countrySelected.icon + ' ml-3'"
                            style="width: 21px !important"></em
                        ><span class="ml-4">{{
                            countrySelected?.countryName
                        }}</span>
                        <img
                            class="ml-4"
                            src="/assets/icons/arrow-down.svg"
                            style="width: 24px" />
                    </button>
                    <div
                        class="dropdown-menu w-100"
                        *ngIf="countries.length > 0">
                        <ng-container *ngFor="let item of countries">
                            <div
                                class="w-100 dropdown-item cursor-pointer"
                                style="padding: 0.75rem 1.25rem"
                                (click)="selectCountry(item)">
                                <div class="w-100 d-flex align-items-center">
                                    <em
                                        [class]="item.icon"
                                        style="width: 21px !important"></em>
                                    <span
                                        class="ml-2 d-flex- align-items-center">
                                        {{ item.countryName }}
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="row bg-white">
                <div class="col-12 bg-white">
                    <form [formGroup]="user">
                        <div class="mb-8">
                            <app-inputv2
                                [autocomplete]="'email'"
                                formControlName="name"
                                [control]="user.controls.name"
                                [type]="'text'"
                                [style]="1"
                                [textLabel]="'Nombre de Usuario'"
                                [placeholder]="'Ingresar Usuario'"
                                [maxLength]="200">
                            </app-inputv2>
                        </div>
                        <div class="mb-8">
                            <app-inputv2
                                [showRecoveryPassword]="true"
                                formControlName="password"
                                [control]="user.controls.password"
                                [type]="'password'"
                                [style]="1"
                                [IsPassword]="true"
                                [textLabel]="'Contraseña'"
                                [placeholder]="'Ingresar Contraseña'"
                                [maxLength]="200"
                                (keyup.enter)="setLogin()">
                            </app-inputv2>
                        </div>
                    </form>
                    <div
                        class="w-100 d-flex justify-content-center align-items-center my-4"
                        [formGroup]="user">
                        Recordar Sesión
                        <div class="form-check form-switch">
                            <label class="switch m-0">
                                <input
                                    type="checkbox"
                                    formControlName="rememberUser" />
                                <span></span>
                            </label>
                        </div>
                    </div>
                    <div class="mb-2 mt-1">
                        <button
                            class="btn btn-warning w-100"
                            (click)="setLogin()"
                            [disabled]="!isDataValid || isLoading">
                            <span class="text-h2 text-white"
                                >Iniciar sesión</span
                            >
                        </button>
                    </div>
                    <div class="mb-2 mt-lg-2 mt-md-2">
                        <button
                            class="btn btn-custom w-100"
                            (click)="redirecToUrl()">
                            <span class="text-h2 text-warning"
                                >Crear cuenta</span
                            >
                        </button>
                    </div>
                    <div class="mt-3 d-flex float-right">
                        <span
                            class="text-h3 underline"
                            role="button"
                            (click)="changeRoute()">
                            Continuar sin iniciar sesión</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <label class="version">v.{{ version }}</label>
</div>
