import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from './select/select/select.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { ModalComponent } from './modal/modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2SearchPipe } from 'ng2-search-filter';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MapComponent } from './map/map.component';
import { AgmCoreModule } from '@agm/core';
import { Inputv2Component } from './inputv2/inputv2.component';
import { Selectv2Component } from './selectv2/selectv2.component';
import { environment } from 'src/environments/environment';
import { PasswordStrengthBarComponent } from './password-strength-bar/password-strength-bar.component';
import { ListModalComponent } from './list-modal/list-modal.component';
import { DateComponent } from './date/date.component';
import {
    BsDatepickerModule,
    BsDaterangepickerConfig,
} from 'ngx-bootstrap/datepicker';
import { CheckoutComponent } from './checkout/checkout.component';
import { DetailCartComponent } from './detail-cart/detail-cart.component';
import { ConfirmPaymentComponent } from './confirm-payment/confirm-payment.component';
import { CardManagementComponent } from './card-management/card-management.component';
import { FormCardManagementComponent } from './form-card-management/form-card-management.component';
import { ListCardManagementComponent } from './list-card-management/list-card-management.component';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxJoyrideModule } from 'src/utility/ngx-joyride/ngx-joyride.module';
import { JoyrideModule, JoyrideStepComponent } from 'ngx-joyride';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BannerCustomComponent } from './banner-custom/banner-custom.component';
import { ListModalWithHeadersComponent } from './list-modal-with-headers/list-modal-with-headers.component';
import { AskForHelpComponent } from './ask-for-help/ask-for-help.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { TabsModule, TabsetConfig } from 'ngx-bootstrap/tabs';
import { FormBillingComponent } from './form-billing/form-billing.component';
import { ViewCheckoutComponent } from './view-checkout/view-checkout.component';
import { CrearInfoFactComponent } from './crear-info-fact/crear-info-fact.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { CategoryCarouselComponent } from './category-carousel/category-carousel.component';
import { ProductCatalogComponent } from './product-catalog/product-catalog.component';
import { DinamicCarouselComponent } from './dinamic-carousel/dinamic-carousel.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ProductFinderComponent } from './product-finder/product-finder.component';
@NgModule({
    declarations: [
        InputComponent,
        ModalComponent,
        SelectComponent,
        MapComponent,
        Inputv2Component,
        Selectv2Component,
        PasswordStrengthBarComponent,
        ListModalComponent,
        CheckoutComponent,
        DetailCartComponent,
        ConfirmPaymentComponent,
        CardManagementComponent,
        FormCardManagementComponent,
        ListCardManagementComponent,
        DateComponent,
        BannerCustomComponent,
        ListModalWithHeadersComponent,
        AskForHelpComponent,
        ClientFormComponent,
        FormBillingComponent,
        ViewCheckoutComponent,
        CrearInfoFactComponent,
        ImageCarouselComponent,
        CategoryCarouselComponent,
        ProductCatalogComponent,
        DinamicCarouselComponent,
        TermsAndConditionsComponent,
        ProductFinderComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ModalModule,
        Ng2SearchPipeModule,
        SlickCarouselModule,
        NgxSelectModule,
        BsDatepickerModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: environment.apigoogle,
            region: 'GT',
            language: 'ES',
        }),
        DatepickerModule,
        BsDatepickerModule,
        TimepickerModule.forRoot(),
        JoyrideModule.forRoot(),
        NgxJoyrideModule,
        NgImageFullscreenViewModule,
        TooltipModule.forRoot(),
        FileUploadModule,
        NgxQRCodeModule,
        TabsModule.forRoot(),
        AccordionModule.forRoot(),
        ImageCropperModule,
    ],
    exports: [
        InputComponent,
        ModalComponent,
        SelectComponent,
        MapComponent,
        Inputv2Component,
        Selectv2Component,
        PasswordStrengthBarComponent,
        ListModalComponent,
        CheckoutComponent,
        DetailCartComponent,
        ConfirmPaymentComponent,
        CardManagementComponent,
        FormCardManagementComponent,
        ListCardManagementComponent,
        DateComponent,
        BsDatepickerModule,
        TooltipModule,
        BannerCustomComponent,
        ListModalWithHeadersComponent,
        ClientFormComponent,
        TabsModule,
        FormBillingComponent,
        ViewCheckoutComponent,
        CrearInfoFactComponent,
        ImageCarouselComponent,
        CategoryCarouselComponent,
        ProductCatalogComponent,
        DinamicCarouselComponent,
        TermsAndConditionsComponent,
        ProductFinderComponent,
    ],
    providers: [Ng2SearchPipe, BsDaterangepickerConfig, TabsetConfig],
    entryComponents: [JoyrideStepComponent],
})
export class ComponentsModule {}
