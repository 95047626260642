import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormValidators } from 'src/utility/form/form.validators';

@Component({
    selector: 'app-form-billing',
    templateUrl: './form-billing.component.html',
    styleUrls: ['./form-billing.component.scss'],
})
export class FormBillingComponent implements OnInit {
    @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
    @Input() blockedButton: boolean = false;
    formBilling: FormGroup;

    validatorsCustom = {
        taxId: [
            Validators.required,
            Validators.maxLength(13),
            FormValidators.matchNit(),
        ],
    };

    constructor() {
        this.formBilling = new FormGroup({ isDefault: new FormControl('') });
    }

    ngOnInit(): void {}

    resetFormBilling() {
        this.formBilling.reset();
    }

    addForm(name: string, form: FormGroup) {
        this.formBilling.removeControl(name);
        this.formBilling.addControl(name, form);
    }

    onClickSave() {
        this.onSave.emit({
            nit: this.getTaxId.value,
            name: this.getName.value,
            direction: this.getDirection.value,
            isDefault: this.getDefault.value,
        });
    }

    disabledButton() {
        if (this.blockedButton) {
            return true;
        } else {
            return this.formBilling.invalid;
        }
    }

    public get getTaxId() {
        return this.formBilling.get('nit').get('input');
    }
    public get getName() {
        return this.formBilling.get('name').get('input');
    }
    public get getDirection() {
        return this.formBilling.get('direction').get('input');
    }
    public get getDefault() {
        return this.formBilling.get('isDefault');
    }
}
