import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
import { SharedModule } from '../shared/shared.module';
import { routes } from './routes';
import { LoginsModule } from 'src/views/Logins/logins.module';
import { AuthGuardService } from 'src/utility/services/auth/auth-guard.service';
import { DeactiveGuardService } from 'src/utility/services/auth/deactive-guard.service';

@NgModule({
    imports: [SharedModule, RouterModule.forRoot(routes), LoginsModule],
    declarations: [],
    exports: [RouterModule],
    providers: [AuthGuardService, DeactiveGuardService],
})
export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService) {}
}
