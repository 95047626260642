import {
    Component,
    Input,
    OnInit,
    Output,
    ViewChild,
    EventEmitter,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { DetectNavigationService } from 'src/utility/functions/detect-navigation.service';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { SweetAlert } from 'src/utility/sweetAlert';
@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
    @Input() fatherClass: string = '';
    @Input() fatherStylePrincipal: string = '';
    @Input() orderNumber: any;
    @Input() origin: string = '';
    @Input() size: string = 'md';
    @Input() title: string = '';
    @Input() nameStyle: string = '';
    @Input() nameClassModalDialog: string = '';
    @ViewChild('closeButton') closeButton;
    @ViewChild('componentContent') componentContent: any;
    isFirefox: boolean = false;
    @Input() idModal: string = 'modal' + Date.now().toFixed();
    @Input() showClose: boolean = true;
    @Input() pt: boolean = true;
    @Input() showHeader: boolean = true;
    @Input() showFooter: boolean = true;
    @Input() video: boolean = false;
    @Input() paymentIsConfirmed: boolean = false;
    @Input() paymentIsCompleted: boolean = false;
    @Output() onClickClose: EventEmitter<any> = new EventEmitter();
    @Output() paymentCompleted: EventEmitter<any> = new EventEmitter();
    constructor(
        public browserServices: DetectNavigationService,
        private sweet: SweetAlert,
        private api: ConnectionApiService,
        private secureStorage: SecureSimpleStorage
    ) {}

    public ngOnInit(): void {
        if (this.browserServices.browser() == 4) {
            this.isFirefox = false;
        }
    }
    public Close() {
        //this.closeButton.nativeElement.click();
        this.componentContent.hide();
    }
    public Open() {
        this.componentContent.show();
        // this.onOpen.emit();
        // let trigger = document.createElement('button');
        // this.componentContent.nativeElement.appendChild(trigger);
        // trigger.setAttribute('style', 'display:none');
        // trigger.setAttribute('data-target', '#' + this.idModal);
        // trigger.setAttribute('data-toggle', 'modal');
        // trigger.click();
        // this.componentContent.nativeElement.removeChild(trigger);
    }

    async onClickCloseFunction() {
        if (this.origin == 'clubForza' && this.paymentIsConfirmed) {
            let paymentInProgress: boolean = false;
            paymentInProgress = await this.validateStatusPayment();
            if (paymentInProgress) {
                if (!this.paymentIsCompleted) {
                    this.sweet.errorCriticalAlert(
                        'Pago en proceso...',
                        'El pago se está procesando, por favor espere.',
                        null,
                        'warning',
                        'orange'
                    );
                    return;
                }
            } else {
            }
        } else {
            this.componentContent.hide();
            this.onClickClose.emit();
        }
    }

    async validateStatusPayment(): Promise<boolean> {
        let userString: any;
        let user: any = null;
        try {
            userString = await this.secureStorage
                .get(Contract.user)
                .toPromise();
            user = JSON.parse(userString);
        } catch (error) {}
        const data = {
            Method: 'GetResponseFacTransactionProcessStatus',
            Params: {
                OrderNumber: this.orderNumber,
                CustomerReference: '1',
                Token: !user ? 'user not logged in' : user.ObjectValue[0].Token,
                SystemOrigin: '2',
                AccountId: !user
                    ? '0'
                    : user.ObjectValue[0].Accounts[0].IdAccount,
            },
        };

        const body = {
            path: 'FAC/GetResponseFacTransactionProcessStatus',
            data: data,
        };
        const response = await this.api.postData(body).toPromise();
        if (response.ObjectValue[0].ReasonCode == 'SP4') {
            return true;
        } else {
            this.componentContent.hide();
            this.onClickClose.emit();
            this.paymentCompleted.emit(response.ObjectValue[0]);
            return false;
        }
    }

    async getUserData() {
        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        return JSON.parse(userString);
    }
}
