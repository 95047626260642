import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
import { OffsidebarComponent } from './offsidebar/offsidebar.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { UserblockService } from './sidebar/userblock/userblock.service';
import { FooterComponent } from './footer/footer.component';
import { ComponentsModule } from 'src/components/components.module';
import { SharedModule } from '../shared/shared.module';
import { SweetAlert } from 'src/utility/sweetAlert';
import { NgxJoyrideModule } from 'src/utility/ngx-joyride/ngx-joyride.module';
import { JoyrideModule, JoyrideStepComponent } from 'ngx-joyride';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';

@NgModule({
    imports: [
        SharedModule,
        ComponentsModule,
        JoyrideModule.forRoot(),
        NgxJoyrideModule,
        NgImageFullscreenViewModule,
    ],
    providers: [UserblockService, SweetAlert],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        OffsidebarComponent,
        FooterComponent,
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        OffsidebarComponent,
        FooterComponent,
    ],
    entryComponents: [JoyrideStepComponent],
})
export class LayoutModule {}
