import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { GetCountriesService } from 'src/utility/getCountries';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';
import { Contract } from 'src/utility/Storage/contract';
@Component({
    selector: 'app-inputv2',
    templateUrl: './inputv2.component.html',
    styleUrls: ['./inputv2.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: Inputv2Component,
            multi: true,
        },
    ],
})
export class Inputv2Component implements OnInit {
    @Input() showRecoveryPassword: boolean = false;
    @Input() fatherClass: string = '';
    countrySelect: any = null;
    countries: any = [];
    @Input() prefixPhone: any = null;

    @Input() typePhone: string = '';

    @Input() isPhone: boolean = false;

    @Input()
    showTextLabel: boolean = true;

    @Input()
    textLabel: string;

    @Input()
    requiredSymbol: string;

    @Input()
    subtleAnnotation: string;

    @Input()
    autocomplete: string = 'new-password';

    @Input()
    placeholder: string;

    @Input()
    control: any;

    @Input()
    type: string;

    @Input()
    InputType: string; // tipo de entrada

    @Input()
    style: number;

    @Input()
    maxLength: number;

    @Input()
    minLength: number;

    @Input()
    alternativeActionLabel: string;

    @Input()
    alternativeActionRoute: string;

    @Input()
    disabled: boolean;

    @Input()
    readonly: boolean = false;

    @Input()
    icono_left: string;
    icono_right: string;

    @Input()
    regex: string;
    myvalue: any;

    @Input()
    mask: Array<number>;
    @Input()
    separador: string;

    @Input()
    IsPassword: boolean;

    @Input()
    PasswordIcon: string = 'bi-eye-fill';

    @Input()
    subtitle: string;

    @Input()
    datatitle: string;

    @Input()
    autocompleteAddressItems: any[] = [];

    @Input() regex2: RegExp = null;

    @Output() selectedList = new EventEmitter<String>();
    constructor(
        private getCountryData: GetCountriesService,
        private encryptedCookieStorageService: EncryptedCookieStorageService
    ) {
        this.disabled = false;
        this.countries = this.getCountryData.getCountries();
    }

    @Output()
    sendPrefixPhone: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit(): void {
        setTimeout(() => {
            if (this.isPhone) {
                let countrySelect =
                    environment.multiCountryData[0].countrySelect;
                if (environment.rol == 'Ventas telemercadeo') {
                    this.countrySelect =
                        environment.TelemercadeoCountryData[0].countrySelect;
                }
                let idx = this.countries.findIndex((item) =>
                    this.prefixPhone
                        ? item.prefixPhone == this.prefixPhone
                        : item.value == countrySelect.value
                );
                if (idx > -1) {
                    this.selectCountry(this.countries[idx]);
                }
            }
        }, 100);
    }
    onChange = (_: any) => {};
    change(event) {
        if (this.mask && this.separador) {
            this.writeValue(
                this.mascara(
                    event.target.value,
                    this.separador,
                    this.mask,
                    true
                )
            );
        }
        if (this.regex2) {
            const valueRemplace = String(event.target.value).replace(
                this.regex2,
                ''
            );

            this.control.setValue(valueRemplace);
        }
        if (this.regex) {
            event.target.value = this.checkPattern(
                event.target.value,
                this.regex
            );
        }

        this.onChange(event.target.value);
    }

    checkPattern(value: string, pattern) {
        const patron = new RegExp(pattern, 'm');

        if (value) {
            if (!patron.test(value)) {
                return value.substr(0, value.length - 1);
            }
        }

        return value;
    }

    mascara(
        val: string,
        sep: string,
        pat: Array<number>,
        nums: boolean
    ): string {
        let val2 = '';
        const valAux = val.replace(sep, '').trim();
        const arrayMask = new Array();
        let newIndex = 0;
        let newEnd = 0;
        let codeChar;
        const code0: number = '0'.charCodeAt(0);
        const code9: number = '9'.charCodeAt(0);

        if (nums) {
            val2 = '';
            for (let index = 0; index < valAux.length; index++) {
                codeChar = valAux.charCodeAt(index);
                if (codeChar >= code0 && codeChar <= code9) {
                    val2 = val2 + valAux.charAt(index);
                }
            }
        }

        for (let s = 0; s < pat.length; s++) {
            newEnd = newIndex + pat[s];
            newEnd = newEnd > val2.length ? val2.length : newEnd;
            arrayMask[s] =
                newIndex > val2.length - 1
                    ? ''
                    : val2.substring(newIndex, newEnd);
            newIndex += pat[s];
        }

        for (let q = 0; q < arrayMask.length; q++) {
            if (q === 0) {
                val = arrayMask[q];
            } else {
                if (arrayMask[q] !== '' && arrayMask[q] !== sep) {
                    val += sep + arrayMask[q];
                }
            }
        }
        return val;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(obj: any): void {
        this.myvalue = obj;
    }

    show_password() {
        this.type = this.type == 'text' ? 'password' : 'text';
        this.PasswordIcon =
            this.PasswordIcon == 'bi-eye-slash-fill'
                ? 'bi-eye-fill'
                : 'bi-eye-slash-fill';
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    selectedSuggestItem(item) {
        this.autocompleteAddressItems = [];
        this.selectedList.emit(item);
    }

    onChangeText(event) {
        const valueRemplace = String(event.target.value).replace(
            this.regex2,
            ''
        );

        this.control.setValue(valueRemplace);
    }

    selectCountry(item) {
        if (item == this.countrySelect) {
            return;
        }
        this.countrySelect = item;
        this.sendPrefixPhone.emit({
            prefix: item.prefixPhone,
            typePhone: this.typePhone,
        });
    }
    recoveryPassword() {
        window.location.href = `${environment.portalRoute}/forgotpassword`;
    }
}
