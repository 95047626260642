<div class="card">
    <div class="row p-4" style="height: 500px">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <app-form-card-management
                (onSaveCard)="getAllCardsAsociate()"></app-form-card-management>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <app-list-card-management
                [listCard]="listCard"></app-list-card-management>
        </div>
    </div>
</div>
