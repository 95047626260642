import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommunicationWithObservableService {
    private componentForTutorial: Subject<any> = new Subject<any>();

    /**
     * @description metodos creados para el componente que sigue, para los tutoriales de al creacion
     * de guías
     * @author Luis Alvarado
     * @date 22/05/2023
     * @memberof CommunicationWithObservableService
     */
    sendComponentForTutorial(message) {
        this.componentForTutorial.next({ text: message });
    }

    onComponentForTutorial(): Observable<any> {
        return this.componentForTutorial.asObservable();
    }
}
