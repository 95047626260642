import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { Ng2SearchPipe } from 'ng2-search-filter';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'app-list-modal',
    templateUrl: './list-modal.component.html',
    styleUrls: ['./list-modal.component.scss'],
})
export class ListModalComponent implements OnInit {
    @Input() title: string = 'Opciones';
    @Input() list: any[] = [];
    @ViewChild('modalselect', { static: false }) modalselect;
    @ViewChildren('itemsOption') itemsOptionElRef:
        | QueryList<ElementRef>
        | undefined;
    @ViewChild('searchInput', { static: false }) searchInput;
    @Output() changeSelected = new EventEmitter<any>();
    @Output() closeModal = new EventEmitter<any>();
    idModal = 'modalListSelect' + Date.now().toFixed();
    public searchText: string = '';
    constructor(private pipeSearch: Ng2SearchPipe) {}

    ngOnInit(): void {}

    onEnterSearch() {
        if (this.itemsOptionElRef) {
            let listfilter = this.pipeSearch.transform(
                this.list,
                this.searchText
            );
            if (listfilter.length > 0) {
                this.itemSelected(listfilter[0]);
            }
        }
    }

    itemSelected(item) {
        this.searchText = '';
        this.changeSelected.emit(item);
        this.modalselect.Close();
    }

    async openModal() {
        this.searchText = '';
        let modal: ModalComponent = this.modalselect;
        modal.Open();

        await new Promise((resolve) => setTimeout(resolve, 500));
        let inputsearch = this.searchInput.nativeElement;
        inputsearch?.focus();
    }

    async onClickClose() {
        this.closeModal.emit();
    }
}
