<!-- <ngx-select
    [allowClear]="false"
    [items]="items"
    [disabled]="disabled"
    (click)="onClick($event)"
    (data)="refreshValue($event)"
    (selected)="selected($event)"
    (removed)="removed($event)"
    (typed)="typed($event)"
    placeholder="No city selected">
</ngx-select> -->
<div [formGroup]="formInput">
    <label
        for="validationServer01"
        class="pl-1 mb-1 text-dark text-h4 control-label"
        *ngIf="label"
        >{{ this.label }}
        <span *ngIf="required" class="text-danger">*</span>
    </label>
    <button
        type="button"
        id="buttonOpenSelect"
        [class]="'d-flex form-control pr-2 py-0 ' + fatherClass"
        [tooltipFadeDuration]="2000"
        #tooltipInput="bs-tooltip"
        (click)="openModal(); tooltipInput.show(); tooltipInput.hide()"
        (focus)="openModal()"
        [tooltip]="tooltipText"
        [ngClass]="{
            borderInvalid: invalid,
            borderSuccess: valid
        }"
        [disabled]="disabled">
        <span
            class="text-left align-self-center value-text"
            [ngClass]="{ disabledselect: !this.selectedText }">
            {{ this.selectedText || placeholder }}</span
        >
        <em class="fa-1x fas fa-caret-down align-self-center ml-auto"></em>
    </button>
    <span class="text-danger" *ngIf="invalid">{{ this.msgInvalid }}</span>
    <br *ngIf="invalid" />
    <span class="text-subTitle" *ngIf="legend">{{ legend }}</span>
</div>
<!-- Select Simple -->
<app-modal
    [idModal]="this.modalid"
    #modalselect
    size="md"
    title="{{ title }}"
    (onClickClose)="onClickClose.emit()">
    <div
        class="row mx-0 flex-nowrap justify-content-around rowRadioButtonHeaders"
        *ngIf="headersRadioButton">
        <div
            class="col-auto radioButtonHeaders d-flex align-items-center"
            *ngFor="let item of listSelectWithHeadersRadio; let i = index">
            <div class="form-check">
                <input
                    class="form-check-input"
                    id="radioHeader{{ i }}"
                    type="radio"
                    value="radioHeader{{ i }}"
                    name="headersRadio"
                    [checked]="item.activeDefault"
                    (click)="onClickRadioHeader(item)" />
                <label
                    class="form-check-label text-h4 pl-1 pt-1 text-nowrap"
                    for="radioHeader{{ i }}">
                    <strong>{{ item.titleRadio }}</strong>
                </label>
            </div>
        </div>
    </div>
    <div class="input-group mb-1">
        <div class="input-group-prepend">
            <span class="input-group-text no-border-radius"
                ><i class="fa fa-search"></i
            ></span>
        </div>
        <input
            #searchInput
            autocomplete="off"
            id="searchInput"
            (keyup.enter)="onEnterSearch()"
            [(ngModel)]="searchText"
            type="text"
            class="form-control no-border-radius"
            placeholder="Búsqueda" />
    </div>
    <div class="list-group-content">
        <div class="contentlist">
            <ul class="list-group" draggable="false" style="cursor: pointer">
                <li
                    #itemsOption
                    *ngFor="
                        let option of getItemListHeaders() | filter : searchText
                    "
                    class="list-group-item px-0 py-0"
                    draggable="true"
                    style="cursor: pointer">
                    <div class="row mx-0" style="height: 34.79px">
                        <div
                            (click)="itemSelected(option); modalselect.Close()"
                            class="col d-flex align-items-center">
                            <label role="button" class="text-h4 mb-0 pr-2"
                                ><strong *ngIf="option.isTitle">{{
                                    option.text
                                }}</strong></label
                            >
                            <label
                                role="button"
                                *ngIf="!option.isTitle"
                                class="text-h4 mb-0"
                                >{{ option.text }}</label
                            >
                            <em
                                *ngIf="option.iconTitle"
                                class="{{
                                    option.iconTitle
                                }} fa-1x text-primary"></em>
                        </div>
                        <div
                            *ngIf="option.icon"
                            (click)="
                                onClickIcon.emit(option); modalselect.Close()
                            "
                            class="col-auto d-flex align-items-center">
                            <em
                                class="{{
                                    option.icon
                                }} sizeIcon text-primary"></em>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</app-modal>
