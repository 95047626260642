import { FormControl, ValidatorFn, FormGroup } from '@angular/forms';
import { element } from 'protractor';
import { formatTarjetaSend } from './form.Data';
interface Result {
    valor: boolean;
    respuesta: any;
}

function cuiIsValid(cui, emptyIsValid: boolean = false) {
    if (emptyIsValid && (cui === '' || cui.trim() === '')) {
        return true;
    }

    if (!cui) {
        return false;
    }
    const cuiRegExp = /^[0-9]{4}\s?[0-9]{5}\s?[0-9]{4}$/;
    if (!cuiRegExp.test(cui)) {
        return false;
    }
    if (cui.length < 13) {
        console.error('longitud no adecuada');
        return false;
    }
    cui = cui.replace(/\s/, '');
    const depto = parseInt(cui.substring(9, 11), 10);
    const muni = parseInt(cui.substring(11, 13), 0);
    const numero = cui.substring(0, 8);
    const verificador = parseInt(cui.substring(8, 9), 0);

    // Se asume que la codificación de Municipios y
    // departamentos es la misma que esta publicada en
    // http://goo.gl/EsxN1a

    // Listado de municipios actualizado segun:
    // http://goo.gl/QLNglm

    // Este listado contiene la cantidad de municipios
    // existentes en cada departamento para poder
    // determinar el código máximo aceptado por cada
    // uno de los departamentos.
    const munisPorDepto = [
        /* 01 - Guatemala tiene:      */ 17 /* municipios. */,
        /* 02 - El Progreso tiene:    */ 8 /* municipios. */,
        /* 03 - Sacatepéquez tiene:   */ 16 /* municipios. */,
        /* 04 - Chimaltenango tiene:  */ 16 /* municipios. */,
        /* 05 - Escuintla tiene:      */ 13 /* municipios. */,
        /* 06 - Santa Rosa tiene:     */ 14 /* municipios. */,
        /* 07 - Sololá tiene:         */ 19 /* municipios. */,
        /* 08 - Totonicapán tiene:    */ 8 /* municipios. */,
        /* 09 - Quetzaltenango tiene: */ 24 /* municipios. */,
        /* 10 - Suchitepéquez tiene:  */ 21 /* municipios. */,
        /* 11 - Retalhuleu tiene:     */ 9 /* municipios. */,
        /* 12 - San Marcos tiene:     */ 30 /* municipios. */,
        /* 13 - Huehuetenango tiene:  */ 32 /* municipios. */,
        /* 14 - Quiché tiene:         */ 21 /* municipios. */,
        /* 15 - Baja Verapaz tiene:   */ 8 /* municipios. */,
        /* 16 - Alta Verapaz tiene:   */ 17 /* municipios. */,
        /* 17 - Petén tiene:          */ 14 /* municipios. */,
        /* 18 - Izabal tiene:         */ 5 /* municipios. */,
        /* 19 - Zacapa tiene:         */ 11 /* municipios. */,
        /* 20 - Chiquimula tiene:     */ 11 /* municipios. */,
        /* 21 - Jalapa tiene:         */ 7 /* municipios. */,
        /* 22 - Jutiapa tiene:        */ 17 /* municipios. */,
    ];

    if (depto === 0 || muni === 0) {
        // console.error('CUI con código de municipio o departamento inválido.');
        return false;
    }

    if (depto > munisPorDepto.length) {
        // console.error('CUI con código de departamento inválido.');
        return false;
    }

    if (muni > munisPorDepto[depto - 1]) {
        // console.error('CUI con código de municipio inválido.');
        return false;
    }

    // Se verifica el correlativo con base
    // en el algoritmo del complemento 11.
    let total = 0;

    for (let i = 0; i < numero.length; i++) {
        total += numero[i] * (i + 2);
    }

    const modulo = total % 11;

    return modulo === verificador;
}
function validarFechaTarjeta(fecha: string): Result {
    const resp: Result = {
        valor: false,
        respuesta: {
            MalFormatDateCard: true,
        },
    };
    fecha = fecha.trim();
    if (fecha.length === 5) {
        const d = new Date();
        const array = fecha.split('/');
        resp.respuesta = {
            EndDateCard: true,
        };
        const mes: number = +array[0];
        const anio: number = +array[1] + 2000;
        const AnioActual: number = d.getFullYear();
        const MesActual: number = d.getMonth() + 1;
        if (anio >= AnioActual) {
            if (
                (mes >= MesActual && mes <= 12) ||
                (anio > AnioActual && mes <= 12)
            ) {
                resp.valor = true;
                resp.respuesta = null;
            } else {
                resp.valor = false;
                resp.respuesta = 'La fecha de vencimiento';
                return resp;
            }
        }
    }

    return resp;
}
function validarNoTarjeta(numero: string): boolean {
    numero = formatTarjetaSend(numero);
    const digitsOnly = Array.from(numero);
    if (digitsOnly.length > 18 || digitsOnly.length < 15) {
        return false;
    }

    let sum = 0;
    let digit = 0;
    let addend = 0;
    let timesTwo = false;

    for (let i: number = digitsOnly.length - 1; i >= 0; i--) {
        digit = +(digitsOnly[i] + '');
        if (timesTwo) {
            addend = digit * 2;
            if (addend > 9) {
                addend -= 9;
            }
        } else {
            addend = digit;
        }

        sum += addend;
        timesTwo = !timesTwo;
    }
    return sum % 10 === 0;
}

function NitValido(NIT: string, useCF?): Result {
    const resp: Result = {
        valor: false,
        respuesta: '',
    };

    if (useCF) {
        if (NIT === 'CF' || NIT === 'cf') {
            resp.valor = true;
            resp.respuesta = 'correcto';
            return resp;
        }
    }

    NIT = NIT.replace('-', '');
    const NITRegExp = /^[1-9]{1}[0-9]*([K]{0,1}[k]{0,1})$/;

    if (!NITRegExp.test(NIT)) {
        resp.valor = false;
        resp.respuesta = 'El NIT debe ingresarse sin guiones';
        return resp;
    }

    let pos = 0;
    let Correlativo = '';
    let DigitoVerificador: string = '';
    let Factor = 0;

    pos = NIT.length - 1;
    Correlativo = NIT.substring(0, pos);
    DigitoVerificador = NIT.substring(pos, NIT.length);
    Factor = Correlativo.length + 1;
    let Suma = 0;
    let Valor = 0;

    // Se verifica el correlativo con base
    // en el algoritmo del complemento 11.

    for (let i = 0; i <= pos - 1; i++) {
        Valor = +NIT.substring(i, i + 1);
        Suma = Suma + Valor * Factor;
        Factor = Factor - 1;
    }
    const XMod11 = (11 - (Suma % 11)) % 11;

    if (
        (XMod11 === 10 &&
            (DigitoVerificador === 'K' || DigitoVerificador === 'k')) ||
        XMod11 + '' === DigitoVerificador
    ) {
        resp.valor = true;
        resp.respuesta = 'Exitoso';
        return resp;
    } else {
        resp.valor = false;
        resp.respuesta = 'El NIT escrito no es valido.';
        if (cuiIsValid(NIT)) {
            resp.valor = true;
            resp.respuesta = 'Exitoso';
        }

        return resp;
    }
}

function RTNValido(RTN: string, useCF?): Result {
    const resp: Result = {
        valor: false,
        respuesta: '',
    };

    if (useCF) {
        if (RTN === 'CF' || RTN === 'cf') {
            resp.valor = true;
            resp.respuesta = 'correcto';
            return resp;
        }else{
            if(RTN.length < 14 || RTN.length > 14){
                resp.valor = false;
                resp.respuesta = 'RTN invalido';
                return resp;
            }
        }
    }

    RTN = RTN.replace('-', '');
    const RTNRegExp = /^[0-9]+$/;

    if (!RTNRegExp.test(RTN)) {
        resp.valor = false;
        resp.respuesta = 'RTN invalido';
        return resp;
    }

    resp.valor = true;
    resp.respuesta = 'Exitoso';
    return resp;
}

export class FormValidators {
    static matchEmail = (func: any): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            const val: string = func() + '';
            if (fc.value.toLocaleLowerCase() === val.toLocaleLowerCase()) {
                return null;
            }
            return { matchEmail: true };
        };
    };

    static matchendDate = (func: any): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            const fechaInit: Date = func();
            const endA = fc.value ? fc.value.split('/') : null;
            const fecha: Date =
                endA && endA.length > 0
                    ? new Date(+endA[2], +endA[1], +endA[0])
                    : null;
            const fechaEnd: Date = fecha;
            if (fechaEnd >= fechaInit || !fechaInit) {
                return null;
            }
            return { matchendDate: true };
        };
    };

    static matchInitDate = (func: any): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            const fechaEnd: Date = func();
            const endA = fc.value ? fc.value.split('/') : null;
            const fecha: Date =
                endA && endA.length > 0
                    ? new Date(+endA[2], +endA[1], +endA[0])
                    : null;
            const fechaInit: Date = fecha;
            if (fechaInit <= fechaEnd || !fechaEnd) {
                return null;
            }
            return { matchInitDate: true };
        };
    };

    static matchendDate2 = (func: any): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            const fechaInit: Date = func();
            const endA = fc.value ? fc.value.split('/') : null;
            const fecha: Date =
                endA && endA.length > 0
                    ? new Date(+endA[2], +endA[1], +endA[0])
                    : null;
            const fechaEnd: Date = fecha;
            if (fechaEnd >= fechaInit || !fechaInit) {
                return null;
            }
            return { matchendDate: true };
        };
    };

    static matchInitDate2 = (func: any): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            const fechaEnd: Date = func();
            const endA = fc.value ? fc.value.split('/') : null;
            const fecha: Date =
                endA && endA.length > 0
                    ? new Date(+endA[2], +endA[1], +endA[0])
                    : null;
            const fechaInit: Date = fecha;
            if (fechaInit <= fechaEnd || !fechaEnd) {
                return null;
            }
            return { matchInitDate: true };
        };
    };

    static matchFechaTarjeta = (): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            if (validarFechaTarjeta(fc.value).valor) {
                return null;
            }
            return { matchFechaTarjeta: true };
        };
    };

    static matchTarjeta = (): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            if (validarNoTarjeta(fc.value)) {
                return null;
            }
            return { matchTarjeta: true };
        };
    };
    static matchNit = (useCF: boolean = false): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            if (!fc.value) {
                return null;
            }
            if (NitValido(fc.value, useCF).valor) {
                return null;
            }
            return { matchNit: true };
        };
    };

    static matchRTN = (useCF: boolean = false): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            if (!fc.value) {
                return null;
            }
            if (RTNValido(fc.value, useCF).valor) {
                return null;
            }
            return { matchNit: true };
        };
    };

    static matchDecimal = (): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            const patron = /^\d+(\.\d+)?$/;

            if (patron.test(fc.value)) {
                if (fc.value.substring(fc.value.length - 1) === '.') {
                    return { pattern: true };
                } else {
                    return null;
                }
            }
            return { pattern: true };
        };
    };

    static matchEntero = (): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            const patron = /^\d+$/;
            if (patron.test(fc.value)) {
                return null;
            }
            return { pattern: true };
        };
    };

    static matchCUIOrEmpty = (): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            if (cuiIsValid(fc.value, true)) {
                return null;
            }
            return { matchCUI: true };
        };
    };

    static matchCUI = (): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            if (cuiIsValid(fc.value)) {
                return null;
            }
            return { matchCUI: true };
        };
    };

    static checkAmount = (func: any): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            if (!fc.value) {
                return { amountEmpty: true };
            }
            const regex = new RegExp('^[0-9]+(\\.[0-9]+)?$');
            if (!regex.test(fc.value)) {
                return { invalidAmount: true };
            }
            const value = Number.parseFloat(
                fc.value.toString().replace(',', '')
            );
            if (value <= 0) {
                return { checkAmountZero: true };
            }
            if (value > func()) {
                return { checkAmount: true };
            }
            return null;
        };
    };

    static orderWeight = (): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            if (!fc.value) {
                return { weightEmpty: true };
            }
            if (!(fc.value >= 1 && fc.value <= 4409)) {
                return { invalidWeight: true };
            }
            return null;
        };
    };

    static orderlength = (): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            if (!(fc.value >= 0 && fc.value <= 200)) {
                return { invalidLength: true };
            }

            return null;
        };
    };

    static orderWidth = (): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            if (!(fc.value >= 0 && fc.value <= 120)) {
                return { invalidWidth: true };
            }
            return null;
        };
    };

    static orderHeight = (): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            if (!(fc.value >= 0 && fc.value <= 100)) {
                return { invalidHeight: true };
            }
            return null;
        };
    };

    static mailDomain = (): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            let exist = false;
            if (fc.value.indexOf('@') !== -1) {
                let str = [];
                str = fc.value
                    .substr(fc.value.indexOf('@') + 1, fc.value.length)
                    .split('.');

                let count: number = 0;
                let current = null;
                str.sort();
                for (let i = 0; i < str.length; i++) {
                    if (str[i] !== current) {
                        if (count > 1) {
                            break;
                        }
                        current = str[i];
                        count = 1;
                    } else {
                        count++;
                    }
                }

                if (count > 1) {
                    return { invalidDomain: true };
                }
            }

            return null;
        };
    };

    static MatchPassword(password: string, confirmPassword: string) {
        return (formGroup: FormGroup) => {
            const passwordControl = formGroup.controls[password];
            const confirmPasswordControl = formGroup.controls[confirmPassword];

            if (!passwordControl || !confirmPasswordControl) {
                return null;
            }
            if (passwordControl.value !== confirmPasswordControl.value) {
                confirmPasswordControl.setErrors({ passwordMismatch: true });
            } else {
                if (
                    confirmPasswordControl.value !== '' ||
                    passwordControl.value !== ''
                ) {
                    confirmPasswordControl.setErrors(null);
                }
            }
        };
    }

    static validZone = (): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            const zone: string = fc.value;
            if (!zone) {
                return { zoneEmpty: true };
            }
            return null;
        };
    };

    static validBank = (bank: any, typeAccount: any): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            const ban: string = bank();
            const account: string = typeAccount();

            var validateAccount = false;
            if (ban == '2') {
                if (account.toLowerCase() === 'ahorro') {
                    if (fc.value.substr(0, 1) === '0') {
                        return { validAccounts: true };
                    }
                } else if (account.toLowerCase() === 'monetaria') {
                    if (
                        fc.value.substr(0, 1) === '0' ||
                        fc.value.substr(0, 1) === '4'
                    ) {
                        return { validAccounts: true };
                    }
                }
            } else if (ban == '5') {
                if (
                    fc.value.length >= '1' &&
                    fc.value.length <= '10' &&
                    account.toLowerCase() === 'ahorro'
                ) {
                    if (fc.value.substr(0, 1) !== '4') {
                        return { validAccounts: true };
                    }
                } else if (
                    fc.value.length >= '1' &&
                    fc.value.length <= '14' &&
                    account.toLowerCase() === 'ahorro'
                ) {
                    if (fc.value.substr(0, 1) !== '0') {
                        return { validAccounts: true };
                    }
                } else if (
                    fc.value.length >= '1' &&
                    fc.value.length <= '10' &&
                    account.toLowerCase() === 'monetaria'
                ) {
                    if (fc.value.substr(0, 1) !== '3') {
                        return { validAccounts: true };
                    }
                } else if (
                    fc.value.length >= '1' &&
                    fc.value.length <= '14' &&
                    account.toLowerCase() === 'monetaria'
                ) {
                    if (fc.value.substr(0, 1) !== '0') {
                        return { validAccounts: true };
                    }
                }
            } else if (ban == '28') {
                if (!(fc.value.substring(0, 1) == '5'))
                    return { validateAccount: true };
            } else if (ban == '31') {
                if (account.toLowerCase() === 'ahorro') {
                    if (fc.value.length == 9) {
                        if (fc.value.substring(0, 2) == '96') {
                            fc.setErrors(null);
                        } else {
                            return { validateAccount: false };
                        }
                    } else if (fc.value.length == 10) {
                        if (fc.value.substring(0, 1) == '1') {
                            fc.setErrors(null);
                        } else {
                            return { validateAccount: false };
                        }
                    } else if (fc.value.length > 10) {
                        fc.setErrors(null);
                    }
                } else if (account.toLowerCase() === 'monetaria') {
                    if (fc.value.length == 10) {
                        return { validateAccount: true };
                    }
                }
            } else if (ban == '97') {
                if (account.toLowerCase() === 'ahorro') {
                    if (fc.value.length == 11 || fc.value.length == 12) {
                        if (!(fc.value.substring(0, 1) == '1')) {
                            return { validateAccount: true };
                        }
                    }
                } else if (account.toLowerCase() === 'monetaria') {
                    if (fc.value.length == 11 || fc.value.length == 12) {
                        if (
                            !(
                                fc.value.substring(0, 1) == '1' ||
                                fc.value.substring(0, 1) == '2' ||
                                fc.value.substring(0, 1) == '5'
                            )
                        ) {
                            return { validateAccount: true };
                        }
                    }
                }
            } else if (ban == '100') {
                if (account.toLowerCase() === 'ahorro') {
                    if (fc.value.length == 12) {
                        if (
                            !(
                                fc.value.substring(0, 2) == '10' ||
                                fc.value.substring(0, 2) == '11'
                            )
                        ) {
                            return { validateAccount: true };
                        }
                    }
                } else if (account.toLowerCase() === 'monetaria') {
                    if (fc.value.length >= 11 && fc.value.length <= 12) {
                        if (fc.value.length == 11) {
                            if (
                                !(
                                    fc.value.substring(0, 1) == '1' ||
                                    fc.value.substring(0, 1) == '2' ||
                                    fc.value.substring(0, 1) == '3'
                                )
                            ) {
                                return { validateAccount: true };
                            }
                        } else if (fc.value.length == 12) {
                            if (
                                !(
                                    fc.value.substring(0, 2) == '01' ||
                                    fc.value.substring(0, 2) == '02' ||
                                    fc.value.substring(0, 2) == '03'
                                )
                            ) {
                                return { validateAccount: true };
                            }
                        }
                    }
                }
            } else if (ban == '103') {
                if (account.toLowerCase() === 'ahorro') {
                    if (fc.value.length == 13) {
                        if (!(fc.value.substring(0, 4) == '4127')) {
                            return { validateAccount: true };
                        }
                    }
                }
            } else if (ban == '106') {
                if (account.toLowerCase() === 'ahorro') {
                    if (fc.value.length == 12) {
                        if (!(fc.value.substring(0, 1) == '2')) {
                            return { validateAccount: true };
                        }
                    }
                } else if (account.toLowerCase() === 'monetaria') {
                    if (fc.value.length == 12) {
                        if (!(fc.value.substring(0, 1) == '0')) {
                            return { validateAccount: true };
                        }
                    }
                }
            }

            return null;
        };
    };

    static maxPackages = (option: any): ValidatorFn => {
        return (fc: FormControl): { [key: string]: boolean } => {
            const opt = option();

            if (!(fc.value >= 1 && fc.value <= 35) && opt === '1') {
                return { invalidPackages: true };
            } else if (!(fc.value >= 11 && fc.value <= 50) && opt === '2') {
                return { invalidPackages2: true };
            }

            return null;
        };
    };
}

export interface Cargas {
    UUID: string;
}
