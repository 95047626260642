import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormValidators } from 'src/utility/form/form.validators';
import { regexOnlyNumber } from 'src/utility/functions/regex.service';
import { BillingService } from 'src/utility/services/billing.service';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { SweetAlert } from 'src/utility/sweetAlert';
@Component({
    selector: 'app-crear-info-fact',
    templateUrl: './crear-info-fact.component.html',
    styleUrls: ['./crear-info-fact.component.scss'],
})
export class CrearInfoFactComponent implements OnInit {
    data: any;
    user: FormGroup;
    buttons;
    taxType: any;
    saveBlock = false;
    @Input() customerSelected: any = null;
    @Input() isModal: boolean = false;
    @Input() closeModal: any = null;
    constructor(
        private router: Router,
        private sweet: SweetAlert,
        private billing: BillingService,
        private secureStorage: SecureSimpleStorage,
        private encryptedCookieStorageService: EncryptedCookieStorageService
    ) {
        this.user = new FormGroup({
            name: new FormControl('', [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(50),
            ]),
            address: new FormControl('', [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(200),
            ]),
            taxid: new FormControl('', []),
            rtn: new FormControl('', []),
            isDefault: new FormControl(''),
        });
    }

    ngOnInit(): void {
        this.taxType = environment.multiCountryData[0].taxType;
        if (environment.rol == 'Ventas telemercadeo') {
            this.taxType = environment.TelemercadeoCountryData[0].taxType;
        }
        this.verifyData();
        this.user.statusChanges.subscribe((data) => {
            this.saveBlock = !this.user.valid;
        });
        if (this.taxType == 'NIT') {
            this.user.controls.taxid.setValidators([
                Validators.maxLength(13),
                FormValidators.matchNit(),
                Validators.required,
            ]);
        } else {
            this.user.controls.rtn.setValidators([
                Validators.required,
                Validators.minLength(14),
                Validators.maxLength(14),
                Validators.pattern(regexOnlyNumber),
            ]);
        }
    }
    setBilling = () => {
        this.user.controls.name.setValue(this.user.controls.name.value.trim());
        this.user.controls.address.setValue(
            this.user.controls.address.value.trim()
        );

        if (this.user.valid) {
            let body = {
                path: 'Ecommerce/SetBillingProfile',
                data: {
                    Method: 'SetBillingProfile',
                    Params: {
                        IdBilling: 0,
                        IdAccount: !this.customerSelected
                            ? this.data.ObjectValue[0].Accounts[0].IdAccount
                            : this.customerSelected.IdAccount,
                        Name: this.user.controls.name.value,
                        Address: this.user.controls.address.value,
                        TaxId:
                            this.taxType == 'NIT'
                                ? this.user.controls.taxid.value
                                : this.user.controls.rtn.value,
                        Status: 1,
                        Token: this.data.ObjectValue[0].Token,
                        IsDefault: this.user.controls.isDefault.value
                            ? 'true'
                            : 'false',
                    },
                },
            };

            this.billing.manageBilling(body, true).subscribe(
                (response: any) => {
                    this.sweet.errorInfoAlert(
                        'Registro Correcto',
                        response.ObjectValue[0].Message,
                        'success'
                    );

                    if (this.isModal) {
                        if (this.closeModal) {
                            this.user.reset();
                            this.closeModal(response);
                            return;
                        }
                    }
                    this.router.navigate(['individual/facturacion']);
                },
                (error) => {
                    this.sweet.errorCriticalAlert(
                        'Error al registrar facturación',
                        error.Message ? error.Message : error
                    );
                }
            );
        } else {
            this.sweet.errorCriticalAlert(
                'Por Favor completar todos los campos',
                ''
            );
        }
    };
    verifyData() {
        this.secureStorage.get(Contract.user).subscribe(
            (data) => {
                this.data = JSON.parse(data);
            },
            (error) => {
                console.error(error);
            }
        );
    }
}
