import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { SweetAlert } from 'src/utility/sweetAlert';

@Component({
    selector: 'app-list-card-management',
    templateUrl: './list-card-management.component.html',
    styleUrls: ['./list-card-management.component.scss'],
})
export class ListCardManagementComponent implements OnInit {
    @Input() listCard: availableCards[] = [];
    @Input() refreshCards: boolean = false;
    @Input() isModal: boolean = false;
    @Input() activeDelete: boolean = true;
    @Input() activeUpdate: boolean = true;
    @Input() deleteDefault: boolean = false;

    //Incorporación de output para aplicar emitter
    @Output() selectedCard: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private sweet: SweetAlert,
        private secureStorage: SecureSimpleStorage,
        private api: ConnectionApiService
    ) {}

    ngOnInit(): void {}

    /**
     * @description retorna la tarjeta seleccionada cuando es un modal
     * @author Randy Can
     * @date 27/07/2022
     * @param {*} card
     * @memberof ListCardManagementComponent
     */
    selectCard(card, event) {
        if (event?.target?.classList?.value.includes('no-generate-action')) {
            return;
        }
        if (!this.isModal) {
            return;
        }
        this.selectedCard.emit(card);
    }

    /**
     * @description inicia con el flujo para actualizar una tarjeta
     * @author Randy Can
     * @date 27/07/2022
     * @param {availableCards} card
     * @returns {*}
     * @memberof ListCardManagementComponent
     */
    onUpdateCard(card: availableCards) {
        if (card.IsDefault) {
            this.sweet.errorInfoAlert(
                `La tarjeta ya es la principal`,
                `La tarjeta ya es la principal`,
                'success'
            );
            return;
        }

        this.sweet
            .warningAlert(
                '',
                `¿Desea convertir la tarjeta ${card.DisplayText} en su tarjeta principal?`
            )
            .then((result) => {
                if (result.isConfirmed) {
                    this.requestUpdatePaymentMethod(card);
                }
            });
    }

    /**
     * @description realiza la llamada a la api para poder modificar que tarjeta sera la principal
     * @author Randy Can
     * @date 27/07/2022
     * @param {availableCards} card
     * @returns {*}
     * @memberof ListCardManagementComponent
     */
    async requestUpdatePaymentMethod(card: availableCards) {
        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        const user = JSON.parse(userString);

        const body = {
            path: 'Payment/UpdatePaymentMethod',
            data: {
                Method: 'UpdatePaymentMethod',
                Params: {
                    AccountId: String(
                        user.ObjectValue[0].Accounts[0].IdAccount
                    ),
                    UpdateId: String(card.Id),
                    Token: String(user.ObjectValue[0].Token),
                },
            },
        };

        try {
            const response = await this.api.postData(body).toPromise();

            //verifica que StatusCode exista
            if (!response.StatusCode) {
                this.sweet.errorCriticalAlert(
                    'Error al modificar tarjeta',
                    response.Message ? response.Message : response
                );
                return;
            }

            //cuando StatusCode sea igual a 400
            if (response.StatusCode === 400) {
                this.sweet.errorCriticalAlert(
                    'Error al modificar tarjeta',
                    'La tarjeta no existe'
                );
                return;
            }

            //cuando StatusCode diferente a 200
            if (response.StatusCode !== 200) {
                this.sweet.errorCriticalAlert(
                    'Error al modificar tarjeta',
                    response.Message ? response.Message : response
                );
                return;
            }

            //cuando StatusCode sea 200
            this.sweet.successCriticalAlert(
                'Modificación exitosa',
                'Modificación exitosa',
                null
            );

            if (this.isModal) {
                this.selectedCard.emit(card);
            }

            for (const iterator of this.listCard) {
                if (iterator.Id == card.Id) {
                    iterator.IsDefault = true;
                } else {
                    iterator.IsDefault = false;
                }
            }
        } catch (error) {
            this.sweet.errorCriticalAlert(
                'Error al modificar tarjeta',
                error.Message ? error.Message : error
            );
        }
    }

    /**
     * @description inicia el proceso para eliminar una tarjeta
     * @author Randy Can
     * @date 27/07/2022
     * @param {availableCards} card
     * @returns {*}
     * @memberof ListCardManagementComponent
     */
    async onDeleteCard(card: availableCards) {
        if (card.IsDefault) {
            if (!this.isModal) {
                if (this.listCard.length == 1) {
                    this.sweet
                        .warningAlert(
                            '',
                            `¿Desea finalizar el proceso de eliminar su tarjeta principal ${card.DisplayText}?`
                        )
                        .then((result) => {
                            if (result.isConfirmed) {
                                this.requestDeletePaymentMethod(card);
                            }
                        });
                } else if (this.listCard.length > 1) {
                    this.sweet.errorCriticalAlert(
                        'Error al eliminar tarjeta',
                        `La tarjeta ${card.DisplayText} es la principal, para eliminarla primero debe marcar otra como la principal`
                    );
                    return;
                }
            } else {
                if (this.listCard.length == 1 && this.deleteDefault) {
                    this.sweet
                        .warningAlert(
                            '',
                            `¿Desea finalizar el proceso de eliminar su tarjeta principal ${card.DisplayText}?`
                        )
                        .then((result) => {
                            if (result.isConfirmed) {
                                this.requestDeletePaymentMethod(card);
                            }
                        });

                    return;
                } else if (this.listCard.length == 1) {
                    this.sweet.errorCriticalAlert(
                        'Error al eliminar tarjeta',
                        `La tarjeta ${card.DisplayText} es la principal, para eliminarla primero debe marcar otra como la principal`
                    );
                    return;
                }
            }
        } else {
            this.sweet
                .warningAlert(
                    '',
                    `¿Desea finalizar el proceso de eliminar la tarjeta ${card.DisplayText}?`
                )
                .then((result) => {
                    if (result.isConfirmed) {
                        this.requestDeletePaymentMethod(card);
                    }
                });
        }
    }

    /**
     * @description realiza la llamada a la api para realizar la eliminacion de la tarjeta seleccionada
     * @author Randy Can
     * @date 13/07/2022
     * @param {availableCards} card
     * @returns {*}
     * @memberof ListCardManagementComponent
     */
    async requestDeletePaymentMethod(card: availableCards) {
        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        const user = JSON.parse(userString);

        const body = {
            path: 'Payment/DeletePaymentMethod',
            data: {
                Method: 'DeletePaymentMethod',
                Params: {
                    AccountId: String(
                        user.ObjectValue[0].Accounts[0].IdAccount
                    ),
                    DeleteId: card.Id,
                    Token: String(user.ObjectValue[0].Token),
                },
            },
        };

        try {
            const response = await this.api.postData(body).toPromise();

            //verifica que StatusCode exista
            if (!response.StatusCode) {
                this.sweet.errorCriticalAlert(
                    'Error al eliminar tarjeta',
                    response.Message ? response.Message : response
                );
                return;
            }

            //cuando StatusCode sea igual a 400
            if (response.StatusCode === 400) {
                this.sweet.errorCriticalAlert(
                    'Error al eliminar tarjeta',
                    'La tarjeta no existe'
                );
                return;
            }

            //cuando StatusCode diferente a 200
            if (response.StatusCode !== 200) {
                this.sweet.errorCriticalAlert(
                    'Error al eliminar tarjeta',
                    response.Message ? response.Message : response
                );
                return;
            }

            //cuando StatusCode sea 200
            this.sweet.successCriticalAlert(
                'Eliminacion exitosa',
                'Eliminación exitosa',
                null
            );
            if (this.isModal) {
                this.selectedCard.emit(null);
            }

            //buscando index a remover
            const indexCard = this.listCard.findIndex(
                (cardList) => cardList == card
            );
            if (indexCard >= 0) {
                //removiendo del listado la tarjeta que se elimino
                this.listCard.splice(indexCard, 1);
            }
        } catch (error) {
            this.sweet.errorCriticalAlert(
                'Error al eliminar tarjeta_',
                error.Message ? error.Message : error
            );
        }
    }
}

export interface availableCards {
    Id: string;
    DisplayText: string;
    IsDefault: boolean;
}
