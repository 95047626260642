import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    AfterViewInit,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AddressService } from 'src/utility/services/address.service';
import { AuthUserService } from 'src/utility/services/auth/auth-user.service';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { SweetAlert } from 'src/utility/sweetAlert';
import {
    emailRegexValidators,
    regexNumber,
} from 'src/utility/functions/regex.service';

@Component({
    selector: 'app-client-form',
    templateUrl: './client-form.component.html',
    styleUrls: ['./client-form.component.scss'],
})
export class ClientFormComponent implements OnInit, AfterViewInit {
    formClient: FormGroup;
    client = null;
    regexNumber = regexNumber;
    idClient = 0;
    @Input() create: boolean = true;
    @Input() idUser = 0;
    @Input() isModal: boolean = false;

    @Output() onClickSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClickCancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() formReady: EventEmitter<any> = new EventEmitter<any>();

    validatorsCustom = {
        email: [Validators.pattern(emailRegexValidators)],
    };

    constructor(
        private secureStorage: SecureSimpleStorage,
        private address: AddressService,
        private sweet: SweetAlert,
        private router: Router,
        private authService: AuthUserService,
        private api: ConnectionApiService
    ) {
        this.formClient = new FormGroup({});
    }
    ngAfterViewInit(): void {
        this.setClientInfo();
    }

    ngOnInit() {
        if (!this.create) {
            this.getClientInfo();
        }
    }

    addForm(name: string, control: FormGroup) {
        this.formClient.removeControl(name);
        this.formClient.addControl(name, control);
    }

    async save() {
        if (this.create) {
            const response = await this.newClient();
            this.onClickSave.emit(response);
        } else {
            const response = await this.updateClient();
            this.onClickSave.emit(response);
        }
    }

    cancel() {
        this.onClickCancel.emit();
        this.secureStorage.remove([Contract.editClient]).subscribe();
        if (!this.isModal) {
            this.router.navigateByUrl('/individual/mis-clientes');
        }
    }

    reset() {
        this.formClient.reset();
    }

    getClientInfo() {
        this.secureStorage.get(Contract.editClient).subscribe(
            (data) => {
                this.client = JSON.parse(data);
            },
            (error) => {}
        );
    }

    setClientInfo() {
        //Primer nombre
        this.formClient
            .get('firstName')
            .get('input')
            .setValue(this.client?.name1);

        //Segundo nombre
        this.formClient
            .get('secondName')
            .get('input')
            .setValue(this.client?.name2);

        //Primer apellido
        this.formClient
            .get('firstLastname')
            .get('input')
            .setValue(this.client?.lastName1);

        //Segundo apellido
        this.formClient
            .get('secondLastname')
            .get('input')
            .setValue(this.client?.lastName2);

        //Primer teléfono
        this.formClient
            .get('phone1')
            .get('input')
            .setValue(this.client?.phone1);

        //Segundo teléfono
        this.formClient
            .get('phone2')
            .get('input')
            .setValue(this.client?.phone2);

        //Correo electrónico
        this.formClient.get('email').get('input').setValue(this.client?.email);
    }

    async newClient() {
        let body = {
            clientInfo: {
                name1: this.formClient.get('firstName').get('input').value,
                name2: this.formClient.get('secondName').get('input').value,
                lastName1: this.formClient.get('firstLastname').get('input')
                    .value,
                lastName2: this.formClient.get('secondLastname').get('input')
                    .value,
                phone1: this.formClient.get('phone1').get('input').value,
                phone2: this.formClient.get('phone2').get('input').value,
                email: this.formClient.get('email').get('input').value,
                userId: this.idUser,
            },
        };
        let path = `${environment.apiLinkEntrega}/client`;

        try {
            let response = await this.api.postDataOAC(path, body).toPromise();

            if (response.id && response.id !== '') {
                this.sweet.errorInfoAlert(
                    'Cliente creado',
                    'El cliente se agregó correctamente',
                    'success'
                );
                setTimeout(() => {
                    if (!this.isModal) {
                        this.router.navigateByUrl('/individual/mis-clientes');
                    }
                }, 2000);
                return response.id;
            } else {
                this.sweet.warningAlert(
                    'Error',
                    'Ha ocurrido un error, intentelo nuevamente'
                );
            }
        } catch (error) {
            this.sweet.warningAlert('', error);
        }
        return null;
    }

    async updateClient() {
        let body = {
            name1: this.formClient.get('firstName').get('input').value,
            name2: this.formClient.get('secondName').get('input').value,
            lastName1: this.formClient.get('firstLastname').get('input').value,
            lastName2: this.formClient.get('secondLastname').get('input').value,
            phone1: this.formClient.get('phone1').get('input').value,
            phone2: this.formClient.get('phone2').get('input').value,
            email: this.formClient.get('email').get('input').value,
            userId: this.idUser,
        };
        let path = `${environment.apiLinkEntrega}/client/${this.client.id}`;

        try {
            let response = await this.api.putDataOAC(path, body).toPromise();

            if (response.message == 'Success') {
                this.sweet.errorInfoAlert(
                    'Cliente actualizado',
                    'El cliente se actulizó correctamente',
                    'success'
                );
                setTimeout(() => {
                    if (!this.isModal) {
                        this.router.navigateByUrl('/individual/mis-clientes');
                    }
                }, 2000);
                return this.client.id;
            } else {
                this.sweet.warningAlert(
                    'Error',
                    'Ha ocurrido un error, intentelo nuevamente'
                );
            }
        } catch (error) {
            this.sweet.warningAlert('', error);
        }
        return null;
    }
}
