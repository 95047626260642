import { Injectable } from '@angular/core';
import { ConnectionApiService } from './connection-api.service';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class AddressService {
    constructor(private api: ConnectionApiService) {}
    manageAddress(body: any, loader: boolean): Observable<any> {
        return this.api.postData(body, loader);
    }

    manageAddressService(body: any, loader: boolean): Observable<any> {
        return this.api.postData(body, loader);
    }
}
