<div class="content-heading row">
    <div class="col-12 col-md-9">
        <label
            style="margin: 0"
            class="text-center text-md-left col-12 pl-0 text-h1"
            ><strong>Nuevo dato de facturación</strong></label
        >
    </div>
</div>
<div class="card border-box-guide">
    <div class="row d-flex justify-content-end m-2" [formGroup]="user">
        <div class="col-auto" *ngIf="!customerSelected">
            <div class="form-check form-switch pt-2">
                <label class="switch m-0 text-h3">
                    <input type="checkbox" formControlName="isDefault" />
                    <span></span>
                    <label class="ml-1 mt-2"> Favorita </label>
                </label>
            </div>
        </div>
    </div>
    <div class="card-body">
        <form [formGroup]="user">
            <app-inputv2
                formControlName="{{ this.taxType == 'NIT' ? 'taxid' : 'rtn' }}"
                [control]="
                    this.taxType == 'NIT'
                        ? user.controls.taxid
                        : user.controls.rtn
                "
                [type]="'text'"
                [style]="1"
                [textLabel]="this.taxType"
                [placeholder]="'Ingresar ' + this.taxType"
                [minLength]="this.taxType == 'NIT' ? 2 : 14"
                [maxLength]="this.taxType == 'NIT' ? 13 : 14"
                [requiredSymbol]="'*'"></app-inputv2>
            <app-inputv2
                formControlName="name"
                [control]="user.controls.name"
                [type]="'text'"
                [style]="1"
                [textLabel]="'Nombre'"
                [placeholder]="'Ingresar Nombre'"
                [maxLength]="50"
                [minLength]="2"
                [requiredSymbol]="'*'"></app-inputv2>
            <app-inputv2
                formControlName="address"
                [control]="user.controls.address"
                [type]="'text'"
                [style]="1"
                [textLabel]="'Dirección'"
                [placeholder]="'Ingresar Dirección'"
                [maxLength]="200"
                [minLength]="2"
                [requiredSymbol]="'*'">
            </app-inputv2>
            <div class="form-group d-flex justify-content-center">
                <button
                    (click)="setBilling()"
                    [disabled]="saveBlock"
                    class="btn btn-primary col-12 col-md-12 col-lg-5 px-3 radius-100 btn-save"
                    type="button">
                    <i class="fa fa-save mx-1"></i>
                    Guardar
                </button>
            </div>
        </form>
    </div>
</div>
