/**
 * metodo que convierte el formato mm/yy en el formato
 * en yymm
 * @param fecha fecha en formato mes/año
 */
export function formatFechaExpiracion(fecha: string): string {
    const fechaA = fecha.split('/');
    if (Array.isArray(fechaA) && fechaA.length > 1) {
        return fechaA[1] + fechaA[0];
    }
    return fecha;
}
/**
 * Deja el monto en un formato número
 * @param monto el monto se obtiene en un formato Q. xxx.xx
 *
 */
export function formatCurrencyTarjeta(monto: string): string {
    monto = monto.replace(' ', '');
    monto = monto.replace(',', '');
    monto = monto.replace('Q.', '');
    monto = monto.replace('q.', '');
    return monto;
}

/**
 * Deja la tarjeta sin espacios, por alguna razon no se quitan todos los espacios con replace
 * @param monto el monto se obtiene en un formato Q. xxx.xx
 *
 */
export function formatTarjetaSend(tarjeta: string): string {
    let newCard = '';
    const charCode0: number = '0'.charCodeAt(0);
    const charCode9: number = '9'.charCodeAt(0);
    let charCode: number = 0;
    for (let index = 0; index < tarjeta.length; index++) {
        charCode = tarjeta.charCodeAt(index);
        if (charCode >= charCode0 && charCode <= charCode9) {
            newCard = newCard + tarjeta.charAt(index);
        }
    }
    return newCard;
}

enum regex {
    zona = '^[0-9]{1,2}$',
    decimal = '',
}

export const formatNumber = (value: any) => {
    const format = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GTQ',
    });
    return format.format(value);
};
