<div
    class="container-fluid container-slick d-flex justify-content-center align-items-center"
    id="containerCategoriesCarousel">
    <div
        joyrideStep="step1"
        appJrAnchor="categories"
        id="step1"
        [title]="AllArraySteps[1].header"
        [text]="AllArraySteps[1].text"
        class="h-100 d-flex align-items-center justify-content-center sub-container-slick">
        <ngx-slick-carousel
            #slickModal="slick-carousel"
            class="carousel my-0 py-0 h-100"
            id="carouselDetailCategories"
            [config]="slideConfig"
            (init)="init($event)">
            <div
                ngxSlickItem
                class="slide parcels-center my-0 py-0 h-100"
                style="width: 255px"
                *ngFor="let item of categories; let i = index">
                <div
                    class="w-100 h-100 d-flex justify-content-center align-items-center">
                    <button
                        id="card-category"
                        class="{{
                            item?.ProductCategoryName +
                                ' class-identificator-categories'
                        }}"
                        (click)="selectCategory(item)"
                        [ngClass]="{
                            'category-selected': item == categorySelected,
                            'text-center w-100 h-100 d-flex align-items-center justify-content-center mx-3 cursor-pointer card-category': true
                        }">
                        {{ item?.ProductCategoryName }}
                    </button>
                </div>
            </div>
        </ngx-slick-carousel>
    </div>
    <i
        class="bi icon-arrow-right-gray btnPrev cursor-pointer"
        *ngIf="categories?.length > slideConfig.slidesToShow"
        (click)="next?.slickPrev()"></i>
    <i
        class="bi icon-arrow-right-gray btnNext cursor-pointer"
        *ngIf="categories?.length > slideConfig.slidesToShow"
        (click)="next?.slickNext()"></i>
</div>
