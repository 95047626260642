<div
    [style]="fatherStylePrincipal"
    id="componentContent"
    #componentContent="bs-modal"
    bsModal
    class="modal fade"
    id="{{ idModal }}"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    [config]="{ backdrop: 'static', keyboard: false }"
    [ngClass]="{ firefox: isFirefox }">
    <div
        *ngIf="!video"
        class="modal-dialog modal-{{ size }} modal-dialog-centered {{
            nameClassModalDialog
        }}"
        role="document">
        <div class="modal-content {{ nameStyle }}">
            <div
                class="modal-header"
                [ngClass]="{
                    'bg-transparent': !showHeader,
                    hideHeader: !showHeader,
                    'bg-warning': showHeader
                }">
                <ng-container *ngIf="showHeader">
                    <h4
                        class="modal-title w-100 text-center"
                        id="staticBackdropLabel">
                        <strong>{{ title }}</strong>
                    </h4>
                    <button
                        *ngIf="showClose"
                        #closeButton
                        type="button"
                        class="close"
                        (click)="onClickCloseFunction()">
                        <em class="fa-1x icon-close mr-1 text-white"></em>
                    </button>
                </ng-container>
            </div>
            <div
                class="modal-body bg-white {{ pt ? '' : 'pt-0' }} {{
                    fatherClass
                }}">
                <ng-content class="bodyElements"></ng-content>
            </div>
            <div
                *ngIf="showFooter"
                class="modal-footer d-flex justify-content-center">
                <ng-content select=".footer"></ng-content>
            </div>
        </div>
    </div>
</div>
