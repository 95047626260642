import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    AfterContentInit,
    ViewChildren,
    QueryList,
    ElementRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalComponent } from 'src/components/modal/modal.component';
import { Ng2SearchPipe } from 'ng2-search-filter';
import { EmittersService } from 'src/utility/services/emitters.service';
@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
    @Input() fatherClass: string = '';
    @Input() title: string = 'Opciones';
    @Input() label: string = '';
    @Input() type: string = 'text';
    @Input() placeholder: string = 'Seleccione una opción';
    @Input() msgInvalid: string = 'Campo inválido';
    @Input() required: boolean = false;
    formInput: FormGroup;
    @Output() formReady = new EventEmitter<FormGroup>();
    @Output() changeSelected = new EventEmitter<any>();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() click = new EventEmitter<any>();
    @Output() onClickIcon = new EventEmitter<any>();
    @Input() listSelectWithHeadersRadio: itemSelectWithRadioButton[] = [];
    @Input() list: any[] = [];
    @Input() tooltipText: string = '';
    @Input() legend: string | null = null;
    @Input() disabled: boolean = false;
    @Input() id: string = null;
    @Input() headersRadioButton: boolean = false;
    public value: any = '';
    public selectedText: any = '';
    public searchText: string = '';
    public invalid: boolean = false;
    public valid: boolean = false;
    modalid: any;
    @ViewChild('modalselect', { static: false }) modalselect;
    @ViewChild('tooltipInput', { static: false }) tooltipInput;
    @ViewChild('searchInput', { static: false }) searchInput;
    @ViewChildren('itemsOption') itemsOptionElRef:
        | QueryList<ElementRef>
        | undefined;
    @Output() onClickClose: EventEmitter<any> = new EventEmitter();

    showModalEdit: boolean = false;

    constructor(
        private fb: FormBuilder,
        private pipeSearch: Ng2SearchPipe,
        private secureStorage: SecureSimpleStorage,
        private emitService: EmittersService
    ) {
        this.formInput = this.fb.group({
            input: ['', []],
        });
        this.modalid = 'idmodal-' + Math.floor(Math.random() * 100 + 1);
    }
    ngOnInit(): void {
        if (this.required)
            this.formInput.get('input')?.setValidators([Validators.required]);
        this.formReady.emit(this.formInput);
        setTimeout(() => {
            document.getElementById('buttonOpenSelect').blur();
        }, 0);
    }
    onChange = (_: any) => {};
    itemSelected(item) {
        this.valid = true;
        this.invalid = false;
        this.formInput.markAsTouched();
        this.searchText = '';
        if (
            this.listSelectWithHeadersRadio[0]?.inputSearchAddresss == false ||
            !this.listSelectWithHeadersRadio[0]?.inputSearchAddresss
        ) {
            this.selectedText = item.text;
            this.formInput.get('input')?.setValue(item);
        }
        this.changeSelected.emit(item);
    }

    markInvalid() {
        if (this.required) {
            this.invalid = true;
        }
        this.valid = false;
        this.selectedText = '';
        this.formInput.markAsTouched();
        this.searchText = '';
        this.formInput.get('input')?.setValue(null);
        this.changeSelected.emit(null);
    }

    markInvalidReset() {
        if (this.required) {
            this.invalid = false;
        }
        this.valid = false;
        this.selectedText = '';
        this.formInput.markAsTouched();
        this.searchText = '';
        this.formInput.get('input')?.setValue('');
        this.changeSelected.emit('');
    }

    async openModal() {
        document.getElementById('buttonOpenSelect')?.blur();
        this.click.emit();
        if (this.required && this.selectedText == '') {
            this.invalid = true;
        }
        this.searchText = '';
        let modal: ModalComponent = this.modalselect;
        modal.Open();

        await new Promise((resolve) => setTimeout(resolve, 500));
        let inputsearch = this.searchInput.nativeElement;
        inputsearch?.focus();
    }

    onEnterSearch() {
        if (this.itemsOptionElRef) {
            let listfilter = this.pipeSearch.transform(
                this.list,
                this.searchText
            );
            if (listfilter.length > 0) {
                this.itemSelected(listfilter[0]);
                this.modalselect.Close();
            }
        }
    }
    /**
     * @description Método para editar dirección desde el modal de select
     * @author Byron de León
     * @date 04/01/2023
     * @memberof SelectComponent
     */
    // editAddress(valor) {
    //     this.showModalEdit = valor;
    //     this.emitService.emitClickEdit();
    // }

    onClickRadioHeader(item: itemSelectWithRadioButton) {
        for (const iterator of this.listSelectWithHeadersRadio) {
            iterator.activeDefault = false;
        }
        item.activeDefault = true;
    }

    getItemListHeaders = () => {
        if (this.headersRadioButton) {
            return this.listSelectWithHeadersRadio.find(
                (item) => item.activeDefault
            ).listItems;
        } else {
            return this.list;
        }
    };
}

export interface itemSelect {
    text: string;
    value: any;
    isTitle?: boolean;
    iconTitle?: string;
}

export interface itemSelectWithRadioButton {
    titleRadio: string;
    activeDefault?: boolean;
    listItems: itemSelect[];
    action?: any;
    inputSearchAddresss?: boolean;
}
