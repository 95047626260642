<div class="content-heading row" *ngIf="!isModal">
    <label
        style="margin: 0"
        class="text-center text-md-left col-12 col-md-9 text-h1">
        <strong>Términos y condiciones</strong>
    </label>
</div>
<div id="container">
    <accordion *ngIf="true" [closeOthers]="true">
        <!-- Portal -->
        <accordion-group
            *ngIf="!isModal && countrySelect.value === 'GT'"
            id="portal"
            [isOpen]="selectedItem == 'portal'"
            (isOpenChange)="openAccordion($event, 'portal')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'portal'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'portal'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Portal Web</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify">
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>Alimentos y Perecederos.</li>
                    <li>Productos con envase de vidrio.</li>
                    <li>
                        Contiene mercancías que no hayan pasado su proceso
                        formal de aduanas.
                    </li>
                    <li>
                        Está clasificado como sustancia tóxica, mercancía
                        peligrosa, artículo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de cuarenta y ocho (48) horas a partir de la
                    fecha en que la empresa aceptó el Envío. A falta de una
                    reclamación o vencido el plazo perentorio indicado, la
                    empresa quedará eximida de toda responsabilidad. Las
                    reclamaciones están limitadas a una por Envío y su finiquito
                    será completo y final para toda pérdida y daño en relación
                    con dicho Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte, o a través de los sistemas
                    automatizados de la empresa, y pague el precio aplicable. La
                    contraprestación no cubre daños o pérdidas indirectas, ni
                    las pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: <br /><br />
                </p>
                <ul>
                    <li>
                        El envío es aceptable para su transporte de acuerdo a
                        estos términos y condiciones;
                    </li>
                    <li>
                        El Envío fue preparado en instalaciones seguras por
                        personal fiable y se protegió de interferencias no
                        autorizadas durante su preparación, almacenamiento y
                        traslado del mismo;
                    </li>
                    <li>
                        El Remitente ha cumplido con la normativa aplicable en
                        materia de control de exportaciones, sanciones,
                        normativas y regulaciones de aduanas u otros
                        requerimientos regulatorios y restricciones relacionadas
                        con la importación, exportación, tránsito o transmisión
                        de las mercancías;
                    </li>
                    <li>
                        El Remitente ha declarado cualquier mercancía militar o
                        de doble uso contenida en el envío sujeta a
                        autorizaciones gubernamentales.
                    </li>
                    <li>
                        El Remitente ha proporcionado toda la información,
                        permisos, licencias u otras autorizaciones
                        gubernamentales y documentos requeridos por la
                        legislación aplicable o a solicitud de y toda la
                        información, permisos, licencias y otras autorizaciones
                        gubernamentales y documentos proporcionadas por el
                        Remitente o sus representantes son verdaderas, completas
                        y precisas, incluidos el valor y la descripción y las
                        mercancías, y la información del remitente y del
                        destinatario.
                    </li>
                    <li>
                        En el caso de que proporcione datos personales el
                        Remitente ha cumplido con sus obligaciones legales para
                        tratar y compartir dichos datos, que incluyen informar a
                        las personas afectadas de que los datos personales
                        (incluidos el email y el número de teléfono del
                        destinatario) son requeridos para el transporte,
                        despacho de aduanas y entrega.
                    </li>
                    <li>
                        El Remitente acepta que el Envío pueda transportarse por
                        cualquier medio de transporte y ruta, que pueda ser
                        desviado, e inclusive parar en lugares de escala
                        intermedios.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país. No obstante, si la legislación
                    aplicable permitiera la elección de fuero o de jurisdicción,
                    esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                </p>
            </div>
        </accordion-group>
        <accordion-group
            *ngIf="!isModal && countrySelect.value === 'HN'"
            id="portal"
            [isOpen]="selectedItem == 'portal'"
            (isOpenChange)="openAccordion($event, 'portal')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'portal'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'portal'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Portal Web HN</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify">
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>Alimentos y Perecederos.</li>
                    <li>Productos con envase de vidrio.</li>
                    <li>
                        Contiene mercancías que no hayan pasado su proceso
                        formal de aduanas.
                    </li>
                    <li>
                        Está clasificado como sustancia tóxica, mercancía
                        peligrosa, artículo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de cuarenta y ocho (48) horas a partir de la
                    fecha en que la empresa aceptó el Envío. A falta de una
                    reclamación o vencido el plazo perentorio indicado, la
                    empresa quedará eximida de toda responsabilidad. Las
                    reclamaciones están limitadas a una por Envío y su finiquito
                    será completo y final para toda pérdida y daño en relación
                    con dicho Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte, o a través de los sistemas
                    automatizados de la empresa, y pague el precio aplicable. La
                    contraprestación no cubre daños o pérdidas indirectas, ni
                    las pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: <br /><br />
                </p>
                <ul>
                    <li>
                        El envío es aceptable para su transporte de acuerdo a
                        estos términos y condiciones;
                    </li>
                    <li>
                        El Envío fue preparado en instalaciones seguras por
                        personal fiable y se protegió de interferencias no
                        autorizadas durante su preparación, almacenamiento y
                        traslado del mismo;
                    </li>
                    <li>
                        El Remitente ha cumplido con la normativa aplicable en
                        materia de control de exportaciones, sanciones,
                        normativas y regulaciones de aduanas u otros
                        requerimientos regulatorios y restricciones relacionadas
                        con la importación, exportación, tránsito o transmisión
                        de las mercancías;
                    </li>
                    <li>
                        El Remitente ha declarado cualquier mercancía militar o
                        de doble uso contenida en el envío sujeta a
                        autorizaciones gubernamentales.
                    </li>
                    <li>
                        El Remitente ha proporcionado toda la información,
                        permisos, licencias u otras autorizaciones
                        gubernamentales y documentos requeridos por la
                        legislación aplicable o a solicitud de y toda la
                        información, permisos, licencias y otras autorizaciones
                        gubernamentales y documentos proporcionadas por el
                        Remitente o sus representantes son verdaderas, completas
                        y precisas, incluidos el valor y la descripción y las
                        mercancías, y la información del remitente y del
                        destinatario.
                    </li>
                    <li>
                        En el caso de que proporcione datos personales el
                        Remitente ha cumplido con sus obligaciones legales para
                        tratar y compartir dichos datos, que incluyen informar a
                        las personas afectadas de que los datos personales
                        (incluidos el email y el número de teléfono del
                        destinatario) son requeridos para el transporte,
                        despacho de aduanas y entrega.
                    </li>
                    <li>
                        El Remitente acepta que el Envío pueda transportarse por
                        cualquier medio de transporte y ruta, que pueda ser
                        desviado, e inclusive parar en lugares de escala
                        intermedios.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país. No obstante, si la legislación
                    aplicable permitiera la elección de fuero o de jurisdicción,
                    esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                </p>
            </div>
        </accordion-group>
        <!-- Membresía -->
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[0].show)) &&
                countrySelect.value === 'GT'
            "
            id="membresia"
            [isOpen]="selectedItem == 'membresia'"
            (isOpenChange)="openAccordion($event, 'membresia')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'membresia'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'membresia'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Club Forza</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify">
                    El programa de lealtad Club Forza (el “Programa Club Forza”)
                    es el programa de lealtad de Delivery Express, S.A., una
                    sociedad constituida y existente de acuerdo con las leyes
                    vigentes de la República de Guatemala.
                    <br /><br />

                    Los términos y condiciones del Programa Club Forza
                    detallados a continuación son efectivos a partir del 1 de
                    enero, 2023 (incluyendo las enmiendas y modificaciones que
                    cada cierto tiempo se realicen, en adelante, los “Términos y
                    Condiciones”). El Programa Club Forza remplazará todos los
                    términos y condiciones previos de cualquier programa de
                    lealtad en el cual cualquiera de los clientes haya
                    participado.
                    <br /><br />

                    Cada miembro del Programa Club Forza (individualmente un
                    “Miembro” y en conjunto los “Miembros”) estarán sujetos a
                    estos Términos y Condiciones.
                    <br /><br />

                    Al contratar los servicios de Delivery Express, S.A. que
                    opera sus actividades bajo la marca comercial Forza Delivery
                    Express (en adelante “la empresa”), usted, en calidad de
                    “cliente”, acuerda y acepta los términos y condiciones del
                    programa “CLUB FORZA” la cual tiene por objetivo brindar
                    beneficios y accesos exclusivos a promociones, descuentos,
                    recolecciones sin costo, con frecuencia programada de visita
                    y muchos beneficios más.
                    <br /><br />

                    <b>TERMINOS Y CONDICIONES GENERALES</b>
                    <br />
                    Los Términos y Condiciones presentes, así como otras reglas,
                    ofertas especiales y beneficios del Programa Club Forza
                    podrán ser modificados, enmendados y/o substituidos en
                    cualquier momento y de cualquier manera, sin previo aviso a
                    sus Miembros.

                    <br /><br />
                    Al aceptar estos Términos y Condiciones, cada miembro
                    acuerda que la terminación del Programa Club Forza y/o
                    cambios, enmiendas o modificaciones al programa o a los
                    Términos y Condiciones no dará lugar a ninguna reclamación
                    por daños de cualquier tipo por parte de algún Miembro
                    contra Delivery Express S.A.
                    <br /><br />
                    Estos Términos y Condiciones regulan la membresía y
                    participación de los Miembros en el Programa Club Forza y
                    ningún acuerdo o regulación de ley o de capital será
                    implementado o incorporado en este documento de ninguna
                    manera.
                    <br /><br />
                    Los Miembros aceptan estos Términos y Condiciones como parte
                    del proceso estándar de inscripción haciendo clic en el
                    espacio correspondiente, y también acuerdan en que lo el
                    pago y uso de la membresía constituye la aceptación expresa
                    de estos Términos y Condiciones y de la Política de
                    Privacidad.
                    <br /><br />
                    <b>Elegibilidad de los Miembros</b>
                    <br />
                    Las personas naturales mayores de dieciocho (18) años de
                    edad son elegibles para convertirse en Miembros
                    (individualmente "Persona Elegible"). Las personas no
                    naturales (tales como corporaciones, sociedades, sociedades
                    de responsabilidad limitada y otras empresas y entidades sin
                    fines de lucro) tendrán elegibilidad limitada para
                    convertirse en Miembros del Programa Club Forza en este
                    momento.
                    <br /><br />
                    <b>Inscripción en el Programa Club Forza</b>
                    <br />
                    La membresía del Programa Club Forza es personal e
                    intransferible. Las Personas Elegibles podrán inscribirse en
                    el Programa Forza Club en el Sitio Web o en ciertos puntos
                    de servicio de Forza Delivery y deberán proporcionar toda la
                    información requerida para su registro. Cada Miembro es
                    responsable de la integridad y exactitud de toda la
                    información personal proporcionada al momento de inscripción
                    en el Programa Club Forza y de actualizar su información,
                    incluyendo dirección de correo electrónico, dirección física
                    y número de teléfono. Delivery Express S.A. no tendrán
                    ninguna responsabilidad por correspondencia mal dirigida o
                    retrasada o por consecuencia de las mismas, y cada Miembro
                    asume toda la responsabilidad derivada de cualquier
                    información incompleta, parcial o inexacta facilitada al
                    Programa Club Forza.
                    <br /><br />
                    Toda la información de la cuenta constituye información
                    propiedad del Programa Club Forza y los Miembros pueden
                    acceder a esta información sólo con el propósito de obtener
                    información sobre sus propias cuentas. Los Miembros no
                    podrán delegar o conceder el acceso (a través de poder
                    notarial, contrato o de otro tipo) a sus cuentas o a la
                    información propiedad del Programa Club Forza a terceros. La
                    información de la cuenta no podrá ser reproducida o
                    distribuida sin el consentimiento previo por escrito de
                    Delivery Express S.A.
                    <br /><br />
                    Una vez aceptado en el Programa Club Forza, el individuo se
                    convierte en Miembro del Programa Club Forza y acepta los
                    presentes Términos y Condiciones y la política de
                    privacidad.
                    <br /><br />
                    Al inscribirse en el Programa Club Forza, los Miembros
                    recibirán un número de cuenta único, que será intransferible
                    y será referido como el "Número de Club Forza" o "número de
                    cuenta" del Miembro; tal número de identificación será
                    válido únicamente en relación con las transacciones del
                    Programa Club Forza. Los Miembros podrán tener acceso a
                    iniciar sesión en el Sitio Web, donde podrán crear una
                    contraseña para acceder a su cuenta dentro del Programa,
                    tendrán acceso a una variedad de herramientas de
                    autoservicio, y serán capaces de completar las transacciones
                    de envío y redención de puntos a las que aplica la
                    membresía.
                    <br /><br />
                    La membresía en el Programa Club Forza es un privilegio que
                    podrá ser revocado por la empresa a su entera discreción y
                    sin ninguna obligación de compensar a un Miembro o de
                    reconocer puntos o beneficios ganados o acumulados por un
                    Miembro a la fecha de terminación de la membresía de ese
                    Miembro en el Programa Club Forza o la suspensión de la
                    cuenta del Miembro, en casos incluyendo, pero sin limitación
                    a, (i) la conducta irregular, fraude o actividades
                    fraudulentas o conducta dolosa, así como el uso de los
                    servicios de Delivery Express S.A. para transportar
                    artículos de procedencia ilegal o que constituyan estafa o
                    sospecha de esta hacia cualquier persona o empresa. (ii)
                    cualquier violación de las leyes, normas o reglamentos
                    aplicables, (iii) conducta dolosa del Miembro incluyendo,
                    pero no limitado a, cualquier tergiversación de la
                    información proporcionada a la empresa, (iv) el abuso verbal
                    o físico por un Miembro a cualquier empleado, agente,
                    director o funcionario de la empresa, o a sus respectivas
                    sucursales cuando sea o no determinado que el Miembro es
                    civilmente, penalmente o de otra manera responsable de tales
                    acciones, (v) el incumplimiento de cualquier ley aplicable,
                    incluyendo sin limitación, reglas de tarifa, condiciones de
                    transporte, aranceles u otros, (vi) el incumplimiento de
                    estos Términos y Condiciones o contrato de transporte de
                    envíos, o (vii) conducta de otra manera perjudicial para los
                    intereses del Programa Club Forza.
                    <br /><br />
                    La terminación de la cuenta de un Miembro resultará en la
                    pérdida de todos los privilegios y/o beneficios a los cuales
                    el Miembro tiene derecho a, bajo el Programa Club Forza,
                    incluyendo, pero no limitado a, la revocación de los accesos
                    y cualquier acción civil o penal que la empresa considere
                    pertinente.
                    <br /><br />
                    Un Miembro cuya cuenta ha sido terminada no será elegible
                    para crear una nueva cuenta en el Programa Club Forza salvo
                    a sola discreción de la empresa. Si la empresa decide no
                    terminar la cuenta del Miembro por cualquiera de las
                    acciones arriba mencionadas y permiten que el Miembro
                    mantenga su membresía en el Programa Club Forza, la empresa
                    podrá tomar otras acciones como descontar de la cuenta del
                    Miembro puntos acumulados y/o reducir su nivel de beneficios
                    en el Programa Club Forza, sin perjuicio de los recursos
                    legales disponibles bajo la ley aplicable.
                    <br /><br />
                    Para asegurar el cumplimiento de estos Términos y
                    Condiciones, las condiciones de transporte aplicable y/o las
                    tarifas, la empresa se reserva el derecho de auditar
                    cualesquiera y todas las cuentas de los Miembros sin previo
                    aviso. Durante el proceso de auditoría, podrá ser necesario
                    bloquear a los Miembros el acceso a sus cuentas.
                    <br /><br />
                    Durante el proceso de una auditoría o investigación, la
                    información de las cuentas de los Miembros podrá ser
                    compartida con cualquier tercero a quien la empresa haya
                    contratado para que asistan en dichas auditorías o
                    investigaciones.
                    <br /><br />
                    En el caso en que una auditoría o investigación revele
                    discrepancias o posibles violaciones a estos Términos y
                    Condiciones, la empresa podrá, sin previo aviso a los
                    Miembros y en adición a cualquier otro recurso descrito en
                    este documento o cualquier otro disponible (i) demorar el
                    procesamiento de solicitudes de redención de puntos, (ii)
                    cancelar cualquier redención de puntos pendientes, (iii)
                    retirar todos los puntos acumulados incorrectamente (ya sea
                    por error, fraude, discrepancia y/o por violación a los
                    Términos y Condiciones), (iv) retener estados de cuenta y/o
                    suspender la cuenta de algún Miembro y (v) terminar la
                    membresía de un Miembro en el Programa Club Forza.
                    <br /><br />
                    <b>Comercios Afiliados</b>
                    <br />
                    Los "comercios afiliados" de Club Forza incluyen
                    restaurantes, tiendas, u otras entidades que ofrecen
                    productos, servicios y/o descuentos en estos como
                    recompensas en el marco del programa Club Forza para los
                    miembros que usan sus productos o servicios.
                    <br /><br />
                    Cada comercio determina la tasa de descuento y beneficios
                    otorgados a los miembros de Club Forza los cuales pueden
                    variar en el tiempo y a su sola conveniencia sin que esto
                    implique una responsabilidad o compromiso por parte de
                    Delivery Express S.A.
                    <br /><br />
                    Los bienes y servicios prestados por cualquier comercio
                    afiliado del Programa Club Forza a los Miembros, que
                    permitan premiar con descuentos o que han sido obtenidos por
                    estos, son de exclusiva responsabilidad de dicho comercio.
                    La empresa no garantiza la satisfacción de los Miembros por
                    los productos o servicios que un Miembro puede adquirir u
                    obtener mediante el uso de su membresía de un comercio
                    afiliado del Programa Club Forza. Cada reclamación
                    relacionada con dichos servicios deberá ser dirigida
                    únicamente a aquellos comercios afiliados del Programa Club
                    Forza y cada Miembro, al convertirse en Miembro del Programa
                    Club Forza, mantiene a la empresa libre de reclamo o
                    solicitud de indemnización por cualquier o todos los daños
                    sufridos por los servicios prestados por cualquier compañía,
                    empresas y / o compañías que presten servicios a los
                    Miembros.
                    <br /><br />
                    Los comercios afiliados no tienen relación accionaria con
                    Delivery Express S.A. por lo que nunca se debe asociar un
                    vínculo más allá de la relación comercial con el programa
                    Club Forza. Delivery Express S.A. no asume ninguna
                    responsabilidad por acciones o violaciones en la que dichos
                    comercios puedan incurrir.
                    <br /><br />
                    <b>Acumulación de Puntos</b>
                    <br />
                    Los puntos pueden ser acumulados por los Miembros solamente
                    luego de que un Miembro se inscriba oficialmente en el
                    Programa Club Forza. La cantidad de puntos acumulados por el
                    Miembro depende de las condiciones de acumulación de puntos
                    del Programa Club Forza en vigencia al momento de la
                    actividad en que se ganan los puntos.

                    <br /><br />
                    Los puntos serán acumulados en la cuenta del Miembro sólo
                    cuando el Miembro haga uso de los medios de acumulación
                    reconocidos por el Programa Club Forza. Los Miembros no
                    podrán acumular puntos para la utilización de servicios de
                    consecución de puntos por otras personas diferentes a ellos
                    mismos, Para asegurar el recibo del crédito para puntos de
                    una actividad de consecución de puntos, el Miembro deberá
                    presentar su Número Club Forza.
                    <br /><br />
                    Los Miembros podrán buscar los puntos ganados en el Sitio
                    Web, iniciando sesión en sus cuentas usando su Contraseña.
                    Es responsabilidad de cada Miembro asegurar que los puntos
                    ganados sean debidamente acreditados a su cuenta.
                    <br /><br />
                    Cuando un Miembro afirme que puntos han sido ganados pero no
                    acreditados, o en el caso en que la empresa considere que
                    una verificación es requerida, la empresa se reserva el
                    derecho de solicitar pruebas para la acumulación por parte
                    de los Miembros, incluyendo, pero sin limitarse a, copias de
                    facturas, recibos de envió o documentación similar,
                    verificando que cualquier transacción de consecución de
                    puntos alegada haya sido comprada o contratada.
                    <br /><br />
                    La empresa se reserva el derecho de establecer puntos de
                    bonificación y ofertas promocionales del Programa Club
                    Forza, disponibles selectivamente a ciertos Miembros, en
                    base a su actividad de compra, ubicación geográfica u otros
                    factores determinados a discreción de la empresa. Los puntos
                    ofrecidos a los Miembros por utilizar los productos o
                    servicios de un socio de negocios del Programa Club Forza
                    podrán variar a discreción de la empresa y detalles de
                    ofertas específicas según sean publicadas cada cierto
                    tiempo.
                    <br /><br />
                    La empresa es la única parte autorizada para determinar si
                    se acreditarán puntos a la cuenta de un Miembro y se
                    reservan el derecho de rechazar o revocar dichos puntos a su
                    discreción cuando se cometa o se sospeche de un error.
                    <br /><br />
                    <b>Sitio Web del Programa Club Forza</b>
                    <br />
                    El Programa Club Forza pone a disposición de sus Miembros el
                    Sitio Web
                    <a href="http://www.forzadelivery.com" target="_blank"
                        >http://www.forzadelivery.com</a
                    >
                    a través del cual los Miembros podrán, entre otras cosas,
                    completar transacciones en línea, ver información de cuenta
                    y redimir puntos. La empresa se reserva el derecho de
                    interrumpir, modificar, actualizar y/o alterar el Sitio Web
                    y su contenido, funcionalidad y/o el acceso sin previo aviso
                    a los Miembros ya sea por motivos técnicos, de seguridad, de
                    control, de mantenimiento o cualquier otra razón.
                    <br /><br />
                    La empresa no garantiza el uso contínuo del Sitio Web o que
                    el Sitio Web funcione sin error y la empresa no serán
                    responsable por cualquier interrupción en el uso del Sitio
                    Web por cualquier razón o por la falta de disponibilidad de
                    los Miembros. Cada Miembro que acceda al Sitio Web es
                    responsable de proteger su equipo con un antivirus,
                    antimalware y otros programas similares.
                    <br /><br />
                    La información, productos y servicios publicados cada cierto
                    tiempo en el sitio web pueden incluir errores y/o puede no
                    estar actualizado, y la empresa no será responsable de la
                    veracidad de la información contenida en el Sitio Web. La
                    empresa, sin embargo, procurará asegurar que la información
                    publicada en el Sitio Web sea exacta y oportuna.
                    <br /><br />
                    Los enlaces a otras páginas web se pueden encontrar en el
                    Sitio Web a través del cual los Miembros pueden realizar
                    transacciones y acumular y/o redimir puntos. La empresa no
                    es responsable por el contenido, exactitud o veracidad de
                    las páginas web de terceros a las que el Sitio Web ofrece
                    enlaces o de las políticas de confidencialidad o privacidad
                    o los términos y condiciones de dichas páginas web; por lo
                    tanto, los miembros son responsables de la revisión y el
                    cumplimiento de los términos, condiciones y políticas de
                    cada página web de terceros antes y/o durante su visita a
                    ellos. La empresa no garantiza ni asume responsabilidad
                    alguna por daños que pudieran derivarse de la utilización de
                    la información o material contenido o mostrado en cualquier
                    página web de terceros que el Sitio Web pueda ofrecer como
                    enlaces. La empresa no será responsable de las acciones,
                    errores, omisiones o incumplimientos de los propietarios y
                    operadores de páginas web de terceros o por daños.
                    <br /><br />
                    <b>DATOS DEL PROGRAMA</b>
                    <br />
                </p>
                <ul>
                    <li>Vigencia: Del 24 de agosto de 2023 en adelante.</li>
                    <li>
                        Servicios que aplican al beneficio: Servicio Estándar y
                        Servicio C.O.D.
                    </li>
                    <li>Lugar en que aplica: República de Guatemala.</li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>MECÁNICA DEL PROGRAMA Y CARACTERÍSTICAS</b>
                    <br />
                    Durante la vigencia del programa, todos los clientes nuevos
                    y actuales elegibles del programa Club Forza podrán realizar
                    sus envíos a toda la República de Guatemala tienen la
                    posibilidad de adquirir los beneficios que el club les
                    proporciona.
                    <br /><br />
                    Los clientes deberán de ingresar, registrar y activar la
                    membresía desde nuestro portal web
                    <a href="https://www.forzadelivery.com" target="_blank">
                        www.forzadelivery.com</a
                    >. Una vez activado y pagado, podrán realizar sus envíos a
                    nivel nacional, pagando el importe que éstos representen
                    según las tarifas vigentes en ese momento (envíos menores de
                    10 libras) obteniendo los beneficios como: acumulación de
                    puntos por cada envío realizado, posibilidad de comprar
                    planes de descuentos en envíos que pueden ser desde el 10%
                    en adelante, según su valor de compra; posibilidad de
                    comprar paquetes con envíos incluidos desde 50 envíos en
                    adelante, según su valor de compra; promociones especiales
                    en comercios afiliados, recolecciones sin costo, fila
                    preferencial en Agencias Express Center, atención y gestión
                    de reclamos, incluyendo su resolución, en un tiempo menor
                    comparado con los clientes regulares, envío de devoluciones
                    al remitente sin costo. <br /><br />
                    El cliente debe poseer una tarjeta de crédito o débito de
                    banco del sistema del país, la cual deberá estar vigente y
                    activa para realizar transacciones.
                    <br /><br />
                    <span>
                        <b
                            >DETALLES DE LOS PLANES DE DESCUENTO Y SUS
                            CARACTERÍSTICAS:</b
                        >
                        <br />
                        <br />
                        <ul>
                            <li>
                                Los planes de descuentos son promociones
                                exclusivas para los miembros activos del Club
                                Forza y a los cuales tienen derecho de acceder
                                por medio de una compra de acuerdo al valor y
                                nivel de descuento que representen. Delivery
                                Express, S. A. decidirá por sí misma y a su
                                entera discreción los niveles de descuento
                                disponibles para la compra así como los valores
                                de venta que tenga cada uno. Los planes de
                                descuento funcionan otorgando un porcentaje (%)
                                de disminución en el valor de la tarifa vigente
                                al momento de realizar el envío. Los envíos a
                                que hace referencia este beneficio, al igual que
                                el CLUB FORZA, son envíos con peso máximo de
                                hasta 10 libras. El valor de descuento será
                                únicamente sobre el valor del flete como tal, no
                                incluye ni exime recargos como sobrepeso,
                                comisiones por el servicio de cobro contra
                                entrega, recargos por cobrar al destinatario y
                                todos aquellos que la empresa considere
                                pertinentes, según sus políticas de precios y
                                servicios vigentes. El sistema de tarificación
                                aplicable o la forma de calcular la tarifa
                                dentro de los planes será la misma que Delivery
                                Express utiliza para sus transacciones normales
                                (cobro por cobertura). Los envíos realizados
                                utilizando el beneficio de algún plan de
                                descuento también pueden permitir la acumulación
                                de puntos por cada envío realizado. Los planes
                                tendrán una vigencia de hasta seis (6) meses,
                                siempre y cuando este tiempo esté dentro de los
                                límites de cobertura que otorga la membresía
                                activa. La empresa se reserva el derecho de
                                modificar total o parcialmente los Términos y
                                Condiciones del beneficio “PLANES DE DESCUENTO”
                                así como el interpretar las disposiciones acá
                                inmersas, siendo inapelables las decisiones que
                                la empresa tome en este sentido, así como
                                también sobre aspectos no previstos en las
                                presentes. Cualquier modificación que se
                                produzca por causas ajenas no imputables a la
                                empresa será publicada para conocimiento del
                                público en general, sin que ello dé lugar a
                                reclamo o indemnización alguna. La empresa
                                también podrá finalizar anticipadamente, o bien,
                                extender la vigencia de los planes a que hace
                                referencia este párrafo a su entera y absoluta
                                discreción.
                            </li>
                        </ul>
                    </span>
                    <br />
                    <span>
                        <b
                            >DETALLES DE LOS PAQUETES DE ENVÍOS Y SUS
                            CARACTERÍSTICAS:</b
                        >
                        <br />
                        <br />
                        <ul>
                            <li>
                                Los paquetes de envíos incluidos son promociones
                                exclusivas para los miembros activos del Club
                                Forza y a los cuales tienen derecho de acceder
                                por medio de una compra de acuerdo al valor y
                                número de envíos que incluyan. Delivery Express,
                                S. A. decidirá por sí misma y a su entera
                                discreción el número de envíos incluidos en cada
                                paquete disponible para la compra así como los
                                valores de venta que tenga cada uno. Los envíos
                                a que hace referencia este beneficio, al igual
                                que el CLUB FORZA, son envíos con peso máximo de
                                hasta 10 libras. El valor incluido en el paquete
                                será únicamente el del valor del flete como tal,
                                no incluye ni exime recargos como sobrepeso,
                                comisiones por el servicio de cobro contra
                                entrega, recargos por cobrar al destinatario y
                                todos aquellos que la empresa considere
                                pertinentes, según sus políticas de precios y
                                servicios vigentes. Los paquetes de envíos
                                incluidos funcionan otorgando una cantidad de
                                envíos disponibles para utilizar por parte del
                                cliente. El sistema de tarificación aplicable o
                                la forma de calcular la tarifa dentro de los
                                paquetes de envíos incluidos será preferencial,
                                asignando un mismo valor o precio a todos los
                                envíos disponibles, según la compra realizada,
                                no importando el lugar de entrega dentro de la
                                República de Guatemala al que se dirigiera tal
                                envío. Los paquees de envíos incluidos tendrán
                                una vigencia de hasta seis (6) meses de duración
                                o hasta que el número de envíos incluidos al que
                                hace referencia se hayan agotado, lo que suceda
                                primero; siempre y cuando este tiempo esté
                                dentro de los límites de cobertura que otorga la
                                membresía activa. Una vez agotado el tiempo de
                                vigencia, la empresa no será responsable bajo
                                ninguna circunstancia de resarcir, reponer,
                                enmendar, compensar o similares a aquellos
                                clientes que con conocimiento de causa o no, no
                                hayan consumido los envíos que contenía el plan
                                adquirido. La empresa se reserva el derecho de
                                modificar total o parcialmente los Términos y
                                Condiciones del beneficio “PAQUETES DE ENVÍOS
                                INCLUIDOS” así como el interpretar las
                                disposiciones acá inmersas, siendo inapelables
                                las decisiones que la empresa tome en este
                                sentido, así como también sobre aspectos no
                                previstos en las presentes. Cualquier
                                modificación que se produzca por causas ajenas
                                no imputables a la empresa será publicada para
                                conocimiento del público en general, sin que
                                ello dé lugar a reclamo o indemnización alguna.
                                La empresa también podrá finalizar
                                anticipadamente, o bien, extender la vigencia de
                                los paquetes de envíos incluidos que hace
                                referencia este párrafo a su entera y absoluta
                                discreción.
                            </li>
                        </ul>
                    </span>
                    <br />
                    <b>RESTRICCIONES</b>
                    <br />
                </p>
                <ul>
                    <li>
                        Válido para clientes nuevos y actuales que generen sus
                        guías a través del portal web y que sean elegibles para
                        utilizar el programa Club Forza.
                    </li>
                    <li>
                        El (los) descuentos que otorgan los paquetes de
                        descuentos y/o la tarifa de los paquetes de envíos
                        incluidos son válidos únicamente sobre la tarifa base
                        del envió en los servicios Estándar y COD.
                    </li>
                    <li>
                        El (los) descuentos que otorgan los paquetes de
                        descuentos y/o la tarifa de los paquetes de envíos
                        incluidos no aplica para: Seguro en tránsito de la
                        mercancía, exceso de peso, cobros adicionales por
                        servicio collect, cobros de comisión COD, material de
                        empaque, servicio internacional, otros cobros o recargos
                        que genere la transacción del envió.
                    </li>
                    <li>
                        No se aplica en combinación con otras promociones
                        vigentes o futuras.
                    </li>
                    <li>
                        Válido únicamente para usuarios que posean tarjeta de
                        crédito o débito registrada para realizar el cobro en el
                        portal web.
                    </li>
                    <li>
                        Válido por 1 año a partir de la fecha de compra. (Club
                        FORZA).
                    </li>
                    <li>
                        Los Planes de Descuento y los Paquetes de envíos
                        incluidos a los que da derecho la membresía pueden ser
                        adquiridas en cualquier momento de la vigencia de ésta
                        siempre y cuando dicha vigencia tenga como mínimo 90
                        días previos al vencimiento. En caso contrario, será
                        necesaria la renovación de la membresía para seguir
                        adquiriendo los beneficios.
                    </li>
                    <li>
                        El valor de la membresía, los planes de descuento, los
                        paquetes de envíos incluidos y cualquier otro beneficio
                        que se haya listado o que en un futuro sea adherido, no
                        serán reembolsables una vez el cliente haya aceptado y
                        pagado los cargos correspondientes.
                    </li>
                    <li>
                        El cliente puede cancelar su membresía en cualquier
                        momento, sin embargo, el valor proporcional no utilizado
                        antes de su vencimiento no será reembolsado, ni de
                        cualquiera de los beneficios que pueda tener activos al
                        momento de tal cancelación.
                    </li>
                    <li>
                        El cliente debe indicar o no su deseo de no renovar
                        automáticamente su afiliación a la membresía del CLUB
                        FORZA. Ante la ausencia de acción por parte del cliente,
                        la empresa se reserva el derecho de renovar la membresía
                        de forma automática informando 30 días antes al correo
                        electrónico registrado del cliente.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>RESPONSABILIDAD DEL MIEMBRO / CLIENTE</b>
                    <br />
                </p>
                <ul>
                    <li>
                        El miembro son los únicos responsables de toda la
                        información proporcionada a la empresa a través del
                        portal web para hacer sus envíos.
                    </li>
                    <li>
                        En cualquier momento durante la vigencia del programa, y
                        aun habiendo reunido las características para aplicar y
                        de estos Términos y Condiciones, la empresa a su entera
                        discreción podrá rehusarse a otorgar el programa
                        indicado.
                    </li>
                    <li>
                        Todo cliente que forme parte da por entendido que conoce
                        la mecánica del programa y que acepta plenamente los
                        términos y condiciones de esta.
                    </li>
                    <li>
                        La membresía tiene una renovación automática que se hará
                        efectiva el día posterior al vencimiento de la ya
                        adquirida. El cliente podrá anular esta opción
                        habilitando o deshabilitando el botón correspondiente al
                        momento de la compra o en cualquier momento durante la
                        vigencia de la membresía siempre y cuando sea al menos
                        el día anterior a la fecha de expiración. De no suceder
                        la acción anterior, la empresa podrá renovar
                        automáticamente la membresía un año más y procesará el
                        cobro correspondiente.
                    </li>
                    <li>
                        Se presume y asume que la tarjeta de crédito o débito
                        utilizada para la compra de la membresía es propiedad de
                        la persona o entidad que realiza la transacción dentro
                        del portal de la empresa. Delivery Express, S. A. no
                        asumirá ningún tipo de responsabilidad civil, moral o
                        penal por las situaciones que deriven del uso indebido
                        de una tarjeta de crédito o débito dentro de su portal;
                        ya sea que ésta hubiera sido usada por el legítimo
                        propietario, por un tercero en representación del
                        propietario o un tercero sin autorización alguna.
                    </li>
                    <li>
                        La empresa se reserva el derecho de modificar total o
                        parcialmente los Términos y Condiciones del programa
                        “CLUB FORZA” así como el interpretar las disposiciones
                        acá inmersas, siendo inapelables las decisiones que la
                        empresa tome en este sentido, así como también sobre
                        aspectos no previstos en las presentes. Cualquier
                        modificación que se produzca por causas ajenas no
                        imputables a la empresa será publicada para conocimiento
                        del público en general, sin que ello dé lugar a reclamo
                        o indemnización alguna.
                    </li>
                    <li>
                        El cliente reconoce que no deberá permitir el uso
                        indebido de su perfil dentro de los distintos canales de
                        la empresa. El uso de códigos y usuarios puesto a
                        disposición por la empresa para el cliente es de uso
                        exclusivo para cada uno de los clientes y no es
                        permitido que lo utilicen terceros.
                    </li>
                    <li>
                        Los clientes al solicitar que les sea aplicado el
                        presente programa, desde ya aceptan que renuncia de
                        plantear cualquier queja, reclamo, denuncia, demanda o
                        cualquier otro tipo de acción derivada de cualquier
                        controversia que pudiera suscitar.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>MODIFICACIÓN DE LAS CONDICIONES DE USO</b>
                    <br />
                    La empresa se reserva el derecho de modificar estos Términos
                    y Condiciones, con o sin aviso previo al cliente, a su
                    entera y absoluta discreción. El cliente podrá consultar la
                    versión más reciente del presente en la parte inferior de la
                    página web de la empresa. La versión más reciente de los
                    Términos y Condiciones reemplazará todas las versiones
                    anteriores. El solicitar o que le sea aplicado al cliente el
                    programa, una vez efectuadas las modificaciones de estas,
                    implica que el cliente acepta quedar obligado por dichas
                    modificaciones. Así mismo, la empresa se reserva el derecho
                    exclusivo de finalizar anticipadamente, o bien, extender la
                    vigencia del programa.
                    <br /><br />
                    <b>PRIVACIDAD E INFORMACIÓN PERSONAL</b>
                    <br />
                    La empresa ha asumido el compromiso de proteger la
                    privacidad de la información personal que proporcionas a
                    través de los distintos canales donde aplica el presente
                    programa. Toda información proporcionada a la empresa se
                    sujetará a la política de privacidad, cuyos términos se
                    incorporan al presente.
                    <br /><br />
                    <b>
                        Renuncia; Divisibilidad; Títulos; Legislación Aplicable
                    </b>
                    <br />
                    La falta o retraso por La empresa para ejercer cualquiera de
                    sus derechos, facultades o recursos o remedios respectivos
                    bajo estos Términos y Condiciones no se entenderá como una
                    renuncia a ese o cualquier otro derecho, facultad o recurso
                    o remedio que pertenecen a la Empresa.
                    <br /><br />
                    Si alguna disposición de estos Términos y Condiciones es
                    considerada por un tribunal de jurisdicción competente o un
                    organismo regulador como nula, inválida o inaplicable, dicha
                    disposición se aplicará en la máxima medida permitida por la
                    legislación aplicable y los restantes términos y condiciones
                    permanecerán en pleno vigor y efecto sin modificaciones.
                    <br /><br />
                    Los títulos de estos Términos y Condiciones se insertan para
                    la conveniencia de referencia solamente y de ninguna manera
                    afectará la construcción, significado o efecto de nada de lo
                    contenido en estos Términos y Condiciones o regularán los
                    derechos y obligaciones de la Empresa.
                    <br /><br />
                    Estos Términos y Condiciones se rigen y deberán
                    interpretarse de conformidad con las leyes de la República
                    de Guatemala.
                </p>
            </div>
        </accordion-group>
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[0].show)) &&
                countrySelect.value === 'HN'
            "
            id="membresia"
            [isOpen]="selectedItem == 'membresia'"
            (isOpenChange)="openAccordion($event, 'membresia')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'membresia'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'membresia'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Club Forza HN</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify">
                    El programa de lealtad Club Forza (el “Programa Club Forza”)
                    es el programa de lealtad de Delivery Express, S.A., una
                    sociedad constituida y existente de acuerdo con las leyes
                    vigentes de la República de Guatemala.
                    <br /><br />

                    Los términos y condiciones del Programa Club Forza
                    detallados a continuación son efectivos a partir del 1 de
                    enero, 2023 (incluyendo las enmiendas y modificaciones que
                    cada cierto tiempo se realicen, en adelante, los “Términos y
                    Condiciones”). El Programa Club Forza remplazará todos los
                    términos y condiciones previos de cualquier programa de
                    lealtad en el cual cualquiera de los clientes haya
                    participado.
                    <br /><br />

                    Cada miembro del Programa Club Forza (individualmente un
                    “Miembro” y en conjunto los “Miembros”) estarán sujetos a
                    estos Términos y Condiciones.
                    <br /><br />

                    Al contratar los servicios de Delivery Express, S.A. que
                    opera sus actividades bajo la marca comercial Forza Delivery
                    Express (en adelante “la empresa”), usted, en calidad de
                    “cliente”, acuerda y acepta los términos y condiciones del
                    programa “CLUB FORZA” la cual tiene por objetivo brindar
                    beneficios y accesos exclusivos a promociones, descuentos,
                    recolecciones sin costo, con frecuencia programada de visita
                    y muchos beneficios más.
                    <br /><br />

                    <b>TERMINOS Y CONDICIONES GENERALES</b>
                    <br />
                    Los Términos y Condiciones presentes, así como otras reglas,
                    ofertas especiales y beneficios del Programa Club Forza
                    podrán ser modificados, enmendados y/o substituidos en
                    cualquier momento y de cualquier manera, sin previo aviso a
                    sus Miembros.

                    <br /><br />
                    Al aceptar estos Términos y Condiciones, cada miembro
                    acuerda que la terminación del Programa Club Forza y/o
                    cambios, enmiendas o modificaciones al programa o a los
                    Términos y Condiciones no dará lugar a ninguna reclamación
                    por daños de cualquier tipo por parte de algún Miembro
                    contra Delivery Express S.A.
                    <br /><br />
                    Estos Términos y Condiciones regulan la membresía y
                    participación de los Miembros en el Programa Club Forza y
                    ningún acuerdo o regulación de ley o de capital será
                    implementado o incorporado en este documento de ninguna
                    manera.
                    <br /><br />
                    Los Miembros aceptan estos Términos y Condiciones como parte
                    del proceso estándar de inscripción haciendo clic en el
                    espacio correspondiente, y también acuerdan en que lo el
                    pago y uso de la membresía constituye la aceptación expresa
                    de estos Términos y Condiciones y de la Política de
                    Privacidad.
                    <br /><br />
                    <b>Elegibilidad de los Miembros</b>
                    <br />
                    Las personas naturales mayores de dieciocho (18) años de
                    edad son elegibles para convertirse en Miembros
                    (individualmente "Persona Elegible"). Las personas no
                    naturales (tales como corporaciones, sociedades, sociedades
                    de responsabilidad limitada y otras empresas y entidades sin
                    fines de lucro) tendrán elegibilidad limitada para
                    convertirse en Miembros del Programa Club Forza en este
                    momento.
                    <br /><br />
                    <b>Inscripción en el Programa Club Forza</b>
                    <br />
                    La membresía del Programa Club Forza es personal e
                    intransferible. Las Personas Elegibles podrán inscribirse en
                    el Programa Forza Club en el Sitio Web o en ciertos puntos
                    de servicio de Forza Delivery y deberán proporcionar toda la
                    información requerida para su registro. Cada Miembro es
                    responsable de la integridad y exactitud de toda la
                    información personal proporcionada al momento de inscripción
                    en el Programa Club Forza y de actualizar su información,
                    incluyendo dirección de correo electrónico, dirección física
                    y número de teléfono. Delivery Express S.A. no tendrán
                    ninguna responsabilidad por correspondencia mal dirigida o
                    retrasada o por consecuencia de las mismas, y cada Miembro
                    asume toda la responsabilidad derivada de cualquier
                    información incompleta, parcial o inexacta facilitada al
                    Programa Club Forza.
                    <br /><br />
                    Toda la información de la cuenta constituye información
                    propiedad del Programa Club Forza y los Miembros pueden
                    acceder a esta información sólo con el propósito de obtener
                    información sobre sus propias cuentas. Los Miembros no
                    podrán delegar o conceder el acceso (a través de poder
                    notarial, contrato o de otro tipo) a sus cuentas o a la
                    información propiedad del Programa Club Forza a terceros. La
                    información de la cuenta no podrá ser reproducida o
                    distribuida sin el consentimiento previo por escrito de
                    Delivery Express S.A.
                    <br /><br />
                    Una vez aceptado en el Programa Club Forza, el individuo se
                    convierte en Miembro del Programa Club Forza y acepta los
                    presentes Términos y Condiciones y la política de
                    privacidad.
                    <br /><br />
                    Al inscribirse en el Programa Club Forza, los Miembros
                    recibirán un número de cuenta único, que será intransferible
                    y será referido como el "Número de Club Forza" o "número de
                    cuenta" del Miembro; tal número de identificación será
                    válido únicamente en relación con las transacciones del
                    Programa Club Forza. Los Miembros podrán tener acceso a
                    iniciar sesión en el Sitio Web, donde podrán crear una
                    contraseña para acceder a su cuenta dentro del Programa,
                    tendrán acceso a una variedad de herramientas de
                    autoservicio, y serán capaces de completar las transacciones
                    de envío y redención de puntos a las que aplica la
                    membresía.
                    <br /><br />
                    La membresía en el Programa Club Forza es un privilegio que
                    podrá ser revocado por la empresa a su entera discreción y
                    sin ninguna obligación de compensar a un Miembro o de
                    reconocer puntos o beneficios ganados o acumulados por un
                    Miembro a la fecha de terminación de la membresía de ese
                    Miembro en el Programa Club Forza o la suspensión de la
                    cuenta del Miembro, en casos incluyendo, pero sin limitación
                    a, (i) la conducta irregular, fraude o actividades
                    fraudulentas o conducta dolosa, así como el uso de los
                    servicios de Delivery Express S.A. para transportar
                    artículos de procedencia ilegal o que constituyan estafa o
                    sospecha de esta hacia cualquier persona o empresa. (ii)
                    cualquier violación de las leyes, normas o reglamentos
                    aplicables, (iii) conducta dolosa del Miembro incluyendo,
                    pero no limitado a, cualquier tergiversación de la
                    información proporcionada a la empresa, (iv) el abuso verbal
                    o físico por un Miembro a cualquier empleado, agente,
                    director o funcionario de la empresa, o a sus respectivas
                    sucursales cuando sea o no determinado que el Miembro es
                    civilmente, penalmente o de otra manera responsable de tales
                    acciones, (v) el incumplimiento de cualquier ley aplicable,
                    incluyendo sin limitación, reglas de tarifa, condiciones de
                    transporte, aranceles u otros, (vi) el incumplimiento de
                    estos Términos y Condiciones o contrato de transporte de
                    envíos, o (vii) conducta de otra manera perjudicial para los
                    intereses del Programa Club Forza.
                    <br /><br />
                    La terminación de la cuenta de un Miembro resultará en la
                    pérdida de todos los privilegios y/o beneficios a los cuales
                    el Miembro tiene derecho a, bajo el Programa Club Forza,
                    incluyendo, pero no limitado a, la revocación de los accesos
                    y cualquier acción civil o penal que la empresa considere
                    pertinente.
                    <br /><br />
                    Un Miembro cuya cuenta ha sido terminada no será elegible
                    para crear una nueva cuenta en el Programa Club Forza salvo
                    a sola discreción de la empresa. Si la empresa decide no
                    terminar la cuenta del Miembro por cualquiera de las
                    acciones arriba mencionadas y permiten que el Miembro
                    mantenga su membresía en el Programa Club Forza, la empresa
                    podrá tomar otras acciones como descontar de la cuenta del
                    Miembro puntos acumulados y/o reducir su nivel de beneficios
                    en el Programa Club Forza, sin perjuicio de los recursos
                    legales disponibles bajo la ley aplicable.
                    <br /><br />
                    Para asegurar el cumplimiento de estos Términos y
                    Condiciones, las condiciones de transporte aplicable y/o las
                    tarifas, la empresa se reserva el derecho de auditar
                    cualesquiera y todas las cuentas de los Miembros sin previo
                    aviso. Durante el proceso de auditoría, podrá ser necesario
                    bloquear a los Miembros el acceso a sus cuentas.
                    <br /><br />
                    Durante el proceso de una auditoría o investigación, la
                    información de las cuentas de los Miembros podrá ser
                    compartida con cualquier tercero a quien la empresa haya
                    contratado para que asistan en dichas auditorías o
                    investigaciones.
                    <br /><br />
                    En el caso en que una auditoría o investigación revele
                    discrepancias o posibles violaciones a estos Términos y
                    Condiciones, la empresa podrá, sin previo aviso a los
                    Miembros y en adición a cualquier otro recurso descrito en
                    este documento o cualquier otro disponible (i) demorar el
                    procesamiento de solicitudes de redención de puntos, (ii)
                    cancelar cualquier redención de puntos pendientes, (iii)
                    retirar todos los puntos acumulados incorrectamente (ya sea
                    por error, fraude, discrepancia y/o por violación a los
                    Términos y Condiciones), (iv) retener estados de cuenta y/o
                    suspender la cuenta de algún Miembro y (v) terminar la
                    membresía de un Miembro en el Programa Club Forza.
                    <br /><br />
                    <b>Comercios Afiliados</b>
                    <br />
                    Los "comercios afiliados" de Club Forza incluyen
                    restaurantes, tiendas, u otras entidades que ofrecen
                    productos, servicios y/o descuentos en estos como
                    recompensas en el marco del programa Club Forza para los
                    miembros que usan sus productos o servicios.
                    <br /><br />
                    Cada comercio determina la tasa de descuento y beneficios
                    otorgados a los miembros de Club Forza los cuales pueden
                    variar en el tiempo y a su sola conveniencia sin que esto
                    implique una responsabilidad o compromiso por parte de
                    Delivery Express S.A.
                    <br /><br />
                    Los bienes y servicios prestados por cualquier comercio
                    afiliado del Programa Club Forza a los Miembros, que
                    permitan premiar con descuentos o que han sido obtenidos por
                    estos, son de exclusiva responsabilidad de dicho comercio.
                    La empresa no garantiza la satisfacción de los Miembros por
                    los productos o servicios que un Miembro puede adquirir u
                    obtener mediante el uso de su membresía de un comercio
                    afiliado del Programa Club Forza. Cada reclamación
                    relacionada con dichos servicios deberá ser dirigida
                    únicamente a aquellos comercios afiliados del Programa Club
                    Forza y cada Miembro, al convertirse en Miembro del Programa
                    Club Forza, mantiene a la empresa libre de reclamo o
                    solicitud de indemnización por cualquier o todos los daños
                    sufridos por los servicios prestados por cualquier compañía,
                    empresas y / o compañías que presten servicios a los
                    Miembros.
                    <br /><br />
                    Los comercios afiliados no tienen relación accionaria con
                    Delivery Express S.A. por lo que nunca se debe asociar un
                    vínculo más allá de la relación comercial con el programa
                    Club Forza. Delivery Express S.A. no asume ninguna
                    responsabilidad por acciones o violaciones en la que dichos
                    comercios puedan incurrir.
                    <br /><br />
                    <b>Acumulación de Puntos</b>
                    <br />
                    Los puntos pueden ser acumulados por los Miembros solamente
                    luego de que un Miembro se inscriba oficialmente en el
                    Programa Club Forza. La cantidad de puntos acumulados por el
                    Miembro depende de las condiciones de acumulación de puntos
                    del Programa Club Forza en vigencia al momento de la
                    actividad en que se ganan los puntos.

                    <br /><br />
                    Los puntos serán acumulados en la cuenta del Miembro sólo
                    cuando el Miembro haga uso de los medios de acumulación
                    reconocidos por el Programa Club Forza. Los Miembros no
                    podrán acumular puntos para la utilización de servicios de
                    consecución de puntos por otras personas diferentes a ellos
                    mismos, Para asegurar el recibo del crédito para puntos de
                    una actividad de consecución de puntos, el Miembro deberá
                    presentar su Número Club Forza.
                    <br /><br />
                    Los Miembros podrán buscar los puntos ganados en el Sitio
                    Web, iniciando sesión en sus cuentas usando su Contraseña.
                    Es responsabilidad de cada Miembro asegurar que los puntos
                    ganados sean debidamente acreditados a su cuenta.
                    <br /><br />
                    Cuando un Miembro afirme que puntos han sido ganados pero no
                    acreditados, o en el caso en que la empresa considere que
                    una verificación es requerida, la empresa se reserva el
                    derecho de solicitar pruebas para la acumulación por parte
                    de los Miembros, incluyendo, pero sin limitarse a, copias de
                    facturas, recibos de envió o documentación similar,
                    verificando que cualquier transacción de consecución de
                    puntos alegada haya sido comprada o contratada.
                    <br /><br />
                    La empresa se reserva el derecho de establecer puntos de
                    bonificación y ofertas promocionales del Programa Club
                    Forza, disponibles selectivamente a ciertos Miembros, en
                    base a su actividad de compra, ubicación geográfica u otros
                    factores determinados a discreción de la empresa. Los puntos
                    ofrecidos a los Miembros por utilizar los productos o
                    servicios de un socio de negocios del Programa Club Forza
                    podrán variar a discreción de la empresa y detalles de
                    ofertas específicas según sean publicadas cada cierto
                    tiempo.
                    <br /><br />
                    La empresa es la única parte autorizada para determinar si
                    se acreditarán puntos a la cuenta de un Miembro y se
                    reservan el derecho de rechazar o revocar dichos puntos a su
                    discreción cuando se cometa o se sospeche de un error.
                    <br /><br />
                    <b>Sitio Web del Programa Club Forza</b>
                    <br />
                    El Programa Club Forza pone a disposición de sus Miembros el
                    Sitio Web
                    <a href="http://www.forzadelivery.com" target="_blank"
                        >http://www.forzadelivery.com</a
                    >
                    a través del cual los Miembros podrán, entre otras cosas,
                    completar transacciones en línea, ver información de cuenta
                    y redimir puntos. La empresa se reserva el derecho de
                    interrumpir, modificar, actualizar y/o alterar el Sitio Web
                    y su contenido, funcionalidad y/o el acceso sin previo aviso
                    a los Miembros ya sea por motivos técnicos, de seguridad, de
                    control, de mantenimiento o cualquier otra razón.
                    <br /><br />
                    La empresa no garantiza el uso contínuo del Sitio Web o que
                    el Sitio Web funcione sin error y la empresa no serán
                    responsable por cualquier interrupción en el uso del Sitio
                    Web por cualquier razón o por la falta de disponibilidad de
                    los Miembros. Cada Miembro que acceda al Sitio Web es
                    responsable de proteger su equipo con un antivirus,
                    antimalware y otros programas similares.
                    <br /><br />
                    La información, productos y servicios publicados cada cierto
                    tiempo en el sitio web pueden incluir errores y/o puede no
                    estar actualizado, y la empresa no será responsable de la
                    veracidad de la información contenida en el Sitio Web. La
                    empresa, sin embargo, procurará asegurar que la información
                    publicada en el Sitio Web sea exacta y oportuna.
                    <br /><br />
                    Los enlaces a otras páginas web se pueden encontrar en el
                    Sitio Web a través del cual los Miembros pueden realizar
                    transacciones y acumular y/o redimir puntos. La empresa no
                    es responsable por el contenido, exactitud o veracidad de
                    las páginas web de terceros a las que el Sitio Web ofrece
                    enlaces o de las políticas de confidencialidad o privacidad
                    o los términos y condiciones de dichas páginas web; por lo
                    tanto, los miembros son responsables de la revisión y el
                    cumplimiento de los términos, condiciones y políticas de
                    cada página web de terceros antes y/o durante su visita a
                    ellos. La empresa no garantiza ni asume responsabilidad
                    alguna por daños que pudieran derivarse de la utilización de
                    la información o material contenido o mostrado en cualquier
                    página web de terceros que el Sitio Web pueda ofrecer como
                    enlaces. La empresa no será responsable de las acciones,
                    errores, omisiones o incumplimientos de los propietarios y
                    operadores de páginas web de terceros o por daños.
                    <br /><br />
                    <b>DATOS DEL PROGRAMA</b>
                    <br />
                </p>
                <ul>
                    <li>Vigencia: Del 24 de agosto de 2023 en adelante.</li>
                    <li>
                        Servicios que aplican al beneficio: Servicio Estándar y
                        Servicio C.O.D.
                    </li>
                    <li>Lugar en que aplica: República de Guatemala.</li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>MECÁNICA DEL PROGRAMA Y CARACTERÍSTICAS</b>
                    <br />
                    Durante la vigencia del programa, todos los clientes nuevos
                    y actuales elegibles del programa Club Forza podrán realizar
                    sus envíos a toda la República de Guatemala tienen la
                    posibilidad de adquirir los beneficios que el club les
                    proporciona.
                    <br /><br />
                    Los clientes deberán de ingresar, registrar y activar la
                    membresía desde nuestro portal web
                    <a href="https://www.forzadelivery.com" target="_blank">
                        www.forzadelivery.com</a
                    >. Una vez activado y pagado, podrán realizar sus envíos a
                    nivel nacional, pagando el importe que éstos representen
                    según las tarifas vigentes en ese momento (envíos menores de
                    10 libras) obteniendo los beneficios como: acumulación de
                    puntos por cada envío realizado, posibilidad de comprar
                    planes de descuentos en envíos que pueden ser desde el 10%
                    en adelante, según su valor de compra; posibilidad de
                    comprar paquetes con envíos incluidos desde 50 envíos en
                    adelante, según su valor de compra; promociones especiales
                    en comercios afiliados, recolecciones sin costo, fila
                    preferencial en Agencias Express Center, atención y gestión
                    de reclamos, incluyendo su resolución, en un tiempo menor
                    comparado con los clientes regulares, envío de devoluciones
                    al remitente sin costo. <br /><br />
                    El cliente debe poseer una tarjeta de crédito o débito de
                    banco del sistema del país, la cual deberá estar vigente y
                    activa para realizar transacciones.
                    <br /><br />
                    <span>
                        <b
                            >DETALLES DE LOS PLANES DE DESCUENTO Y SUS
                            CARACTERÍSTICAS:</b
                        >
                        <br />
                        <br />
                        <ul>
                            <li>
                                Los planes de descuentos son promociones
                                exclusivas para los miembros activos del Club
                                Forza y a los cuales tienen derecho de acceder
                                por medio de una compra de acuerdo al valor y
                                nivel de descuento que representen. Delivery
                                Express, S. A. decidirá por sí misma y a su
                                entera discreción los niveles de descuento
                                disponibles para la compra así como los valores
                                de venta que tenga cada uno. Los planes de
                                descuento funcionan otorgando un porcentaje (%)
                                de disminución en el valor de la tarifa vigente
                                al momento de realizar el envío. Los envíos a
                                que hace referencia este beneficio, al igual que
                                el CLUB FORZA, son envíos con peso máximo de
                                hasta 10 libras. El valor de descuento será
                                únicamente sobre el valor del flete como tal, no
                                incluye ni exime recargos como sobrepeso,
                                comisiones por el servicio de cobro contra
                                entrega, recargos por cobrar al destinatario y
                                todos aquellos que la empresa considere
                                pertinentes, según sus políticas de precios y
                                servicios vigentes. El sistema de tarificación
                                aplicable o la forma de calcular la tarifa
                                dentro de los planes será la misma que Delivery
                                Express utiliza para sus transacciones normales
                                (cobro por cobertura). Los envíos realizados
                                utilizando el beneficio de algún plan de
                                descuento también pueden permitir la acumulación
                                de puntos por cada envío realizado. Los planes
                                tendrán una vigencia de hasta seis (6) meses,
                                siempre y cuando este tiempo esté dentro de los
                                límites de cobertura que otorga la membresía
                                activa. La empresa se reserva el derecho de
                                modificar total o parcialmente los Términos y
                                Condiciones del beneficio “PLANES DE DESCUENTO”
                                así como el interpretar las disposiciones acá
                                inmersas, siendo inapelables las decisiones que
                                la empresa tome en este sentido, así como
                                también sobre aspectos no previstos en las
                                presentes. Cualquier modificación que se
                                produzca por causas ajenas no imputables a la
                                empresa será publicada para conocimiento del
                                público en general, sin que ello dé lugar a
                                reclamo o indemnización alguna. La empresa
                                también podrá finalizar anticipadamente, o bien,
                                extender la vigencia de los planes a que hace
                                referencia este párrafo a su entera y absoluta
                                discreción.
                            </li>
                        </ul>
                    </span>
                    <br />
                    <span>
                        <b
                            >DETALLES DE LOS PAQUETES DE ENVÍOS Y SUS
                            CARACTERÍSTICAS:</b
                        >
                        <br />
                        <br />
                        <ul>
                            <li>
                                Los paquetes de envíos incluidos son promociones
                                exclusivas para los miembros activos del Club
                                Forza y a los cuales tienen derecho de acceder
                                por medio de una compra de acuerdo al valor y
                                número de envíos que incluyan. Delivery Express,
                                S. A. decidirá por sí misma y a su entera
                                discreción el número de envíos incluidos en cada
                                paquete disponible para la compra así como los
                                valores de venta que tenga cada uno. Los envíos
                                a que hace referencia este beneficio, al igual
                                que el CLUB FORZA, son envíos con peso máximo de
                                hasta 10 libras. El valor incluido en el paquete
                                será únicamente el del valor del flete como tal,
                                no incluye ni exime recargos como sobrepeso,
                                comisiones por el servicio de cobro contra
                                entrega, recargos por cobrar al destinatario y
                                todos aquellos que la empresa considere
                                pertinentes, según sus políticas de precios y
                                servicios vigentes. Los paquetes de envíos
                                incluidos funcionan otorgando una cantidad de
                                envíos disponibles para utilizar por parte del
                                cliente. El sistema de tarificación aplicable o
                                la forma de calcular la tarifa dentro de los
                                paquetes de envíos incluidos será preferencial,
                                asignando un mismo valor o precio a todos los
                                envíos disponibles, según la compra realizada,
                                no importando el lugar de entrega dentro de la
                                República de Guatemala al que se dirigiera tal
                                envío. Los paquees de envíos incluidos tendrán
                                una vigencia de hasta seis (6) meses de duración
                                o hasta que el número de envíos incluidos al que
                                hace referencia se hayan agotado, lo que suceda
                                primero; siempre y cuando este tiempo esté
                                dentro de los límites de cobertura que otorga la
                                membresía activa. Una vez agotado el tiempo de
                                vigencia, la empresa no será responsable bajo
                                ninguna circunstancia de resarcir, reponer,
                                enmendar, compensar o similares a aquellos
                                clientes que con conocimiento de causa o no, no
                                hayan consumido los envíos que contenía el plan
                                adquirido. La empresa se reserva el derecho de
                                modificar total o parcialmente los Términos y
                                Condiciones del beneficio “PAQUETES DE ENVÍOS
                                INCLUIDOS” así como el interpretar las
                                disposiciones acá inmersas, siendo inapelables
                                las decisiones que la empresa tome en este
                                sentido, así como también sobre aspectos no
                                previstos en las presentes. Cualquier
                                modificación que se produzca por causas ajenas
                                no imputables a la empresa será publicada para
                                conocimiento del público en general, sin que
                                ello dé lugar a reclamo o indemnización alguna.
                                La empresa también podrá finalizar
                                anticipadamente, o bien, extender la vigencia de
                                los paquetes de envíos incluidos que hace
                                referencia este párrafo a su entera y absoluta
                                discreción.
                            </li>
                        </ul>
                    </span>
                    <br />
                    <b>RESTRICCIONES</b>
                    <br />
                </p>
                <ul>
                    <li>
                        Válido para clientes nuevos y actuales que generen sus
                        guías a través del portal web y que sean elegibles para
                        utilizar el programa Club Forza.
                    </li>
                    <li>
                        El (los) descuentos que otorgan los paquetes de
                        descuentos y/o la tarifa de los paquetes de envíos
                        incluidos son válidos únicamente sobre la tarifa base
                        del envió en los servicios Estándar y COD.
                    </li>
                    <li>
                        El (los) descuentos que otorgan los paquetes de
                        descuentos y/o la tarifa de los paquetes de envíos
                        incluidos no aplica para: Seguro en tránsito de la
                        mercancía, exceso de peso, cobros adicionales por
                        servicio collect, cobros de comisión COD, material de
                        empaque, servicio internacional, otros cobros o recargos
                        que genere la transacción del envió.
                    </li>
                    <li>
                        No se aplica en combinación con otras promociones
                        vigentes o futuras.
                    </li>
                    <li>
                        Válido únicamente para usuarios que posean tarjeta de
                        crédito o débito registrada para realizar el cobro en el
                        portal web.
                    </li>
                    <li>
                        Válido por 1 año a partir de la fecha de compra. (Club
                        FORZA).
                    </li>
                    <li>
                        Los Planes de Descuento y los Paquetes de envíos
                        incluidos a los que da derecho la membresía pueden ser
                        adquiridas en cualquier momento de la vigencia de ésta
                        siempre y cuando dicha vigencia tenga como mínimo 90
                        días previos al vencimiento. En caso contrario, será
                        necesaria la renovación de la membresía para seguir
                        adquiriendo los beneficios.
                    </li>
                    <li>
                        El valor de la membresía, los planes de descuento, los
                        paquetes de envíos incluidos y cualquier otro beneficio
                        que se haya listado o que en un futuro sea adherido, no
                        serán reembolsables una vez el cliente haya aceptado y
                        pagado los cargos correspondientes.
                    </li>
                    <li>
                        El cliente puede cancelar su membresía en cualquier
                        momento, sin embargo, el valor proporcional no utilizado
                        antes de su vencimiento no será reembolsado, ni de
                        cualquiera de los beneficios que pueda tener activos al
                        momento de tal cancelación.
                    </li>
                    <li>
                        El cliente debe indicar o no su deseo de no renovar
                        automáticamente su afiliación a la membresía del CLUB
                        FORZA. Ante la ausencia de acción por parte del cliente,
                        la empresa se reserva el derecho de renovar la membresía
                        de forma automática informando 30 días antes al correo
                        electrónico registrado del cliente.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>RESPONSABILIDAD DEL MIEMBRO / CLIENTE</b>
                    <br />
                </p>
                <ul>
                    <li>
                        El miembro son los únicos responsables de toda la
                        información proporcionada a la empresa a través del
                        portal web para hacer sus envíos.
                    </li>
                    <li>
                        En cualquier momento durante la vigencia del programa, y
                        aun habiendo reunido las características para aplicar y
                        de estos Términos y Condiciones, la empresa a su entera
                        discreción podrá rehusarse a otorgar el programa
                        indicado.
                    </li>
                    <li>
                        Todo cliente que forme parte da por entendido que conoce
                        la mecánica del programa y que acepta plenamente los
                        términos y condiciones de esta.
                    </li>
                    <li>
                        La membresía tiene una renovación automática que se hará
                        efectiva el día posterior al vencimiento de la ya
                        adquirida. El cliente podrá anular esta opción
                        habilitando o deshabilitando el botón correspondiente al
                        momento de la compra o en cualquier momento durante la
                        vigencia de la membresía siempre y cuando sea al menos
                        el día anterior a la fecha de expiración. De no suceder
                        la acción anterior, la empresa podrá renovar
                        automáticamente la membresía un año más y procesará el
                        cobro correspondiente.
                    </li>
                    <li>
                        Se presume y asume que la tarjeta de crédito o débito
                        utilizada para la compra de la membresía es propiedad de
                        la persona o entidad que realiza la transacción dentro
                        del portal de la empresa. Delivery Express, S. A. no
                        asumirá ningún tipo de responsabilidad civil, moral o
                        penal por las situaciones que deriven del uso indebido
                        de una tarjeta de crédito o débito dentro de su portal;
                        ya sea que ésta hubiera sido usada por el legítimo
                        propietario, por un tercero en representación del
                        propietario o un tercero sin autorización alguna.
                    </li>
                    <li>
                        La empresa se reserva el derecho de modificar total o
                        parcialmente los Términos y Condiciones del programa
                        “CLUB FORZA” así como el interpretar las disposiciones
                        acá inmersas, siendo inapelables las decisiones que la
                        empresa tome en este sentido, así como también sobre
                        aspectos no previstos en las presentes. Cualquier
                        modificación que se produzca por causas ajenas no
                        imputables a la empresa será publicada para conocimiento
                        del público en general, sin que ello dé lugar a reclamo
                        o indemnización alguna.
                    </li>
                    <li>
                        El cliente reconoce que no deberá permitir el uso
                        indebido de su perfil dentro de los distintos canales de
                        la empresa. El uso de códigos y usuarios puesto a
                        disposición por la empresa para el cliente es de uso
                        exclusivo para cada uno de los clientes y no es
                        permitido que lo utilicen terceros.
                    </li>
                    <li>
                        Los clientes al solicitar que les sea aplicado el
                        presente programa, desde ya aceptan que renuncia de
                        plantear cualquier queja, reclamo, denuncia, demanda o
                        cualquier otro tipo de acción derivada de cualquier
                        controversia que pudiera suscitar.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>MODIFICACIÓN DE LAS CONDICIONES DE USO</b>
                    <br />
                    La empresa se reserva el derecho de modificar estos Términos
                    y Condiciones, con o sin aviso previo al cliente, a su
                    entera y absoluta discreción. El cliente podrá consultar la
                    versión más reciente del presente en la parte inferior de la
                    página web de la empresa. La versión más reciente de los
                    Términos y Condiciones reemplazará todas las versiones
                    anteriores. El solicitar o que le sea aplicado al cliente el
                    programa, una vez efectuadas las modificaciones de estas,
                    implica que el cliente acepta quedar obligado por dichas
                    modificaciones. Así mismo, la empresa se reserva el derecho
                    exclusivo de finalizar anticipadamente, o bien, extender la
                    vigencia del programa.
                    <br /><br />
                    <b>PRIVACIDAD E INFORMACIÓN PERSONAL</b>
                    <br />
                    La empresa ha asumido el compromiso de proteger la
                    privacidad de la información personal que proporcionas a
                    través de los distintos canales donde aplica el presente
                    programa. Toda información proporcionada a la empresa se
                    sujetará a la política de privacidad, cuyos términos se
                    incorporan al presente.
                    <br /><br />
                    <b>
                        Renuncia; Divisibilidad; Títulos; Legislación Aplicable
                    </b>
                    <br />
                    La falta o retraso por La empresa para ejercer cualquiera de
                    sus derechos, facultades o recursos o remedios respectivos
                    bajo estos Términos y Condiciones no se entenderá como una
                    renuncia a ese o cualquier otro derecho, facultad o recurso
                    o remedio que pertenecen a la Empresa.
                    <br /><br />
                    Si alguna disposición de estos Términos y Condiciones es
                    considerada por un tribunal de jurisdicción competente o un
                    organismo regulador como nula, inválida o inaplicable, dicha
                    disposición se aplicará en la máxima medida permitida por la
                    legislación aplicable y los restantes términos y condiciones
                    permanecerán en pleno vigor y efecto sin modificaciones.
                    <br /><br />
                    Los títulos de estos Términos y Condiciones se insertan para
                    la conveniencia de referencia solamente y de ninguna manera
                    afectará la construcción, significado o efecto de nada de lo
                    contenido en estos Términos y Condiciones o regularán los
                    derechos y obligaciones de la Empresa.
                    <br /><br />
                    Estos Términos y Condiciones se rigen y deberán
                    interpretarse de conformidad con las leyes de la República
                    de Guatemala.
                </p>
            </div>
        </accordion-group>
        <!-- Suscripción básica -->
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[1].show)) &&
                countrySelect.value === 'GT'
            "
            id="basico"
            [isOpen]="selectedItem == 'basico'"
            (isOpenChange)="openAccordion($event, 'basico')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'basico'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'basico'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Paquete Básico</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[1].show)) &&
                countrySelect.value === 'HN'
            "
            id="basico"
            [isOpen]="selectedItem == 'basico'"
            (isOpenChange)="openAccordion($event, 'basico')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'basico'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'basico'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Paquete Básico HN</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
        <!-- Suscripción plus -->
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[2].show)) &&
                countrySelect.value === 'GT'
            "
            id="plus"
            [isOpen]="selectedItem == 'plus'"
            (isOpenChange)="openAccordion($event, 'plus')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'plus'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'plus'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Paquete Plus</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[2].show)) &&
                countrySelect.value === 'HN'
            "
            id="plus"
            [isOpen]="selectedItem == 'plus'"
            (isOpenChange)="openAccordion($event, 'plus')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'plus'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'plus'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Paquete Plus HN</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
        <!-- Suscripción gold -->
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[3].show)) &&
                countrySelect.value === 'GT'
            "
            id="gold"
            [isOpen]="selectedItem == 'gold'"
            (isOpenChange)="openAccordion($event, 'gold')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'gold'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'gold'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Paquete Gold</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[3].show)) &&
                countrySelect.value === 'HN'
            "
            id="gold"
            [isOpen]="selectedItem == 'gold'"
            (isOpenChange)="openAccordion($event, 'gold')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'gold'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'gold'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Paquete Gold HN</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
        <!-- Suscripción corporativo -->
        <accordion-group
            *ngIf="false"
            id="corporativo"
            [isOpen]="selectedItem == 'corporativo'"
            (isOpenChange)="openAccordion($event, 'corporativo')">
            <div accordion-heading class="d-flex align-items-center">
                <small
                    class="mr-2 text-h1"
                    *ngIf="selectedItem != 'corporativo'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small
                    class="mr-2 text-h1"
                    *ngIf="selectedItem == 'corporativo'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Plan Corporativo</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
        <!-- Plan Amigo -->
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[4].show)) &&
                countrySelect.value === 'GT'
            "
            id="amigo"
            [isOpen]="selectedItem == 'amigo'"
            (isOpenChange)="openAccordion($event, 'amigo')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'amigo'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'amigo'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Plan amigo</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify">
                    El programa de lealtad Club Forza (el “Programa Club Forza”)
                    es el programa de lealtad de Delivery Express, S.A., una
                    sociedad constituida y existente de acuerdo con las leyes
                    vigentes de la República de Guatemala.
                    <br /><br />

                    Los términos y condiciones del Programa Club Forza
                    detallados a continuación son efectivos a partir del 1 de
                    enero, 2023 (incluyendo las enmiendas y modificaciones que
                    cada cierto tiempo se realicen, en adelante, los “Términos y
                    Condiciones”). El Programa Club Forza remplazará todos los
                    términos y condiciones previos de cualquier programa de
                    lealtad en el cual cualquiera de los clientes haya
                    participado.
                    <br /><br />

                    Cada miembro del Programa Club Forza (individualmente un
                    “Miembro” y en conjunto los “Miembros”) estarán sujetos a
                    estos Términos y Condiciones.
                    <br /><br />

                    Al contratar los servicios de Delivery Express, S.A. que
                    opera sus actividades bajo la marca comercial Forza Delivery
                    Express (en adelante “la empresa”), usted, en calidad de
                    “cliente”, acuerda y acepta los términos y condiciones del
                    programa “CLUB FORZA” la cual tiene por objetivo brindar
                    beneficios y accesos exclusivos a promociones, descuentos,
                    recolecciones sin costo, con frecuencia programada de visita
                    y muchos beneficios más.
                    <br /><br />

                    <b>TERMINOS Y CONDICIONES GENERALES</b>
                    <br />
                    Los Términos y Condiciones presentes, así como otras reglas,
                    ofertas especiales y beneficios del Programa Club Forza
                    podrán ser modificados, enmendados y/o substituidos en
                    cualquier momento y de cualquier manera, sin previo aviso a
                    sus Miembros.

                    <br /><br />
                    Al aceptar estos Términos y Condiciones, cada miembro
                    acuerda que la terminación del Programa Club Forza y/o
                    cambios, enmiendas o modificaciones al programa o a los
                    Términos y Condiciones no dará lugar a ninguna reclamación
                    por daños de cualquier tipo por parte de algún Miembro
                    contra Delivery Express S.A.
                    <br /><br />
                    Estos Términos y Condiciones regulan la membresía y
                    participación de los Miembros en el Programa Club Forza y
                    ningún acuerdo o regulación de ley o de capital será
                    implementado o incorporado en este documento de ninguna
                    manera.
                    <br /><br />
                    Los Miembros aceptan estos Términos y Condiciones como parte
                    del proceso estándar de inscripción haciendo clic en el
                    espacio correspondiente, y también acuerdan en que lo el
                    pago y uso de la membresía constituye la aceptación expresa
                    de estos Términos y Condiciones y de la Política de
                    Privacidad.
                    <br /><br />
                    <b>Elegibilidad de los Miembros</b>
                    <br />
                    Las personas naturales mayores de dieciocho (18) años de
                    edad son elegibles para convertirse en Miembros
                    (individualmente "Persona Elegible"). Las personas no
                    naturales (tales como corporaciones, sociedades, sociedades
                    de responsabilidad limitada y otras empresas y entidades sin
                    fines de lucro) tendrán elegibilidad limitada para
                    convertirse en Miembros del Programa Club Forza en este
                    momento.
                    <br /><br />
                    <b>Inscripción en el Programa Club Forza</b>
                    <br />
                    La membresía del Programa Club Forza es personal e
                    intransferible. Las Personas Elegibles podrán inscribirse en
                    el Programa Forza Club en el Sitio Web o en ciertos puntos
                    de servicio de Forza Delivery y deberán proporcionar toda la
                    información requerida para su registro. Cada Miembro es
                    responsable de la integridad y exactitud de toda la
                    información personal proporcionada al momento de inscripción
                    en el Programa Club Forza y de actualizar su información,
                    incluyendo dirección de correo electrónico, dirección física
                    y número de teléfono. Delivery Express S.A. no tendrán
                    ninguna responsabilidad por correspondencia mal dirigida o
                    retrasada o por consecuencia de las mismas, y cada Miembro
                    asume toda la responsabilidad derivada de cualquier
                    información incompleta, parcial o inexacta facilitada al
                    Programa Club Forza.
                    <br /><br />
                    Toda la información de la cuenta constituye información
                    propiedad del Programa Club Forza y los Miembros pueden
                    acceder a esta información sólo con el propósito de obtener
                    información sobre sus propias cuentas. Los Miembros no
                    podrán delegar o conceder el acceso (a través de poder
                    notarial, contrato o de otro tipo) a sus cuentas o a la
                    información propiedad del Programa Club Forza a terceros. La
                    información de la cuenta no podrá ser reproducida o
                    distribuida sin el consentimiento previo por escrito de
                    Delivery Express S.A.
                    <br /><br />
                    Una vez aceptado en el Programa Club Forza, el individuo se
                    convierte en Miembro del Programa Club Forza y acepta los
                    presentes Términos y Condiciones y la política de
                    privacidad.
                    <br /><br />
                    Al inscribirse en el Programa Club Forza, los Miembros
                    recibirán un número de cuenta único, que será intransferible
                    y será referido como el "Número de Club Forza" o "número de
                    cuenta" del Miembro; tal número de identificación será
                    válido únicamente en relación con las transacciones del
                    Programa Club Forza. Los Miembros podrán tener acceso a
                    iniciar sesión en el Sitio Web, donde podrán crear una
                    contraseña para acceder a su cuenta dentro del Programa,
                    tendrán acceso a una variedad de herramientas de
                    autoservicio, y serán capaces de completar las transacciones
                    de envío y redención de puntos a las que aplica la
                    membresía.
                    <br /><br />
                    La membresía en el Programa Club Forza es un privilegio que
                    podrá ser revocado por la empresa a su entera discreción y
                    sin ninguna obligación de compensar a un Miembro o de
                    reconocer puntos o beneficios ganados o acumulados por un
                    Miembro a la fecha de terminación de la membresía de ese
                    Miembro en el Programa Club Forza o la suspensión de la
                    cuenta del Miembro, en casos incluyendo, pero sin limitación
                    a, (i) la conducta irregular, fraude o actividades
                    fraudulentas o conducta dolosa, así como el uso de los
                    servicios de Delivery Express S.A. para transportar
                    artículos de procedencia ilegal o que constituyan estafa o
                    sospecha de esta hacia cualquier persona o empresa. (ii)
                    cualquier violación de las leyes, normas o reglamentos
                    aplicables, (iii) conducta dolosa del Miembro incluyendo,
                    pero no limitado a, cualquier tergiversación de la
                    información proporcionada a la empresa, (iv) el abuso verbal
                    o físico por un Miembro a cualquier empleado, agente,
                    director o funcionario de la empresa, o a sus respectivas
                    sucursales cuando sea o no determinado que el Miembro es
                    civilmente, penalmente o de otra manera responsable de tales
                    acciones, (v) el incumplimiento de cualquier ley aplicable,
                    incluyendo sin limitación, reglas de tarifa, condiciones de
                    transporte, aranceles u otros, (vi) el incumplimiento de
                    estos Términos y Condiciones o contrato de transporte de
                    envíos, o (vii) conducta de otra manera perjudicial para los
                    intereses del Programa Club Forza.
                    <br /><br />
                    La terminación de la cuenta de un Miembro resultará en la
                    pérdida de todos los privilegios y/o beneficios a los cuales
                    el Miembro tiene derecho a, bajo el Programa Club Forza,
                    incluyendo, pero no limitado a, la revocación de los accesos
                    y cualquier acción civil o penal que la empresa considere
                    pertinente.
                    <br /><br />
                    Un Miembro cuya cuenta ha sido terminada no será elegible
                    para crear una nueva cuenta en el Programa Club Forza salvo
                    a sola discreción de la empresa. Si la empresa decide no
                    terminar la cuenta del Miembro por cualquiera de las
                    acciones arriba mencionadas y permiten que el Miembro
                    mantenga su membresía en el Programa Club Forza, la empresa
                    podrá tomar otras acciones como descontar de la cuenta del
                    Miembro puntos acumulados y/o reducir su nivel de beneficios
                    en el Programa Club Forza, sin perjuicio de los recursos
                    legales disponibles bajo la ley aplicable.
                    <br /><br />
                    Para asegurar el cumplimiento de estos Términos y
                    Condiciones, las condiciones de transporte aplicable y/o las
                    tarifas, la empresa se reserva el derecho de auditar
                    cualesquiera y todas las cuentas de los Miembros sin previo
                    aviso. Durante el proceso de auditoría, podrá ser necesario
                    bloquear a los Miembros el acceso a sus cuentas.
                    <br /><br />
                    Durante el proceso de una auditoría o investigación, la
                    información de las cuentas de los Miembros podrá ser
                    compartida con cualquier tercero a quien la empresa haya
                    contratado para que asistan en dichas auditorías o
                    investigaciones.
                    <br /><br />
                    En el caso en que una auditoría o investigación revele
                    discrepancias o posibles violaciones a estos Términos y
                    Condiciones, la empresa podrá, sin previo aviso a los
                    Miembros y en adición a cualquier otro recurso descrito en
                    este documento o cualquier otro disponible (i) demorar el
                    procesamiento de solicitudes de redención de puntos, (ii)
                    cancelar cualquier redención de puntos pendientes, (iii)
                    retirar todos los puntos acumulados incorrectamente (ya sea
                    por error, fraude, discrepancia y/o por violación a los
                    Términos y Condiciones), (iv) retener estados de cuenta y/o
                    suspender la cuenta de algún Miembro y (v) terminar la
                    membresía de un Miembro en el Programa Club Forza.
                    <br /><br />
                    <b>Comercios Afiliados</b>
                    <br />
                    Los "comercios afiliados" de Club Forza incluyen
                    restaurantes, tiendas, u otras entidades que ofrecen
                    productos, servicios y/o descuentos en estos como
                    recompensas en el marco del programa Club Forza para los
                    miembros que usan sus productos o servicios.
                    <br /><br />
                    Cada comercio determina la tasa de descuento y beneficios
                    otorgados a los miembros de Club Forza los cuales pueden
                    variar en el tiempo y a su sola conveniencia sin que esto
                    implique una responsabilidad o compromiso por parte de
                    Delivery Express S.A.
                    <br /><br />
                    Los bienes y servicios prestados por cualquier comercio
                    afiliado del Programa Club Forza a los Miembros, que
                    permitan premiar con descuentos o que han sido obtenidos por
                    estos, son de exclusiva responsabilidad de dicho comercio.
                    La empresa no garantiza la satisfacción de los Miembros por
                    los productos o servicios que un Miembro puede adquirir u
                    obtener mediante el uso de su membresía de un comercio
                    afiliado del Programa Club Forza. Cada reclamación
                    relacionada con dichos servicios deberá ser dirigida
                    únicamente a aquellos comercios afiliados del Programa Club
                    Forza y cada Miembro, al convertirse en Miembro del Programa
                    Club Forza, mantiene a la empresa libre de reclamo o
                    solicitud de indemnización por cualquier o todos los daños
                    sufridos por los servicios prestados por cualquier compañía,
                    empresas y / o compañías que presten servicios a los
                    Miembros.
                    <br /><br />
                    Los comercios afiliados no tienen relación accionaria con
                    Delivery Express S.A. por lo que nunca se debe asociar un
                    vínculo más allá de la relación comercial con el programa
                    Club Forza. Delivery Express S.A. no asume ninguna
                    responsabilidad por acciones o violaciones en la que dichos
                    comercios puedan incurrir.
                    <br /><br />
                    <b>Acumulación de Puntos</b>
                    <br />
                    Los puntos pueden ser acumulados por los Miembros solamente
                    luego de que un Miembro se inscriba oficialmente en el
                    Programa Club Forza. La cantidad de puntos acumulados por el
                    Miembro depende de las condiciones de acumulación de puntos
                    del Programa Club Forza en vigencia al momento de la
                    actividad en que se ganan los puntos.

                    <br /><br />
                    Los puntos serán acumulados en la cuenta del Miembro sólo
                    cuando el Miembro haga uso de los medios de acumulación
                    reconocidos por el Programa Club Forza. Los Miembros no
                    podrán acumular puntos para la utilización de servicios de
                    consecución de puntos por otras personas diferentes a ellos
                    mismos, Para asegurar el recibo del crédito para puntos de
                    una actividad de consecución de puntos, el Miembro deberá
                    presentar su Número Club Forza.
                    <br /><br />
                    Los Miembros podrán buscar los puntos ganados en el Sitio
                    Web, iniciando sesión en sus cuentas usando su Contraseña.
                    Es responsabilidad de cada Miembro asegurar que los puntos
                    ganados sean debidamente acreditados a su cuenta.
                    <br /><br />
                    Cuando un Miembro afirme que puntos han sido ganados pero no
                    acreditados, o en el caso en que la empresa considere que
                    una verificación es requerida, la empresa se reserva el
                    derecho de solicitar pruebas para la acumulación por parte
                    de los Miembros, incluyendo, pero sin limitarse a, copias de
                    facturas, recibos de envió o documentación similar,
                    verificando que cualquier transacción de consecución de
                    puntos alegada haya sido comprada o contratada.
                    <br /><br />
                    La empresa se reserva el derecho de establecer puntos de
                    bonificación y ofertas promocionales del Programa Club
                    Forza, disponibles selectivamente a ciertos Miembros, en
                    base a su actividad de compra, ubicación geográfica u otros
                    factores determinados a discreción de la empresa. Los puntos
                    ofrecidos a los Miembros por utilizar los productos o
                    servicios de un socio de negocios del Programa Club Forza
                    podrán variar a discreción de la empresa y detalles de
                    ofertas específicas según sean publicadas cada cierto
                    tiempo.
                    <br /><br />
                    La empresa es la única parte autorizada para determinar si
                    se acreditarán puntos a la cuenta de un Miembro y se
                    reservan el derecho de rechazar o revocar dichos puntos a su
                    discreción cuando se cometa o se sospeche de un error.
                    <br /><br />
                    <b>Sitio Web del Programa Club Forza</b>
                    <br />
                    El Programa Club Forza pone a disposición de sus Miembros el
                    Sitio Web
                    <a href="http://www.forzadelivery.com" target="_blank"
                        >http://www.forzadelivery.com</a
                    >
                    a través del cual los Miembros podrán, entre otras cosas,
                    completar transacciones en línea, ver información de cuenta
                    y redimir puntos. La empresa se reserva el derecho de
                    interrumpir, modificar, actualizar y/o alterar el Sitio Web
                    y su contenido, funcionalidad y/o el acceso sin previo aviso
                    a los Miembros ya sea por motivos técnicos, de seguridad, de
                    control, de mantenimiento o cualquier otra razón.
                    <br /><br />
                    La empresa no garantiza el uso contínuo del Sitio Web o que
                    el Sitio Web funcione sin error y la empresa no serán
                    responsable por cualquier interrupción en el uso del Sitio
                    Web por cualquier razón o por la falta de disponibilidad de
                    los Miembros. Cada Miembro que acceda al Sitio Web es
                    responsable de proteger su equipo con un antivirus,
                    antimalware y otros programas similares.
                    <br /><br />
                    La información, productos y servicios publicados cada cierto
                    tiempo en el sitio web pueden incluir errores y/o puede no
                    estar actualizado, y la empresa no será responsable de la
                    veracidad de la información contenida en el Sitio Web. La
                    empresa, sin embargo, procurará asegurar que la información
                    publicada en el Sitio Web sea exacta y oportuna.
                    <br /><br />
                    Los enlaces a otras páginas web se pueden encontrar en el
                    Sitio Web a través del cual los Miembros pueden realizar
                    transacciones y acumular y/o redimir puntos. La empresa no
                    es responsable por el contenido, exactitud o veracidad de
                    las páginas web de terceros a las que el Sitio Web ofrece
                    enlaces o de las políticas de confidencialidad o privacidad
                    o los términos y condiciones de dichas páginas web; por lo
                    tanto, los miembros son responsables de la revisión y el
                    cumplimiento de los términos, condiciones y políticas de
                    cada página web de terceros antes y/o durante su visita a
                    ellos. La empresa no garantiza ni asume responsabilidad
                    alguna por daños que pudieran derivarse de la utilización de
                    la información o material contenido o mostrado en cualquier
                    página web de terceros que el Sitio Web pueda ofrecer como
                    enlaces. La empresa no será responsable de las acciones,
                    errores, omisiones o incumplimientos de los propietarios y
                    operadores de páginas web de terceros o por daños.
                    <br /><br />
                    <b>DATOS DEL PROGRAMA</b>
                    <br />
                </p>
                <ul>
                    <li>Vigencia: Del 24 de agosto de 2023 en adelante.</li>
                    <li>
                        Servicios que aplican al beneficio: Servicio Estándar y
                        Servicio C.O.D.
                    </li>
                    <li>Lugar en que aplica: República de Guatemala.</li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>MECÁNICA DEL PROGRAMA Y CARACTERÍSTICAS</b>
                    <br />
                    Durante la vigencia del programa, todos los clientes nuevos
                    y actuales elegibles del programa Club Forza podrán realizar
                    sus envíos a toda la República de Guatemala tienen la
                    posibilidad de adquirir los beneficios que el club les
                    proporciona.
                    <br /><br />
                    Los clientes deberán de ingresar, registrar y activar la
                    membresía desde nuestro portal web
                    <a href="https://www.forzadelivery.com" target="_blank">
                        www.forzadelivery.com</a
                    >. Una vez activado y pagado, podrán realizar sus envíos a
                    nivel nacional, pagando el importe que éstos representen
                    según las tarifas vigentes en ese momento (envíos menores de
                    10 libras) obteniendo los beneficios como: acumulación de
                    puntos por cada envío realizado, posibilidad de comprar
                    planes de descuentos en envíos que pueden ser desde el 10%
                    en adelante, según su valor de compra; posibilidad de
                    comprar paquetes con envíos incluidos desde 50 envíos en
                    adelante, según su valor de compra; promociones especiales
                    en comercios afiliados, recolecciones sin costo, fila
                    preferencial en Agencias Express Center, atención y gestión
                    de reclamos, incluyendo su resolución, en un tiempo menor
                    comparado con los clientes regulares, envío de devoluciones
                    al remitente sin costo. <br /><br />
                    El cliente debe poseer una tarjeta de crédito o débito de
                    banco del sistema del país, la cual deberá estar vigente y
                    activa para realizar transacciones.
                    <br /><br />
                    <span>
                        <b
                            >DETALLES DE LOS PLANES DE DESCUENTO Y SUS
                            CARACTERÍSTICAS:</b
                        >
                        <br />
                        <br />
                        <ul>
                            <li>
                                Los planes de descuentos son promociones
                                exclusivas para los miembros activos del Club
                                Forza y a los cuales tienen derecho de acceder
                                por medio de una compra de acuerdo al valor y
                                nivel de descuento que representen. Delivery
                                Express, S. A. decidirá por sí misma y a su
                                entera discreción los niveles de descuento
                                disponibles para la compra así como los valores
                                de venta que tenga cada uno. Los planes de
                                descuento funcionan otorgando un porcentaje (%)
                                de disminución en el valor de la tarifa vigente
                                al momento de realizar el envío. Los envíos a
                                que hace referencia este beneficio, al igual que
                                el CLUB FORZA, son envíos con peso máximo de
                                hasta 10 libras. El valor de descuento será
                                únicamente sobre el valor del flete como tal, no
                                incluye ni exime recargos como sobrepeso,
                                comisiones por el servicio de cobro contra
                                entrega, recargos por cobrar al destinatario y
                                todos aquellos que la empresa considere
                                pertinentes, según sus políticas de precios y
                                servicios vigentes. El sistema de tarificación
                                aplicable o la forma de calcular la tarifa
                                dentro de los planes será la misma que Delivery
                                Express utiliza para sus transacciones normales
                                (cobro por cobertura). Los envíos realizados
                                utilizando el beneficio de algún plan de
                                descuento también pueden permitir la acumulación
                                de puntos por cada envío realizado. Los planes
                                tendrán una vigencia de hasta seis (6) meses,
                                siempre y cuando este tiempo esté dentro de los
                                límites de cobertura que otorga la membresía
                                activa. La empresa se reserva el derecho de
                                modificar total o parcialmente los Términos y
                                Condiciones del beneficio “PLANES DE DESCUENTO”
                                así como el interpretar las disposiciones acá
                                inmersas, siendo inapelables las decisiones que
                                la empresa tome en este sentido, así como
                                también sobre aspectos no previstos en las
                                presentes. Cualquier modificación que se
                                produzca por causas ajenas no imputables a la
                                empresa será publicada para conocimiento del
                                público en general, sin que ello dé lugar a
                                reclamo o indemnización alguna. La empresa
                                también podrá finalizar anticipadamente, o bien,
                                extender la vigencia de los planes a que hace
                                referencia este párrafo a su entera y absoluta
                                discreción.
                            </li>
                        </ul>
                    </span>
                    <br />
                    <span>
                        <b
                            >DETALLES DE LOS PAQUETES DE ENVÍOS Y SUS
                            CARACTERÍSTICAS:</b
                        >
                        <br />
                        <br />
                        <ul>
                            <li>
                                Los paquetes de envíos incluidos son promociones
                                exclusivas para los miembros activos del Club
                                Forza y a los cuales tienen derecho de acceder
                                por medio de una compra de acuerdo al valor y
                                número de envíos que incluyan. Delivery Express,
                                S. A. decidirá por sí misma y a su entera
                                discreción el número de envíos incluidos en cada
                                paquete disponible para la compra así como los
                                valores de venta que tenga cada uno. Los envíos
                                a que hace referencia este beneficio, al igual
                                que el CLUB FORZA, son envíos con peso máximo de
                                hasta 10 libras. El valor incluido en el paquete
                                será únicamente el del valor del flete como tal,
                                no incluye ni exime recargos como sobrepeso,
                                comisiones por el servicio de cobro contra
                                entrega, recargos por cobrar al destinatario y
                                todos aquellos que la empresa considere
                                pertinentes, según sus políticas de precios y
                                servicios vigentes. Los paquetes de envíos
                                incluidos funcionan otorgando una cantidad de
                                envíos disponibles para utilizar por parte del
                                cliente. El sistema de tarificación aplicable o
                                la forma de calcular la tarifa dentro de los
                                paquetes de envíos incluidos será preferencial,
                                asignando un mismo valor o precio a todos los
                                envíos disponibles, según la compra realizada,
                                no importando el lugar de entrega dentro de la
                                República de Guatemala al que se dirigiera tal
                                envío. Los paquees de envíos incluidos tendrán
                                una vigencia de hasta seis (6) meses de duración
                                o hasta que el número de envíos incluidos al que
                                hace referencia se hayan agotado, lo que suceda
                                primero; siempre y cuando este tiempo esté
                                dentro de los límites de cobertura que otorga la
                                membresía activa. Una vez agotado el tiempo de
                                vigencia, la empresa no será responsable bajo
                                ninguna circunstancia de resarcir, reponer,
                                enmendar, compensar o similares a aquellos
                                clientes que con conocimiento de causa o no, no
                                hayan consumido los envíos que contenía el plan
                                adquirido. La empresa se reserva el derecho de
                                modificar total o parcialmente los Términos y
                                Condiciones del beneficio “PAQUETES DE ENVÍOS
                                INCLUIDOS” así como el interpretar las
                                disposiciones acá inmersas, siendo inapelables
                                las decisiones que la empresa tome en este
                                sentido, así como también sobre aspectos no
                                previstos en las presentes. Cualquier
                                modificación que se produzca por causas ajenas
                                no imputables a la empresa será publicada para
                                conocimiento del público en general, sin que
                                ello dé lugar a reclamo o indemnización alguna.
                                La empresa también podrá finalizar
                                anticipadamente, o bien, extender la vigencia de
                                los paquetes de envíos incluidos que hace
                                referencia este párrafo a su entera y absoluta
                                discreción.
                            </li>
                        </ul>
                    </span>
                    <br />
                    <b>RESTRICCIONES</b>
                    <br />
                </p>
                <ul>
                    <li>
                        Válido para clientes nuevos y actuales que generen sus
                        guías a través del portal web y que sean elegibles para
                        utilizar el programa Club Forza.
                    </li>
                    <li>
                        El (los) descuentos que otorgan los paquetes de
                        descuentos y/o la tarifa de los paquetes de envíos
                        incluidos son válidos únicamente sobre la tarifa base
                        del envió en los servicios Estándar y COD.
                    </li>
                    <li>
                        El (los) descuentos que otorgan los paquetes de
                        descuentos y/o la tarifa de los paquetes de envíos
                        incluidos no aplica para: Seguro en tránsito de la
                        mercancía, exceso de peso, cobros adicionales por
                        servicio collect, cobros de comisión COD, material de
                        empaque, servicio internacional, otros cobros o recargos
                        que genere la transacción del envió.
                    </li>
                    <li>
                        No se aplica en combinación con otras promociones
                        vigentes o futuras.
                    </li>
                    <li>
                        Válido únicamente para usuarios que posean tarjeta de
                        crédito o débito registrada para realizar el cobro en el
                        portal web.
                    </li>
                    <li>
                        Válido por 1 año a partir de la fecha de compra. (Club
                        FORZA).
                    </li>
                    <li>
                        Los Planes de Descuento y los Paquetes de envíos
                        incluidos a los que da derecho la membresía pueden ser
                        adquiridas en cualquier momento de la vigencia de ésta
                        siempre y cuando dicha vigencia tenga como mínimo 90
                        días previos al vencimiento. En caso contrario, será
                        necesaria la renovación de la membresía para seguir
                        adquiriendo los beneficios.
                    </li>
                    <li>
                        El valor de la membresía, los planes de descuento, los
                        paquetes de envíos incluidos y cualquier otro beneficio
                        que se haya listado o que en un futuro sea adherido, no
                        serán reembolsables una vez el cliente haya aceptado y
                        pagado los cargos correspondientes.
                    </li>
                    <li>
                        El cliente puede cancelar su membresía en cualquier
                        momento, sin embargo, el valor proporcional no utilizado
                        antes de su vencimiento no será reembolsado, ni de
                        cualquiera de los beneficios que pueda tener activos al
                        momento de tal cancelación.
                    </li>
                    <li>
                        El cliente debe indicar o no su deseo de no renovar
                        automáticamente su afiliación a la membresía del CLUB
                        FORZA. Ante la ausencia de acción por parte del cliente,
                        la empresa se reserva el derecho de renovar la membresía
                        de forma automática informando 30 días antes al correo
                        electrónico registrado del cliente.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>RESPONSABILIDAD DEL MIEMBRO / CLIENTE</b>
                    <br />
                </p>
                <ul>
                    <li>
                        El miembro son los únicos responsables de toda la
                        información proporcionada a la empresa a través del
                        portal web para hacer sus envíos.
                    </li>
                    <li>
                        En cualquier momento durante la vigencia del programa, y
                        aun habiendo reunido las características para aplicar y
                        de estos Términos y Condiciones, la empresa a su entera
                        discreción podrá rehusarse a otorgar el programa
                        indicado.
                    </li>
                    <li>
                        Todo cliente que forme parte da por entendido que conoce
                        la mecánica del programa y que acepta plenamente los
                        términos y condiciones de esta.
                    </li>
                    <li>
                        La membresía tiene una renovación automática que se hará
                        efectiva el día posterior al vencimiento de la ya
                        adquirida. El cliente podrá anular esta opción
                        habilitando o deshabilitando el botón correspondiente al
                        momento de la compra o en cualquier momento durante la
                        vigencia de la membresía siempre y cuando sea al menos
                        el día anterior a la fecha de expiración. De no suceder
                        la acción anterior, la empresa podrá renovar
                        automáticamente la membresía un año más y procesará el
                        cobro correspondiente.
                    </li>
                    <li>
                        Se presume y asume que la tarjeta de crédito o débito
                        utilizada para la compra de la membresía es propiedad de
                        la persona o entidad que realiza la transacción dentro
                        del portal de la empresa. Delivery Express, S. A. no
                        asumirá ningún tipo de responsabilidad civil, moral o
                        penal por las situaciones que deriven del uso indebido
                        de una tarjeta de crédito o débito dentro de su portal;
                        ya sea que ésta hubiera sido usada por el legítimo
                        propietario, por un tercero en representación del
                        propietario o un tercero sin autorización alguna.
                    </li>
                    <li>
                        La empresa se reserva el derecho de modificar total o
                        parcialmente los Términos y Condiciones del programa
                        “CLUB FORZA” así como el interpretar las disposiciones
                        acá inmersas, siendo inapelables las decisiones que la
                        empresa tome en este sentido, así como también sobre
                        aspectos no previstos en las presentes. Cualquier
                        modificación que se produzca por causas ajenas no
                        imputables a la empresa será publicada para conocimiento
                        del público en general, sin que ello dé lugar a reclamo
                        o indemnización alguna.
                    </li>
                    <li>
                        El cliente reconoce que no deberá permitir el uso
                        indebido de su perfil dentro de los distintos canales de
                        la empresa. El uso de códigos y usuarios puesto a
                        disposición por la empresa para el cliente es de uso
                        exclusivo para cada uno de los clientes y no es
                        permitido que lo utilicen terceros.
                    </li>
                    <li>
                        Los clientes al solicitar que les sea aplicado el
                        presente programa, desde ya aceptan que renuncia de
                        plantear cualquier queja, reclamo, denuncia, demanda o
                        cualquier otro tipo de acción derivada de cualquier
                        controversia que pudiera suscitar.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>MODIFICACIÓN DE LAS CONDICIONES DE USO</b>
                    <br />
                    La empresa se reserva el derecho de modificar estos Términos
                    y Condiciones, con o sin aviso previo al cliente, a su
                    entera y absoluta discreción. El cliente podrá consultar la
                    versión más reciente del presente en la parte inferior de la
                    página web de la empresa. La versión más reciente de los
                    Términos y Condiciones reemplazará todas las versiones
                    anteriores. El solicitar o que le sea aplicado al cliente el
                    programa, una vez efectuadas las modificaciones de estas,
                    implica que el cliente acepta quedar obligado por dichas
                    modificaciones. Así mismo, la empresa se reserva el derecho
                    exclusivo de finalizar anticipadamente, o bien, extender la
                    vigencia del programa.
                    <br /><br />
                    <b>PRIVACIDAD E INFORMACIÓN PERSONAL</b>
                    <br />
                    La empresa ha asumido el compromiso de proteger la
                    privacidad de la información personal que proporcionas a
                    través de los distintos canales donde aplica el presente
                    programa. Toda información proporcionada a la empresa se
                    sujetará a la política de privacidad, cuyos términos se
                    incorporan al presente.
                    <br /><br />
                    <b>
                        Renuncia; Divisibilidad; Títulos; Legislación Aplicable
                    </b>
                    <br />
                    La falta o retraso por La empresa para ejercer cualquiera de
                    sus derechos, facultades o recursos o remedios respectivos
                    bajo estos Términos y Condiciones no se entenderá como una
                    renuncia a ese o cualquier otro derecho, facultad o recurso
                    o remedio que pertenecen a la Empresa.
                    <br /><br />
                    Si alguna disposición de estos Términos y Condiciones es
                    considerada por un tribunal de jurisdicción competente o un
                    organismo regulador como nula, inválida o inaplicable, dicha
                    disposición se aplicará en la máxima medida permitida por la
                    legislación aplicable y los restantes términos y condiciones
                    permanecerán en pleno vigor y efecto sin modificaciones.
                    <br /><br />
                    Los títulos de estos Términos y Condiciones se insertan para
                    la conveniencia de referencia solamente y de ninguna manera
                    afectará la construcción, significado o efecto de nada de lo
                    contenido en estos Términos y Condiciones o regularán los
                    derechos y obligaciones de la Empresa.
                    <br /><br />
                    Estos Términos y Condiciones se rigen y deberán
                    interpretarse de conformidad con las leyes de la República
                    de Guatemala.
                </p>
            </div>
        </accordion-group>
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[4].show)) &&
                countrySelect.value === 'HN'
            "
            id="amigo"
            [isOpen]="selectedItem == 'amigo'"
            (isOpenChange)="openAccordion($event, 'amigo')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'amigo'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'amigo'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Plan amigo HN</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify">
                    El programa de lealtad Club Forza (el “Programa Club Forza”)
                    es el programa de lealtad de Delivery Express, S.A., una
                    sociedad constituida y existente de acuerdo con las leyes
                    vigentes de la República de Guatemala.
                    <br /><br />

                    Los términos y condiciones del Programa Club Forza
                    detallados a continuación son efectivos a partir del 1 de
                    enero, 2023 (incluyendo las enmiendas y modificaciones que
                    cada cierto tiempo se realicen, en adelante, los “Términos y
                    Condiciones”). El Programa Club Forza remplazará todos los
                    términos y condiciones previos de cualquier programa de
                    lealtad en el cual cualquiera de los clientes haya
                    participado.
                    <br /><br />

                    Cada miembro del Programa Club Forza (individualmente un
                    “Miembro” y en conjunto los “Miembros”) estarán sujetos a
                    estos Términos y Condiciones.
                    <br /><br />

                    Al contratar los servicios de Delivery Express, S.A. que
                    opera sus actividades bajo la marca comercial Forza Delivery
                    Express (en adelante “la empresa”), usted, en calidad de
                    “cliente”, acuerda y acepta los términos y condiciones del
                    programa “CLUB FORZA” la cual tiene por objetivo brindar
                    beneficios y accesos exclusivos a promociones, descuentos,
                    recolecciones sin costo, con frecuencia programada de visita
                    y muchos beneficios más.
                    <br /><br />

                    <b>TERMINOS Y CONDICIONES GENERALES</b>
                    <br />
                    Los Términos y Condiciones presentes, así como otras reglas,
                    ofertas especiales y beneficios del Programa Club Forza
                    podrán ser modificados, enmendados y/o substituidos en
                    cualquier momento y de cualquier manera, sin previo aviso a
                    sus Miembros.

                    <br /><br />
                    Al aceptar estos Términos y Condiciones, cada miembro
                    acuerda que la terminación del Programa Club Forza y/o
                    cambios, enmiendas o modificaciones al programa o a los
                    Términos y Condiciones no dará lugar a ninguna reclamación
                    por daños de cualquier tipo por parte de algún Miembro
                    contra Delivery Express S.A.
                    <br /><br />
                    Estos Términos y Condiciones regulan la membresía y
                    participación de los Miembros en el Programa Club Forza y
                    ningún acuerdo o regulación de ley o de capital será
                    implementado o incorporado en este documento de ninguna
                    manera.
                    <br /><br />
                    Los Miembros aceptan estos Términos y Condiciones como parte
                    del proceso estándar de inscripción haciendo clic en el
                    espacio correspondiente, y también acuerdan en que lo el
                    pago y uso de la membresía constituye la aceptación expresa
                    de estos Términos y Condiciones y de la Política de
                    Privacidad.
                    <br /><br />
                    <b>Elegibilidad de los Miembros</b>
                    <br />
                    Las personas naturales mayores de dieciocho (18) años de
                    edad son elegibles para convertirse en Miembros
                    (individualmente "Persona Elegible"). Las personas no
                    naturales (tales como corporaciones, sociedades, sociedades
                    de responsabilidad limitada y otras empresas y entidades sin
                    fines de lucro) tendrán elegibilidad limitada para
                    convertirse en Miembros del Programa Club Forza en este
                    momento.
                    <br /><br />
                    <b>Inscripción en el Programa Club Forza</b>
                    <br />
                    La membresía del Programa Club Forza es personal e
                    intransferible. Las Personas Elegibles podrán inscribirse en
                    el Programa Forza Club en el Sitio Web o en ciertos puntos
                    de servicio de Forza Delivery y deberán proporcionar toda la
                    información requerida para su registro. Cada Miembro es
                    responsable de la integridad y exactitud de toda la
                    información personal proporcionada al momento de inscripción
                    en el Programa Club Forza y de actualizar su información,
                    incluyendo dirección de correo electrónico, dirección física
                    y número de teléfono. Delivery Express S.A. no tendrán
                    ninguna responsabilidad por correspondencia mal dirigida o
                    retrasada o por consecuencia de las mismas, y cada Miembro
                    asume toda la responsabilidad derivada de cualquier
                    información incompleta, parcial o inexacta facilitada al
                    Programa Club Forza.
                    <br /><br />
                    Toda la información de la cuenta constituye información
                    propiedad del Programa Club Forza y los Miembros pueden
                    acceder a esta información sólo con el propósito de obtener
                    información sobre sus propias cuentas. Los Miembros no
                    podrán delegar o conceder el acceso (a través de poder
                    notarial, contrato o de otro tipo) a sus cuentas o a la
                    información propiedad del Programa Club Forza a terceros. La
                    información de la cuenta no podrá ser reproducida o
                    distribuida sin el consentimiento previo por escrito de
                    Delivery Express S.A.
                    <br /><br />
                    Una vez aceptado en el Programa Club Forza, el individuo se
                    convierte en Miembro del Programa Club Forza y acepta los
                    presentes Términos y Condiciones y la política de
                    privacidad.
                    <br /><br />
                    Al inscribirse en el Programa Club Forza, los Miembros
                    recibirán un número de cuenta único, que será intransferible
                    y será referido como el "Número de Club Forza" o "número de
                    cuenta" del Miembro; tal número de identificación será
                    válido únicamente en relación con las transacciones del
                    Programa Club Forza. Los Miembros podrán tener acceso a
                    iniciar sesión en el Sitio Web, donde podrán crear una
                    contraseña para acceder a su cuenta dentro del Programa,
                    tendrán acceso a una variedad de herramientas de
                    autoservicio, y serán capaces de completar las transacciones
                    de envío y redención de puntos a las que aplica la
                    membresía.
                    <br /><br />
                    La membresía en el Programa Club Forza es un privilegio que
                    podrá ser revocado por la empresa a su entera discreción y
                    sin ninguna obligación de compensar a un Miembro o de
                    reconocer puntos o beneficios ganados o acumulados por un
                    Miembro a la fecha de terminación de la membresía de ese
                    Miembro en el Programa Club Forza o la suspensión de la
                    cuenta del Miembro, en casos incluyendo, pero sin limitación
                    a, (i) la conducta irregular, fraude o actividades
                    fraudulentas o conducta dolosa, así como el uso de los
                    servicios de Delivery Express S.A. para transportar
                    artículos de procedencia ilegal o que constituyan estafa o
                    sospecha de esta hacia cualquier persona o empresa. (ii)
                    cualquier violación de las leyes, normas o reglamentos
                    aplicables, (iii) conducta dolosa del Miembro incluyendo,
                    pero no limitado a, cualquier tergiversación de la
                    información proporcionada a la empresa, (iv) el abuso verbal
                    o físico por un Miembro a cualquier empleado, agente,
                    director o funcionario de la empresa, o a sus respectivas
                    sucursales cuando sea o no determinado que el Miembro es
                    civilmente, penalmente o de otra manera responsable de tales
                    acciones, (v) el incumplimiento de cualquier ley aplicable,
                    incluyendo sin limitación, reglas de tarifa, condiciones de
                    transporte, aranceles u otros, (vi) el incumplimiento de
                    estos Términos y Condiciones o contrato de transporte de
                    envíos, o (vii) conducta de otra manera perjudicial para los
                    intereses del Programa Club Forza.
                    <br /><br />
                    La terminación de la cuenta de un Miembro resultará en la
                    pérdida de todos los privilegios y/o beneficios a los cuales
                    el Miembro tiene derecho a, bajo el Programa Club Forza,
                    incluyendo, pero no limitado a, la revocación de los accesos
                    y cualquier acción civil o penal que la empresa considere
                    pertinente.
                    <br /><br />
                    Un Miembro cuya cuenta ha sido terminada no será elegible
                    para crear una nueva cuenta en el Programa Club Forza salvo
                    a sola discreción de la empresa. Si la empresa decide no
                    terminar la cuenta del Miembro por cualquiera de las
                    acciones arriba mencionadas y permiten que el Miembro
                    mantenga su membresía en el Programa Club Forza, la empresa
                    podrá tomar otras acciones como descontar de la cuenta del
                    Miembro puntos acumulados y/o reducir su nivel de beneficios
                    en el Programa Club Forza, sin perjuicio de los recursos
                    legales disponibles bajo la ley aplicable.
                    <br /><br />
                    Para asegurar el cumplimiento de estos Términos y
                    Condiciones, las condiciones de transporte aplicable y/o las
                    tarifas, la empresa se reserva el derecho de auditar
                    cualesquiera y todas las cuentas de los Miembros sin previo
                    aviso. Durante el proceso de auditoría, podrá ser necesario
                    bloquear a los Miembros el acceso a sus cuentas.
                    <br /><br />
                    Durante el proceso de una auditoría o investigación, la
                    información de las cuentas de los Miembros podrá ser
                    compartida con cualquier tercero a quien la empresa haya
                    contratado para que asistan en dichas auditorías o
                    investigaciones.
                    <br /><br />
                    En el caso en que una auditoría o investigación revele
                    discrepancias o posibles violaciones a estos Términos y
                    Condiciones, la empresa podrá, sin previo aviso a los
                    Miembros y en adición a cualquier otro recurso descrito en
                    este documento o cualquier otro disponible (i) demorar el
                    procesamiento de solicitudes de redención de puntos, (ii)
                    cancelar cualquier redención de puntos pendientes, (iii)
                    retirar todos los puntos acumulados incorrectamente (ya sea
                    por error, fraude, discrepancia y/o por violación a los
                    Términos y Condiciones), (iv) retener estados de cuenta y/o
                    suspender la cuenta de algún Miembro y (v) terminar la
                    membresía de un Miembro en el Programa Club Forza.
                    <br /><br />
                    <b>Comercios Afiliados</b>
                    <br />
                    Los "comercios afiliados" de Club Forza incluyen
                    restaurantes, tiendas, u otras entidades que ofrecen
                    productos, servicios y/o descuentos en estos como
                    recompensas en el marco del programa Club Forza para los
                    miembros que usan sus productos o servicios.
                    <br /><br />
                    Cada comercio determina la tasa de descuento y beneficios
                    otorgados a los miembros de Club Forza los cuales pueden
                    variar en el tiempo y a su sola conveniencia sin que esto
                    implique una responsabilidad o compromiso por parte de
                    Delivery Express S.A.
                    <br /><br />
                    Los bienes y servicios prestados por cualquier comercio
                    afiliado del Programa Club Forza a los Miembros, que
                    permitan premiar con descuentos o que han sido obtenidos por
                    estos, son de exclusiva responsabilidad de dicho comercio.
                    La empresa no garantiza la satisfacción de los Miembros por
                    los productos o servicios que un Miembro puede adquirir u
                    obtener mediante el uso de su membresía de un comercio
                    afiliado del Programa Club Forza. Cada reclamación
                    relacionada con dichos servicios deberá ser dirigida
                    únicamente a aquellos comercios afiliados del Programa Club
                    Forza y cada Miembro, al convertirse en Miembro del Programa
                    Club Forza, mantiene a la empresa libre de reclamo o
                    solicitud de indemnización por cualquier o todos los daños
                    sufridos por los servicios prestados por cualquier compañía,
                    empresas y / o compañías que presten servicios a los
                    Miembros.
                    <br /><br />
                    Los comercios afiliados no tienen relación accionaria con
                    Delivery Express S.A. por lo que nunca se debe asociar un
                    vínculo más allá de la relación comercial con el programa
                    Club Forza. Delivery Express S.A. no asume ninguna
                    responsabilidad por acciones o violaciones en la que dichos
                    comercios puedan incurrir.
                    <br /><br />
                    <b>Acumulación de Puntos</b>
                    <br />
                    Los puntos pueden ser acumulados por los Miembros solamente
                    luego de que un Miembro se inscriba oficialmente en el
                    Programa Club Forza. La cantidad de puntos acumulados por el
                    Miembro depende de las condiciones de acumulación de puntos
                    del Programa Club Forza en vigencia al momento de la
                    actividad en que se ganan los puntos.

                    <br /><br />
                    Los puntos serán acumulados en la cuenta del Miembro sólo
                    cuando el Miembro haga uso de los medios de acumulación
                    reconocidos por el Programa Club Forza. Los Miembros no
                    podrán acumular puntos para la utilización de servicios de
                    consecución de puntos por otras personas diferentes a ellos
                    mismos, Para asegurar el recibo del crédito para puntos de
                    una actividad de consecución de puntos, el Miembro deberá
                    presentar su Número Club Forza.
                    <br /><br />
                    Los Miembros podrán buscar los puntos ganados en el Sitio
                    Web, iniciando sesión en sus cuentas usando su Contraseña.
                    Es responsabilidad de cada Miembro asegurar que los puntos
                    ganados sean debidamente acreditados a su cuenta.
                    <br /><br />
                    Cuando un Miembro afirme que puntos han sido ganados pero no
                    acreditados, o en el caso en que la empresa considere que
                    una verificación es requerida, la empresa se reserva el
                    derecho de solicitar pruebas para la acumulación por parte
                    de los Miembros, incluyendo, pero sin limitarse a, copias de
                    facturas, recibos de envió o documentación similar,
                    verificando que cualquier transacción de consecución de
                    puntos alegada haya sido comprada o contratada.
                    <br /><br />
                    La empresa se reserva el derecho de establecer puntos de
                    bonificación y ofertas promocionales del Programa Club
                    Forza, disponibles selectivamente a ciertos Miembros, en
                    base a su actividad de compra, ubicación geográfica u otros
                    factores determinados a discreción de la empresa. Los puntos
                    ofrecidos a los Miembros por utilizar los productos o
                    servicios de un socio de negocios del Programa Club Forza
                    podrán variar a discreción de la empresa y detalles de
                    ofertas específicas según sean publicadas cada cierto
                    tiempo.
                    <br /><br />
                    La empresa es la única parte autorizada para determinar si
                    se acreditarán puntos a la cuenta de un Miembro y se
                    reservan el derecho de rechazar o revocar dichos puntos a su
                    discreción cuando se cometa o se sospeche de un error.
                    <br /><br />
                    <b>Sitio Web del Programa Club Forza</b>
                    <br />
                    El Programa Club Forza pone a disposición de sus Miembros el
                    Sitio Web
                    <a href="http://www.forzadelivery.com" target="_blank"
                        >http://www.forzadelivery.com</a
                    >
                    a través del cual los Miembros podrán, entre otras cosas,
                    completar transacciones en línea, ver información de cuenta
                    y redimir puntos. La empresa se reserva el derecho de
                    interrumpir, modificar, actualizar y/o alterar el Sitio Web
                    y su contenido, funcionalidad y/o el acceso sin previo aviso
                    a los Miembros ya sea por motivos técnicos, de seguridad, de
                    control, de mantenimiento o cualquier otra razón.
                    <br /><br />
                    La empresa no garantiza el uso contínuo del Sitio Web o que
                    el Sitio Web funcione sin error y la empresa no serán
                    responsable por cualquier interrupción en el uso del Sitio
                    Web por cualquier razón o por la falta de disponibilidad de
                    los Miembros. Cada Miembro que acceda al Sitio Web es
                    responsable de proteger su equipo con un antivirus,
                    antimalware y otros programas similares.
                    <br /><br />
                    La información, productos y servicios publicados cada cierto
                    tiempo en el sitio web pueden incluir errores y/o puede no
                    estar actualizado, y la empresa no será responsable de la
                    veracidad de la información contenida en el Sitio Web. La
                    empresa, sin embargo, procurará asegurar que la información
                    publicada en el Sitio Web sea exacta y oportuna.
                    <br /><br />
                    Los enlaces a otras páginas web se pueden encontrar en el
                    Sitio Web a través del cual los Miembros pueden realizar
                    transacciones y acumular y/o redimir puntos. La empresa no
                    es responsable por el contenido, exactitud o veracidad de
                    las páginas web de terceros a las que el Sitio Web ofrece
                    enlaces o de las políticas de confidencialidad o privacidad
                    o los términos y condiciones de dichas páginas web; por lo
                    tanto, los miembros son responsables de la revisión y el
                    cumplimiento de los términos, condiciones y políticas de
                    cada página web de terceros antes y/o durante su visita a
                    ellos. La empresa no garantiza ni asume responsabilidad
                    alguna por daños que pudieran derivarse de la utilización de
                    la información o material contenido o mostrado en cualquier
                    página web de terceros que el Sitio Web pueda ofrecer como
                    enlaces. La empresa no será responsable de las acciones,
                    errores, omisiones o incumplimientos de los propietarios y
                    operadores de páginas web de terceros o por daños.
                    <br /><br />
                    <b>DATOS DEL PROGRAMA</b>
                    <br />
                </p>
                <ul>
                    <li>Vigencia: Del 24 de agosto de 2023 en adelante.</li>
                    <li>
                        Servicios que aplican al beneficio: Servicio Estándar y
                        Servicio C.O.D.
                    </li>
                    <li>Lugar en que aplica: República de Guatemala.</li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>MECÁNICA DEL PROGRAMA Y CARACTERÍSTICAS</b>
                    <br />
                    Durante la vigencia del programa, todos los clientes nuevos
                    y actuales elegibles del programa Club Forza podrán realizar
                    sus envíos a toda la República de Guatemala tienen la
                    posibilidad de adquirir los beneficios que el club les
                    proporciona.
                    <br /><br />
                    Los clientes deberán de ingresar, registrar y activar la
                    membresía desde nuestro portal web
                    <a href="https://www.forzadelivery.com" target="_blank">
                        www.forzadelivery.com</a
                    >. Una vez activado y pagado, podrán realizar sus envíos a
                    nivel nacional, pagando el importe que éstos representen
                    según las tarifas vigentes en ese momento (envíos menores de
                    10 libras) obteniendo los beneficios como: acumulación de
                    puntos por cada envío realizado, posibilidad de comprar
                    planes de descuentos en envíos que pueden ser desde el 10%
                    en adelante, según su valor de compra; posibilidad de
                    comprar paquetes con envíos incluidos desde 50 envíos en
                    adelante, según su valor de compra; promociones especiales
                    en comercios afiliados, recolecciones sin costo, fila
                    preferencial en Agencias Express Center, atención y gestión
                    de reclamos, incluyendo su resolución, en un tiempo menor
                    comparado con los clientes regulares, envío de devoluciones
                    al remitente sin costo. <br /><br />
                    El cliente debe poseer una tarjeta de crédito o débito de
                    banco del sistema del país, la cual deberá estar vigente y
                    activa para realizar transacciones.
                    <br /><br />
                    <span>
                        <b
                            >DETALLES DE LOS PLANES DE DESCUENTO Y SUS
                            CARACTERÍSTICAS:</b
                        >
                        <br />
                        <br />
                        <ul>
                            <li>
                                Los planes de descuentos son promociones
                                exclusivas para los miembros activos del Club
                                Forza y a los cuales tienen derecho de acceder
                                por medio de una compra de acuerdo al valor y
                                nivel de descuento que representen. Delivery
                                Express, S. A. decidirá por sí misma y a su
                                entera discreción los niveles de descuento
                                disponibles para la compra así como los valores
                                de venta que tenga cada uno. Los planes de
                                descuento funcionan otorgando un porcentaje (%)
                                de disminución en el valor de la tarifa vigente
                                al momento de realizar el envío. Los envíos a
                                que hace referencia este beneficio, al igual que
                                el CLUB FORZA, son envíos con peso máximo de
                                hasta 10 libras. El valor de descuento será
                                únicamente sobre el valor del flete como tal, no
                                incluye ni exime recargos como sobrepeso,
                                comisiones por el servicio de cobro contra
                                entrega, recargos por cobrar al destinatario y
                                todos aquellos que la empresa considere
                                pertinentes, según sus políticas de precios y
                                servicios vigentes. El sistema de tarificación
                                aplicable o la forma de calcular la tarifa
                                dentro de los planes será la misma que Delivery
                                Express utiliza para sus transacciones normales
                                (cobro por cobertura). Los envíos realizados
                                utilizando el beneficio de algún plan de
                                descuento también pueden permitir la acumulación
                                de puntos por cada envío realizado. Los planes
                                tendrán una vigencia de hasta seis (6) meses,
                                siempre y cuando este tiempo esté dentro de los
                                límites de cobertura que otorga la membresía
                                activa. La empresa se reserva el derecho de
                                modificar total o parcialmente los Términos y
                                Condiciones del beneficio “PLANES DE DESCUENTO”
                                así como el interpretar las disposiciones acá
                                inmersas, siendo inapelables las decisiones que
                                la empresa tome en este sentido, así como
                                también sobre aspectos no previstos en las
                                presentes. Cualquier modificación que se
                                produzca por causas ajenas no imputables a la
                                empresa será publicada para conocimiento del
                                público en general, sin que ello dé lugar a
                                reclamo o indemnización alguna. La empresa
                                también podrá finalizar anticipadamente, o bien,
                                extender la vigencia de los planes a que hace
                                referencia este párrafo a su entera y absoluta
                                discreción.
                            </li>
                        </ul>
                    </span>
                    <br />
                    <span>
                        <b
                            >DETALLES DE LOS PAQUETES DE ENVÍOS Y SUS
                            CARACTERÍSTICAS:</b
                        >
                        <br />
                        <br />
                        <ul>
                            <li>
                                Los paquetes de envíos incluidos son promociones
                                exclusivas para los miembros activos del Club
                                Forza y a los cuales tienen derecho de acceder
                                por medio de una compra de acuerdo al valor y
                                número de envíos que incluyan. Delivery Express,
                                S. A. decidirá por sí misma y a su entera
                                discreción el número de envíos incluidos en cada
                                paquete disponible para la compra así como los
                                valores de venta que tenga cada uno. Los envíos
                                a que hace referencia este beneficio, al igual
                                que el CLUB FORZA, son envíos con peso máximo de
                                hasta 10 libras. El valor incluido en el paquete
                                será únicamente el del valor del flete como tal,
                                no incluye ni exime recargos como sobrepeso,
                                comisiones por el servicio de cobro contra
                                entrega, recargos por cobrar al destinatario y
                                todos aquellos que la empresa considere
                                pertinentes, según sus políticas de precios y
                                servicios vigentes. Los paquetes de envíos
                                incluidos funcionan otorgando una cantidad de
                                envíos disponibles para utilizar por parte del
                                cliente. El sistema de tarificación aplicable o
                                la forma de calcular la tarifa dentro de los
                                paquetes de envíos incluidos será preferencial,
                                asignando un mismo valor o precio a todos los
                                envíos disponibles, según la compra realizada,
                                no importando el lugar de entrega dentro de la
                                República de Guatemala al que se dirigiera tal
                                envío. Los paquees de envíos incluidos tendrán
                                una vigencia de hasta seis (6) meses de duración
                                o hasta que el número de envíos incluidos al que
                                hace referencia se hayan agotado, lo que suceda
                                primero; siempre y cuando este tiempo esté
                                dentro de los límites de cobertura que otorga la
                                membresía activa. Una vez agotado el tiempo de
                                vigencia, la empresa no será responsable bajo
                                ninguna circunstancia de resarcir, reponer,
                                enmendar, compensar o similares a aquellos
                                clientes que con conocimiento de causa o no, no
                                hayan consumido los envíos que contenía el plan
                                adquirido. La empresa se reserva el derecho de
                                modificar total o parcialmente los Términos y
                                Condiciones del beneficio “PAQUETES DE ENVÍOS
                                INCLUIDOS” así como el interpretar las
                                disposiciones acá inmersas, siendo inapelables
                                las decisiones que la empresa tome en este
                                sentido, así como también sobre aspectos no
                                previstos en las presentes. Cualquier
                                modificación que se produzca por causas ajenas
                                no imputables a la empresa será publicada para
                                conocimiento del público en general, sin que
                                ello dé lugar a reclamo o indemnización alguna.
                                La empresa también podrá finalizar
                                anticipadamente, o bien, extender la vigencia de
                                los paquetes de envíos incluidos que hace
                                referencia este párrafo a su entera y absoluta
                                discreción.
                            </li>
                        </ul>
                    </span>
                    <br />
                    <b>RESTRICCIONES</b>
                    <br />
                </p>
                <ul>
                    <li>
                        Válido para clientes nuevos y actuales que generen sus
                        guías a través del portal web y que sean elegibles para
                        utilizar el programa Club Forza.
                    </li>
                    <li>
                        El (los) descuentos que otorgan los paquetes de
                        descuentos y/o la tarifa de los paquetes de envíos
                        incluidos son válidos únicamente sobre la tarifa base
                        del envió en los servicios Estándar y COD.
                    </li>
                    <li>
                        El (los) descuentos que otorgan los paquetes de
                        descuentos y/o la tarifa de los paquetes de envíos
                        incluidos no aplica para: Seguro en tránsito de la
                        mercancía, exceso de peso, cobros adicionales por
                        servicio collect, cobros de comisión COD, material de
                        empaque, servicio internacional, otros cobros o recargos
                        que genere la transacción del envió.
                    </li>
                    <li>
                        No se aplica en combinación con otras promociones
                        vigentes o futuras.
                    </li>
                    <li>
                        Válido únicamente para usuarios que posean tarjeta de
                        crédito o débito registrada para realizar el cobro en el
                        portal web.
                    </li>
                    <li>
                        Válido por 1 año a partir de la fecha de compra. (Club
                        FORZA).
                    </li>
                    <li>
                        Los Planes de Descuento y los Paquetes de envíos
                        incluidos a los que da derecho la membresía pueden ser
                        adquiridas en cualquier momento de la vigencia de ésta
                        siempre y cuando dicha vigencia tenga como mínimo 90
                        días previos al vencimiento. En caso contrario, será
                        necesaria la renovación de la membresía para seguir
                        adquiriendo los beneficios.
                    </li>
                    <li>
                        El valor de la membresía, los planes de descuento, los
                        paquetes de envíos incluidos y cualquier otro beneficio
                        que se haya listado o que en un futuro sea adherido, no
                        serán reembolsables una vez el cliente haya aceptado y
                        pagado los cargos correspondientes.
                    </li>
                    <li>
                        El cliente puede cancelar su membresía en cualquier
                        momento, sin embargo, el valor proporcional no utilizado
                        antes de su vencimiento no será reembolsado, ni de
                        cualquiera de los beneficios que pueda tener activos al
                        momento de tal cancelación.
                    </li>
                    <li>
                        El cliente debe indicar o no su deseo de no renovar
                        automáticamente su afiliación a la membresía del CLUB
                        FORZA. Ante la ausencia de acción por parte del cliente,
                        la empresa se reserva el derecho de renovar la membresía
                        de forma automática informando 30 días antes al correo
                        electrónico registrado del cliente.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>RESPONSABILIDAD DEL MIEMBRO / CLIENTE</b>
                    <br />
                </p>
                <ul>
                    <li>
                        El miembro son los únicos responsables de toda la
                        información proporcionada a la empresa a través del
                        portal web para hacer sus envíos.
                    </li>
                    <li>
                        En cualquier momento durante la vigencia del programa, y
                        aun habiendo reunido las características para aplicar y
                        de estos Términos y Condiciones, la empresa a su entera
                        discreción podrá rehusarse a otorgar el programa
                        indicado.
                    </li>
                    <li>
                        Todo cliente que forme parte da por entendido que conoce
                        la mecánica del programa y que acepta plenamente los
                        términos y condiciones de esta.
                    </li>
                    <li>
                        La membresía tiene una renovación automática que se hará
                        efectiva el día posterior al vencimiento de la ya
                        adquirida. El cliente podrá anular esta opción
                        habilitando o deshabilitando el botón correspondiente al
                        momento de la compra o en cualquier momento durante la
                        vigencia de la membresía siempre y cuando sea al menos
                        el día anterior a la fecha de expiración. De no suceder
                        la acción anterior, la empresa podrá renovar
                        automáticamente la membresía un año más y procesará el
                        cobro correspondiente.
                    </li>
                    <li>
                        Se presume y asume que la tarjeta de crédito o débito
                        utilizada para la compra de la membresía es propiedad de
                        la persona o entidad que realiza la transacción dentro
                        del portal de la empresa. Delivery Express, S. A. no
                        asumirá ningún tipo de responsabilidad civil, moral o
                        penal por las situaciones que deriven del uso indebido
                        de una tarjeta de crédito o débito dentro de su portal;
                        ya sea que ésta hubiera sido usada por el legítimo
                        propietario, por un tercero en representación del
                        propietario o un tercero sin autorización alguna.
                    </li>
                    <li>
                        La empresa se reserva el derecho de modificar total o
                        parcialmente los Términos y Condiciones del programa
                        “CLUB FORZA” así como el interpretar las disposiciones
                        acá inmersas, siendo inapelables las decisiones que la
                        empresa tome en este sentido, así como también sobre
                        aspectos no previstos en las presentes. Cualquier
                        modificación que se produzca por causas ajenas no
                        imputables a la empresa será publicada para conocimiento
                        del público en general, sin que ello dé lugar a reclamo
                        o indemnización alguna.
                    </li>
                    <li>
                        El cliente reconoce que no deberá permitir el uso
                        indebido de su perfil dentro de los distintos canales de
                        la empresa. El uso de códigos y usuarios puesto a
                        disposición por la empresa para el cliente es de uso
                        exclusivo para cada uno de los clientes y no es
                        permitido que lo utilicen terceros.
                    </li>
                    <li>
                        Los clientes al solicitar que les sea aplicado el
                        presente programa, desde ya aceptan que renuncia de
                        plantear cualquier queja, reclamo, denuncia, demanda o
                        cualquier otro tipo de acción derivada de cualquier
                        controversia que pudiera suscitar.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>MODIFICACIÓN DE LAS CONDICIONES DE USO</b>
                    <br />
                    La empresa se reserva el derecho de modificar estos Términos
                    y Condiciones, con o sin aviso previo al cliente, a su
                    entera y absoluta discreción. El cliente podrá consultar la
                    versión más reciente del presente en la parte inferior de la
                    página web de la empresa. La versión más reciente de los
                    Términos y Condiciones reemplazará todas las versiones
                    anteriores. El solicitar o que le sea aplicado al cliente el
                    programa, una vez efectuadas las modificaciones de estas,
                    implica que el cliente acepta quedar obligado por dichas
                    modificaciones. Así mismo, la empresa se reserva el derecho
                    exclusivo de finalizar anticipadamente, o bien, extender la
                    vigencia del programa.
                    <br /><br />
                    <b>PRIVACIDAD E INFORMACIÓN PERSONAL</b>
                    <br />
                    La empresa ha asumido el compromiso de proteger la
                    privacidad de la información personal que proporcionas a
                    través de los distintos canales donde aplica el presente
                    programa. Toda información proporcionada a la empresa se
                    sujetará a la política de privacidad, cuyos términos se
                    incorporan al presente.
                    <br /><br />
                    <b>
                        Renuncia; Divisibilidad; Títulos; Legislación Aplicable
                    </b>
                    <br />
                    La falta o retraso por La empresa para ejercer cualquiera de
                    sus derechos, facultades o recursos o remedios respectivos
                    bajo estos Términos y Condiciones no se entenderá como una
                    renuncia a ese o cualquier otro derecho, facultad o recurso
                    o remedio que pertenecen a la Empresa.
                    <br /><br />
                    Si alguna disposición de estos Términos y Condiciones es
                    considerada por un tribunal de jurisdicción competente o un
                    organismo regulador como nula, inválida o inaplicable, dicha
                    disposición se aplicará en la máxima medida permitida por la
                    legislación aplicable y los restantes términos y condiciones
                    permanecerán en pleno vigor y efecto sin modificaciones.
                    <br /><br />
                    Los títulos de estos Términos y Condiciones se insertan para
                    la conveniencia de referencia solamente y de ninguna manera
                    afectará la construcción, significado o efecto de nada de lo
                    contenido en estos Términos y Condiciones o regularán los
                    derechos y obligaciones de la Empresa.
                    <br /><br />
                    Estos Términos y Condiciones se rigen y deberán
                    interpretarse de conformidad con las leyes de la República
                    de Guatemala.
                </p>
            </div>
        </accordion-group>
        <!-- Plan Petit -->
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[5].show)) &&
                countrySelect.value === 'GT'
            "
            id="petit"
            [isOpen]="selectedItem == 'petit'"
            (isOpenChange)="openAccordion($event, 'petit')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'petit'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'petit'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Paquete Petit</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[5].show)) &&
                countrySelect.value === 'HN'
            "
            id="petit"
            [isOpen]="selectedItem == 'petit'"
            (isOpenChange)="openAccordion($event, 'petit')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'petit'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'petit'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Paquete Petit HN</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
        <!-- Plan Platino -->
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[6].show)) &&
                countrySelect.value === 'GT'
            "
            id="platino"
            [isOpen]="selectedItem == 'platino'"
            (isOpenChange)="openAccordion($event, 'platino')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'platino'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'platino'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Paquete Platino</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[6].show)) &&
                countrySelect.value === 'HN'
            "
            id="platino"
            [isOpen]="selectedItem == 'platino'"
            (isOpenChange)="openAccordion($event, 'platino')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'platino'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'platino'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Paquete Platino HN</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
        <!-- Plan PRO -->
        <accordion-group
            *ngIf="
                (!isModal || (isModal && termsProducts[7].show)) &&
                countrySelect.value === 'GT'
            "
            id="pro"
            [isOpen]="selectedItem == 'pro'"
            (isOpenChange)="openAccordion($event, 'pro')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'platino'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'platino'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Paquete PRO</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
        <accordion-group
            *ngIf="false"
            id="pro"
            [isOpen]="selectedItem == 'pro'"
            (isOpenChange)="openAccordion($event, 'pro')">
            <div accordion-heading class="d-flex align-items-center">
                <small class="mr-2 text-h1" *ngIf="selectedItem != 'platino'">
                    <i class="bi bi-plus-circle-fill text-primary"></i>
                </small>
                <small class="mr-2 text-h1" *ngIf="selectedItem == 'platino'">
                    <i class="bi bi-dash-circle-fill text-danger"></i>
                </small>
                <span class="text-h2">Paquete PRO HN</span>
            </div>
            <div class="pl-4">
                <p style="text-align: justify" class>
                    Al contratar los servicios de FORZA DELIVERY EXPRESS (en
                    adelante “la empresa”), usted, en calidad de “Remitente”,
                    acuerda y acepta, en su nombre y en nombre del destinatario
                    del Envío (“Destinatario”) y de cualquier persona interesada
                    en el Envío, que se aplicaran estos Términos y Condiciones:
                    <br /><br />
                    Envío: significa todos los documentos o paquetes que viajan
                    amparados por una guía de transporte y que la empresa
                    considere que puede transportar y por el medio de transporte
                    que considere idóneo, incluido el transporte, por carretera
                    o a través de un tercero. Se entenderá como guía de
                    transporte cualquier documento o número identificador del
                    Envío que se genere por los sistemas automatizados de la
                    empresa tales como: etiqueta, código de barras, conocimiento
                    de embarque o carta de porte, así como cualquier versión
                    electrónica de los mismos. Cada Envío se transporta de
                    conformidad con una responsabilidad limitada de acuerdo con
                    lo establecido en los presentes Términos y Condiciones. Si
                    el Remitente requiere una protección mayor, podrá concertar
                    una protección que cubra el valor del envío pagando un
                    seguro. la empresa solo transportará bienes que sean
                    propiedad del Remitente y este afirma estar autorizado a
                    aceptar estos términos y condiciones, no solo por sí mismo
                    sino también como agente o por cuenta de otra persona que
                    pueda, posteriormente, tener interés en los bienes. El
                    remitente indemnizará a la empresa contra cualquier daño o
                    gasto resultante de cualquier violación a esta garantía.

                    <br /><br />
                    <b>Envíos no aceptables</b>
                    <br /><br />
                    Se considerará inaceptable un Envío si este:
                    <br />
                </p>
                <ul>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Contiene artículos falsos, animales, lingotes de oro o
                        plata, dinero, piedras preciosas, armas, explosivos y/o
                        munición; restos humanos; artículos ilegales tales como
                        marfil y/o narcóticos.
                    </li>
                    <li>
                        Está clasificado como sustancia toxica, mercancía
                        peligrosa, articulo prohibido o restringido por la
                        autoridad local, u otra organización pertinente
                        (“Mercancía peligrosa”);
                    </li>
                    <li>
                        Su dirección es incorrecta o no está adecuadamente
                        indicada.
                    </li>
                    <li>
                        Su embalaje es defectuoso o inadecuado para su
                        transporte seguro mediante un manejo y cuidado habitual.
                    </li>
                    <li>
                        Contiene cualquier otro artículo que la empresa decida
                        que no puede ser transportado de forma segura o legal.
                    </li>
                </ul>
                <p style="text-align: justify">
                    <br />
                    <b>Entregas e imposibilidades de entrega</b>
                    <br />
                    Los Envíos no podrán realizarse hacia apartados de correos o
                    códigos postales. Los Envíos son entregados en la dirección
                    del Destinatario indicada por el Remitente, pero no
                    necesariamente a la persona indicada como Destinatario. Los
                    envíos a direcciones que dispongan de un área central de
                    recepción serán entregados en dicha área. <br /><br />
                    La empresa podrá notificar al Destinatario una entrega a
                    realizar o una entrega fallida. Se le podrán ofrecer al
                    Destinatario opciones de entrega alternativas, tales como
                    entrega otro día, entrega sin necesidad de firma,
                    redirección del envío o recogida en un Punto de Venta de la
                    empresa. El Remitente podrá descartar ciertas opciones de
                    entrega bajo petición.
                    <br /><br />
                    Si el Envío es considerado inaceptable debido a que el
                    Destinatario no puede ser razonablemente identificado o
                    localizado, o el Destinatario rechaza el envío y/u otros
                    cargos del Envío, la empresa hará cuanto razonablemente esté
                    a su alcance para devolver el Envío al Remitente por cuenta
                    y cargo de este último y, de no ser posible, el Envío podrá
                    ser abandonado, destruido, enajenado o vendido sin incurrir
                    en ningún tipo de responsabilidad hacia el Remitente ni
                    ninguna otra persona, aplicándose las ganancias contra los
                    cargos del Envío y costes administrativos relacionados. La
                    empresa tendrá derecho a destruir cualquier Envío que la
                    legislación aplicable impida devolver al Remitente, así como
                    cualquier Envío de mercancía peligrosa. <br /><br />
                    <b>Inspección</b>
                    <br />
                    La empresa se reserva el derecho de abrir e inspeccionar un
                    Envío sin notificación previa, por motivos de seguridad o
                    por otros motivos de regulación, sin que esto conlleve a la
                    empresa responsabilidad alguna. <br /><br />
                    <b>Precio del Envío y tasas</b>
                    <br />
                    El precio del Envío de la empresa se calcula en función del
                    peso real o volumétrico del envío, por distancia y/o tiempo
                    de espera o tránsito aplicándose siempre el mayor, y
                    cualquier envío podrá ser pesado y medido de nuevo por la
                    empresa para confirmar este cálculo, aceptando desde ya como
                    líquido y exacto el precio y la modificación que pudiera
                    sufrir por la confirmación que se realice. <br /><br />
                    El Remitente o el Destinatario, cuando la empresa actué en
                    nombre del Destinatario, pagará o reembolsará a la empresa
                    todos los cargos del Envío u otros cargos vencidos por los
                    servicios prestados por la empresa o incurridos por la
                    empresa en nombre del Remitente o del Destinatario. En caso
                    de que ni el Remitente o el Destinatario no pagaran o
                    reembolsaran a la empresa el cargo que se les indique, esta
                    última estará facultada para retener el envío hasta su
                    efectiva cancelación y adicionar los costos administrativos,
                    o cualesquiera otros que se generen, derivado de la
                    retención del Envío. <br /><br />
                    <b>Responsabilidad de la empresa</b>
                    <br />
                    La responsabilidad de la empresa en relación con cualquier
                    Envío transportado por carretera, se considera limitada al
                    monto declarado si y solo si, el remitente adquirió un
                    seguro para este fin. Caso contrario la empresa será
                    responsable únicamente por reembolsar hasta un máximo de
                    Q800.00 o su equivalente en moneda local. <br /><br />
                    Si el Remitente requiere extender el límite de
                    responsabilidad, podrá adquirir el producto de Protección de
                    Valor del Envío pagando un precio adicional, o bien
                    contratar su propio seguro, lo cual deberá hacer de
                    conocimiento previo de la empresa y presentar la
                    documentación correspondiente previo a realizar el Envío.
                    <br /><br />
                    La responsabilidad de la empresa queda limitada
                    estrictamente a la perdida y daño directo al Envío. Quedan
                    excluidos todos los demás tipos de pérdida o daño, tales
                    como, pero sin limitarse a: el lucro cesante, la pérdida de
                    intereses y de futuros negocios, con independencia de que
                    dicha perdida o daño sea indirecto o de especial
                    consideración, e incluso si se hubiera avisado a la empresa
                    sobre el riesgo de dicha perdida o daño. <br /><br />
                    La empresa hará cuanto razonablemente esté a su alcance para
                    entregar el Envío de acuerdo con los tiempos de tránsito
                    habituales de la empresa, pero estos tiempos de tránsito no
                    son vinculantes y no forman parte del contrato, ni serán
                    motivo de reclamo en contra de la empresa. La empresa no se
                    hace responsable de pérdidas o daños ocasionados por
                    demoras.
                    <br /><br />
                    <b>Reclamaciones</b>
                    <br />
                    Toda reclamación que pudiera suscitar derivado de un Envío
                    debe ser realizada por escrito a la empresa en un plazo
                    perentorio de treinta (30) días a partir de la fecha en que
                    la empresa aceptó el Envío. A falta de una reclamación o
                    vencido el plazo perentorio indicado, la empresa quedará
                    eximida de toda responsabilidad. Las reclamaciones están
                    limitadas a una por Envío y su finiquito será completo y
                    final para toda pérdida y daño en relación con dicho
                    Envío.<br /><br />
                    <b>Contraprestación</b>
                    <br />
                    La empresa podrá adicionar una contraprestación que cubra el
                    valor en caso de pérdida o daño del envío, siempre y cuando
                    el remitente expresamente se lo indique a la empresa por
                    escrito, rellenando la casilla designada al efecto en el
                    anverso de la Guía de Transporte o a través de los sistemas
                    automatizados de la empresa y pague el precio aplicable. La
                    contraprestación no cubre pérdidas indirectas, ni las
                    pérdidas o daños ocasionados por demoras ajenas a la
                    entidad. <br /><br />
                    <b>Circunstancias ajenas al control de la empresa</b>
                    <br />
                    La empresa no es responsable de las pérdidas o daños
                    derivados de circunstancias ajenas a su control y ejecución
                    del envío, incluyendo casos fortuitos y de fuerza mayor.
                    Estas circunstancias incluyen pero no se limitan a: daño
                    eléctrico o magnético a imágenes electrónicas o
                    fotográficas, datos o grabaciones o borrado de los elementos
                    mencionados anteriormente; cualquier defecto o
                    característica relacionada con la naturaleza del Envío,
                    incluso, si estos son conocidos por la empresa; cualquier
                    acción u omisión por parte de personas no empleadas o
                    contratadas por la empresa, tales como pero sin limitarse
                    al: Remitente, Destinatario, terceros, aduanas u otros
                    representantes gubernamentales; y en el caso de Fuerza Mayor
                    tales como pero sin limitarse a: terremotos, huracanes,
                    tormentas, inundaciones, niebla, guerras, pandemias,
                    accidentes, disturbios, embargos, conmoción civil o acciones
                    sindicales.
                    <br /><br />
                    <b>Garantías e indemnización del Remitente </b>
                    <br />
                    El Remitente indemnizará y mantendrá indemne a la empresa
                    por cualquier daño o pérdida que se derive del
                    incumplimiento por parte del Remitente, por cualquier
                    legislación o normativa aplicable, y del incumplimiento por
                    parte del Remitente de cualquiera de las siguientes
                    declaraciones y garantías: El Remitente acepta que el Envío
                    pueda transportarse por cualquier medio de transporte y
                    ruta, que pueda ser desviado, e inclusive parar en lugares
                    de escala intermedios. <br /><br />
                    <b>Legislación aplicable</b>
                    <br />
                    El Remitente acepta expresa e irrevocablemente que cualquier
                    controversia que se derive o esté relacionada con estos
                    Términos y Condiciones estará sujeta a la jurisdicción de
                    los Tribunales del país de origen del Envío y se regirá
                    igualmente por la legislación de dicho país. No obstante, si
                    la legislación aplicable permitiera la elección de fuero o
                    de jurisdicción, esta será la determinada por la empresa.
                    <br /><br />
                    <b>Nulidad e ineficacia</b>
                    <br />
                    Si alguna de las cláusulas o parte de estas fuese nulas o
                    ineficaces, permanecerán subsistentes el resto de los
                    Términos y Condiciones no afectados por la nulidad o
                    ineficacia.
                    <br /><br />
                    <b>Otras Declaraciones</b>
                    <br />
                    El Remitente declara y acepta que la empresa considerará que
                    toda la información facilitada por el Remitente o sus
                    representantes es completa y exacta; que el envío es
                    aceptable para su transporte; que el Envío fue preparado en
                    instalaciones seguras por personal fiable y se protegió de
                    interferencias no autorizadas durante su preparación,
                    almacenamiento y traslado del mismo a la empresa; que el
                    Remitente ha cumplido con la legislación aplicable en
                    importación, exportación, protección de datos, sanciones,
                    embargos y todas las leyes y normativas vigentes y
                    aplicables; y, que el Remitente ha obtenido todos los
                    consentimientos necesarios en relación con los datos
                    personales facilitados a la empresa, incluyendo información
                    del Destinatario que podrá ser solicitada para el
                    transporte, despacho de aduanas y la entrega, como la
                    dirección de correo electrónico y número de teléfono móvil.
                </p>
            </div>
        </accordion-group>
    </accordion>
</div>
