import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { Ng2SearchPipe } from 'ng2-search-filter';
import { ModalComponent } from '../modal/modal.component';
@Component({
    selector: 'app-list-modal-with-headers',
    templateUrl: './list-modal-with-headers.component.html',
    styleUrls: ['./list-modal-with-headers.component.scss'],
})
export class ListModalWithHeadersComponent implements OnInit {
    @Input() title: string = 'Opciones';
    @Input() config: any[] = [];
    @ViewChild('modalselectListWithHeaders', { static: false }) modalselect;
    @ViewChildren('itemsOption') itemsOptionElRef:
        | QueryList<ElementRef>
        | undefined;
    @ViewChild('searchInput', { static: false }) searchInput;
    @Output() changeSelected = new EventEmitter<any>();
    @Output() enterSearch = new EventEmitter<any>();
    idModal = 'modalListSelectWithHeaders' + Date.now().toFixed();
    public searchText: string = '';
    constructor(private pipeSearch: Ng2SearchPipe) {}

    ngOnInit(): void {}

    onEnterSearch(item) {
        this.enterSearch.emit({ ...item, textSearch: this.searchText });
    }

    itemSelected(item, option, subOption, newAddress) {
        if (newAddress == 0) {
            const changeSelected = {
                item: item,
                option: option,
                subOption: subOption,
                value: newAddress,
            };
            this.searchText = '';
            this.changeSelected.emit(changeSelected);
            this.modalselect.Close();
        } else {
            const changeSelected = {
                item: item,
                option: option,
                subOption: option.subList[0],
                value: newAddress,
            };

            this.searchText = '';
            this.changeSelected.emit(changeSelected);
            this.modalselect.Close();
        }

        // const changeSelected = {
        //     item: item,
        //     option: option,
        //     subOption: subOption,
        // };
        // this.searchText = '';
        // this.changeSelected.emit(changeSelected);
        // this.modalselect.Close();

        // const newChangeSelected = {}
    }

    async openModal() {
        this.searchText = '';
        let modal: ModalComponent = this.modalselect;
        modal.Open();

        await new Promise((resolve) => setTimeout(resolve, 500));
        let inputsearch = this.searchInput.nativeElement;
        inputsearch?.focus();
    }

    onClickHeader(item) {
        for (const iterator of this.config) {
            iterator.selected = false;
        }
        item.selected = true;
        this.searchText = '';
        item.list = [];
    }

    onClickSubHeader(item, radio) {
        this.searchText = '';
        for (const iterator of item.headers) {
            iterator.selected = false;
        }
        radio.selected = true;
        item.list = [];
    }
}
