import { CustomStepInfo } from '../ngx-joyride/custom-step-info';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { Contract } from 'src/utility/Storage/contract';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';

export const stepsArrayCategories: CustomStepInfo[] = [
    {
        stepId: 'step1',
        anchorId: 'categories',
        header: 'Sección de Categorías',
        text: 'Estas son nuestras principales categorías de productos y servicios. Aquí encontrarás todo lo que necesitas para optimizar tus envíos',
    },
];

export const stepsArrayCartIcon: CustomStepInfo[] = [
    {
        stepId: 'step2',
        anchorId: 'cart-icon',
        header: 'Carrito de Compras',
        text: 'Acá podrás revisar tus productos seleccionados. Además de poder realizar tus pagos de manera segura y rápida.',
    },
];

export const stepsArrayLoginIcon: CustomStepInfo[] = [
    {
        stepId: 'step3',
        anchorId: 'login-icon',
        header: 'Cuenta',
        text: 'Aquí podrás iniciar sesión si ya tienes una cuenta o crear una nueva para comprar y gestionar tus envíos.',
    },
];

export const allStepsArray: CustomStepInfo[] = [null]
    .concat(stepsArrayCategories)
    .concat(stepsArrayCartIcon)
    .concat(stepsArrayLoginIcon);

export const stepsStandar = ['step1', 'step2', 'step3'];

export const idElementStep = {
    step1: { child: 'step1', parent: 'sourceAddress' },
    step2: { child: 'step2', parent: 'sourceAddress' },
    step3: { child: 'step3', parent: 'sourceAddress' },
};

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class OnBoardingCrearGuia {
    constructor(
        private secureStorage: SecureSimpleStorage,
        private connectionApi: ConnectionApiService
    ) {}

    tutoriales: any;

    public async setTutorial(idTutorial: string) {
        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        const user = JSON.parse(userString);
        const body = {
            path: 'Portal/SetStatusOfTutorialOfAccount',
            data: {
                Method: 'SetStatusOfTutorialOfAccount',
                Params: {
                    AccountId: user.ObjectValue[0].Accounts[0].IdAccount,
                    Token: user.ObjectValue[0].Token,
                    TutorialId: idTutorial,
                },
            },
        };
        try {
            const response = await this.connectionApi
                .postData(body, true)
                .toPromise();
            if (!response.ObjectValue) {
                return;
            }
        } catch (error) {}
    }
}
