import { Component, OnInit } from '@angular/core';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { SweetAlert } from 'src/utility/sweetAlert';
import { availableCards } from '../list-card-management/list-card-management.component';

@Component({
    selector: 'app-card-management',
    templateUrl: './card-management.component.html',
    styleUrls: ['./card-management.component.scss'],
})
export class CardManagementComponent implements OnInit {
    listCard: availableCards[] = [];
    constructor(
        private secureStorage: SecureSimpleStorage,
        private api: ConnectionApiService,
        private sweet: SweetAlert
    ) {}

    ngOnInit(): void {
        this.getAllCardsAsociate();
    }

    /**
     * @description realiza la llamada a la api unicamente si no es un modal
     * @author Randy Can
     * @date 27/07/2022
     * @returns {*}
     * @memberof ListCardManagementComponent
     */
    async getAllCardsAsociate() {
        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        const user = JSON.parse(userString);

        const body = {
            path: 'Catalog/GetDynamicCatalog',
            data: {
                Method: 'GetDynamicCatalog',
                Params: {
                    TypeMethod: 'GetPaymentMethod',
                    IdAccount: String(
                        user.ObjectValue[0].Accounts[0].IdAccount
                    ),
                    Token: String(user.ObjectValue[0].Token),
                },
            },
        };

        this.api.postData(body, false).subscribe((response) => {
            this.listCard = response.ObjectValue;
        });
    }
}
