<div class="w-100 h-100" style="position: relative">
    <div class="w-100 h-100 principal-container">
        <div class="col-12 container-form-search" [ngClass]="{}">
            <form
                class="form-inline col-12 p-0"
                style="z-index: 1000"
                [ngClass]="{ 'pb-2': text != '' }">
                <div class="form-group col-12 px-0">
                    <div
                        class="col-1 text-left px-0 container-icon-search"
                        style="max-width: 32px">
                        <em
                            [ngClass]="{
                                'cursor-pointer':
                                    (text != '' || text) && !searchResultEmpty
                            }"
                            (click)="showAllResults()"
                            class="fa icon-search fa-2x d-block"
                            style="width: 24px"></em>
                    </div>
                    <input
                        [ngClass]="{
                            'col-12': text == '',
                            'col-10': text != ''
                        }"
                        class="form-control col-10 img px-4"
                        [class.open]="isOpenMobile"
                        [(ngModel)]="text"
                        [ngModelOptions]="{ standalone: true }"
                        (keyup)="searchTerm(text)"
                        (keyup.enter)="showAllResults()"
                        type="text"
                        autocomplete="off"
                        placeholder="Buscar"
                        style="z-index: 1000" />
                    <div
                        class="col-1 text-right px-0 container-icon-close"
                        *ngIf="text != ''">
                        <i
                            class="bi bi-x-circle-fill cursor-pointer"
                            (click)="clear()"></i>
                    </div>
                    <div
                        class="container-results overflow-auto w-100 pt-2"
                        [class.is-open]="searchResult"
                        *ngIf="productItems?.length && text != ''"
                        id="search-outer"
                        style="max-height: 295px !important">
                        <div
                            class="item-result item-result-2"
                            *ngFor="let element of productItems">
                            <div class="px-2 my-2 weight-700">
                                {{ element?.Name }}
                            </div>
                            <div
                                *ngFor="
                                    let subElement of element.Elements;
                                    let i = index
                                "
                                class="item-for-select cursor-pointer"
                                (click)="navigation(element, subElement)">
                                <div class="item-result px-3">
                                    <div class="text-dark my-2 row px-3">
                                        <div
                                            class="h-100 mr-2"
                                            style="width: 30px"
                                            *ngIf="subElement?.Image">
                                            <img
                                                class="h-100 w-100"
                                                [src]="
                                                    subElement?.Image[0]
                                                        ?.CatProductImageSmallImageURL
                                                "
                                                alt="" />
                                        </div>
                                        <span class="text-dark m-0 p-0">
                                            {{
                                                element?.Name == 'Categorias'
                                                    ? subElement?.ProductCategoryName
                                                    : subElement?.Name
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="searchResultEmpty">
                        <div>
                            <div class="pt-1 pl-3">
                                No se encontraron resultados.
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
