import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app';
import { MenuService } from 'src/app/core/menu/menu.service';
import { Constants, environment } from 'src/environments/environment';
import { FormValidators } from 'src/utility/form/form.validators';
import { emailRegexValidators } from 'src/utility/functions/regex.service';
import { AuthUserService } from 'src/utility/services/auth/auth-user.service';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { SweetAlert } from 'src/utility/sweetAlert';

@Component({
    selector: 'app-login-affiliate',
    templateUrl: './login-affiliate.component.html',
    styleUrls: ['./login-affiliate.component.scss'],
})
export class LoginAffiliateComponent implements OnInit {
    user: FormGroup;
    token: string;
    data2: any;
    isDataValid: boolean;
    isLoading: boolean;
    version;
    coverPage: string;

    responseLogin: any[];

    iconEye: string = 'fa fa-eye fa-1x';
    iconEyeClose: string = 'fa fa-eye-slash fa-1x';
    showIconEye: boolean = false;

    validate = {
        user: [
            Validators.required,
            Validators.maxLength(200),
            // FormValidators.mailDomain(),
            Validators.pattern(emailRegexValidators),
        ],
        password: [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(200),
        ],
    };

    constructor(
        private authService: AuthUserService,
        private secureStorage: SecureSimpleStorage,
        private sweet: SweetAlert,
        private cnxAPIServ: ConnectionApiService,
        private router: Router,
        private menuService: MenuService,
        private appComponent: AppComponent
    ) {
        this.authService.getImages().then((response) => {
            this.coverPage = Constants.api.toString();
            this.coverPage = this.coverPage.replace(
                'fd/Home.aspx/API',
                response.ObjectValue[2].Value
            );
        });
        this.SessionOnn();
    }

    SessionOnn() {
        this.secureStorage.get(Contract.user).subscribe(
            (data) => {
                //parseamos la data.
                this.data2 = JSON.parse(data);
                this.router.navigateByUrl('/affiliate/redeem-center');
            },
            (error) => {
                this.router.navigateByUrl('/login-affiliate');
            }
        );
    }

    ngOnInit() {
        this.user = new FormGroup({});
        this.isDataValid = false;
        this.isLoading = false;
        this.version = environment.version;
        // this.user.statusChanges.subscribe((data) => {
        //     this.isDataValid = data === 'VALID';
        // });
    }

    corporateLogin() {
        this.isLoading = true;
        let body = {
            path: 'Ecommerce/GetAffiliateLogIn',
            data: {
                Method: 'GetAffiliateLogIn',
                Params: {
                    Mail: this.user.get('user')?.get('input').value,
                    Password: this.user.get('password')?.get('input')?.value,
                    UserName: '',
                    SystemName: 'Hermes Comercios Afiliados',
                },
            },
        };

        this.cnxAPIServ.postData(body).subscribe(
            (response) => {
                environment.rol = '';
                this.responseLogin = { ...response.ObjectValue };
                response.ObjectValue = [];
                response.ObjectValue.push(this.responseLogin);
                if (response.ObjectValue[0].IdResult == 200) {
                    //Response OK
                    this.secureStorage
                        .save(Contract.updateVersion, false)
                        .subscribe();

                    this.cnxAPIServ.login(response).subscribe();
                    this.authService.setUserData(response);

                    let rolname = response.ObjectValue[0].Accounts[0].RolName;
                    environment.rol =
                        response.ObjectValue[0].Accounts[0].TacName;
                    if (rolname !== undefined) {
                        environment.rol = rolname;
                    }
                    this.menuService.clearMenu();
                    this.menuService.clearSecondaryMenu();
                    this.menuService.addMenu(response.ObjectValue[0].Modules);
                    this.router.navigateByUrl('/affiliate/redeem-center');
                    this.authService.updateVersion();
                    this.isLoading = false;
                } else if (response.ObjectValue[0].IdResult == 400) {
                    this.sweet.errorCriticalAlert(
                        'Error al iniciar sesión',
                        'Los datos ingresados no son correctos'
                    );
                    this.isLoading = false;
                } else {
                }
            },
            (error) => {
                this.sweet.errorCriticalAlert(
                    'Error al iniciar sesión',
                    error.Message ? error.Message : error
                );
                this.isLoading = false;
            }
        );
    }

    OnclickEye() {
        this.showIconEye = !this.showIconEye;
    }

    addForm(name: string, form: FormGroup) {
        this.user.removeControl(name);

        this.user.addControl(name, form);
    }
}
