<div class="login-container p-0 m-0 bg-white">
    <div class="row w-100 p-0 m-0">
        <div class="col-lg-5 col-md-12 col-sm-12 col-12 p-0 m-0">
            <img
                src="./assets/img/Login/login-internal.jpg"
                alt="Login Marge"
                class="imageSection p-0 m-0" />
        </div>
        <div
            class="col-lg-7 col-md-12 col-sm-12 col-12 form-container bg-white">
            <div class="row p-0">
                <div class="col-12 d-flex justify-content-center p-0 m-0">
                    <div class="areaLogo p-0">
                        <img src="assets/images/forza-delivery-logo.png" />
                        <img
                            src="assets/images/envio-facil-entrega-rapida-color.png" />
                    </div>
                </div>
            </div>
            <div class="row bg-white">
                <div class="col-12 bg-white">
                    <form [formGroup]="user">
                        <div class="mb-8">
                            <app-inputv2
                                [autocomplete]="'code'"
                                formControlName="code"
                                [control]="user.controls.code"
                                [type]="'text'"
                                [style]="1"
                                [textLabel]="'Código'"
                                [placeholder]="'Ingresar Código'"
                                [maxLength]="10">
                            </app-inputv2>
                        </div>
                        <div class="mb-8">
                            <app-inputv2
                                [autocomplete]="'email'"
                                formControlName="name"
                                [control]="user.controls.name"
                                [type]="'text'"
                                [style]="1"
                                [textLabel]="'Nombre de Usuario'"
                                [placeholder]="'Ingresar Usuario'"
                                [maxLength]="200">
                            </app-inputv2>
                        </div>
                        <div class="mb-8">
                            <app-inputv2
                                formControlName="password"
                                [control]="user.controls.password"
                                [type]="'password'"
                                [style]="1"
                                [textLabel]="'Contraseña'"
                                [placeholder]="'Ingresar Contraseña'"
                                [maxLength]="200"
                                (keyup.enter)="corporateLogin()">
                            </app-inputv2>
                        </div>
                    </form>
                    <div
                        class="w-100 d-flex justify-content-center align-items-center my-5"
                        [formGroup]="user">
                        Recordar Sesión
                        <div class="form-check form-switch">
                            <label class="switch m-0">
                                <input
                                    type="checkbox"
                                    formControlName="rememberUser" />
                                <span></span>
                            </label>
                        </div>
                    </div>
                    <div class="pb-lg-0 pb-5 mt-4">
                        <button
                            class="btn btn-warning w-100"
                            (click)="corporateLogin()"
                            [disabled]="!isDataValid || isLoading">
                            Ingresar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <label class="version">v.{{ version }}</label>
</div>
