import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ViewChildren,
    QueryList,
    ElementRef,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Ng2SearchPipe } from 'ng2-search-filter';
import { ModalComponent } from '../modal/modal.component';
@Component({
    selector: 'app-selectv2',
    templateUrl: './selectv2.component.html',
    styleUrls: ['./selectv2.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: Selectv2Component,
            multi: true,
        },
    ],
})
export class Selectv2Component implements OnInit {
    reset() {
        this.selectedText = '';
        this.searchText = '';
        this.listSelect = [];
        (this.control as FormControl).reset();
    }
    @Input()
    title: string;

    @Input()
    textLabel: string;

    @Input()
    placeholder: string;

    @Input()
    control: any;

    @Input()
    type: string;

    @Input()
    requiredSymbol: string;

    @Input()
    subtleAnnotation: string;

    @Input()
    isLoading: boolean;

    @Input()
    style: number;

    @Input()
    maxLength: number;

    @Input()
    minLength: number;

    @Input()
    disabled: boolean;

    @Input()
    icono: string;

    @Input()
    regex: string;
    myvalue: string;

    @Input()
    mask: Array<number>;

    @Input()
    separador: string;

    @Input()
    listSelect: any[];

    @Input()
    selectedText: string = '';

    @Input()
    readonly: boolean = false;

    @Input()
    listType: number = 0;

    @Input()
    SettlementType: boolean = false;

    @Input()
    portfolioData: any = {};

    touched: boolean = false;
    modal: any;
    searchText = '';
    modalid: any;
    @ViewChild('selectInput', { static: false }) selectInput;
    @ViewChildren('itemsOption') itemsOptionElRef: QueryList<ElementRef>;
    @ViewChild('modalselect', { static: false }) modalselect;
    @ViewChild('searchInput') searchInput;

    @Input() set list(value: any[]) {
        this.listSelect = value;
        if (value && value.length > 0) {
            const selected = value.filter((x) => x.value === this.myvalue);
            this.selectedText = selected.length > 0 ? selected[0].text : null;
        }
    }
    constructor(private pipeSearch: Ng2SearchPipe) {
        this.disabled = false;
        this.modalid = 'idmodal-' + Math.floor(Math.random() * 100 + 1);
    }

    ngOnInit(): void {
        this.control.valueChanges.subscribe((e) => this.updateSelect(e));
        this.updateSelect(this.control.value);
    }
    updateSelect(value: any) {
        this.listSelect.forEach((element, index: number) => {
            if (element.value == value) {
                this.listSelect[index].selected = true;
                this.myvalue = element.text;
                this.selectedText = element.text;
            } else {
                this.listSelect[index].selected = false;
            }
        });
    }

    onTouch() {
        if (this.control) {
            this.touched = true;
        }
    }

    onChange = (_: any) => {};

    focus(event) {
        if (event.relatedTarget) {
            this.mostrarModal();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(obj: any): void {
        this.myvalue = obj;
    }

    async mostrarModal() {
        this.searchText = '';
        let modal: ModalComponent = this.modalselect;
        modal.Open();

        await new Promise((resolve) => setTimeout(resolve, 500));
        let inputsearch = this.searchInput.nativeElement;
        inputsearch?.focus();
    }
    onEnterSearch() {
        if (this.itemsOptionElRef) {
            let listfilter = this.pipeSearch.transform(
                this.listSelect,
                this.searchText
            );
            if (listfilter && listfilter.length > 0) {
                this.modalselect.Close();
                this.onChange(listfilter[0].value);
            }
        }
    }
    selectedItem(item) {
        this.selectedText = item.text;
        this.onChange(item.value);
        this.modalselect.Close();
    }
}
