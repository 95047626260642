import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { InputOptionHorizontalForm } from '../../entities/portal/formInterfaces';
@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnChanges {
    @Input() fatherClass: string = '';
    @Input() title: string = '';
    @Input() AnnotationTitle: string = null;
    @Input() type: string = 'text';
    @Input() placeholder: string = '';
    @Input() maxLength: number = 50;
    @Input() minLength: number = 0;
    @Input() regex: RegExp = /[\/\\|\[\]\{\}?><]/gi;
    @Input() msgInvalid: string = 'Campo obligatorio';
    @Input() required: boolean = false;
    @Input() preffix: string | null = null;
    @Input() isIconPrefix: boolean = false;
    @Input() suffix: string | null = null;
    @Input() isIconSuffix: boolean = true;
    @Input() horizontalFormOptions: InputOptionHorizontalForm | undefined;
    @Input() formGroupClass: string = '';
    @Input() spliter: string = '';
    @Input() arraySpliter: number[] = [];
    @Input() listInput: any[] = [];
    @Input() disabled: boolean = false;
    @Input() validators: ValidatorFn[] = [];
    @Input() loading: boolean = false;
    @Input() autocomplete: string = 'new-password';
    @Input() id: string = '';
    @Output() onClickIcon: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClickIconPreffix: EventEmitter<any> = new EventEmitter<any>();
    formInput: FormGroup;
    @Output() formReady = new EventEmitter<FormGroup>();
    @Output() keyup: EventEmitter<any> = new EventEmitter<any>();
    @Output() blurInput: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedItem: EventEmitter<any> = new EventEmitter<any>();
    @Output() onChangeInput: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('inputapp', { static: false }) inputapp;
    constructor(private fb: FormBuilder) {
        this.formInput = this.fb.group({
            input: ['', []],
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.loading != undefined) {
            if (changes.loading.currentValue) {
                this.formInput.get('input').disable();
            } else {
                this.formInput.get('input').enable();
            }
        }
        if (changes.disabled != undefined) {
            if (changes.disabled.currentValue) {
                this.formInput.get('input').disable();
            } else {
                this.formInput.get('input').enable();
            }
        }
        if (changes.validators !== undefined) {
            this.formInput
                .get('input')
                .setValidators(changes.validators.currentValue);
        } else {
            if (this.required && this.validators.length == 0) {
                this.formInput
                    .get('input')
                    ?.setValidators([Validators.required]);
            }
        }
    }

    ngOnInit(): void {
        this.formReady.emit(this.formInput);
    }

    onChange(event) {
        const valueRemplace = String(event.target.value).replace(
            this.regex,
            ''
        );
        var countIndex = 0;
        var valueSplited = '';
        if (this.spliter != '' && event.inputType != 'deleteContentBackward') {
            const textWithoutSpliter = valueRemplace.replace(
                new RegExp(this.spliter, 'g'),
                ''
            );

            for (const index in this.arraySpliter) {
                const iterator = this.arraySpliter[index];
                const subString = textWithoutSpliter.substring(
                    countIndex,
                    countIndex + iterator
                );
                countIndex = countIndex + iterator;
                if (
                    subString.length == iterator &&
                    Number(index) != this.arraySpliter.length - 1
                ) {
                    valueSplited = valueSplited + subString + this.spliter;
                } else {
                    valueSplited = valueSplited + subString;
                }
            }
        }

        this.formInput
            .get('input')
            ?.setValue(valueSplited == '' ? valueRemplace : valueSplited);
        this.onChangeInput.emit(event);
    }

    onClickSuffix($event) {
        let input = this.inputapp.nativeElement;
        input?.focus();
        setTimeout(() => {
            if (!this.disabled) {
                this.onClickIcon.emit($event);
            }
        }, 250);
    }

    onClickPreffix($event) {
        setTimeout(() => {
            if (!this.disabled) {
                this.onClickIconPreffix.emit($event);
            }
        }, 250);
    }

    keyUp(event) {
        //enviamos valor de evento al componente padre
        this.keyup.emit(event);
    }

    onBlur(event) {
        this.blurInput.emit(event);
        setTimeout(() => {
            this.listInput = [];
        }, 250);
    }

    selectItemOfList(item) {
        this.selectedItem.emit(item);
        this.listInput = [];
    }
}
