import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JrToAnchorDirective } from './jr-to-anchor.directive';
import { JrAnchorDirective } from './jr-anchor.directive';
import { JoyrideModule, JoyrideStepComponent } from 'ngx-joyride';

@NgModule({
    declarations: [JrToAnchorDirective, JrAnchorDirective],
    imports: [CommonModule, JoyrideModule.forRoot()],
    entryComponents: [JoyrideStepComponent],
    exports: [JrToAnchorDirective, JrAnchorDirective],
})
export class NgxJoyrideModule {}
