import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginInternalComponent } from './login-internal/login-internal.component';
import { ComponentsModule } from 'src/components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginAffiliateComponent } from './login-affiliate/login-affiliate.component';
import { LoginMarketplaceComponent } from './login-marketplace/login-marketplace.component';

@NgModule({
    declarations: [
        LoginInternalComponent,
        LoginAffiliateComponent,
        LoginMarketplaceComponent,
    ],
    imports: [CommonModule, ComponentsModule, ReactiveFormsModule],
})
export class LoginsModule {}
