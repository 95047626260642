import {
    Directive,
    Inject,
    Input,
    PLATFORM_ID,
    QueryList,
} from '@angular/core';
import { Router } from '@angular/router';
import {
    DomRefService,
    JoyrideDirective,
    JoyrideStepsContainerService,
    TemplatesService,
} from 'ngx-joyride';
import { CustomStepInfo } from './custom-step-info';
import { JrAnchorDirective } from './jr-anchor.directive';

@Directive({
    selector: '[appJrToAnchor]',
})
export class JrToAnchorDirective {
    /* Step ID, same as joyrideStep="", or an array of step info objects */
    @Input('appJrToAnchor') steps: string | Array<CustomStepInfo> = '';
    /* Ref to host component, to access anchors */
    @Input() componentRef: { anchors: QueryList<JrAnchorDirective> };

    /* Anchor ID, must be one of the ones specified in the component */
    @Input() anchorId: string;

    /* joyrideStep pass-through inputs */
    @Input() jrHeader: string = '';
    @Input() jrText: string = '';

    constructor(
        /* Constructor injections, to pass through to the JoyrideDirective constructor */
        private readonly joyrideStepsContainer: JoyrideStepsContainerService,
        private readonly domService: DomRefService,
        private readonly router: Router,
        private readonly templateService: TemplatesService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {}

    ngAfterViewInit(): void {
        if (typeof this.steps === 'string') {
            const stepDirective: JoyrideDirective = this.buildStep(
                this.anchorId,
                this.steps,
                this.jrHeader,
                this.jrText
            );

            stepDirective.ngAfterViewInit();
        } else {
            this.steps.forEach((step: CustomStepInfo): void => {
                const stepDirective: JoyrideDirective = this.buildStep(
                    step.anchorId,
                    step.stepId,
                    step.header,
                    step.text
                );
                if (stepDirective) {
                    stepDirective.ngAfterViewInit();
                }
            });
        }
    }

    private buildStep(
        anchorId: string,
        stepName: string,
        stepTitle: string,
        stepText: string
    ): JoyrideDirective {
        try {
            const myAnchor: JrAnchorDirective = this.componentRef.anchors.find(
                (anchor: JrAnchorDirective): boolean => {
                    return anchor.anchorId === anchorId;
                }
            );
            const newStep: JoyrideDirective = new JoyrideDirective(
                this.joyrideStepsContainer,
                myAnchor.viewContainer,
                this.domService,
                this.router,
                this.templateService,
                this.platformId
            );
            newStep.name = stepName;
            newStep.title = stepTitle;
            newStep.text = stepText;

            return newStep;
        } catch (error) {
            console.error('directiva jr-to-anchor');
            return null;
        }
    }
}
