<div class="mb-4" [ngClass]="{ disabledDiv: disabled }">
    <div class="col">
        <div class="d-flex justify-content-between">
            <label
                class="pl-1 mb-1 text-dark text-h4 control-label"
                *ngIf="textLabel"
                >{{ textLabel }}
                <span *ngIf="required" class="text-danger">*</span>
            </label>
        </div>
        <div class="row">
            <div class="col">
                <div class="input-group">
                    <input
                        type="text"
                        class="form-control form-control-solid py-1 px-1 rounded-lg"
                        [ngClass]="{
                            requiredTrue: !(control && control.invalid),
                            requiredFalse: control && control.invalid,
                            'h-auto': style == 1
                        }"
                        bsDatepicker
                        #dp="bsDatepicker"
                        [bsConfig]="bsConfig"
                        [value]="myvalue | date : 'dd/MM/yyyy'"
                        [disabled]="disabled"
                        [minDate]="fromDate"
                        [maxDate]="dateNow"
                        (keyup)="change($event)"
                        (input)="change2($event)"
                        [(bsValue)]="myvalue"
                        [datesDisabled]="disabledDates"
                        [placeholder]="placeholder"
                        (bsValueChange)="onDateChange($event)" />
                    <span class="input-group-append bg-white">
                        <button
                            class="btn border border-left-0"
                            [disabled]="false"
                            (click)="dp.show()"
                            type="button">
                            <i class="icon fas fa-calendar-alt"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <div
            *ngIf="
                control && control.invalid && (control.dirty || control.touched)
            "
            disabled="construirFecha"
            class="input-error-section">
            <p
                *ngIf="control.errors.pattern"
                [ngClass]="{ error: style == 1 || style == 2 }">
                El campo debe ser una fecha con formato válido (dd/mm/yyyy).
            </p>
            <p
                *ngIf="construirFecha"
                [ngClass]="{ error: style == 1 || style == 2 }">
                La fecha {{ fechaIngresada }} es incorrecta.
            </p>
            <p
                *ngIf="control.errors.required && !construirFecha"
                [ngClass]="{ error: style == 1 || style == 2 }">
                El campo {{ textLabel.toLowerCase() }} es obligatorio.
            </p>
        </div>
    </div>
</div>
