<div
    class="secundary-container d-block d-md-flex"
    style="background-color: #f6f7f9">
    <div
        class="h-auto m-auto align-content-center text-center order-1 order-md-2 container-options px-5 pt-5 pb-md-5">
        <div class="title pb-4">Te damos la bienvenida a</div>
        <img
            class="img-fluid img-logo m-0"
            src="assets/images/logoForza.png"
            style="width: 350px"
            alt="App Logo" />
        <div class="text mt-5">Para comenzar, por favor selecciona tu país</div>
        <div *ngFor="let item of countries">
            <div
                class="d-flex align-items-center justify-content-init container-icon mt-4 cursor-pointer"
                (click)="selectCountry(item)">
                <em [class]="item.icon"></em>
                <span class="ml-4 text">{{ item.countryName }}</span>
            </div>
        </div>
    </div>
    <div
        class="img-container-select-country h-100 order-2 order-md-1 container-img mt-5 mt-md-0"></div>
</div>
<footer class="footer text-center">
    <img
        class="img-logo m-0"
        style="width: 150px"
        src="assets/images/logoDarkTarjeta.png"
        alt="App Logo" />
    <p class="text-center mt-3">Todos los derechos reservados. 2024</p>
</footer>
