import { formatCurrency } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { FormatService } from 'src/utility/functions/format.service';
import {
    emailRegexValidators,
    regexNumber,
} from 'src/utility/functions/regex.service';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { EmittersService } from 'src/utility/services/emitters.service';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { SweetAlert } from 'src/utility/sweetAlert';
import { SelectComponent } from '../select/select/select.component';
import { environment } from 'src/environments/environment';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';

@Component({
    selector: 'app-detail-cart',
    templateUrl: './detail-cart.component.html',
    styleUrls: ['./detail-cart.component.scss'],
})
export class DetailCartComponent implements OnInit, OnDestroy {
    @Input() listProductsOfCart: any[] = [];
    @Input() isOffSideBar: boolean = false;
    @Input() btnPayment: boolean = false;
    @Output() listProduct: EventEmitter<any> = new EventEmitter();
    countrySelect: any;
    currency: string = 'Q. ';
    emitterReloadCart: Subscription;
    amountTotal: number = 0;
    discountTotal: number = 0;
    detailCartForm: FormGroup;
    listCODAccounts: any[] = [];
    onlyNumber = regexNumber;
    validator = {
        gift: [Validators.required, Validators.pattern(emailRegexValidators)],
    };
    disableButtonSave: boolean = true;
    user: any = {};
    userLogged: boolean = false;
    userRol: string = environment.rol;
    constructor(
        private secureStorage: SecureSimpleStorage,
        private connectionApi: ConnectionApiService,
        private sweet: SweetAlert,
        private emitterServices: EmittersService,
        private router: Router,
        private format: FormatService,
        public settings: SettingsService,
        private encryptedCookieStorageService: EncryptedCookieStorageService
    ) {}
    ngOnDestroy(): void {
        if (this.emitterReloadCart) {
            this.emitterReloadCart.unsubscribe();
        }
    }

    async ngOnInit() {
        this.secureStorage.get(Contract.user).subscribe(
            async (data) => {
                this.user = JSON.parse(data);
                this.userLogged = true;
                await this.getListProduct(true);
                this.emitterReloadCart = this.emitterServices
                    .getRealoadCart()
                    .subscribe(async () => {
                        if (this.userLogged) {
                            await this.getListProduct();
                        }
                    });
            },
            (error) => {
                this.userLogged = false;
                this.getLocalCart();
            }
        );
        this.countrySelect = environment.multiCountryData[0].countrySelect;
        if (environment.rol == 'Ventas telemercadeo') {
            this.countrySelect =
                environment.TelemercadeoCountryData[0].countrySelect;
        }
        this.currency = this.countrySelect.currency === 'GTQ' ? 'Q. ' : 'L. ';
    }

    async ngAfterViewInit() {
        this.detailCartForm = new FormGroup({});
    }

    getLocalCart() {
        this.secureStorage.get(Contract.localCart).subscribe(
            async (data) => {
                this.listProductsOfCart = JSON.parse(data);
                this.amountTotal = 0;
                this.discountTotal = 0;
                if (this.listProductsOfCart.length < 1) {
                    await this.secureStorage
                        .save(Contract.numberGuide, 0)
                        .toPromise();
                    this.emitterServices.emitChangeGuideNumbers(true);
                    return;
                }
                let index: number = -1;
                for (const iterator of this.listProductsOfCart) {
                    index++;
                    this.amountTotal += Number(iterator.CatProductCost);
                    this.amountTotal = Number(this.amountTotal.toFixed(2));
                    this.discountTotal += Number(
                        iterator.CatProductDiscountValue
                    );
                    this.discountTotal = Number(this.discountTotal.toFixed(2));
                    this.listProductsOfCart[index]['showGift'] = false;
                    this.listProductsOfCart[index]['giftEmail'] = '';
                    this.listProductsOfCart[index]['isInvalidEmail'] = true;
                }
                this.amountTotal = this.amountTotal - this.discountTotal;
                await this.secureStorage
                    .save(Contract.numberGuide, this.listProductsOfCart.length)
                    .toPromise();
                this.listProduct.emit(this.listProductsOfCart);
                this.emitterServices.emitChangeGuideNumbers(true);
            },
            async (error) => {
                await this.secureStorage
                    .save(Contract.numberGuide, 0)
                    .toPromise();
                this.emitterServices.emitChangeGuideNumbers(true);
            }
        );
    }

    async onChangeEmail(event: any, i) {
        this.listProductsOfCart[i].isInvalidEmail = true;
        let email = event.target.value;
        let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        let emailValid = pattern.test(email);
        setTimeout(async () => {
            if (email !== '' && emailValid) {
                const body = {
                    path: 'Fac/GetValidEmailUser',
                    data: {
                        Method: 'GetValidEmailUser',
                        Params: {
                            Email: email,
                            CountryId: this.countrySelect.value,
                        },
                    },
                };

                try {
                    const response = await this.connectionApi
                        .postData(body)
                        .toPromise();
                    if (response.StatusCode != 200 || !response.ObjectValue) {
                        return;
                    }

                    if (response.ObjectValue[0].IsValid !== 1) {
                        this.sweet.transactionError(
                            'Correo no válido',
                            'La compra solo es válida en el país de la cuenta. Por favor, utiliza una cuenta registrada en el país de la compra. Para más ayuda, contacta a soporte.'
                        );
                        this.getInputGift(i).reset();
                        this.listProductsOfCart[i].giftEmail = '';
                    } else {
                        this.listProductsOfCart[i].isInvalidEmail = false;
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        }, 1500);
    }

    async getListProduct(loader: boolean = false) {
        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        const user = JSON.parse(userString);
        const body = {
            path: 'Marketplace/GetServiceCartMarketPlace',
            data: {
                Method: 'GetServiceCartMarketPlace',
                Params: {
                    IdAccount:
                        this.userRol != 'Ventas telemercadeo'
                            ? Number(user.ObjectValue[0].Accounts[0]?.IdAccount)
                            : Number(user.ObjectValue[0].Accounts[0]?.IdUser),
                    Token: this.user.ObjectValue[0].Token,
                    IdCountry: this.countrySelect.value,
                    IsUserTeleMarketing:
                        this.userRol != 'Ventas telemercadeo' ? 0 : 1,
                },
            },
        };
        let response;
        try {
            response = await this.connectionApi
                .postData(body, loader)
                .toPromise();
            if (!response.ObjectValue) {
                return;
            }
        } catch (error) {
            return;
        }
        this.listProductsOfCart = response.ObjectValue;
        this.amountTotal = 0;
        this.discountTotal = 0;
        let index = -1;
        for (const iterator of this.listProductsOfCart) {
            index++;
            this.amountTotal += Number(iterator.CatProductCost);
            this.amountTotal = Number(this.amountTotal.toFixed(2));
            this.discountTotal += Number(iterator.CatProductDiscountValue);
            this.discountTotal = Number(this.discountTotal.toFixed(2));
            this.listProductsOfCart[index]['showGift'] = false;
            this.listProductsOfCart[index]['giftEmail'] = '';
            this.listProductsOfCart[index]['isInvalidEmail'] = true;
        }
        this.amountTotal = this.amountTotal - this.discountTotal;
        await this.secureStorage
            .save(Contract.numberGuide, this.listProductsOfCart.length)
            .toPromise();
        this.listProduct.emit(this.listProductsOfCart);
        this.emitterServices.emitChangeGuideNumbers(true);
    }

    async deleteGuideOfCart(item: any) {
        if (this.userLogged) {
            const body = {
                path: 'Marketplace/RemoveProductsfromtheMarketplaceCart',
                data: {
                    Method: 'RemoveProductsfromtheMarketplaceCart',
                    Params: {
                        IdAccount:
                            this.userRol != 'Ventas telemercadeo'
                                ? Number(
                                      this.user.ObjectValue[0].Accounts[0]
                                          ?.IdAccount
                                  )
                                : Number(
                                      this.user.ObjectValue[0].Accounts[0]
                                          ?.IdUser
                                  ),
                        IdMarketplaceCart: item.IdMarketplaceCartDetail,
                        Token: this.user.ObjectValue[0].Token,
                    },
                },
            };
            try {
                const response = await this.connectionApi
                    .postData(body)
                    .toPromise();
                if (!response.StatusCode) {
                    this.sweet.transactionError(
                        '',
                        'No se eliminó el producto con éxito, por favor inténtelo más tarde'
                    );
                    return;
                }
                await this.getListProduct();
            } catch (error) {
                this.sweet.transactionError(
                    '',
                    'No se eliminó el producto con éxito, por favor inténtelo más tarde'
                );
            }
        } else {
            const index = this.listProductsOfCart.findIndex(
                (itemFind) => itemFind == item
            );
            if (index < 0) {
                return;
            }
            this.listProductsOfCart.splice(index, 1);
            await this.secureStorage
                .save(
                    Contract.localCart,
                    JSON.stringify(this.listProductsOfCart)
                )
                .toPromise();
            this.getLocalCart();
        }
    }

    redirectToPay() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
        this.secureStorage.save(Contract.routeActive, '/compra').subscribe();
        this.router.navigate(['/compra']);
    }

    currencyFormat(x: any) {
        return formatCurrency(+x, 'en-US', this.currency);
    }

    currencyFormatNotSymbol(x: any) {
        return x.toFixed(2);
    }

    changeInputGift(index: number) {
        let inputGift: any = document.getElementById(`inputIsGift${index}`);
        inputGift.value = inputGift.value == 'true' ? 'false' : 'true';
        this.listProductsOfCart[index]['showGift'] = inputGift.value == 'true';
    }

    addForm(name: string, form: FormGroup) {
        this.detailCartForm.removeControl(name);
        this.detailCartForm.addControl(name, form);
    }

    getInputGift(index) {
        return this.detailCartForm?.get(`inputGift${index}`)?.get('input');
    }

    textFinalizarYEnviarPaquetes = () => {
        if (this.amountTotal > 0) {
            return 'Finalizar y pagar';
        }
        return 'Finalizar';
    };

    sendValueForGiftInput(item) {
        if (this.getInputGift(item).status == 'VALID') {
            this.listProductsOfCart[item].giftEmail =
                this.getInputGift(item).value;
        } else {
            this.listProductsOfCart[item].giftEmail = '';
        }
    }
}
