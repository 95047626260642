<router-outlet></router-outlet>
<app-modal
    size="sm-md"
    #modalForCookies
    [showHeader]="false"
    [showFooter]="false"
    [idModal]="'modalForCookies'"
    fatherStylePrincipal="z-index: 100000000">
    <div class="px-4">
        <div class="w-100 d-flex justify-content-center">
            <div class="swal2-icon swal2-warning swal2-icon-show">
                <div class="swal2-icon-content d-flex justify-content-center">
                    !
                </div>
            </div>
        </div>
        <div
            class="w-100 text-center"
            style="
                color: #595959;
                font-size: 24.5px;
                font-weight: 600 !important;
            ">
            Se detectaron datos de inicio de sesión
        </div>
        <div
            class="w-100 mt-2 text-center"
            style="
                color: #545454;
                font-size: 14.5px;
                font-weight: 600 !important;
            ">
            {{ message }}
        </div>
        <div class="w-100 mb-2 mt-4">
            <div class="col-12">
                <div class="row">
                    <div class="col-6 d-flex justify-content-end pr-1">
                        <button
                            class="btn btn-cancel text-h3 text-white"
                            (click)="cancel()">
                            Cancelar
                        </button>
                    </div>
                    <div class="col-6 d-flex justify-content-start pl-1">
                        <button
                            class="btn btn-accept text-h3 text-white"
                            (click)="accept()">
                            Aceptar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-modal>
