import { Component, Input, OnInit } from '@angular/core';
import { SettingsService } from '../../core/settings/settings.service';
import { Router } from '@angular/router';

@Component({
    selector: '[app-footer]',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    @Input() footerInfo: any[] = [];
    @Input() pbx: string = '';

    constructor(public settings: SettingsService, private router: Router) {}

    ngOnInit() {}

    goToTerms() {
        this.router.navigate(['/terminos-condiciones']);
    }
}
