import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constants, environment } from 'src/environments/environment';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { AuthUserService } from 'src/utility/services/auth/auth-user.service';
import { SweetAlert } from 'src/utility/sweetAlert';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/core/menu/menu.service';
import { FormValidators } from 'src/utility/form/form.validators';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';
import { SaveLocalCartService } from 'src/utility/functions/save-local-cart.service';
import { GetCountriesService } from 'src/utility/getCountries';

@Component({
    selector: 'app-login-marketplace',
    templateUrl: './login-marketplace.component.html',
    styleUrls: ['./login-marketplace.component.scss'],
})
export class LoginMarketplaceComponent implements OnInit {
    countrySelected: any;
    countries: any = [];
    user: FormGroup;
    token: string;
    data2: any;
    isDataValid: boolean;
    isLoading: boolean;
    version;
    coverPage: string;
    userSaved: boolean = false;
    responseLogin: any[];
    constructor(
        private authService: AuthUserService,
        private secureStorage: SecureSimpleStorage,
        private sweet: SweetAlert,
        private cnxAPIServ: ConnectionApiService,
        private router: Router,
        private menuService: MenuService,
        private encryptedCookieStorageService: EncryptedCookieStorageService,
        private saveLocalCartService: SaveLocalCartService,
        private getCountryData: GetCountriesService
    ) {
        this.countries = this.getCountryData.getCountries();
        this.authService.getImages().then((response) => {
            this.coverPage = Constants.api.toString();
            this.coverPage = this.coverPage.replace(
                'fd/Home.aspx/API',
                response.ObjectValue[2].Value
            );
        });
        this.SessionOnn();
        this.user = new FormGroup({
            name: new FormControl('', [
                Validators.required,
                Validators.maxLength(200),
                //Validators.pattern("([a-z]|[A-Z]|[0-9]|\\.|-|_)+@(([a-z]|[A-Z]|[0-9]|-|_)+\\.)+([a-z]|[A-Z]|[0-9]|-|_)+"),
                FormValidators.mailDomain(),
            ]),
            password: new FormControl('', [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(200),
            ]),
            rememberUser: new FormControl(false, []),
        });
    }
    SessionOnn() {
        this.secureStorage.get(Contract.user).subscribe(
            (data) => {
                //parseamos la data.
                this.data2 = JSON.parse(data);
                this.router.navigateByUrl('/inicio');
            },
            (error) => {
                this.router.navigateByUrl('/inicio-sesion');
            }
        );
    }
    async ngOnInit() {
        this.countrySelected = environment.multiCountryData[0].countrySelect;
        this.authService.updateVersion();
        this.isDataValid = false;
        this.isLoading = false;
        this.version = environment.version;
        this.user.statusChanges.subscribe((data) => {
            this.isDataValid = data === 'VALID';
        });
        this.secureStorage.get(Contract.userIndividual).subscribe(
            (data) => {
                let user: any = JSON.parse(data);
                this.user.controls.name.setValue(user.UserName);
                this.user.controls.password.setValue(user.Password);
                this.user.controls.rememberUser.setValue(true);
                this.userSaved = true;
            },
            (error) => {}
        );
    }
    ngAfterViewInit(): void {}

    setLogin() {
        if (this.user.valid) {
            this.isLoading = true;
            let body = {
                path: 'MarketPlace/SetLogin',
                data: {
                    Method: 'SetLogin',
                    Params: {
                        UserName: this.user.controls.name.value,
                        Password: this.user.controls.password.value,
                        IdCountry: this.countrySelected.value,
                    },
                },
            };
            this.cnxAPIServ.postData(body).subscribe(
                async (response) => {
                    environment.rol = '';
                    this.responseLogin = { ...response.ObjectValue };
                    response.ObjectValue = [];
                    response.ObjectValue.push(this.responseLogin);
                    if (response.ObjectValue[0].IdResult == 200) {
                        if (
                            response?.ObjectValue[0]?.Accounts[0]?.TacName ==
                            'Empresarial'
                        ) {
                            response.ObjectValue[0].Accounts[0].TacName =
                                'Individual';
                        }
                        if (this.user.controls.rememberUser.value) {
                            this.secureStorage
                                .save(
                                    Contract.userIndividual,
                                    JSON.stringify(body.data.Params)
                                )
                                .subscribe();
                        } else {
                            if (this.userSaved) {
                                this.secureStorage
                                    .remove([Contract.userIndividual])
                                    .subscribe();
                            }
                        }
                        this.secureStorage
                            .save(Contract.updateVersion, false)
                            .subscribe();

                        this.cnxAPIServ.login(response).subscribe();
                        this.authService.setUserData(response);

                        let rolname =
                            response.ObjectValue[0].Accounts[0].RolName;
                        environment.rol =
                            response.ObjectValue[0].Accounts[0].TacName;
                        if (rolname !== undefined) {
                            environment.rol = rolname;
                        }
                        this.menuService.clearMenu();
                        this.menuService.clearSecondaryMenu();
                        this.menuService.addMenu(
                            response.ObjectValue[0].Modules
                        );

                        let dateExpiration = new Date();
                        dateExpiration.setHours(dateExpiration.getHours() + 8);
                        this.encryptedCookieStorageService.save(
                            Contract.userCredential,
                            this.user.controls.name.value,
                            dateExpiration,
                            '.forzadelivery.com'
                        );
                        this.encryptedCookieStorageService.save(
                            Contract.passwordCredential,
                            this.user.controls.password.value,
                            dateExpiration,
                            '.forzadelivery.com'
                        );
                        this.encryptedCookieStorageService.save(
                            Contract.countryCredential,
                            this.countrySelected.value,
                            dateExpiration,
                            '.forzadelivery.com'
                        );
                        this.getCountryData.saveData();
                        await this.saveLocalCartService.saveLocalCart();
                        this.router.navigateByUrl('/inicio');
                        this.authService.updateVersion();
                        this.isLoading = false;
                    } else if (response.ObjectValue[0].IdResult == 400) {
                        this.sweet.errorCriticalAlert(
                            'Error al iniciar sesión',
                            'Los datos ingresados no son correctos'
                        );
                        this.isLoading = false;
                    } else {
                        this.sweet.errorCriticalAlert(
                            'Error al iniciar sesión',
                            response.ObjectValue[0].message
                        );
                        this.isLoading = false;
                    }
                },
                (error) => {
                    this.sweet.errorCriticalAlert(
                        'Error al iniciar sesión',
                        error.Message ? error.Message : error
                    );
                    this.isLoading = false;
                }
            );
        } else {
            this.sweet.errorCriticalAlert(
                'Error al iniciar sesión',
                'Por favor completar el formulario'
            );
        }
    }
    getWindowWidth() {
        let img = document.getElementById('container-img-login');
        let newWidth = `calc(100% - ${img.clientWidth}px)`;
        document.documentElement.style.setProperty(
            '--width-inputs-login',
            newWidth
        );
    }

    changeRoute() {
        this.secureStorage.save(Contract.routeActive, '/inicio').subscribe();
        this.router.navigateByUrl('/inicio');
    }

    redirecToUrl() {
        window.location.href = environment.portalRoute + '/crear-cuenta';
    }

    selectCountry(item) {
        if (item == this.countrySelected) {
            return;
        }
        let expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        this.encryptedCookieStorageService.save(
            Contract.countrySelected,
            JSON.stringify(item),
            expirationDate,
            '.forzadelivery.com'
        );
        this.secureStorage.remove(['productRoutes', 'localCart']).subscribe();
        this.getCountryData.saveData();
        this.countrySelected = item;
    }
}
