<div class="content">
    <br />
    <div class="row">
        <!-- Título-->
        <div class="col-lg-12 text-center">
            <div class="areaLogo">
                <img src="assets/img/forza-delivery-logo.png" />
                <img src="assets/img/envio-facil-entrega-rapida-color.png" />
            </div>
        </div>
        <div class="col-lg-12 text-center">
            <h1
                class="font-weight-boldest text-dark-75 mt-15"
                style="font-size: 10rem">
                404
            </h1>
            <p class="font-size-h3 text-muted font-weight-normal">
                OOPS! Página no encontrada
            </p>
        </div>
    </div>
</div>
