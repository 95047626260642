import { LoginMarketplaceComponent } from './../../views/Logins/login-marketplace/login-marketplace.component';
import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { PageNotFoundComponent } from 'src/views/page-not-found/page-not-found.component';
import { LoginInternalComponent } from 'src/views/Logins/login-internal/login-internal.component';
import { LoginAffiliateComponent } from 'src/views/Logins/login-affiliate/login-affiliate.component';
import { SelectCountryPageComponent } from 'src/views/select-country-page/select-country-page.component';

export const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'inicio',
                pathMatch: 'full',
            },
            {
                path: '',
                loadChildren: () =>
                    import('../../views/views.module').then(
                        (m) => m.ViewsModule
                    ),
            },
        ],
    },
    {
        path: 'inicio-sesion-interno',
        component: LoginInternalComponent,
        data: { title: 'inicio-sesion-interno' },
    },
    {
        path: 'bienvenida',
        component: SelectCountryPageComponent,
        data: { title: 'bienvenida' },
    },
    {
        path: 'inicio-sesion',
        component: LoginMarketplaceComponent,
        data: { title: 'inicio-sesion' },
    },

    // Not found
    { path: '**', component: PageNotFoundComponent },
];
