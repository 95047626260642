import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import {
    HttpClientModule,
    HttpClient,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
    TranslateService,
    TranslateModule,
    TranslateLoader,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { dataSourceSecureFactory } from 'src/utility/Storage/data-source-secure.factory';
import { CrReqRes } from 'src/utility/Storage/crReqRes';
import { environment } from 'src/environments/environment';
import { ComponentsModule } from 'src/components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NoCacheInterceptor } from 'src/utility/functions/NoCacheInterceptor';

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        ComponentsModule,
        NgbModule,
    ],
    providers: [
        // { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NoCacheInterceptor,
            multi: true,
        },
        {
            provide: SecureSimpleStorage,
            useFactory: dataSourceSecureFactory,
            deps: [CrReqRes],
        },
        CrReqRes,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
