/* eslint-disable prettier/prettier */
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const noCacheReq = req.clone({
            setHeaders: {
                'Cache-Control': 'no-cache, no-store, must-revalidate, max-age=0',
                'Pragma': 'no-cache',
                'Expires': 'Thu, 01 Dec 1994 16:00:00 GMT',
                'Last-Modified': '0'
            },
        });
        return next.handle(noCacheReq);
    }
}
