<div class="w-100 px-4">
    <div class="w-100 mb-3" *ngFor="let item of productCatalog; let i = index">
        <div *ngIf="!categorySelected" class="text-title">
            {{ item?.TagName }}
        </div>
        <div class="w-100 d-flex mt-2">
            <div
                *ngIf="!categorySelected"
                class="mr-5 text-subtitle d-flex align-items-center justify-content-start text-justify"
                [ngClass]="{
                    'w-100': numberElementsDisplay >= item.Products.length,
                    'w-75': numberElementsDisplay < item.Products.length
                }">
                {{ item.TagDescription }}
            </div>
            <div
                *ngIf="numberElementsDisplay < item.Products.length"
                class="ml-2 d-flex align-items-center justify-content-end view-more cursor-pointer"
                [ngClass]="{
                    'w-25': !categorySelected,
                    'w-100': categorySelected
                }"
                (click)="viewMore(i)">
                <span *ngIf="item.viewMore">Ver más...</span>
                <span *ngIf="!item.viewMore">Ver menos...</span>
            </div>
        </div>
        <div
            class="w-100 mt-4 principal-container-products-carousel"
            name="principal-container-products-carousel-name"
            id="principal-container-products-carousel-{{ i }}">
            <div class="col-12 px-0">
                <div class="row px-0">
                    <div
                        id="container-products-carousel-{{ i }}"
                        *ngFor="let product of item.Products; let j = index"
                        class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                        <app-dinamic-carousel [carousel]="product" [index]="j">
                        </app-dinamic-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal
    #modalLoaderxs
    [idModal]="'modalLoaderxs'"
    [showHeader]="false"
    [showFooter]="false"
    size="xs">
    <div
        class="row align-items-center justify-content-center container-loader m-0">
        <div class="hwa-fdworking hwa-spinner text-center col-12 p-0">
            <img
                src="assets/images/fd-icon.png"
                style="height: 75px; width: 75px"
                class="hwa-icon-loader" />
        </div>
        <div class="col-12 text-center mt-1 text-loader p-0">Cargando...</div>
    </div>
</app-modal>
