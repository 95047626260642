<div class="mx-3 mx-sm-1 mx-md-3 mb-4">
    <div class="d-flex w-100 justify-content-center align-items-center">
        <div class="w-100 container-card-product" style="position: relative">
            <div
                *ngIf="label"
                class="container-label d-flex justify-content-center align-items-center">
                <span
                    class="w-auto d-flex justify-content-center align-items-center text-center {{
                        label.length > 7 ? ' large' : ' short'
                    }}"
                    id="container-label-{{ index }}">
                    {{ label }}</span
                >
            </div>
            <ngx-slick-carousel
                #slickModal="slick-carousel"
                class="my-0 py-0 h-100 slick-slider"
                id="carouselDetailCategories"
                [config]="slideConfig">
                <div
                    ngxSlickItem
                    class="h-100"
                    *ngFor="let images of carousel.Image; let i = index">
                    <div class="w-100 h-100">
                        <img
                            (click)="
                                viewDetail(carousel.IdCatProduct, carousel.Name)
                            "
                            style="object-fit: fill"
                            class="w-100 h-100 cursor-pointer"
                            [src]="images.CatProductImageSmallImageURL"
                            alt="" />
                    </div>
                </div>
            </ngx-slick-carousel>
            <i
                class="bi icon-arrow-right-small-gray btnPrev cursor-pointer"
                *ngIf="carousel.Image.length > 1"
                (click)="next?.slickPrev()"></i>
            <i
                class="bi icon-arrow-right-small-gray btnNext cursor-pointer"
                *ngIf="carousel.Image.length > 1"
                (click)="next?.slickNext()"></i>
        </div>
    </div>
    <div class="mt-3 w-100 px-3 text-product">
        <p class="text-h2 mb-1">
            {{ carousel?.Name }}
        </p>
        <p
            class="text-h3 mb-1 text-justify"
            style="font-weight: 100 !important">
            {{ carousel?.CatProductDescription }}
        </p>
        <p class="text-h2 mb-1">
            {{ carousel?.CurrencySymbol }}
            {{ carousel?.CatProductCost | number : '1.2-2' }}
        </p>
    </div>
</div>
