import { LocalSimpleStorage } from './local-simple-storage';
import { LocalStorageData } from './local-storage-data';
import { CrReqRes } from './crReqRes';
import { environment } from 'src/environments/environment';

export let dataSourceSecureFactory = (crReqRes: CrReqRes) => {
    if (environment.production) {
        return new LocalStorageData(crReqRes);
    } else {
        return new LocalSimpleStorage();
    }
};
