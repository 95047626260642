<!-- eslint-disable prettier/prettier -->
<div [ngClass]="{ 'container': isModal }">
    <div class="row" *ngIf="isModal">
        <div class="col-12">
            <div class="card">
                <label class="text-h2"> Agregar una tarjeta nueva </label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-12 mb-3" *ngIf="!isModal">
                    <div class="w-100 mb-3 text-justify">
                        Todas las transacciones viajan de manera segura y la información de la tarjeta se guarda encriptada para su seguridad.
                    </div>
                    <div class="w-100">Tarjetas</div>
                    <div class="w-100 d-flex align-items-center">
                        <i class="hwa-visa"></i>
                        <i class="hwa-masterCard ml-2"></i>
                        <i class="hwa-americanExpress ml-2"></i>
                    </div>
                </div>
                <div class="col-12">
                    <app-input
                        title="Nombre como aparece en tu tarjeta crédito/débito"
                        placeholder="Ingrese el nombre como aparece en tu tarjeta"
                        (formReady)="addForm('nameCard', $event)"
                        [required]="true"
                        [disabled]="showFinish">
                    </app-input>
                </div>
                <div class="col-12">
                    <app-input
                        title="Número de tarjeta crédito/débito"
                        placeholder="Ingrese el número de la tarjeta"
                        (formReady)="addForm('numCard', $event)"
                        [required]="true"
                        [arraySpliter]="arraySpliter"
                        spliter=" "
                        [suffix]="typeCardIcon"
                        [disabled]="showFinish">
                    </app-input>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12" [ngClass]="{' col-lg-10': !isModal}">
            <div class="row" [ngClass]="{ 'px-1': !isModal }">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <app-select
                        #selectMonth
                        label="Mes"
                        placeholder="Mes"
                        [list]="listMonth"
                        title="Meses"
                        (formReady)="addForm('month', $event)"
                        [required]="true"
                        [disabled]="showFinish">
                    </app-select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <app-select
                        #selectYear
                        label="Año"
                        placeholder="Año"
                        [list]="listYears"
                        title="Años"
                        (formReady)="addForm('year', $event)"
                        [required]="true"
                        [disabled]="showFinish">
                    </app-select>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <app-input
                        title="Número de CVV"
                        placeholder="Ingrese el CVV"
                        (formReady)="addForm('ccv', $event)"
                        [required]="true"
                        suffix="bi bi-question-circle"
                        (onClickIcon)="onClickQuestion()"
                        [disabled]="showFinish"></app-input>
                </div>
                <div *ngIf="!isModal" class="col-12 col-sm-6 d-flex d-lg-none pt-2 pt-sm-4 justify-content-center justify-content-sm-start">
                    <img class="img-fac" src="./assets/img/logo-FAC.jpeg" alt="">
                </div>
            </div>
        </div>
        <div *ngIf="!isModal" class="col-2 d-none d-lg-flex justify-content-center pt-4">
            <img class="img-fac" src="./assets/img/logo-FAC.jpeg" alt="">
        </div>
    </div>
    <div class="row" *ngIf="isModal">
        <div class="col-12 text-center mt-3">
            <button
                class="btn btn-primary radius-100 px-4"
                (click)="saveCard()"
                [disabled]="disableSave">
                Guardar
            </button>
        </div>
    </div>
</div>
<app-modal title="CVV" #modalCVV>
    <label class="text-h3">
        El código de seguridad de tu tarjeta (CVV) es el número de 3 o 4 dígitos
        que se encuentra en el reverso de la mayoría de las tarjetas.
    </label>
    <div class="w-100 d-flex justify-content-center mt-4">
        <img class="cvvImg" src="assets/img/cards/cvv.png" />
    </div>
</app-modal>
