import { FormControl } from '@angular/forms';

export function witheText(control: FormControl) {
    let texto: string = control.value;
    if (texto == null) {
        return { witheText: true };
    }
    if (texto.trim().length <= 0) {
        return { witheText: true };
    }
    return null;
}

export function minWeight(control: FormControl) {
    let texto: string = control.value;
    if (texto == null) {
        return { witheText: true };
    }
    try {
        if (Number(texto) < 60) {
            return { witheText: true };
        } else {
            return null;
        }
    } catch (e) {
        return { witheText: true };
    }
    return null;
}

export function maxWeight(control: FormControl) {
    let texto: string = control.value;
    if (texto == null) {
        return { witheText: true };
    }
    try {
        if (Number(texto) > 4409) {
            return { witheText: true };
        } else {
            return null;
        }
    } catch (e) {
        return { witheText: true };
    }
    return null;
}
