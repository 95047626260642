<app-modal
    #modalselect
    size="md"
    title="{{ title }}"
    [idModal]="idModal"
    (onClickClose)="onClickClose()">
    <div class="input-group mb-3">
        <div class="input-group-prepend">
            <span class="input-group-text no-border-radius"
                ><i class="fa fa-search"></i
            ></span>
        </div>
        <input
            #searchInput
            autocomplete="off"
            id="searchInput"
            (keyup.enter)="onEnterSearch()"
            [(ngModel)]="searchText"
            type="text"
            class="form-control no-border-radius"
            placeholder="Búsqueda" />
    </div>
    <div class="contentlist">
        <ul class="list-group" draggable="false" style="cursor: pointer">
            <li
                [ngClass]="{ 'option-add': option.value == 'add' }"
                #itemsOption
                data-dismiss="modal"
                (click)="itemSelected(option)"
                *ngFor="let option of list | filter : searchText"
                class="list-group-item"
                draggable="true"
                style="cursor: pointer">
                <span [innerHTML]="option.text"> </span>
            </li>
        </ul>
    </div>
</app-modal>
