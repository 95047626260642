/**
 * Define the name of keys for simple storage
 */
export enum Contract {
    token = 'token',
    user = 'user',
    versionPortal = 'versionPortal',
    routeActive = 'routeActive',
    numberGuide = 'numberGuide',
    userTelemercadeo = 'userTelemercadeo',
    userIndividual = 'userIndividual',
    updateVersion = 'updateVersion',
    currentLocation = 'currentLocation',
    localCart = 'localCart',
    loginCredentials = 'loginCredentials',
    userCredential = 'userCredential',
    passwordCredential = 'passwordCredential',
    countryCredential = 'countryCredential',
    queryParamsRouteActive = 'queryParamsRouteActive',
    editClient = 'editClient',
    fact = 'fact',
    destinyAddress = 'destinyAddress',
    selectaddr = 'selectaddr',
    addr = 'addr',
    selectaddrdes = 'selectaddrdes',
    cotizacion = 'cotizacion',
    ManualDestino = 'ManualDestino',
    step1 = 'shipping',
    ManualOrigen = 'ManualOrigen',
    shr = 'shr',
    step2 = 'details',
    step3 = 'guia',
    userFac = 'userFac',
    step4 = 'facturacion',
    couponData = 'cupon',
    isreturn = 'devoluciones',
    parcels = 'parcels',
    idAddressEdit = 'idAddressEdit',
    productRoutes = 'productRoutes',
    showTutorial = 'showTutorial',
    countrySelected = 'countrySelected',
    TelemercadeoCountrySelected = 'TelemercadeoCountrySelected',
    contactInfo = 'contactInfo',
}
