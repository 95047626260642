import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Contract } from 'src/utility/Storage/contract';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from 'src/utility/services/google-analitycs.service';
@Component({
    selector: 'app-confirm-payment',
    templateUrl: './confirm-payment.component.html',
    styleUrls: ['./confirm-payment.component.scss'],
    host: {
        '(window:resize)': 'getWindowWidth()',
    },
})
export class ConfirmPaymentComponent implements OnInit {
    @Input() orderNumber: any;
    @Input() title: string = 'Confirma tus datos';
    @Input() data: any[] = [
        {
            text: 'Prueba',
            value: 'Valor',
        },
    ];
    @Input() origin: string = '';
    @Input() onCloseModal: any = null;
    @Output() openComponent: EventEmitter<any> = new EventEmitter();
    @Output() confirmPayment: EventEmitter<any> = new EventEmitter();
    @Output() sendStatustransaction: EventEmitter<any> = new EventEmitter();
    @Output() sendValidationStatustransaction: EventEmitter<any> =
        new EventEmitter();
    @ViewChild('divRequestFAC', { static: true })
    divRequestFAC: ElementRef;
    @ViewChild('message', { static: true })
    message: ElementRef;
    @Input() html: any;
    public hideData: boolean = false;
    public showFinish: number = 0;
    showButtonFinally: boolean = false;
    counterForNumberOfTransactionValidations: number = 0;
    responseTransaction: any = '';

    constructor(
        private api: ConnectionApiService,
        private secureStorage: SecureSimpleStorage,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {}

    ngOnInit(): void {}

    ngOnChanges(changes: any) {
        if (this.origin == 'clubForza') {
            this.resetComponent();
        }
        if (this.html && this.data.length > 0) {
            this.openComponent.emit();
        }
    }

    onClickConfirm() {
        this.showFinish = 0;
        this.hideData = true;
        this.confirmPayment.emit(true);
        this.divRequestFAC.nativeElement.innerHTML =
            '<iframe width="100%" height="360" scrolling="yes" id="RequestFAC" frameBorder="0" [src]="" align = "middle"></iframe>';
        const frameWin = frames['RequestFAC'];
        let iFrame = document.getElementById('RequestFAC');
        iFrame.addEventListener('load', () => {
            this.showFinish++;
            if (this.showFinish == 2) {
                if (this.origin == 'clubForza') {
                    this.validStatusTransaction();
                }
                this.message.nativeElement.innerHTML =
                    '<h3><strong>Por favor espere hasta que la transacción haya finalizado</strong></h3>';
            } else if (this.showFinish == 4) {
                if (this.origin == 'clubForza') {
                    this.counterForNumberOfTransactionValidations = 0;
                    this.validStatusTransaction();
                }
            }
        });
        frameWin.contentWindow.document.write(this.html);
    }

    onClickFinalizar() {
        this.hideData = false;
        this.divRequestFAC.nativeElement.innerHTML = '';
        if (this.onCloseModal) {
            this.onCloseModal();
            this.sendStatustransaction.emit(this.responseTransaction);
        }
    }

    resetComponent() {
        this.confirmPayment.emit(false);
        this.hideData = false;
        this.showFinish = 0;
        const element = document?.getElementById('RequestFAC');
        this.message.nativeElement.innerHTML = '<h3></h3>';
        element?.remove();
        this.title = 'Confirma tus datos';
        this.showButtonFinally = false;
        this.counterForNumberOfTransactionValidations = 0;
    }

    validStatusTransaction(): any {
        this.counterForNumberOfTransactionValidations++;
        this.sendRequestForValidStatusTransaction();
    }

    async sendRequestForValidStatusTransaction() {
        if (this.counterForNumberOfTransactionValidations >= 10) {
            return;
        }

        let userString: any;
        let user: any = null;
        try {
            userString = await this.secureStorage
                .get(Contract.user)
                .toPromise();
            user = JSON.parse(userString);
        } catch (error) {}
        const data = {
            Method: 'GetResponseFacTransactionProcessStatus',
            Params: {
                OrderNumber: this.orderNumber,
                CustomerReference: '1',
                Token: !user ? 'user not logged in' : user.ObjectValue[0].Token,
                SystemOrigin: '2',
                AccountId: !user
                    ? '0'
                    : user.ObjectValue[0].Accounts[0].IdAccount,
            },
        };

        const body = {
            path: 'FAC/GetResponseFacTransactionProcessStatus',
            data: data,
        };
        const response = await this.api.postData(body, false).toPromise();
        if (response.ObjectValue[0].ReasonCode == 'SP4') {
            this.sendValidationStatustransaction.emit(false);
            setTimeout(() => {
                this.validStatusTransaction();
            }, 3000);
        } else {
            this.sendValidationStatustransaction.emit(true);
            this.showButtonFinally = true;
            let iframe = window.parent.document.getElementById('RequestFAC');
            let windowWidth = document?.body?.clientWidth;
            let windowHeight = document?.body?.clientHeight;
            iframe.style.height =
                windowWidth <= 460
                    ? '250px'
                    : windowHeight < 310
                    ? '145px'
                    : '160px';
            this.responseTransaction = response.ObjectValue[0];
            if (
                environment.rol == '' &&
                this.responseTransaction.ReasonCode == '00'
            ) {
                this.secureStorage.remove([Contract.localCart]).subscribe();
                this.purchaseMade('Usuario no logueado', this.orderNumber);
            } else if (this.responseTransaction.ReasonCode == '00') {
                this.purchaseMade('Individual', this.orderNumber);
            }
        }
    }

    async getUserData() {
        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        return JSON.parse(userString);
    }

    getWindowWidth() {
        if (!this.showButtonFinally) {
            return;
        }
        let iframe = window.parent.document.getElementById('RequestFAC');
        let windowWidth = document.body.clientWidth;
        let windowHeight = document.body.clientHeight;
        iframe.style.height =
            windowWidth <= 460
                ? '250px'
                : windowHeight < 310
                ? '145px'
                : '160px';
    }

    purchaseMade(user, transactionNumber) {
        this.googleAnalyticsService.sendEvent(
            'purchase',
            'Compra',
            user,
            transactionNumber
        );
    }
}
