import { Contract } from './../../../../utility/Storage/contract';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserblockService } from './userblock.service';
import { AuthUserService } from 'src/utility/services/auth/auth-user.service';
import { interval } from 'rxjs';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss'],
})
export class UserblockComponent implements OnInit, OnDestroy {
    userTimer: any;
    user: any = {};
    imagenUser: string = 'assets/img/user/user.png';
    showFlag: boolean = false;
    currentImage = [];
    sideBarCollapse = () => {
        return this.settings.getLayoutSetting('isCollapsed');
    };
    constructor(
        public userblockService: UserblockService,
        private authService: AuthUserService,
        private secureStorage: SecureSimpleStorage,
        private settings: SettingsService,
        private router: Router
    ) {}
    ngOnDestroy(): void {
        $('#viewImagePhotoProfileSideBar').remove();
    }

    async ngOnInit() {
        this.displayUser();
        const userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        const user = JSON.parse(userString);
        let image: any = user?.ObjectValue[0]?.Accounts[0]?.ImageProfile;
        if (image && image != '') {
            this.imagenUser = image;
        }
    }

    displayUser() {
        // This method SHOULD be deprecated once the main login is functional
        if (this.userTimer) this.userTimer.unsubscribe();
        if (Object.keys(this.user).length === 0) {
            this.userTimer = interval(1000).subscribe(() => {
                if (Object.keys(this.user).length > 0)
                    this.userTimer.unsubscribe();
                else {
                    this.user = this.authService.getUserData();
                    if (Object.keys(this.user).length === 0) this.displayUser();
                }
            });
        }
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

    closeEventHandler() {
        this.showFlag = false;
    }

    async viewImage() {
        const user = await this.getUser();
        if (user.ObjectValue[0].Accounts[0].TacName == 'Individual') {
            this.router.navigate(['/individual/perfil']);
        } else {
            this.currentImage = [
                {
                    title: 'Foto de perfil',
                    image: this.imagenUser,
                },
            ];
            this.showFlag = true;
            $('#viewImagePhotoProfileSideBar').insertAfter($('body'));
        }
    }

    async getUser() {
        try {
            let userString = await this.secureStorage
                .get(Contract.user)
                .toPromise();
            return JSON.parse(userString);
        } catch (error) {
            return null;
        }
    }
}
