import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-banner-custom',
    templateUrl: './banner-custom.component.html',
    styleUrls: ['./banner-custom.component.scss'],
})
export class BannerCustomComponent implements OnInit {
    constructor() {}
    @Input() pathImage;

    ngOnInit(): void {}
}
