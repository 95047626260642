<div class="item user-block" *ngIf="userBlockIsVisible()">
    <!-- User picture-->
    <div class="user-block-picture">
        <div class="user-block-status">
            <img
                class="img-thumbnail rounded-circle"
                [ngClass]="{
                    avatarImage: !sideBarCollapse()
                }"
                [src]="imagenUser"
                alt="Avatar"
                (click)="viewImage()" />
            <div class="circle bg-success circle-lg"></div>
        </div>
    </div>
    <!-- Name and Job-->
    <div class="user-block-info">
        <span class="user-block-name">
            Hola,
            <strong class="text-dark text-wrap"
                >{{ user.FirstName }} {{ user.LastName }}</strong
            >
        </span>
        <span class="user-block-role text-dark">{{ user.UserName }}</span>
    </div>
</div>
<ng-image-fullscreen-view
    class="ng-image-fullscreen-view"
    id="viewImagePhotoProfileSideBar"
    [images]="currentImage"
    [imageIndex]="0"
    [show]="showFlag"
    style="z-index: 99999"
    (close)="closeEventHandler()">
</ng-image-fullscreen-view>
