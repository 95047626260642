import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConnectionApiService } from '../connection-api.service';
//import { AppComponent } from 'src/app';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        private authService: ConnectionApiService,
        public router: Router //private appComponent: AppComponent
    ) {}

    canActivate(): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            this.authService.isLogged().subscribe((logged) => {
                //this.appComponent.callApp();
                if (!logged) {
                    if (environment.rol === 'Corporativo') {
                        this.router.navigate(['/inicio-sesion-interno']);
                    }
                    if (environment.rol === 'Operaciones web') {
                        this.router.navigate(['/inicio-sesion-interno']);
                    } else {
                        window.location.href = '/inicio-sesion';
                    }
                    observer.next(false);
                    observer.complete();
                }
                observer.next(true);
                observer.complete();
            });
        });
    }
}
