<div class="container-fluid">
    <div class="row text-left" [formGroup]="formHelp">
        <div class="col-12">
            <app-input
                title="Nombre"
                placeholder="Ingrese su nombre"
                [validators]="validatorsCustom.name"
                (formReady)="addForm('name', $event)">
            </app-input>
        </div>
        <div class="col-12">
            <app-input
                title="Teléfono"
                placeholder="Ingrese su número de teléfono"
                [maxLength]="8"
                [minLength]="8"
                [validators]="validatorsCustom.phone"
                (formReady)="addForm('phone', $event)">
            </app-input>
        </div>
        <div class="col-12">
            <app-input
                title="Correo electrónico"
                placeholder="Ingrese su correo electronico"
                [validators]="validatorsCustom.email"
                (formReady)="addForm('email', $event)">
            </app-input>
        </div>
        <div class="col-12">
            <textarea
                formControlName="comment"
                class="form-control-textarea w-100 border rounded textAreaComent"
                [ngClass]="{
                    isInvalid:
                        getComment.invalid &&
                        (getComment.dirty || getComment.touched),
                    isValid:
                        getComment.valid &&
                        (getComment.dirty || getComment.touched)
                }"
                placeholder="Ingrese comentario a enviar a soporte"
                rows="5"
                minlength="5"
                maxlength="200"></textarea>
        </div>
        <div class="col-12">
            <div class="row justify-content-center">
                <div class="col">
                    <button
                        class="btn btn-danger active w-100"
                        (click)="sendCancel()">
                        Cancelar
                    </button>
                </div>
                <div class="col">
                    <button
                        class="btn btn-primary active w-100"
                        (click)="sendNotification()"
                        [disabled]="disabledButton()">
                        Enviar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
