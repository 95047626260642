import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Abstraction of Secure Simple Storage
 */
@Injectable({
    providedIn: 'root',
})
export abstract class SecureSimpleStorage {
    abstract save(key: any, value: any): Observable<any>;

    abstract get(key: any): Observable<any>;

    abstract remove(keys?: Array<string>): Observable<any>;
}
