<div class="form-group {{ this.formGroupClass }}" [formGroup]="formInput">
    <label
        class="text-dark"
        *ngIf="title"
        [class]="
            this.horizontalFormOptions
                ? this.horizontalFormOptions.classLabel +
                  ' control-label text-h4'
                : ''
        ">
        {{ title }}
        <span *ngIf="required" class="text-danger text-h4 pr-1">*</span>
        <span *ngIf="AnnotationTitle" class="text-referencia">{{
            AnnotationTitle
        }}</span>
    </label>

    <div
        class="row mx-0"
        [class]="
            this.horizontalFormOptions
                ? this.horizontalFormOptions.classControl
                : ''
        ">
        <div class="col px-0">
            <div class="input-group">
                <!--Icon Preffix-->
                <span
                    [ngClass]="{
                        'input-group-text': true,
                        preffix: true,
                        borderInvalid:
                            formInput.invalid &&
                            (formInput.dirty || formInput.touched),
                        borderSuccess:
                            formInput.valid &&
                            (formInput.dirty || formInput.touched),
                        disabled: disabled
                    }"
                    id="basic-addon1"
                    *ngIf="preffix"
                    (click)="onClickPreffix($event)">
                    <i
                        *ngIf="isIconPrefix"
                        class="{{ preffix }} pl-1"
                        id="togglePassword"
                        style="cursor: pointer"></i>
                    <strong> {{ !isIconPrefix ? preffix : '' }}</strong>
                </span>
                <!--Input-->
                <input
                    #inputapp
                    [class]="'form-control pr-1 ' + fatherClass"
                    [autocomplete]="autocomplete"
                    (input)="onChange($event)"
                    (keyup)="keyUp($event)"
                    (blur)="onBlur($event)"
                    [type]="type"
                    [placeholder]="placeholder"
                    formControlName="input"
                    [maxlength]="maxLength"
                    [minlength]="minLength"
                    [ngClass]="{
                        'border-default': true,
                        borderInvalid:
                            formInput.invalid &&
                            (formInput.dirty || formInput.touched),
                        borderSuccess:
                            formInput.valid &&
                            (formInput.dirty || formInput.touched),
                        borderRight: suffix,
                        borderleft: preffix
                    }" />
                <!--Icon suffix-->
                <span
                    class="pl-0"
                    [ngClass]="{
                        'input-group-text': true,
                        suffix: true,
                        borderInvalid:
                            formInput.invalid &&
                            (formInput.dirty || formInput.touched),
                        borderSuccess:
                            formInput.valid &&
                            (formInput.dirty || formInput.touched),
                        disabled: disabled
                    }"
                    *ngIf="suffix"
                    (click)="onClickSuffix($event)">
                    <i
                        *ngIf="isIconSuffix"
                        class="{{ suffix }} pl-1"
                        id="togglePassword"
                        style="cursor: pointer"></i>
                    {{ !isIconSuffix ? suffix : '' }}
                </span>
                <!--Listado desplegable en input-->
                <div
                    class="contentlist"
                    [ngClass]="{
                        widthContent_whitSuffix: suffix,
                        widthContent_whitoutSuffix: !suffix
                    }"
                    *ngIf="listInput.length > 0">
                    <ul class="list-group" draggable="false">
                        <li
                            class="list-group-item"
                            style="cursor: pointer"
                            *ngFor="let option of listInput"
                            (click)="
                                selectItemOfList(option);
                                $event.stopPropagation()
                            ">
                            <em
                                _ngcontent-asb-103=""
                                class="fa {{ option.icon }}"></em>
                            {{ option.description }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-auto pr-0 pl-2" *ngIf="loading">
            <div class="card m-0 p-0 pt-1">
                <div class="card-body">
                    <div class="ball-clip-rotate-pulse">
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <span
            class="text-danger"
            *ngIf="
                formInput.invalid &&
                (formInput.dirty || formInput.touched) &&
                formInput.controls.input.value == ''
            ">
            {{ msgInvalid }}
        </span>
        <span
            class="text-danger"
            *ngIf="
                formInput.invalid &&
                (formInput.dirty || formInput.touched) &&
                formInput.controls.input.value != ''
            ">
            Campo inválido
        </span>
    </div>
</div>
