import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { GenerateImgFromHtmlService } from './services/generate-img-from-html.service';

@Injectable()
export class SweetAlert {
    constructor(
        private generateImgFromHtmlService: GenerateImgFromHtmlService
    ) {}

    /**
     * @description Alerta para inidicar que ocurrió un error
     * @author Alejandro Rodríguez
     * @date 12/08/2022
     * @param {string} titulo Título que aparecerá en el recuadro de la alerta
     * @param {string} mensaje Información que se mostrará en el texto de la alerta
     * @param {*} [event] Evento que se desea ejecutar de nuevo al cerrar el módulo
     * @param {*} [icon] Ícono que se mostrará en la alerta, por defecto es una X roja
     * @memberof SweetAlert
     */
    errorCriticalAlert(
        titulo: string,
        mensaje: string,
        event?: any,
        icon?: any,
        colorIcon?: any
    ) {
        Swal.fire({
            html: '<b>' + mensaje + '</b>',
            title: titulo,
            //text: "<b>" + mensaje + "</b>",
            icon: icon ? icon : 'error',
            iconColor: colorIcon ? colorIcon : 'red',
            confirmButtonColor: '#FF5100',
            confirmButtonText: 'Aceptar',
            backdrop: true,
            allowOutsideClick: true,
            //position: 'center',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            customClass: {
                container: 'swal-wide',
                confirmButton: 'colorTexto',
            },
            onClose: () => {
                if (event) event();
            },
            reverseButtons: true,
        });
    }

    /**
     * @description Alerta para inidicar que una petición se ha realizado correctamente
     * @author Alejandro Rodríguez
     * @date 12/08/2022
     * @param {string} titulo Título que aparecerá en el recuadro de la alerta
     * @param {string} mensaje Información que se mostrará en el texto de la alerta
     * @param {*} [event] Evento que se desea ejecutar de nuevo al cerrar el módulo
     * @memberof SweetAlert
     */
    successCriticalAlert(titulo: string, mensaje: string, event?: any) {
        Swal.fire({
            html: '<b>' + mensaje + '</b>',
            title: titulo,
            //text: "<b>" + mensaje + "</b>",
            icon: 'success',
            confirmButtonColor: '#FF5100',
            confirmButtonText: 'Aceptar',
            backdrop: true,
            allowOutsideClick: true,
            //position: 'center',
            customClass: {
                container: 'swal-wide',
                confirmButton: 'colorTexto',
            },
            onClose: () => {
                if (event) event();
            },
        });
    }

    /**
     * @description Alertas de peticiones exitosas al agregar o modificar una cuenta bancaria, cuenta de NIT o un destino de un asociado
     * @author Alejandro Rodríguez
     * @date 12/08/2022
     * @param {string} titulo Título que aparecerá en el recuadro de la alerta
     * @param {string} mensaje Información que se mostrará en el texto de la alerta
     * @param {*} [event] Evento que se desea ejecutar de nuevo al cerrar el módulo
     * @param {*} [cancelButtonText] Texto que se desea mostrar en el botón de Cancelar, por defecto es 'Siguiente'
     * @returns {*}
     * @memberof SweetAlert
     */
    successAfilliate(
        titulo: string,
        mensaje: string,
        event?: any,
        cancelButtonText?: any
    ) {
        return Swal.fire({
            html: '<b>' + mensaje + '</b>',
            title: titulo,
            //text: "<b>" + mensaje + "</b>",
            icon: 'success',
            allowOutsideClick: true,
            confirmButtonColor: '#FF5100',
            confirmButtonText: 'Agregar',
            showCancelButton: true,
            backdrop: true,
            cancelButtonColor: '#d33',
            cancelButtonText: cancelButtonText ? cancelButtonText : 'Siguiente',
            //position: 'center',
            customClass: {
                container: 'swal-wide',
                confirmButton: 'colorTexto',
            },
            onClose: () => {
                if (event) event();
            },
            reverseButtons: true,
        });
    }

    /**
     * @description Alerta utilizada mayormente para las alertas que se muestran en la vista de solicitud de recolección
     * @author Alejandro Rodríguez
     * @date 12/08/2022
     * @param {string} titulo Título que aparecerá en el recuadro de la alerta
     * @param {string} mensaje Información que se mostrará en el texto de la alerta
     * @param {*} icono Ícono que se mostrará en la alerta
     * @memberof SweetAlert
     */
    noEventCriticalAlert(titulo: string, mensaje: string, icono: any) {
        Swal.fire({
            html: '<b>' + mensaje + '</b>',
            title: titulo,
            //text: "<b>" + mensaje + "</b>",
            icon: icono,
            confirmButtonColor: '#FF5100',
            confirmButtonText: 'Aceptar',
            backdrop: true,
            allowOutsideClick: true,
            //position: 'center',
            customClass: {
                container: 'swal-wide',
                confirmButton: 'colorTexto',
            },
        });
    }

    /**
     * @description Alerta que se presenta como un recuadro pequeño en el tope de la pantalla
     * @author Alejandro Rodríguez
     * @date 12/08/2022
     * @param {string} titulo Título que aparecerá en el recuadro de la alerta
     * @param {string} mensaje Información que se mostrará en el texto de la alerta
     * @param {*} icono Ícono que se mostrará en la alerta
     * @param {*} [event] Evento que se desea ejecutar
     * @memberof SweetAlert
     */
    errorInfoAlert(
        titulo: string,
        mensaje: string,
        icono: any,
        event?: any,
        customClass = '',
        timer: number = 4000
    ) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            confirmButtonColor: '#FF5100',
            confirmButtonText: 'Aceptar',
            timer: timer,
            customClass: { popup: customClass },
            //timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
            didClose: () => {
                if (event) event();
            },
        });

        Toast.fire({
            icon: icono,
            title: mensaje,
        });
    }

    /**
     * @description Alerta para confirmar con el usuario la acción a realizar
     * @author Alejandro Rodríguez
     * @date 12/08/2022
     * @param {string} titulo Título que aparecerá en el recuadro de la alerta
     * @param {string} mensaje Información que se mostrará en el texto de la alerta
     * @param {*} [time] Tiempo en microsegundos que se mostrará la alerta antes de realizar una acción
     * @param {*} [icon] Ícono que se mostrará en la alerta
     * @param {*} [confirmButtonText] Texto que se desea mostrar en el botón de Aceptar, por defecto es 'Aceptar'
     * @param {*} [cancelButtonText] Texto que se desea mostrar en el botón de Cancelar, por defecto es 'Cancelar'
     * @param {boolean} [cancelActive=true] Valor que indica si se desea tener activo el botón Cancelar
     * @returns {*}  {Promise<any>}
     * @memberof SweetAlert
     */
    warningAlert(
        titulo: string,
        mensaje: string,
        time?: any,
        icon?: any,
        confirmButtonText?: any,
        cancelButtonText?: any,
        cancelActive = true
    ): Promise<any> {
        return Swal.fire({
            html: '<b>' + mensaje + '</b>',
            title: titulo,
            //text: "<b>" + mensaje + "</b>",
            confirmButtonColor: '#FF5100',
            confirmButtonText: confirmButtonText
                ? confirmButtonText
                : 'Aceptar',
            icon: icon ? icon : 'warning',
            showCancelButton: cancelActive,
            backdrop: true,
            cancelButtonColor: '#d33',
            cancelButtonText: cancelButtonText ? cancelButtonText : 'Cancelar',
            timer: time,
            reverseButtons: true,
        });
    }

    /**
     * @description Alertra utilizada para inidicar que una guía no posee cupón
     * @author Alejandro Rodríguez
     * @date 12/08/2022
     * @param {string} titulo Título que aparecerá en el recuadro de la alerta
     * @param {string} mensaje Información que se mostrará en el texto de la alerta
     * @param {*} [time] Tiempo en microsegundos que se mostrará la alerta antes de realizar una acción
     * @param {*} [icon] Ícono que se mostrará en la alerta
     * @param {*} [confirmButtonText] Texto que se desea mostrar en el botón de Aceptar, por defecto es 'Aceptar'
     * @param {*} [cancelButtonText] Texto que se desea mostrar en el botón de Cancelar, por defecto es 'Cancelar'
     * @param {boolean} [cancelActive=true] Valor que indica si se desea tener activo el botón Cancelar
     * @returns {*}  {Promise<any>}
     * @memberof SweetAlert
     */
    successAlert(
        titulo: string,
        mensaje: string,
        time?: any,
        icon?: any,
        confirmButtonText?: any,
        cancelButtonText?: any,
        cancelActive = true
    ): Promise<any> {
        return Swal.fire({
            html: '<b>' + mensaje + '</b>',
            title: titulo,
            //text: "<b>" + mensaje + "</b>",
            confirmButtonColor: '#FF5100',
            confirmButtonText: confirmButtonText
                ? confirmButtonText
                : 'Aceptar',
            icon: icon ? icon : 'warning',
            backdrop: true,
            timer: time,
        });
    }

    /**
     * @description Alerta que se muestra cuando falla el pago de una guía en cualquiera de los flujos de pago
     * @author Alejandro Rodríguez
     * @date 12/08/2022
     * @param {string} titulo Título que aparecerá en el recuadro de la alerta
     * @param {string} mensaje Información que se mostrará en el texto de la alerta
     * @param {*} [event] Evento que se desea ejecutar de nuevo al cerrar el módulo
     * @memberof SweetAlert
     */
    retryRequest(titulo: string, mensaje: string, event?: any) {
        Swal.fire({
            onBeforeOpen: () => {
                Swal.showLoading();
            },
            html: '<b>' + mensaje + '</b>',
            title: titulo,
            icon: 'warning',
            //text: "<b>" + mensaje + "</b>",
            backdrop: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: { popup: 'servicesAlert' },
            onClose: () => {
                if (event) event();
            },
        });
    }

    /**
     * @description Alerta que se muestra cuando una transición de pago de guía falla después de haber realizado varios intentos
     * @author Alejandro Rodríguez
     * @date 12/08/2022
     * @param {string} titulo Título que aparecerá en el recuadro de la alerta
     * @param {string} mensaje Información que se mostrará en el texto de la alerta
     * @returns {*}  {Promise<any>}
     * @memberof SweetAlert
     */
    transactionError(titulo: string, mensaje: string): Promise<any> {
        return Swal.fire({
            html: '<b>' + mensaje + '</b>',
            title: titulo,
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonColor: '#FF5100',
            confirmButtonText: 'Aceptar',
            icon: 'warning',
            backdrop: true,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
    }

    newAlert(
        titulo: string,
        mensaje: string,
        time?: any,
        iconHtml?: any,
        icon?: any,
        confirmButtonText?: any,
        confirmButtonColor?: any,
        cancelButtonText?: any,
        cancelButtonColor?: any,
        cancelActive = true,
        classForModal: string = null,
        showDenyButton: boolean = false,
        denyButtonText: string = null,
        denyButtonColor: string = null,
        contentForDownload: string = null,
        nameForDownload: string = null,
        contentForAlert: string = ''
    ): Promise<any> {
        return Swal.fire({
            html: '<b>' + mensaje + '</b>',
            title: titulo,
            confirmButtonColor: confirmButtonColor
                ? confirmButtonColor
                : '#FF5100',
            confirmButtonText: confirmButtonText
                ? confirmButtonText
                : 'Aceptar',
            showCloseButton: true,
            iconHtml: iconHtml ? iconHtml : '',
            icon: icon ? icon : 'success',
            showCancelButton: cancelActive,
            backdrop: true,
            cancelButtonColor: cancelButtonColor ? cancelButtonColor : '#d33',
            cancelButtonText: cancelButtonText ? cancelButtonText : 'Cancelar',
            timer: time,
            reverseButtons: true,
            customClass: {
                actions:
                    !showDenyButton || !cancelActive
                        ? 'containerAcctionsFotAlertSwal'
                        : 'containerAcctionsFotAlertSwal2',
                popup: classForModal ? classForModal : '',
                cancelButton: 'buttonForAlertSwal confirmBtnForAlertSwal',
                confirmButton: cancelActive
                    ? 'buttonForAlertSwal'
                    : 'hiddenCancelBtn',
                denyButton: showDenyButton
                    ? !cancelActive
                        ? 'buttonForAlertSwal'
                        : 'denyButtonForAlertSwal'
                    : '',
                closeButton: 'swalCloseBtn',
                content: contentForAlert,
            },
            showDenyButton: showDenyButton ? true : false,
            denyButtonColor: denyButtonColor ? denyButtonColor : '',
            denyButtonText: denyButtonText,
            preDeny: async (result) => {
                const content = contentForDownload;
                const name = nameForDownload;
                await this.generateImgFromHtmlService.downloadContent(
                    content,
                    name
                );
                return false;
            },
        });
    }
}
