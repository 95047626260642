<div class="wrapper wrapper-2" id="container-layuout">
    <!-- top navbar-->
    <app-header
        class="topnavbar-wrapper"
        #headerComponent
        id="headerComponent"
        [appJrToAnchor]="stepsArrayPortalHeaderBar"
        [componentRef]="headerComponent"></app-header>
    <!-- sidebar-->
    <app-sidebar
        class="aside-container"
        #sidebaromponent
        [appJrToAnchor]="stepsArrayPortalSideBar"
        [componentRef]="sidebaromponent"
        *ngIf="false"></app-sidebar>
    <!-- offsidebar-->
    <app-offsidebar
        class="offsidebar sidebar"
        [style]="heightForSideBar"></app-offsidebar>
    <!-- Main section-->
    <section
        class="section-container ml-0 section-container-2"
        [style]="heightForSideBar">
        <!-- Page content-->
        <div class="content-wrapper pt-0">
            <router-outlet></router-outlet>
        </div>
    </section>
    <!-- banner information tips-->
    <div
        class="banner bannerChange fixed-bottom"
        *ngIf="this.bannerServ.visibleBannerPickupIndividual">
        <div class="card border-box-guide bg-white">
            <app-banner-custom
                pathImage="assets/img/my-pickups/banner_information_tips.PNG"></app-banner-custom>
        </div>
    </div>
    <div
        class="iconWhatsapp fixed-bottom"
        [ngClass]="{ 'only-one-btn': !showButtonForTutorial }">
        <a
            role="button"
            [href]="footerInfo[3]?.Link"
            target="_blank"
            rel="noopener noreferrer">
            <em class="bi bi-whatsapp sizeIcon text-white p-0 m-0"></em>
        </a>
    </div>
    <!-- <div
        class="iconTutorial fixed-bottom"
        (click)="initTutorial()"
        *ngIf="showButtonForTutorial">
        <a role="button" class="d-flex align-items-center p-sm-1">
            <em class="bi icon-book-half sizeIcon text-white p-0 m-0"></em>
            <span class="ml-2 d-none d-sm-flex">Ver tutorial</span>
        </a>
    </div> -->
    <footer
        class="footer-container colorFooter"
        app-footer
        [footerInfo]="footerInfo"
        [pbx]="pbx"></footer>
</div>
<!--Modal para consultar si desea ver el tutorial-->
<app-modal
    #modalChangePassword
    title="Cambio de contraseña"
    [showClose]="false"
    [showFooter]="false">
    <app-input
        [minLength]="1"
        [maxLength]="200"
        title="Contraseña actual"
        [required]="true"
        [type]="this.inputs.oldPassword.type"
        [suffix]="this.inputs.oldPassword.icon"
        (onClickIcon)="clickIconOldPassword()"
        [regex]="regexInput"
        (formReady)="addFormChangePassword('oldPassword', $event)"></app-input>
    <app-input
        [minLength]="1"
        [maxLength]="200"
        title="Contraseña nueva"
        [required]="true"
        [type]="this.inputs.password.type"
        [suffix]="this.inputs.password.icon"
        (onClickIcon)="clickIconPassword()"
        (formReady)="addFormChangePassword('password', $event)"
        [validators]="validators.newPassword"
        [regex]="regexInput"
        (onChangeInput)="recheckValidations()"></app-input>
    <app-input
        [minLength]="1"
        [maxLength]="200"
        title="Confirmar contraseña"
        [required]="true"
        [type]="this.inputs.confirmPassword.type"
        [suffix]="this.inputs.confirmPassword.icon"
        (formReady)="addFormChangePassword('confirmPassword', $event)"
        (onClickIcon)="clickIconConfirmPassword()"
        [regex]="regexInput"
        [validators]="validators.copyNewPassword"></app-input>
    <app-password-strength-bar
        [passwordToCheck]="getPassword?.value"
        [barLabel]="barLabel">
    </app-password-strength-bar>
    <span class="text-referencia">
        La contraseña debe tener como mínimo 8 caracteres, al menos un dígito,
        al menos una minúscula, al menos una mayúscula y al menos un caracter no
        alfanumérico (#?!@$%^&*-).
    </span>
    <button
        type="button"
        class="btn btn-warning w-100 mt-2"
        [disabled]="formChangePassword.invalid"
        (click)="changePassword()">
        Guardar
    </button>
</app-modal>

<app-modal
    #modalTutorial
    id="modalTutorial"
    [showClose]="false"
    [showFooter]="false"
    [showHeader]="false">
    <div class="w-100">
        <div class="w-100 text-center title">
            <strong> ¡Bienvenido a nuestra nueva tienda virtual! </strong>
        </div>
        <div class="w-100 px-4">
            <div class="w-100 text-justify mt-2">
                Aquí podrás descubrir una experiencia de compra mejorada con
                funcionalidades que se adaptan a tus necesidades:
            </div>
            <ul class="text-justify mt-2">
                <li>
                    <strong>Productos Exclusivos:</strong> Navega y adquiere
                    nuestros productos especialmente seleccionados para ti.
                </li>
                <li class="mt-1">
                    <strong>Membresías y Planes de Descuento:</strong> Accede a
                    membresías y planes que te permitirán ahorrar en tus envíos.
                </li>
                <li class="mt-1">
                    <strong>Compras para Regalo:</strong> Adquiere membresías y
                    planes de descuento para regalar a un ser querido.
                </li>
            </ul>
            <div class="w-100 text-justify">
                Explora nuestras nuevas opciones y disfruta de una experiencia
                de compra personalizada, segura y eficiente. ¡Empieza ahora y
                aprovecha todos los beneficios que tenemos para ofrecerte!
            </div>
            <button
                type="button"
                class="btn btn-warning w-100 mt-4 mb-3 btn-close"
                (click)="initTutorial(1000, true)">
                Explorar la tienda
            </button>
        </div>
    </div>
</app-modal>
