import { Directive, Input, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appJrAnchor]',
})
export class JrAnchorDirective {
    @Input('appJrAnchor') anchorId: string;

    constructor(public viewContainer: ViewContainerRef) {}
}
