<div class="container">
    <div *ngIf="!isModal" class="row">
        <div class="col-12">
            <div class="card">
                <label class="text-h2"> Tarjetas </label>
            </div>
        </div>
    </div>
    <div
        *ngIf="!isModal"
        class="card"
        style="height: 430px; overflow-y: auto; overflow-x: hidden">
        <div class="row mt-4" *ngFor="let card of listCard">
            <div class="col containerCard">
                <div class="cardCustom">
                    <div
                        role="button"
                        class="container cardContainerRow"
                        (click)="selectCard(card, $event)">
                        <div class="row cardRowHeader">
                            <img
                                src="assets/img/forza/fd-icon.png"
                                class="logoVisa" />
                            <div class="iconStar" *ngIf="activeUpdate">
                                <button
                                    (click)="onUpdateCard(card)"
                                    class="btn btn-outline-light btnCard">
                                    <i
                                        class="bi bi-star-fill"
                                        *ngIf="card.IsDefault"></i>
                                    <i
                                        class="bi bi-star"
                                        *ngIf="!card.IsDefault"></i>
                                    <!-- <icon
                                        name="star-outline"
                                        *ngIf="!card.IsDefault"></icon>
                                    <icon name="star" *ngIf="card.IsDefault"></icon> -->
                                </button>
                            </div>
                            <div
                                class="col-12 d-flex flex-column justify-content-end ml-4">
                                <div class="titlenumberCard">
                                    Número de tarjeta
                                </div>
                                <div class="numberCard">
                                    <strong>{{ card.DisplayText }}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="row cardRowContain ml-2">
                            <div class="col-6">
                                <div class="titleccvCard">CVV</div>
                                <div class="ccvCard"><strong>***</strong></div>
                            </div>
                            <div class="col-6">
                                <div class="titledateCard">Fecha</div>
                                <div class="dateCard">
                                    <strong>**/**</strong>
                                </div>
                            </div>
                            <div class="iconTrash" *ngIf="activeDelete">
                                <button
                                    (click)="onDeleteCard(card)"
                                    class="btn btn-outline-light btnCard">
                                    <i class="bi bi-trash"></i>
                                    <!-- <ion-icon name="trash"></ion-icon> -->
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isModal" class="card">
        <div class="row mt-4" *ngFor="let card of listCard">
            <div class="col containerCard">
                <div class="cardCustom">
                    <div
                        role="button"
                        class="container cardContainerRow"
                        (click)="selectCard(card, $event)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Seleccionar tarjeta">
                        <div class="row cardRowHeader">
                            <img
                                src="assets/img/forza/fd-icon.png"
                                class="logoVisa" />
                            <div class="iconStar" *ngIf="activeUpdate">
                                <button
                                    (click)="onUpdateCard(card)"
                                    class="btn btn-outline-light btnCard no-generate-action"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Marcar como favorita">
                                    <i
                                        class="bi bi-star-fill no-generate-action"
                                        *ngIf="card.IsDefault"></i>
                                    <i
                                        class="bi bi-star no-generate-action"
                                        *ngIf="!card.IsDefault"></i>
                                    <!-- <icon
                                    name="star-outline"
                                    *ngIf="!card.IsDefault"></icon>
                                <icon name="star" *ngIf="card.IsDefault"></icon> -->
                                </button>
                            </div>
                            <div
                                class="col-12 d-flex flex-column justify-content-end ml-4">
                                <div class="titlenumberCard">
                                    Número de tarjeta
                                </div>
                                <div class="numberCard">
                                    <strong>{{ card.DisplayText }}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="row cardRowContain ml-2">
                            <div class="col-6">
                                <div class="titleccvCard">CVV</div>
                                <div class="ccvCard"><strong>***</strong></div>
                            </div>
                            <div class="col-6">
                                <div class="titledateCard">Fecha</div>
                                <div class="dateCard">
                                    <strong>**/**</strong>
                                </div>
                            </div>
                            <div class="iconTrash" *ngIf="activeDelete">
                                <button
                                    (click)="onDeleteCard(card)"
                                    class="btn btn-outline-light btnCard no-generate-action"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Eliminar tarjeta">
                                    <i
                                        class="bi bi-trash no-generate-action"></i>
                                    <!-- <ion-icon name="trash"></ion-icon> -->
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
