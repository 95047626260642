import {
    Component,
    OnInit,
    ViewChild,
    Injector,
    ViewChildren,
    QueryList,
    ElementRef,
    OnDestroy,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ModalComponent } from 'src/components/modal/modal.component';
import { EmittersService } from 'src/utility/services/emitters.service';
import { Subscription } from 'rxjs';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { Contract } from 'src/utility/Storage/contract';
import { regexGuide } from 'src/utility/functions/regex.service';
import { JrAnchorDirective } from 'src/utility/ngx-joyride/jr-anchor.directive';
import { AuthUserService } from 'src/utility/services/auth/auth-user.service';
import { JoyrideService } from 'ngx-joyride';
import { CommunicationWithObservableService } from 'src/utility/services/communication-with-observable-service.service';
import { allStepsArray } from 'src/utility/functions/OnBoardingCrearGuia.service';
import { GetCountriesService } from 'src/utility/getCountries';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';

const screenfull = require('screenfull');
declare var TrackingBox: any;
declare var TrackingBox1: any;
declare var $: any;
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    host: {
        '(window:resize)': 'getWindowWidth()',
    },
})
export class HeaderComponent implements OnInit, OnDestroy {
    componentForTutorial$: Subscription;
    AllArraySteps = allStepsArray;
    imageProfile: string = '';
    lastWidth: number = 0;
    categorySelected: string = '';
    showBackBtn: boolean = false;
    showRoutes: boolean = false;
    private routeSubscription: Subscription;
    @ViewChildren(JrAnchorDirective) anchors: QueryList<JrAnchorDirective>;
    @ViewChild('modalSearchProduct') modalSearchProduct: ModalComponent;
    navCollapsed = true; // for horizontal layout
    menuItems: Array<any> = []; // for horizontal layout
    formRastreo: FormGroup;
    regexNumber = regexGuide;
    countries: any = [];
    enableCountry: boolean = false;
    countrySelect: any;
    isNavSearchVisible: boolean;

    serietag = {
        state: 0,
    };
    nrSelect = 'FD';
    subscription: Subscription;
    numGuide: number = 0;

    statusGuideTracking: any;

    validatorsCustom = {
        phone: [
            Validators.minLength(8),
            Validators.pattern('(5|2|3|4|6|7)[0-9]{7}'),
        ],
    };

    @ViewChild('fsbutton', { static: true }) fsbutton; // the fullscreen button
    @ViewChild('modalPhone') modalPhone: ModalComponent;

    userRol = '';
    userLogged: boolean = false;
    data: any;
    fullName: string = '';

    constructor(
        public menu: MenuService,
        public userblockService: UserblockService,
        public settings: SettingsService,
        public injector: Injector,
        private coneccionServices: ConnectionApiService,
        private formulario: FormBuilder,
        private emitterServices: EmittersService,
        private secureStorage: SecureSimpleStorage,
        public authService: AuthUserService,
        private router: Router,
        private communicationWithObservable: CommunicationWithObservableService,
        private readonly joyrideService: JoyrideService,
        private getCountriesService: GetCountriesService,
        private encryptedCookieStorageService: EncryptedCookieStorageService
    ) {
        // show only a few items on demo
        this.menuItems = menu.getSecondaryMenu(); // for horizontal layout
        this.formRastreo = this.formulario.group({});
        this.countries = this.getCountriesService.getCountries();
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.subscription = null;
        this.routeSubscription?.unsubscribe();
        this.componentForTutorial$.unsubscribe();
    }

    async ngOnInit() {
        this.userRol = environment.rol;
        try {
            this.numGuide = Number(
                await this.secureStorage.get(Contract.numberGuide).toPromise()
            );
        } catch (error) {}
        this.isNavSearchVisible = false;

        var ua = window.navigator.userAgent;
        if (ua.indexOf('MSIE ') > 0 || !!ua.match(/Trident.*rv\:11\./)) {
            // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }

        // Switch fullscreen icon indicator
        const el = this.fsbutton.nativeElement.firstElementChild;
        this.subscription = this.emitterServices
            .getChangeGuideNumbers()
            .subscribe(async (item) => {
                if (item) {
                    this.numGuide = Number(
                        await this.secureStorage
                            .get(Contract.numberGuide)
                            .toPromise()
                    );
                }
            });

        this.handleRouteEvents();
        this.componentForTutorial$ = this.communicationWithObservable
            .onComponentForTutorial()
            .subscribe(async (message) => {
                if (message.text == '2') {
                    this.startMyTourSourceAddress();
                    let element = document.getElementById('logoHeaderId');
                    setTimeout(() => {
                        element.scrollIntoView({ block: 'center' });
                    }, 100);
                }
            });
        this.countrySelect = environment.multiCountryData[0].countrySelect;

        if (this.userRol == 'Individual') {
            this.enableCountry = true;
        } else if (this.userRol == 'Ventas telemercadeo') {
            this.countrySelect =
                environment.TelemercadeoCountryData[0].countrySelect;
            this.enableCountry = true;
        } else if (!this.userLogged) {
            this.enableCountry = false;
        }
    }

    async ngAfterViewInit() {
        this.verifyData();
        setTimeout(() => {
            this.getWindowWidth();
        }, 10);
    }

    handleRouteEvents() {
        let route: string = '';
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                route = event.url.split('/')[1];
                this.getRoute(route);
                setTimeout(() => {
                    this.getWindowWidth();
                }, 10);
            }
        });
        route = this.router.url.split('/')[1];
        this.getRoute(route);
    }

    getRoute(route: string = '') {
        if (
            route == 'compra' ||
            route == 'terminos-condiciones' ||
            route == 'detalle-producto' ||
            route == 'mis-transacciones'
        ) {
            this.showBackBtn = true;
        } else {
            this.showBackBtn = false;
        }

        if (route == 'resultado-busqueda') {
            this.showRoutes = true;
        } else {
            this.showRoutes = false;
        }
    }

    verifyData() {
        this.secureStorage.get(Contract.user).subscribe(
            (data) => {
                this.data = JSON.parse(data);
                let imgProfile =
                    this.data.ObjectValue[0].Accounts[0]?.ImageProfile;
                if (imgProfile != '' && imgProfile?.toLowerCase() != 'n/d') {
                    this.imageProfile = imgProfile;
                }
                let profile = this.data.ObjectValue[0].Profile[0];
                this.userLogged = true;
                this.fullName = profile.FirstName + ' ' + profile.LastName;
            },
            (error) => {
                this.userLogged = false;
                console.error(error);
            }
        );
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        //this.emitterServices.emitRealoadCart(true);
        //this.settings.toggleLayoutSetting('offsidebarOpen');
        this.secureStorage.save(Contract.routeActive, '/compra').subscribe();
        this.router.navigate(['/compra']);
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }

    /**
     * @description método para validación de input al ingresar número de guía
     * @author Byron de León
     * @date 19/08/2022
     * @return {*} variable booleana para true si es correcto
     * @memberof HeaderComponent
     */
    validetGuia() {
        if (
            this.formRastreo.get('inputRastreo')?.get('input')?.status ===
            'INVALID'
        ) {
            return false;
        } else {
            return true;
        }
    }

    addForm(name: string, formulario: FormGroup) {
        this.formRastreo.removeControl(name);
        this.formRastreo.addControl(name, formulario);
    }

    async saveRoute(path: string) {
        if (
            path == '/mis-clientes' ||
            path == '/link-entrega' ||
            path == '/mis-links' ||
            path == '/inventario'
        ) {
            window.location.href = path;
        }
        await this.secureStorage.save(Contract.routeActive, path).toPromise();
    }

    showModalPhone() {
        this.modalPhone.Open();
        //Se aplica para poder mostrar backdrop atras de modal.
        $('#modalPhone').insertAfter($('body'));
    }

    redirectToCotizar() {
        this.secureStorage.save(Contract.routeActive, '/cotizador').subscribe();
        this.router.navigate(['/cotizador']);
    }

    async getUser() {
        try {
            const userString = await this.secureStorage
                .get(Contract.user)
                .toPromise();
            return JSON.parse(userString);
        } catch (error) {
            return null;
        }
    }

    transformDate(response: any, rolName: string) {
        if (rolName == 'Ventas telemercadeo') {
            response.order.milestones[0].latitude = '14.5765397';
            response.order.milestones[0].longitude = '-90.5541213';
            response.ObjectValue = {
                GuideSerie: response.order.GuideSerie,
                GuideNumber: response.order.GuideNumber,
                ManifestNumber: response.order.ManifestNumber,
                Message: 'Success',
                Latitude: response.order.Latitude,
                Longitude: response.order.Longitude,
                Price: null,
                COD: null,
                OrderDetail: response.order,
            };
            return response;
        } else {
            return response;
        }
    }

    toLogin() {
        this.secureStorage.remove([Contract.routeActive]).subscribe();
        setTimeout(() => {
            window.location.href = '/inicio-sesion';
        }, 0);
    }

    goToHome() {
        this.secureStorage.save(Contract.routeActive, '/inicio').subscribe();
        this.router.navigate(['/inicio']);
    }

    toTermsAndConditions() {
        this.router.navigate(['/terminos-condiciones']);
    }

    changeRoute(route: string) {
        this.secureStorage.save(Contract.routeActive, route).subscribe();
        this.router.navigate([route]);
    }

    openModalSearch() {
        this.modalSearchProduct?.Open();
        $('#modalSearchProduct').insertAfter($('body'));
    }

    back() {
        this.secureStorage.save(Contract.routeActive, '/inicio').subscribe();
        this.router.navigateByUrl('/inicio');
    }

    getWindowWidth() {
        let logo = document.getElementById('logoHeaderId');
        let cart = document.getElementById('cartHeaderId');
        let search = document.getElementById('searchHeaderId');
        search.setAttribute(
            'style',
            `width: calc(100% - ${cart.clientWidth}px - 220px - 20px)`
        );
        let width = document.body?.clientWidth;
        if (width == this.lastWidth) {
            return;
        }
        this.lastWidth = width;
        this.modalSearchProduct?.Close();
    }

    closeModalSearchProduct(event) {
        this.categorySelected = event;
        this.modalSearchProduct?.Close();
    }

    startMyTourSourceAddress(): void {
        let steps = !this.userLogged
            ? ['step2', 'step5']
            : this.userRol == 'Ventas telemercadeo'
            ? ['step2', 'step4']
            : ['step2', 'step3'];
        const options = {
            steps: steps,
            stepDefaultPosition: 'bottom',
            themeColor: '#345632',
            showPrevButton: true,
            showCounter: false,
            customTexts: {
                next: '>>',
                prev: '<<',
                done: 'Empezar a comprar',
            },
        };
        var stepControll;
        this.joyrideService.startTour(options).subscribe(
            (step) => {
                stepControll = step;
            },
            (e) => {},
            () => {
                this.communicationWithObservable.sendComponentForTutorial('3');
            }
        );
    }

    selectCountry(countrySelect) {
        let expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        this.countrySelect = countrySelect;
        let countrySelectedCookie =
            environment.multiCountryData[0].countrySelect;

        if (this.userRol == 'Ventas telemercadeo') {
            countrySelectedCookie =
                environment.TelemercadeoCountryData[0].countrySelect;
        }

        if (countrySelectedCookie.value !== countrySelect.value) {
            if (this.userRol !== 'Ventas telemercadeo' || !this.userLogged) {
                this.encryptedCookieStorageService.save(
                    Contract.countrySelected,
                    JSON.stringify(countrySelect),
                    expirationDate,
                    '.forzadelivery.com'
                );
            } else {
                this.encryptedCookieStorageService.save(
                    Contract.TelemercadeoCountrySelected,
                    JSON.stringify(countrySelect),
                    expirationDate,
                    '.forzadelivery.com'
                );
            }

            this.secureStorage
                .remove(['productRoutes', 'localCart'])
                .subscribe();
            window.location.reload();
        }
    }
}
