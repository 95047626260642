import { Component, OnInit, ViewChild } from '@angular/core';
import { BannerService } from 'src/utility/services/banner.service';
import { ModalComponent } from 'src/components/modal/modal.component';
import { JoyrideService, JoyrideStepComponent } from 'ngx-joyride';
import { OnBoardingCrearGuia } from 'src/utility/functions/OnBoardingCrearGuia.service';
import {
    stepsArrayPortalSideBar,
    stepsNuevoPortal,
    stepsArrayPortalHeaderBar,
} from 'src/utility/functions/OnBoardingPortal.service';
import { SettingsService } from '../core/settings/settings.service';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { Contract } from 'src/utility/Storage/contract';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { SweetAlert } from 'src/utility/sweetAlert';
import { NavigationEnd, Router } from '@angular/router';
import { CommunicationWithObservableService } from 'src/utility/services/communication-with-observable-service.service';
import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    host: {
        '(window:resize)': 'onResize()',
    },
})
export class LayoutComponent implements OnInit {
    showButtonForTutorial: boolean = false;
    footerInfo: any[] = [];
    countrySelect: any;
    pbx: string = '';
    @ViewChild('modalTutorial') modalTutorial: ModalComponent;
    @ViewChild('modalSuscripciones') modalSuscripciones: ModalComponent;
    @ViewChild('modalChangePassword') modalChangePassword: ModalComponent;
    heightForSideBar: string = '';
    showModal: any = 0;
    showSuscripciones = 1;
    public barLabel: string = 'Nivel de Seguridad:';
    currentIndex: any = -1;
    showFlag: any = false;
    currentImage = [
        {
            video: 'https://youtu.be/aILGDgQJJdw',
            posterImage:
                'https://forzadelivery.com/images/Tutorial/Bienvenida.png',
            videoAutoPlay: false,
        },
    ];

    regexInput: RegExp = new RegExp('');

    formChangePassword: FormGroup;
    inputs = {
        oldPassword: {
            icon: 'bi bi-eye-fill',
            type: 'password',
        },
        password: {
            icon: 'bi bi-eye-fill',
            type: 'password',
        },
        confirmPassword: {
            icon: 'bi bi-eye-fill',
            type: 'password',
        },
    };

    validators = {
        newPassword: [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(200),
            Validators.pattern(
                '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
            ),
        ],
        copyNewPassword: [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(200),
            this.validateSamePassword.bind(this),
        ],
    };

    constructor(
        private sweet: SweetAlert,
        public bannerServ: BannerService,
        private readonly joyrideService: JoyrideService,
        private OnBoard: OnBoardingCrearGuia,
        public settings: SettingsService,
        private secureStorage: SecureSimpleStorage,
        private api: ConnectionApiService,
        private router: Router,
        private communicationWithObservable: CommunicationWithObservableService,
        private encryptedCookieStorageService: EncryptedCookieStorageService
    ) {
        this.formChangePassword = new FormGroup({});
    }

    async ngOnInit() {
        this.secureStorage.get(Contract.user).subscribe((result) => {
            let account = JSON.parse(result)?.ObjectValue[0]?.Accounts[0];
            if (account.TacName == 'Individual') {
                if (account?.ChangePassword == 1) {
                    if (this.showModal > 0) {
                        setTimeout(() => {
                            this.modalTutorial.Close();
                        }, 0);
                    }
                    this.modalChangePassword.Open();
                }
            }
        });
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    this.onResize();
                    let element = document.getElementById('container-layuout');
                    setTimeout(() => {
                        element.scrollTop = 0;
                        element.scrollIntoView({ behavior: 'smooth' });
                    }, 10);
                }, 500);
            }
        });
        this.countrySelect = environment.multiCountryData[0].countrySelect;

        if (environment.rol == 'Ventas telemercadeo') {
            this.countrySelect =
                environment.TelemercadeoCountryData[0].countrySelect;
        }

        if (this.countrySelect === null) {
            if(window.location.pathname !== '/inicio-sesion-interno'){
                this.router.navigate(['bienvenida']);
                this.secureStorage.save(Contract.routeActive, '/bienvenida').subscribe();
            }
        }
        this.getInfoFooter();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.onResize();
            this.handleRouteEvents();
        }, 100);
    }

    async getInfoFooter() {
        const body = {
            path: 'MarketPlace/SocialNetworks',
            data: {
                Method: 'SocialNetworks',
                Params: {
                    IdCountry: this.countrySelect.value,
                },
            },
        };
        try {
            let response = await this.api.postData(body).toPromise();
            if (response.StatusCode != 200 || !response.ObjectValue) {
                return;
            }
            for (const iterator of response.ObjectValue) {
                if (
                    iterator.Description === 'Url de facebook'
                ) {
                    iterator.icon = 'bi-facebook';
                } else if (
                    iterator.Description === 'Url de instagram'
                ) {
                    iterator.icon = 'bi-instagram';
                } else if (
                    iterator.Description === 'Url de Linkedin'
                ) {
                    iterator.icon = 'bi-linkedin';
                } else if (
                    iterator.Description === 'Url de Whatsapp'
                ) {
                    iterator.icon = 'bi-whatsapp';
                }
            }
            this.pbx = response.ObjectValue[4]?.Link;

            this.footerInfo = response.ObjectValue.filter(
                (item) => item.Description !== 'Numero de telefono'
            );

            let expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 365);
            this.encryptedCookieStorageService.save(
                Contract.contactInfo,
                response.ObjectValue[4]?.Link,
                expirationDate,
                '.forzadelivery.com'
            );
        } catch (error) {
            console.error(error);
        }
    }

    handleRouteEvents() {
        let route: string = '';
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                route = event.url.split('/')[1];
                if (route == 'inicio') {
                    // this.showButtonForTutorial = true;
                    this.showTutorial();
                } else {
                    this.showButtonForTutorial = false;
                }
            }
        });
        route = this.router.url.split('/')[1];
        if (route == 'inicio') {
            // this.showButtonForTutorial = true;
            this.showTutorial();
        } else {
            this.showButtonForTutorial = false;
        }
    }

    showTutorial() {
        let showTutorial = this.encryptedCookieStorageService.get(
            Contract.showTutorial
        );
        if (!showTutorial) {
            // this.modalTutorial?.Open();
        }
    }

    cancelTutorial() {
        this.modalTutorial.Close();
        this.OnBoard.setTutorial(this.showModal);
    }

    public stepsArrayPortalSideBar = stepsArrayPortalSideBar;
    public stepsArrayPortalHeaderBar = stepsArrayPortalHeaderBar;

    toggleCollapsedSideabar() {
        if (!this.settings.getLayoutSetting('asideToggled')) {
            this.settings.toggleLayoutSetting('asideToggled');
        }
    }

    showLightbox(item) {
        this.cancelTutorial();
        this.currentIndex = item;
        this.showFlag = true;
        $('#viewImage').insertAfter($('body'));
    }

    closeEventHandler() {
        this.showFlag = false;
        this.currentIndex = -1;
    }

    clickIconOldPassword() {
        if (this.inputs.oldPassword.type == 'password') {
            this.inputs.oldPassword.type = 'text';
            this.inputs.oldPassword.icon = 'bi bi-eye-slash-fill';
        } else {
            this.inputs.oldPassword.type = 'password';
            this.inputs.oldPassword.icon = 'bi bi-eye-fill';
        }
    }

    clickIconPassword() {
        if (this.inputs.password.type == 'password') {
            this.inputs.password.type = 'text';
            this.inputs.password.icon = 'bi bi-eye-slash-fill';
        } else {
            this.inputs.password.type = 'password';
            this.inputs.password.icon = 'bi bi-eye-fill';
        }
    }

    clickIconConfirmPassword() {
        if (this.inputs.confirmPassword.type == 'password') {
            this.inputs.confirmPassword.type = 'text';
            this.inputs.confirmPassword.icon = 'bi bi-eye-slash-fill';
        } else {
            this.inputs.confirmPassword.type = 'password';
            this.inputs.confirmPassword.icon = 'bi bi-eye-fill';
        }
    }

    addFormChangePassword(name: string, form: FormGroup) {
        this.formChangePassword.removeControl(name);
        this.formChangePassword.addControl(name, form);
    }

    public get getOldPassword() {
        return this.formChangePassword?.get('oldPassword')?.get('input');
    }
    public get getPassword() {
        return this.formChangePassword?.get('password')?.get('input');
    }
    public get getConfirmPassword() {
        return this.formChangePassword?.get('confirmPassword')?.get('input');
    }

    validateSamePassword() {
        if (this.getConfirmPassword.value != this.getPassword.value) {
            return { validateSamePassword: true };
        }
        return null;
    }

    async changePassword() {
        let userString = await this.secureStorage
            .get(Contract.user)
            .toPromise();
        let user = JSON.parse(userString);
        let body = {
            path: 'Login/SetNewPassword',
            data: {
                Method: 'SetNewPassword',
                Params: {
                    IdAccount: user.ObjectValue[0].Accounts[0].IdAccount,
                    SessionToken: user.ObjectValue[0].Token,
                    OldPassword: this.getOldPassword?.value,
                    Password: this.getConfirmPassword?.value,
                },
            },
        };
        try {
            const response = await this.api.postData(body).toPromise();
            if (response.ObjectValue[0].IdResult == 200) {
                this.sweet.noEventCriticalAlert(
                    '¡Contraseña modificada exitosamente!',
                    '',
                    'success'
                );
                user.ObjectValue[0].Accounts[0].ChangePassword = 0;
                this.secureStorage
                    .save(Contract.user, JSON.stringify(user))
                    .subscribe((result) => {
                        location.reload();
                    });
                this.modalChangePassword.Close();
            } else {
                this.sweet.noEventCriticalAlert(
                    '¡Error!',
                    response.ObjectValue[0].Message,
                    'error'
                );
            }
        } catch (error) {
            this.sweet.errorCriticalAlert(
                'Error al obtener su información.',
                error.Message ? error.Message : error
            );
        }
    }

    recheckValidations() {
        this.getConfirmPassword.updateValueAndValidity();
    }

    onResize() {
        setTimeout(() => {
            let elementHeader = document.getElementById('headerComponent');
            this.heightForSideBar = `margin-top: ${elementHeader.clientHeight}px`;
        }, 100);
    }

    initTutorial(timeOut: number = 100, closeModal: boolean = false) {
        if (closeModal) {
            let dateExpiration = new Date();
            dateExpiration.setDate(dateExpiration.getDate() + 365);
            this.encryptedCookieStorageService.save(
                Contract.showTutorial,
                'true',
                dateExpiration,
                '.forzadelivery.com'
            );
            this.modalTutorial?.Close();
        }
        setTimeout(() => {
            this.communicationWithObservable.sendComponentForTutorial('1');
        }, timeOut);
    }
}
