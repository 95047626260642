<div class="mb-4">
    <div class="position-relative">
        <div
            class="d-flex justify-content-between"
            *ngIf="requiredSymbol || subtleAnnotation">
            <label
                position="stacked"
                class="font-size-h6 font-weight-bolder text-dark"
                [ngClass]="{ 'text-muted': isLoading }"
                >{{ textLabel }}
                <span
                    *ngIf="requiredSymbol"
                    title="Campo requerido"
                    class="text-danger font-size-md"
                    >{{ requiredSymbol }}</span
                >
                <span
                    *ngIf="subtleAnnotation"
                    class="text-muted font-size-sm font-weight-normal"
                    >&nbsp;{{ subtleAnnotation }}</span
                >
            </label>
        </div>
        <div class="inner-addon" [ngClass]="{ withSpinnerLoading: isLoading }">
            <em
                class="fa-1x fas fa-caret-down align-self-center ml-auto icon right-addon"></em>
            <input
                style="cursor: pointer"
                (click)="mostrarModal()"
                (focus)="mostrarModal()"
                #selectInput
                class="form-control input-select form-control-solid h-auto rounded-lg"
                [type]="type"
                [placeholder]="placeholder"
                [value]="selectedText"
                [disabled]="listSelect.length === 0 || disabled || isLoading"
                [readonly]="listSelect.length === 0"
                [ngClass]="{
                    requiredTrue: control && control.valid,
                    requiredFalse:
                        control &&
                        control.invalid &&
                        (isLoading || listSelect.length == 0),
                    requiredFalse2:
                        !(control && control.valid && listSelect.length != 0) &&
                        subtleAnnotation != 'Opcional',
                    'hidden-text-input': isLoading || listSelect.length == 0
                }" />
        </div>
    </div>
    <!--div *ngIf="control && control.invalid && (control.dirty || control.touched)" class="input-error-section">
      <p *ngIf="control.errors.required" [ngClass]="{'error-center': style==1, 'error':style == 2}">
        El campo {{textLabel.toLowerCase()}} es obligatorio.
      </p>
    </div-->
</div>

<app-modal #modalselect [idModal]="this.modalid" size="md" title="{{ title }}">
    <div class="input-group mb-3">
        <div class="input-group-prepend">
            <span class="input-group-text no-border-radius"
                ><i class="fa fa-search"></i
            ></span>
        </div>
        <input
            #searchInput
            autocomplete="off"
            (keyup.enter)="onEnterSearch()"
            [(ngModel)]="searchText"
            type="text"
            class="form-control no-border-radius"
            placeholder="Búsqueda" />
    </div>
    <div class="list-group-content">
        <div class="contentlist">
            <ul class="list-group" draggable="false" style="cursor: pointer">
                <li
                    #itemsOption
                    data-dismiss="modal"
                    (click)="this.selectedItem(option)"
                    *ngFor="let option of listSelect | filter: searchText"
                    class="list-group-item"
                    draggable="true"
                    style="cursor: pointer">
                    {{ option.text }}
                </li>
            </ul>
        </div>
    </div>
</app-modal>
