<div class="card">
    <div
        class="row d-flex justify-content-center mt-4 mb-4"
        *ngIf="!showButtonFinally">
        <div class="col-auto">
            <!-- <label class="text-h2 text-dark">
                {{ title }}
            </label> -->
            <!-- <label class="text-h3 text-damn rk" *ngIf="hideData && showFinish < 2">
                Procesando...
            </label>
            <label
                class="text-h3 text-dark"
                *ngIf="hideData && showFinish >= 2">
            </label> -->
        </div>
    </div>
    <div
        class="hwa-fdworking hwa-spinner text-center"
        *ngIf="hideData && showFinish < 2">
        <img
            src="assets/images/fd-icon.png"
            style="height: 75px; width: 75px"
            class="hwa-icon-loader" />
    </div>
    <ng-container *ngIf="!hideData" class="border-box-guide">
        <div
            class="row d-flex justify-content-between"
            *ngFor="let item of data">
            <ng-container *ngIf="item.text && item.value">
                <div class="col-6">
                    <label class="text-h4 pl-2 text-dark">
                        {{ item.text }}
                    </label>
                </div>
                <div class="col-6 text-right">
                    <label class="text-h4 pr-2">
                        {{ item.value }}
                    </label>
                </div>
            </ng-container>
            <div *ngIf="item.value === ''" class="col-12 text-center">
                <label class="text-h2 pl-2 text-dark">{{ item.text }}</label>
            </div>
            <ng-container *ngIf="item.text === ''">
                <!-- <div class="col-auto mr-0 pr-0 pl-2" style="top: 3px"></div> -->
                <div
                    class="col-12 pl-4 text-left d-flex align-items-center mb-2">
                    <em
                        class="fa fa-check-circle text-primary d-flex align-items-center"></em
                    >&nbsp;&nbsp;
                    <label class="text-h4 pr-2 mb-0"
                        ><span [innerHTML]="item.value"></span
                    ></label>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <div class="row d-flex justify-content-center">
        <div class="col-12">
            <div #divRequestFAC [hidden]="showFinish < 2"></div>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col-12 tex-center pb-2">
            <div
                class="text-center"
                #message
                [hidden]="showButtonFinally"></div>
        </div>
    </div>
    <div class="row d-flex justify-content-center mt-4 mb-4" *ngIf="!hideData">
        <div class="col-auto">
            <button class="btn btn-warning" (click)="onClickConfirm()">
                Confirmar
            </button>
        </div>
    </div>
    <div
        class="row d-flex justify-content-center mt-4 mb-4"
        *ngIf="hideData && showFinish >= 2 && showButtonFinally">
        <div class="col-auto">
            <button class="btn btn-warning" (click)="onClickFinalizar()">
                Siguiente
            </button>
        </div>
    </div>
</div>
