import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class BannerService {
    public visibleBanner: boolean = false;
    public visibleBannerPickupIndividual: boolean = false;
    constructor() {}

    showBanner() {
        this.visibleBanner = true;
    }
    hideBanner() {
        this.visibleBanner = false;
    }
    isVisible() {
        return this.visibleBanner;
    }

    showBannerPickupIndividual() {
        this.visibleBannerPickupIndividual = true;
    }
    hideBannerPickupIndividual() {
        this.visibleBannerPickupIndividual = false;
    }
    isVisiblePickupIndividual() {
        return this.visibleBannerPickupIndividual;
    }
}
