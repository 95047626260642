<div class="container-fluid" [formGroup]="formBilling">
    <div class="row justify-content-end">
        <div class="col-auto">
            <div class="form-check form-switch pt-2">
                <label class="switch m-0 text-h3">
                    <input type="checkbox" formControlName="isDefault" />
                    <span></span>
                    <label class="ml-1 mt-2"> Favorita </label>
                </label>
            </div>
        </div>
        <div class="col-12">
            <app-input
                title="NIT"
                [required]="true"
                [maxLength]="13"
                [minLength]="1"
                [validators]="validatorsCustom.taxId"
                (formReady)="addForm('nit', $event)"></app-input>
        </div>
        <div class="col-12">
            <app-input
                title="Nombre"
                [required]="true"
                [maxLength]="50"
                [minLength]="2"
                (formReady)="addForm('name', $event)"></app-input>
        </div>
        <div class="col-12">
            <app-input
                title="Dirección"
                [required]="true"
                [maxLength]="200"
                [minLength]="2"
                (formReady)="addForm('direction', $event)"></app-input>
        </div>
        <div class="col-12">
            <button
                class="btn btn-warning w-100"
                [disabled]="disabledButton()"
                (click)="onClickSave()">
                Guardar
            </button>
        </div>
    </div>
</div>
