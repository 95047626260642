import { MapsAPILoader } from '@agm/core';
import {
    Component,
    OnInit,
    Input,
    NgZone,
    forwardRef,
    ViewChild,
    Output,
    EventEmitter,
} from '@angular/core';
import { MapAddressPredictionService } from './map-address-prediction.service';
import {
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
declare var google: any;
@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MapComponent,
            multi: true,
        },
    ],
})
export class MapComponent implements OnInit, ControlValueAccessor {
    @Input() labelMap: string = 'Opciones';
    @Input() placeholder: string = '';
    @Input() control: any;
    @Input() markerList: any[] = [];
    @Input() onlyOnMoreMarker: boolean = false;
    @Input() onlyListEntre: boolean = false;
    //Google Maps Parameters
    @Input() multipleMarkers: boolean = false;
    @Input() requiredSymbol: string;
    @Input() subtleAnnotation: string;
    // zoom: number = 0;
    @Input() zoom: number = 0;
    @Input() showInputSuggest: boolean = false;
    myvalueSuggest = '';
    @Input() autocompleteAddressItems: any[] = [];
    @ViewChild('InputSuggestElement') InputSuggestElement;
    @Input() scrollwheel: boolean = true;
    @Input() zoomControl: boolean = true;
    @Input() fullscreenControl: boolean = false;
    @Input() disableDefaultUI = true;
    @Input('mapDraggable') draggable = false;
    @Input() streetViewControl: boolean = false;
    @Input() height: number = 400;
    @Output() onClickMap: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClickMarker: EventEmitter<any> = new EventEmitter<any>();
    addFirst: boolean = false;
    //--------------------
    countryRestriction = {
        latLngBounds: {
            north: 18.915697,
            south: 12.0121,
            west: -92.988,
            east: -88.19820009999999,
        },
        strictBounds: true,
    };
    // AUCKLAND = { lat: 19.783471, lng: -90.23075899999999 };
    AUCKLAND = { lat: 14.64072, lng: -90.51327 };
    constructor(
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private fb: FormBuilder,
        private MapAddressPredictionService: MapAddressPredictionService
    ) {}
    writeValue(obj: any): void {
        if (obj && obj.lat && obj.lng) {
            this.AUCKLAND = {
                lat: parseFloat(obj.lat),
                lng: parseFloat(obj.lng),
            };
            this.addMark(obj.lat, obj.lng);
        }
        if (obj?.zoom) {
            this.zoom = obj.zoom;
        } else {
            this.zoom = 2;
        }
    }
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}
    onChange = (_: any) => {};
    ngOnInit(): void {}
    requiredAddress() {}
    setAddress(value: any) {}
    mapClick($event) {
        this.onClickMap.emit($event);
        if (!this.onlyListEntre) {
            this.addMark($event.coords.lat, $event.coords.lng);
        }
    }
    addMark(lat, lng) {
        if (!this.multipleMarkers) this.markerList = [];
        let value = {
            lat: lat,
            lng: lng,
            icon: '',
        };
        if (
            this.addFirst &&
            this.markerList.length >= 1 &&
            this.onlyOnMoreMarker
        ) {
            this.markerList.pop();
        }
        this.markerList.push(value);
        this.onChange(value);
        this.addFirst = true;
        this.zoom = 16;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    setMarcerByPlaceId(placeId) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ placeId: placeId }).then(({ results }) => {
            let location = results[0].geometry.location;
            this.zoom = 8;
            this.AUCKLAND.lat = location.lat();
            this.AUCKLAND.lng = location.lng();
            this.addMark(location.lat(), location.lng());
        });
    }
    resetListSuggest() {
        this.markerList = [];
        this.onChange({ lat: null, lng: null });
    }
    dragEndMarker(item, index) {
        let value = {
            lat: item.coords.lat,
            lng: item.coords.lng,
        };
        this.markerList[index].lat = item.coords.lat;
        this.markerList[index].lng = item.coords.lng;
        this.onChange(value);
    }
    selectedSuggestItem(item) {
        this.autocompleteAddressItems = [];
        this.InputSuggestElement.nativeElement.value = item.description;
        if (item.value !== -1) {
            //this.addr.patchValue({
            //    address1: $event.description,
            //});
            this.setMarcerByPlaceId(item.value);
        } else {
            this.resetListSuggest();
        }
    }
    async UpdateSearchResults() {
        let textToSearch = this.InputSuggestElement.nativeElement.value;

        if (textToSearch.length <= 0) {
            this.autocompleteAddressItems = [];
            return;
        }
        try {
            let resultSuggest =
                await this.MapAddressPredictionService.getSuggestAddress(
                    textToSearch
                );
            this.autocompleteAddressItems = [];
            resultSuggest.predictions.forEach((element) => {
                this.autocompleteAddressItems.push({
                    description: element.description,
                    value: element.place_id,
                    icon: 'fa fa-map-marker px-2',
                });
            });
            this.autocompleteAddressItems.push({
                description: 'Ubicar en el mapa',
                value: -1,
                icon: 'fa icon-map px-2',
            });
        } catch (e) {
            this.autocompleteAddressItems = [];
            return;
        }
    }

    markerClicked(event, item) {
        this.onClickMarker.emit({ event: event, item: item });
    }

    resetSearchInput() {
        this.InputSuggestElement.nativeElement.value = '';
    }
}
