import { EncryptedCookieStorageService } from 'src/utility/services/marketplace/encrypted-cookie-storage.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Contract } from 'src/utility/Storage/contract';
import { SecureSimpleStorage } from 'src/utility/Storage/secure-simple-storage';
import { LogoutAPIService } from '../logoutAPI.service';
import { OverlayRef, ToastrService } from 'ngx-toastr';
import { SweetAlert } from 'src/utility/sweetAlert';
import { ConnectionApiService } from '../connection-api.service';

@Injectable({
    providedIn: 'root',
})
export class AuthUserService {
    private user: any = {};
    private userInfo: any = {};
    data: any;
    constructor(
        private secureStorage: SecureSimpleStorage,
        private out: LogoutAPIService,
        public router: Router,
        private sweet: SweetAlert,
        private toastr: ToastrService,
        private encryptedCookieStorageService: EncryptedCookieStorageService,
        private api: ConnectionApiService
    ) {}

    setUserData(userData: any) {
        if (userData && userData['ObjectValue'][0]['Profile'][0]) {
            this.user = userData;
            this.userInfo = userData['ObjectValue'][0]['Profile'][0];
            this.userInfo['UserName'] = this.user['Params']['UserName'];
        }
    }

    getUserData() {
        return this.userInfo;
    }

    getUser() {
        return this.user;
    }
    verifyData() {
        this.secureStorage.get(Contract.user).subscribe(
            (data) => {
                this.data = JSON.parse(data);
            },
            (error) => {}
        );
    }
    logout() {
        let saveUser: boolean = false;
        let userTelemercadeo: string = null;
        let userIndividual: string = null;
        if (environment.rol == 'Ventas telemercadeo') {
            this.secureStorage.get(Contract.userTelemercadeo).subscribe(
                (data) => {
                    userTelemercadeo = JSON.parse(data);
                    saveUser = true;
                },
                (error) => {
                    saveUser = false;
                }
            );
        } else if (
            environment.rol == 'Individual' ||
            environment.rol == 'Nuevo estandar'
        ) {
            this.secureStorage.get(Contract.userIndividual).subscribe(
                (data) => {
                    userIndividual = JSON.parse(data);
                    saveUser = true;
                },
                (error) => {
                    saveUser = false;
                }
            );
        }
        //Limpieza de cookie de login
        this.encryptedCookieStorageService.remove(
            Contract.userCredential,
            '.forzadelivery.com'
        );
        this.encryptedCookieStorageService.remove(
            Contract.passwordCredential,
            '.forzadelivery.com'
        );
        this.encryptedCookieStorageService.remove(
            Contract.countryCredential,
            '.forzadelivery.com'
        );

        this.clearLocalStorage();
        if (saveUser) {
            if (environment.rol == 'Ventas telemercadeo') {
                this.saveUser(userTelemercadeo);
            } else if (
                environment.rol == 'Individual' ||
                environment.rol == 'Nuevo estandar'
            ) {
                this.saveUser(userIndividual);
            }
        }
        window.location.reload();
        return this.secureStorage.remove([Contract.fact]);
    }
    setLogout = (message?: string, method?: string) => {
        this.verifyData();
        // this.out.loader();
        let body = {
            path: 'Login/SetLogout',
            data: {
                Method: 'SetLogout',
                Params: {
                    Token: this.data.ObjectValue[0].Token,
                },
            },
        };
        this.out.postData(body).subscribe(
            (response: any) => {
                this.logout();
                // this.out.loaderSwall.close();
                // this.out.loaderSwall = null;
            },
            (error) => {
                // this.out.loaderSwall.close();
                // this.out.loaderSwall = null;
                if (!method) {
                    this.sweet
                        .warningAlert(
                            'Ocurrió un error al cerrar sesión, intente de nuevo',
                            '¿ Desea extender su sesión ?',
                            60000
                        )
                        .then((result) => {
                            if (result.isConfirmed) {
                                //this.refreshExpirationLogin();
                            } else if (result.isDismissed) {
                                this.setLogout();
                            }
                        });
                } else if (method === 'GetDynamicCatalog') {
                    this.setLogout(message, method);
                }
            }
        );
    };
    updateVersion(path?: string) {
        let body = {
            path: 'Portal/GetConfigParams',
            data: {
                Method: 'GetConfigParams',
                Params: {
                    Name: 'TiendaVirtualVersion',
                },
            },
        };

        this.out.postData(body).subscribe(
            (response: any) => {
                const versionBD = response.ObjectValue[0].Value; // Version registrada en base de datos
                this.secureStorage
                    .save(Contract.versionPortal, versionBD)
                    .subscribe();

                if (versionBD !== environment.version) {
                    if (environment.test !== 'local') {
                        this.showToastReload(path);
                    }
                }
            },
            (error) => {}
        );
    }

    async showToastReload(path?: string) {
        let message =
            'Se ha detectado una nueva versión, se procederá a actualizar Tienda Virtual';

        this.toastr
            .show(message, 'ADVERTENCIA', {
                timeOut: 5000,
                closeButton: true,
                positionClass: 'toast-top-center',
            })
            .onHidden.subscribe(async () => {
                try {
                    let usertype = null;
                    this.secureStorage.get(Contract.user).subscribe((data) => {
                        const user = JSON.parse(data);
                        usertype = user.ObjectValue[0].Accounts[0].TacName;
                    });

                    if (usertype) {
                        //Limpieza de cookie de login
                        this.encryptedCookieStorageService.remove(
                            Contract.userCredential,
                            '.forzadelivery.com'
                        );
                        this.encryptedCookieStorageService.remove(
                            Contract.passwordCredential,
                            '.forzadelivery.com'
                        );
                        this.encryptedCookieStorageService.remove(
                            Contract.countryCredential,
                            '.forzadelivery.com'
                        );
                        this.clearLocalStorage();
                        if (environment.rol == 'Ventas telemercadeo') {
                            this.router.navigate(['/inicio-sesion-interno']);
                            setTimeout(() => {
                                this.cleanCache();
                            }, 500);
                        } else {
                            this.cleanCache();
                        }
                    } else {
                        //Limpieza de cookie de login
                        this.encryptedCookieStorageService.remove(
                            Contract.userCredential,
                            '.forzadelivery.com'
                        );
                        this.encryptedCookieStorageService.remove(
                            Contract.passwordCredential,
                            '.forzadelivery.com'
                        );
                        this.encryptedCookieStorageService.remove(
                            Contract.countryCredential,
                            '.forzadelivery.com'
                        );
                        this.clearLocalStorage();
                        this.cleanCache();
                    }
                } catch (error) {
                    //Limpieza de cookie de login
                    this.encryptedCookieStorageService.remove(
                        Contract.userCredential,
                        '.forzadelivery.com'
                    );
                    this.encryptedCookieStorageService.remove(
                        Contract.passwordCredential,
                        '.forzadelivery.com'
                    );
                    this.encryptedCookieStorageService.remove(
                        Contract.countryCredential,
                        '.forzadelivery.com'
                    );
                    this.clearLocalStorage();
                    this.cleanCache();
                }
            });
    }

    async cleanCache() {
        try {
            for (let i = 0; i < 20; i++) {
                this.api.loader();
                caches.keys().then((names) => {
                    names.forEach(async (name) => {
                        await caches.delete(name);
                    });
                    if (i == 19) {
                        setTimeout(async () => {
                            window.location.reload();
                        }, 1000);
                    }
                });
            }
        } catch (error) {
            window.location.reload();
        }
    }

    async getImages() {
        let body = {
            path: 'Portal/GetConfigParams',
            data: {
                Method: 'GetConfigParams',
                Params: {
                    Name: 'Images',
                },
            },
        };
        return this.out.postData(body).toPromise();
    }

    clearLocalStorage() {
        this.secureStorage.remove([Contract.user]).subscribe();
        this.secureStorage.remove([Contract.numberGuide]).subscribe();
        this.secureStorage.remove([Contract.routeActive]).subscribe();
        this.secureStorage.remove([Contract.localCart]).subscribe();
    }

    saveUser(user) {
        if (environment.rol == 'Ventas telemercadeo') {
            this.secureStorage
                .save(Contract.userTelemercadeo, JSON.stringify(user))
                .subscribe();
        } else if (
            environment.rol == 'Individual' ||
            environment.rol == 'Nuevo estandar'
        ) {
            this.secureStorage
                .save(Contract.userIndividual, JSON.stringify(user))
                .subscribe();
        }
    }
}
