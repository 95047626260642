import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { emailRegexValidators } from 'src/utility/functions/regex.service';
import { ConnectionApiService } from 'src/utility/services/connection-api.service';
import { SweetAlert } from 'src/utility/sweetAlert';

@Component({
    selector: 'app-ask-for-help',
    templateUrl: './ask-for-help.component.html',
    styleUrls: ['./ask-for-help.component.scss'],
})
export class AskForHelpComponent implements OnInit {
    formHelp: FormGroup;
    @Output() clickSendSuccess: EventEmitter<any> = new EventEmitter<any>();
    @Output() clickSendCancel: EventEmitter<any> = new EventEmitter<any>();

    guide: any;

    validatorsCustom = {
        phone: [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern('(5|2|3|4|6|7)[0-9]{7}'),
        ],
        name: [Validators.required, Validators.minLength(3)],
        email: [Validators.required, Validators.pattern(emailRegexValidators)],
    };

    constructor(private api: ConnectionApiService, private sweet: SweetAlert) {
        this.formHelp = new FormGroup({
            comment: new FormControl('', [
                Validators.required,
                Validators.minLength(5),
            ]),
        });
    }

    ngOnInit(): void {}

    sendCancel() {
        this.formHelp.reset();
        this.clickSendCancel.emit();
    }

    async sendNotification() {
        this.getComment.setValue(this.getComment.value.trim());
        if (this.getComment?.value?.length <= 0 && !this.getComment.value) {
            return;
        }

        let body = {
            path: 'Portal/RequestSACHelp',
            data: {
                Method: 'RequestSACHelp',
                Params: {
                    Phone: this.getPhone.value,
                    Email: this.getEmail.value,
                    Name: this.getName.value,
                    Comment:
                        this.guide != ''
                            ? this.guide + ':' + this.getComment.value
                            : this.getComment.value,
                },
            },
        };

        try {
            let response = await this.api
                .postData(
                    body,
                    true,
                    'Estamos contactando al personal de Forza'
                )
                .toPromise();
            if (response.StatusCode !== 200) {
                this.sweet.errorInfoAlert(
                    'Envió de correo',
                    'Ocurrió un error al enviar el correo',
                    'error',
                    null
                );
                return;
            }

            this.sweet.errorInfoAlert(
                'Envió de correo',
                'Correo se envió con éxito',
                'success',
                null
            );
            this.formHelp.reset();
            this.clickSendSuccess.emit(200);
            return;
        } catch (error) {
            this.sweet.errorInfoAlert(
                'Envió de correo',
                'Ocurrió un error al enviar el correo',
                'error',
                null
            );
            this.clickSendSuccess.emit(0);
            return;
        }
        this.clickSendSuccess.emit(0);
    }

    addForm(name: string, form: FormGroup) {
        this.formHelp.removeControl(name);
        this.formHelp.addControl(name, form);
    }

    disabledButton() {
        return (
            this.getPhone.invalid ||
            this.getName.invalid ||
            this.getEmail.invalid ||
            this.getComment.invalid
        );
    }

    async sendDing() {
        const serie = this.guide.toString().substring(0, 2);
        const number = this.guide.toString().substring(2, this.guide.length);

        const body = {
            path: 'Portal/SendMessageGuideETPToCourier',
            data: {
                Method: 'SendMessageGuideETPToCourier',
                Params: {
                    GuideSerie: serie,
                    GuideNumber: number,
                },
            },
        };

        try {
            const response = await this.api.postData(body, false).toPromise();

            // if (response.StatusCode != 200) {
            //     this.sweet.errorCriticalAlert(
            //         'Error al enviar mensaje',
            //         'Ha ocurrido un error al realizar el proceso, intente de nuevo y si persiste comuníquese con soporte al cliente.'
            //     );
            //     return;
            // } else if (
            //     response.StatusCode == 200 &&
            //     response.ObjectValue == true
            // ) {
            //     this.sweet.successCriticalAlert(
            //         'Mensaje enviado correctamente',
            //         'El mensaje ha sido enviado correctamente'
            //     );
            // }
        } catch (error) {
            // this.sweet.errorCriticalAlert(
            //     'Error al enviar mensaje',
            //     'Ha ocurrido un error al realizar el proceso, intente de nuevo y si persiste comuníquese con soporte al cliente.'
            // );
        }
    }

    public get getPhone() {
        return this.formHelp.get('phone').get('input');
    }
    public get getName() {
        return this.formHelp.get('name').get('input');
    }
    public get getEmail() {
        return this.formHelp.get('email').get('input');
    }
    public get getComment() {
        return this.formHelp.get('comment');
    }
}
