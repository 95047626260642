<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <app-input
                title="Primer nombre:"
                placeholder="Ingrese primer nombre"
                [maxLength]="200"
                [required]="true"
                (formReady)="addForm('firstName', $event)"></app-input>
        </div>
        <div class="col-12">
            <app-input
                title="Segundo nombre:"
                placeholder="Ingrese segundo nombre"
                [maxLength]="200"
                (formReady)="addForm('secondName', $event)"></app-input>
        </div>
        <div class="col-12">
            <app-input
                title="Primer apellido:"
                placeholder="Ingrese primer apellido"
                [maxLength]="200"
                [required]="true"
                (formReady)="addForm('firstLastname', $event)"></app-input>
        </div>
        <div class="col-12">
            <app-input
                title="Segundo apellido:"
                placeholder="Ingrese segundo apellido"
                [maxLength]="200"
                (formReady)="addForm('secondLastname', $event)"></app-input>
        </div>
        <div class="col-12">
            <app-input
                title="Primer teléfono:"
                placeholder="Ingrese teléfono"
                [maxLength]="8"
                [minLength]="8"
                [required]="true"
                [regex]="regexNumber"
                (formReady)="addForm('phone1', $event)"></app-input>
        </div>
        <div class="col-12">
            <app-input
                title="Segundo teléfono:"
                placeholder="Ingrese teléfono"
                [maxLength]="8"
                [minLength]="8"
                [regex]="regexNumber"
                (formReady)="addForm('phone2', $event)"></app-input>
        </div>
        <div class="col-12">
            <app-input
                title="Email:"
                placeholder="Ingrese correo electrónico"
                [maxLength]="200"
                [required]="false"
                [validators]="validatorsCustom.email"
                (formReady)="addForm('email', $event)"></app-input>
        </div>
        <div class="col-12 mt-1">
            <div class="row justify-content-around">
                <div class="col-4">
                    <button
                        class="btn btn-danger btn-lg w-100 active"
                        (click)="cancel()">
                        Cancelar
                    </button>
                </div>
                <div class="col-4">
                    <button
                        [disabled]="this.formClient.invalid"
                        class="btn btn-primary btn-lg w-100 active"
                        (click)="save()">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
