<div class="form-group col-xs-6">
    <div class="d-flex justify-content-between">
        <label
            *ngIf="showTextLabel"
            position="stacked"
            class="font-size-h6 font-weight-bolder text-dark"
            >{{ textLabel }}
            <span
                *ngIf="requiredSymbol"
                title="Campo requerido"
                class="text-danger font-size-md"
                >{{ requiredSymbol }}</span
            >
            <span
                *ngIf="subtleAnnotation"
                class="text-muted font-size-sm font-weight-normal"
                >&nbsp;{{ subtleAnnotation }}</span
            >
        </label>

        <a
            *ngIf="alternativeActionRoute && alternativeActionLabel"
            class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5">
            {{ alternativeActionLabel }}
        </a>
    </div>
    <p class="mb-2" *ngIf="subtitle">{{ subtitle }}</p>
    <div
        *ngIf="showRecoveryPassword"
        (click)="recoveryPassword()"
        class="w-100 text-right text-recovery-password">
        Recuperar contraseña
    </div>
    <div
        [attr.data-tip]="datatitle"
        [ngClass]="{
            'inner-addon': icono_left || icono_right || PasswordIcon
        }">
        <span class="icon left-addon  {{ icono_left }}"></span>
        <span class="icon right-addon {{ icono_right }}"></span>
        <span
            *ngIf="IsPassword"
            (click)="show_password()"
            class="icon right-addon h3 p-0 m-0 pt-2 mr-2 {{
                PasswordIcon
            }}"></span>
        <div class="principal-container-prefix-phone" *ngIf="isPhone">
            <div class="sub-container">
                <div class="dropdown">
                    <button
                        class="dropdown-toggle d-flex align-items-center justify-content-start px-2"
                        type="button"
                        [disabled]="disabled"
                        data-toggle="dropdown"
                        aria-expanded="false">
                        <ng-container *ngIf="countrySelect">
                            <em
                                [class]="countrySelect.icon"
                                style="width: 21px !important"></em
                            ><span class="ml-2">{{
                                countrySelect?.prefixPhone
                            }}</span>
                        </ng-container>
                        <div
                            class="container-icon-arrow h-100 d-flex align-items-center justify-content-center">
                            <i class="bi bi-caret-down-fill"></i>
                        </div>
                    </button>
                    <div
                        class="dropdown-menu w-100"
                        *ngIf="countries.length > 0">
                        <ng-container *ngFor="let item of countries">
                            <div
                                class="w-100 dropdown-item cursor-pointer"
                                (click)="selectCountry(item)">
                                <div
                                    class="w-100 d-flex align-items-center justify-content-start px-2">
                                    <em
                                        [class]="item.icon"
                                        style="width: 21px !important"></em>
                                    <span
                                        class="d-flex align-items-center ml-3">
                                        {{ item.prefixPhone }}
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <input
            [ngClass]="{
                'border-default': true,
                borderInvalid:
                    control.invalid && (control.dirty || control.touched),
                requiredTrue: !(
                    control.invalid &&
                    (control.dirty || control.touched)
                ),
                requiredFalse:
                    control.invalid && (control.dirty || control.touched),
                'left-addon-input': icono_left,
                'right-addon-input': icono_right,
                'h-auto': style == 1,
                'input-Phone': isPhone
            }"
            [type]="type"
            min="0"
            [autocomplete]="autocomplete"
            [placeholder]="placeholder"
            [value]="myvalue"
            [maxlength]="maxLength"
            [minlength]="minLength"
            (keyup)="change($event)"
            [disabled]="disabled"
            [readonly]="readonly"
            [(ngModel)]="myvalue"
            class="form-control"
            placeholder="Username" />
        <!-- <button
            class="right-addon"
            type="button"
            *ngIf="IsPassword"
            (click)="show_password()"
            fill="clear"
            item-right>
            <i class="bi iconInput {{ PasswordIcon }} mr-2"></i>
        </button> -->
    </div>

    <div
        *ngIf="control && control.invalid && (control.dirty || control.touched)"
        [ngClass]="{ 'input-error-section ml-3': style == 1 || style == 2 }">
        <p
            *ngIf="control.errors.pattern && type == 'tel'"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            Número de teléfono inválido.
        </p>
        <p
            *ngIf="
                (control.errors.pattern && type == 'password') ||
                (control.errors.pattern &&
                    type == 'text' &&
                    textLabel == 'Contraseña')
            "
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            La contraseña debe tener como mínimo 8 caracteres, al menos un
            dígito, al menos una minúscula, al menos una mayúscula y al menos un
            caracter no alfanumérico (#?!@$%^&*-).
        </p>
        <p
            *ngIf="
                (control.errors.pattern &&
                    type != 'tel' &&
                    type != 'password') ||
                control.errors.invalidDomain
            "
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            El campo {{ textLabel.toLowerCase() }} es inválido.
        </p>
        <p
            *ngIf="control.errors.required || control.value === ''"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            {{
                textLabel
                    ? 'El campo ' + textLabel.toLowerCase() + ' es obligatorio.'
                    : 'Campo obligatorio'
            }}
            <!-- El campo {{ textLabel.toLowerCase() }} es obligatorio. -->
        </p>
        <p
            *ngIf="control.errors.minlength"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            El campo debe tener al menos {{ minLength }} caracteres.
        </p>
        <p
            *ngIf="control.errors.maxlength"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            El campo no debe tener más de {{ maxLength }} caracteres.
        </p>
        <p
            *ngIf="control.errors.matchTarjeta"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            Número de tarjeta ingresado no es válido.
        </p>
        <p
            *ngIf="control.errors.matchFechaTarjeta"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            Número de Fecha de vencimiento ingresado no es válido.
        </p>
        <p
            *ngIf="control.errors.matchCUI"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            El {{ textLabel.toLowerCase() }} ingresado no es válido.
        </p>
        <p
            *ngIf="control.errors.matchNit"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            El {{ textLabel.toLowerCase() }} ingresado no es válido.
        </p>
        <p
            *ngIf="control.errors.invalidWeight"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            El peso permitido de su paquete es de 1 a 4,409 lbs.
        </p>
        <p
            *ngIf="control.errors.invalidLength"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            El largo máximo permitido es de 200 cm.
        </p>
        <p
            *ngIf="control.errors.invalidWidth"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            El ancho máximo permitido es de 120 cm.
        </p>
        <p
            *ngIf="control.errors.invalidHeight"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            El alto máximo permitido es de 100 cm.
        </p>
        <p
            *ngIf="control.errors.validateAccount"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            El campo {{ textLabel.toLowerCase() }} es inválido.
        </p>
        <p
            *ngIf="
                control.errors.validAccounts &&
                !control.errors.maxlength &&
                !control.errors.minlength
            "
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            El campo {{ textLabel.toLowerCase() }} es inválido.
        </p>
        <p
            *ngIf="control.errors.passwordMismatch"
            [ngClass]="{ 'text-danger': style == 1 }">
            La confirmación de contraseña no coincide.
        </p>
        <p
            *ngIf="control.errors.invalidPackages && !control.errors.required"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            Número de paquetes válidos: 1 a 35.
        </p>
        <p
            *ngIf="control.errors.invalidPackages2 && !control.errors.required"
            [ngClass]="{ 'text-danger': style == 1 || style == 2 }">
            Número de paquetes válidos: 11 a 50.
        </p>
    </div>
    <div
        *ngIf="this.myvalue"
        class="contentlist"
        style="z-index: 2; position: absolute; width: 100%"
        class="pt-0">
        <ul class="list-group" draggable="false">
            <li
                (click)="this.selectedSuggestItem(option)"
                *ngFor="let option of autocompleteAddressItems"
                class="list-group-item"
                style="cursor: pointer">
                <em _ngcontent-asb-103="" class="fa {{ option.icon }}"></em>
                {{ option.description }}
            </li>
        </ul>
    </div>
</div>
